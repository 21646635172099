import React, { Component, useState, useEffect } from 'react'
import SyncLoader from "react-spinners/SyncLoader"
import { Redirect } from "react-router-dom"
import _, { set } from 'lodash'
import axios from 'axios'
import { NotificationManager } from 'react-notifications';
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import validator from 'validator'
import Select from 'react-select'
import { Button } from '@material-ui/core'

const override = `
	position: fixed;
	display: block;
	top: 45%;
	left: 50%;
	transform: translateX(-50%);
	transform: translate(-50%, -50%);
	z-index: 9999;
`

const Selectsearch = (props) => {
    const { setter, wareHouse } = props
    const [options, setOptions] = useState({})
    const [value, setValue] = useState('')
    useEffect(() => {
        axios.get('/api/viewwarehouse').then((response) => { 
            let temp = response.data.map((ele) => {
                return { value: ele, label: `Name:${ele.wn} Code: ${ele.wc}` }
            })
            setOptions(temp)
        }).catch((err) => {
            alert(err.message)
        }, [])
    }, [])
    // console.log('vlue', value)
    return (
        <>

            <Select
                options={options}
                onChange={(opt) => setter(opt.value)}
                isSearchable
            />

        </>
    )
}





export default class EditLogisticUser extends Component {
    constructor(props) {
        super(props)
        this.state = {
            redirect: false,
            type: [],
            name: "",
            email: "",
            password: "",
            oldPassword: "",
            wareHouse: "",
            wareHouseId: "",
            errors: {}
        }
    }

    componentDidMount() {
        axios.get(`/api/getSelectedLogisticUser/${localStorage.getItem("code")}`)
            .then(result => {
                // console.log(result.data.warehouse)
                this.setState({
                    name: result.data.logisticUser_id ? result.data.logisticUser_id : "",
                    wareHouse: result?.data?.warehouse,
                    wareHouseId: result?.data?.warehouseId,
                    email: result.data.email ? result.data.email : "", type: result.data.type ? result.data.type.split(",") : [],
                    oldPassword: result.data.password, loading: false
                })
            })
            .catch(err => {
                console.log(err)
                NotificationManager.error("Sorry, couldn't fetch the user properly")
            })
    }
    handleUpdateUSer = e => {
        e.preventDefault()
        let obj = {
            name: this.state.name,
            password: this.state.password,
            email: this.state.email,
            type: this.state.type.toString(),
            _id: localStorage.getItem("code"),
            oldPassword: this.state.oldPassword,
            wareHouse: this.state.wareHouse,
            warehouseId: this.state.wareHouseId
        }
        let validate = this.validateData(obj)
        console.log('obj', obj)
        if (validate.isValid) {
            this.setState({ loading: true })
            axios.post("/api/updateLogisticUser", obj)
                .then(result => {
                    if (result.data === 1) {
                        NotificationManager.success("Logistic User updated successfully")
                        this.setState({ loading: false, redirect: true })
                    } else {
                        NotificationManager.error("Failed to update user")
                    }
                })
                .catch(err => {
                    console.log(err)
                    NotificationManager.error("Failed to update user")
                })
        } else {
            this.setState({ errors: validate.errors })
        }

    }

    validateData(data) {
        let errors = {}

        if (_.isEmpty(data.name)) {
            errors.name = "Name should not be empty"
        }

        if (_.isEmpty(data.email)) {
            errors.email = "Email Address should not be empty"
        } else if (!validator.isEmail(data.email)) {
            errors.email = "Invalid Email Address"
        }

        return { errors: errors, isValid: _.isEmpty(errors) }
    }
    handleWareHouse = (value) => {
        this.setState({ wareHouse: '' + value.wc })
        this.setState({ wareHouseId: '' + value._id })
    }

    render() {

        if (this.state.redirect) {
            return (
                <Redirect to="/admin/logisticUsersList" />
            )
        } else {
            return (
                <div>
                    <br></br>
                    <SyncLoader css={override} size={15} margin={6} color={"#3E3E3E"} loading={this.state.loading} />
                    <div className="container-fluid">
                        <div className="card" style={{ padding: "1em" }}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Logistic User Id</label>
                                        <input type="text" className="form-control"
                                            placeholder="Logistic User Id"
                                            value={this.state.name}
                                            onChange={(e) => this.setState({ name: e.target.value })} />
                                    </div>
                                    { this.state.errors.name && <span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.name}</span>}
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Email Address</label>
                                        <input type="text" className="form-control"
                                            placeholder="Email Address"
                                            value={this.state.email}
                                            onChange={(e) => this.setState({ email: e.target.value, errors: {} })} />
                                    </div>
                                    {
                                        this.state.errors.email
                                        &&
                                        <span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.email}</span>
                                    }
                                </div>
                            </div> 
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Type</label>
                                        <TagsInput 
                                            value={this.state.type}
                                            onChange={type => this.setState({ type })}
                                            onlyUnique
                                            addKeys={[9, 13, 32, 188]}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input type="text" className="form-control"
                                            placeholder="Email Password"
                                            value={this.state.password}
                                            onChange={(e) => this.setState({ password: e.target.value, errors: {} })} />
                                    </div>
                                </div>
                            </div> 
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>search warehouse</label>
                                        <br />
                                        <Selectsearch setter={this.handleWareHouse} wareHouse={this.state.wareHouse} />
                                    </div>
                                </div>
                            </div> 
                            <div className="row">
                                <div className="col-md-3 ">
                                    <button className="btn btn-outline-primary" style={{ backgroundColor: 'rgb(255, 160, 0)', color: 'rgb(255, 255, 255)', fontWeight: '500',  width: "140px", height: "40px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 4, border:'0px', 'fontSize': '15px' }} onClick={this.handleUpdateUSer}>Update User</button>
                                </div> 
                            </div>
                        </div>
                    </div>

                </div>
            )
        }
    }
}