// import Dashboard from "./views/Dashboard.jsx";
// import AddOnProduct from "./views/AddProduct.jsx"
// import ProductList from "./views/ProductList.jsx"
// import CsvProduct from "./views/CsvProduct.jsx"
// import ShopifyProduct from "./views/ShopifyProduct.jsx"
//import ProductLayout from "./layouts/Products.jsx";
import SubscribeProduct from "./layouts/SubscribeProduct.jsx";
// import Orders from "./views/Orders.jsx"
// import AmazonMarketPlace from "./views/AmazonMarketPlace.jsx";
import MarketPlace from "./views/MarketPlace.jsx";
// import FulfilledOrders from "./views/FulfilledOrders.jsx"
import MerchantProfileSetting from "./views/MerchantProfileSetting.jsx";
import MerchantDashboard from "./views/MerchantDashboard.jsx";
import MerchantSupport from "./views/MerchantSupport.jsx";
// import MerchantStoreApi from "./views/MerchantStoreApi.jsx";

import CustomerList from "./views/MerchantStoreCust.jsx";
import OrderList from "./views/MerchantStoreOrder";
// import EditCustomer from "./views/MerchantCustEdit";
// import CustomerView from "./views/MerchantCustView";
import MerchantSubscribe from "./views/MerchantSubscribe";
import ShowOrder from "./views/MerchantOrderView";
import walletSummary from "./views/walletSummary";
// import addBalance from "./views/addBalance";
// import Success from "./views/success";
// import Fail from "./views/fail";
// import MerchantbulkUploadOrder from "./views/MerchantbulkUploadOrder";
import MerchantSettings from "./views/MerchantSettings.jsx";
import TrackOrder from "./views/TrackOrder.jsx"
import "./assets/css/pe-icon-7-stroke.css";
import "./assets/css/admin_style.css";
import jwt_decoder from "jwt-decode";
const jwt = require("jwt-simple");
const uuid = require("uuid");
const url = require("url");
const subdomain = "creationist";
const shared_key = "DvYGZ3Rj0hb2IMIhSnbPsoLMTR48JF1t3BXz2YpPc0ePrtZ9";
const token = localStorage.getItem("token");
let decoder = token ? jwt_decoder(token) : "";
const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "pe-7s-graph",
    component: MerchantDashboard,
    layout: "/merchant",
  },
  // {
  //   path: "/shopifyproduct",
  //   name: "Shopify Products",
  //   icon: "pe-7s-box2",
  //   component: ShopifyProduct,
  //   layout: "/merchant"
  // },
  {
    path: "/orders",
    name: "Orders",
    icon: "pe-7s-note",
    component: OrderList,
    layout: "/merchant",
    // subRoutes:[
    //     {
    //         path: "/orders",
    //         name: "Order List",
    //         icon: "pe-7s-note",
    //         component: OrderList,
    //         layout: "/merchant"
    //     },
    //     {
    //         path: "/order-bulk-update",
    //         name: "Bulk upload",
    //         icon: "pe-7s-note",
    //         component: MerchantbulkUploadOrder,
    //         layout: "/merchant"
    //     }
    // ]
  },

  {
    path: "/customers",
    name: "Customers",
    icon: "pe-7s-users",
    component: CustomerList,
    layout: "/merchant",
  },
  {
    path: "/marketplace",
    name: "Marketplace",
    icon: "pe-7s-bell",
    component: MarketPlace,
    layout: "/merchant",
    // subRoutes:[
    //   {
    //     path: "/marketplace",
    //     name: "Amazon",
    //     icon: "pe-7s-bell",
    //     component: AmazonMarketPlace,
    //     layout: "/merchant",
    //   },
    //   {
    //     path: "/shopifyApi",
    //     name: "Shopify",
    //     icon: "pe-7s-plus",
    //     component: MerchantStoreApi,
    //     layout: "/merchant"
    //   }
    // ]
  },
  {
    path: "/collection",
    name: "Products",
    icon: "pe-7s-keypad",
    component: SubscribeProduct,
    layout: "/merchant",
    // subRoutes:[
    //   {
    //     path: "https://ds.shopperr.in",
    //     name: "Store",
    //     icon: "pe-7s-keypad",
    //     component: SubscribeProduct,
    //     layout: "outerUrl"
    //   },
    //   {
    //     path: "/subscribeproduct",
    //     name: "Subscribed",
    //     icon: "pe-7s-bell",
    //     component: SubscribeProduct,
    //     layout: "/merchant"
    //   },
    //   {
    //     path: "/uploadSubscribeProducts",
    //     name: "Bulk Subscribe",
    //     icon: "pe-7s-plus",
    //     component: MerchantSubscribe,
    //     layout: "/merchant"
    //   },
    // ]
  },
  
  // {
  //   path: "/request-product",
  //   name: "Product Request Form",
  //   icon: "pe-7s-note2",
  //   component: RequestProduct,
  //   layout: "/merchant"
  // },
  {
    path: "/profile-setting",
    name: "Account Details",
    icon: "pe-7s-id",
    component: MerchantProfileSetting,
    layout: "/merchant",
  },
  {
    path: "/wallet-summary",
    name: "E-Wallet Balance",
    icon: "pe-7s-id",
    component: walletSummary,
    layout: "/merchant",
  },
  {
    path: "/settings",
    name: "Settings",
    icon: "fa fa-gear",
    component: MerchantSettings,
    layout: "/merchant",
  },
  // {
  //   path: "/track-order",
  //   name: "Track Orders",
  //   icon: "pe-7s-note",
  //   component: TrackOrder,
  //   layout: "/merchant",
  // },
  {
    // path: zendeskUrl(),
    path: "https://shopperr.zendesk.com/hc/en-us",
    name: "Support",
    icon: "pe-7s-headphones",
    component: MerchantSupport,
    layout: "outerUrl",
    id: "supportUrl",
  },
  {
    path: "/uploadSubscribeProducts",
    // name: "Bulk Subscribe",
    // icon: "pe-7s-plus",
    component: MerchantSubscribe,
    layout: "/merchant",
  },
  // {
  //   path: "/edit-customer/:id",
  //   component: EditCustomer,
  //   layout: "/merchant"
  // },
  // {
  //   path: "/customer/:id",
  //   component: CustomerView,
  //   layout: "/merchant"
  // },
  {
    path: "/order/:id",
    component: ShowOrder,
    layout: "/merchant"
  },
  // {
  //   path: "/add-balance",
  //   component: addBalance,
  //   layout: "/merchant"
  // },
  // {
  //   path: "/success",
  //   component: Success,
  //   layout: "/merchant"
  // },
  // {
  //   path: "/fail",
  //   component: Fail,
  //   layout: "/merchant"
  // }
];

function zendeskUrl() {
  // console.log(decoder)
  let payload = {
    iat: new Date().getTime() / 1000,
    jti: uuid.v4(),
    name: decoder.name,
    email: decoder.email,
  };
  var token = jwt.encode(payload, shared_key);
  var redirect =
    "https://" + subdomain + ".zendesk.com/access/jwt?jwt=" + token;
  var query = url.parse(window.location.href, true).query;
  if (query["return_to"]) {
    redirect += "&return_to=" + encodeURIComponent(query["return_to"]);
  }
  return redirect;
}
export default dashboardRoutes;
