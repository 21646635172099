import React, {useState, useEffect} from 'react';  
import axios from 'axios'
const Contact = () =>{
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
    const submitForm = (e) =>{
        e.preventDefault(); 
        if(validate()){
            let obj = {
                'email' : email
            }
            axios.post('/test/contact', obj).then((response) => {
                console.log(response.data);
            }) 
        } 
    }
    const validate = () => {
        let errors = {};
        let isValid = true; 
        if (!email) {
            isValid = false;
            errors["email"] = "Please enter your email Address."; 
        } 
        if (email) { 
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(email)) {
                isValid = false;
                errors["email"] = "Please enter valid email address.";
            }
        }  
        setErrors(errors) 
        return isValid;
    } 
    return (
        <div>
            <form  onSubmit = { submitForm } >
                <div className="form-group ">
                    <label for="exampleInputEmail1">Email address</label>
                    <input
                        className="form-control"
                        type='email' 
                        placeholder='Enter Email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)} 
                    />
                    <div className="text-danger">{errors.email}</div>
                    <br/> 
                    <button type="submit" style={{'width' : '100px'}} className="btn ">Submit</button>
                </div>  
            </form>
        </div>
    )
}
export default Contact;
