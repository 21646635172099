import React, { useState, useEffect, Component } from 'react';
import { Grid, Row, Col, Table, Checkbox } from 'react-bootstrap';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { NotificationManager } from 'react-notifications';
import isEmpty from 'is-empty'
import ReactPaginate from 'react-paginate'
import SyncLoader from "react-spinners/SyncLoader"
import _ from 'lodash'
import Swal from 'sweetalert2'
import BarcodePo from './BarcodePo'

const override = `
	position: fixed;
	display: block;
	top: 45%;
	left: 50%;
	transform: translateX(-50%);
	transform: translate(-50%, -50%);
	z-index: 1;
`
const statusArray = [
	{
		"id": 1,
		"display": "1- New",
		"value": "New"
	},
	{
		"id": 2,
		"display": "2- Acknowledge",
		"value": "Acknowledge"
	},
	{
		"id": 3,
		"display": "3- Barcode Generated (Barcode Printed)",
		"value": "Barcode Generated (Barcode Printed)"
	},
	{
		"id": 4,
		"display": "4- PO Packaging",
		"value": "PO Packaging"
	},
	{
		"id": 5,
		"display": "5- Ready for Dispatch",
		"value": "Ready for Dispatch"
	},
	{
		"id": 6,
		"display": "6- Ready for Pickup",
		"value": "Ready for Pickup"
	},
	{
		"id": 7,
		"display": "7- Pickup from source",
		"value": "Pickup from source"
	},
	{
		"id": 8,
		"display": "8- Reached China Warehouse",
		"value": "Reached China Warehouse"
	},
	{
		"id": 9,
		"display": "9- Manifest Awaited",
		"value": "Manifest Awaited"
	},
	{
		"id": 10,
		"display": "10- Manifest Generated (Barcode Printed)",
		"value": "Manifest Generated (Barcode Printed)"
	},
	{
		"id": 11,
		"display": "11- Ready for Dispatch to India",
		"value": "Ready for Dispatch to India"
	},
	{
		"id": 12,
		"display": "12- Packing Label Generated",
		"value": "Packing Label Generated"
	},
	{
		"id": 13,
		"display": "13- Pick Up Requested",
		"value": "Pick Up Requested"
	},
	{
		"id": 14,
		"display": "14- Pickup from China warehouse",
		"value": "Pickup from China warehouse"
	},
	{
		"id": 15,
		"display": "15- In transit",
		"value": "In transit"
	},
	{
		"id": 16,
		"display": "16- Reached to India Customs",
		"value": "Reached to India Customs"
	},
	{
		"id": 17,
		"display": "17- BCD payment awaited",
		"value": "BCD payment awaited"
	},
	{
		"id": 18,
		"display": "18- BCD payment done",
		"value": "BCD payment done"
	},
	{
		"id": 19,
		"display": "19- Custom Cleared",
		"value": "Custom Cleared"
	},
	{
		"id": 20,
		"display": "20- Dispatch to India Warehouse",
		"value": "Dispatch to India Warehouse"
	},
	{
		"id": 21,
		"display": "21- Receiving of goods at India Warehouse",
		"value": "Receiving of goods at India Warehouse"
	},
	{
		"id": 22,
		"display": "22- PO Completed",
		"value": "PO Completed"
	}
]

export default class SupplierOrders extends Component{
	constructor(props){
		super(props)
		this.state = {
			pageDataCount: 0,
			loading: true,
			orders: [],
			currentPage: 1,
			showPerPage: 50,
			pageCount: 0,
			productTotalCount: 0,
			query: { source: "orders" },
			fromDate: "",
			toDate: "",
			errors: {},
			searchSku: "",
			selectedIds: [],
			selectedStatus: 0,
			barcodeStatus: 0,
			allSelected: false,
            type: [],
            loadStatus: false
		}
	}

	componentDidMount(){
		this.getOrders(this.state.currentPage, this.state.showPerPage, JSON.stringify(this.state.query))
	}

	getOrders(page, perPage, query){
		this.setState({loading: true})
		axios.get(`/po/logisticUserGetOrders?page=${page}&limit=${perPage}&logisticUserId=${jwt_decode(localStorage.getItem('token')).id}&query=${!_.isEmpty(query) ? query : "{}"}`)
		.then(result => {
			this.setState({orders: result.data.items, productTotalCount: result.data.totalCount,
						pageCount: Math.ceil(result.data.totalCount / this.state.showPerPage), currentPage: page,
						loading: false, query, selectedIds: [], pageDataCount: result.data.items.length, 
                        type: result.data.type, loadStatus: true})
			if(_.isEmpty(query)){
				this.setState({fromDate: "", searchSku: "", toDate: ""})
			}
		})
	}

	handlePageClick = e => {
		this.getOrders((e.selected + 1), this.state.showPerPage, this.state.query)
	}

	triggerHanleSearch = e => {
		e.persist()
		if(e.key === 'Enter'){
			this.handleSearchSKU()
		}
	}

	handleSearchSKU(){
		this.setState({loading: true})
		if(_.isEmpty(this.state.searchSku)){
			this.getOrders(1, this.state.showPerPage, {})
		}else{
			axios.get(`/po/logisticUserSearchBySku?sku=${this.state.searchSku}&logisticUserId=${jwt_decode(localStorage.getItem('token')).id}`)
			.then(result => {
				if(result.data === 2){
					NotificationManager.success("No Product found on this SKU")
					this.setState({loading: false})
				}else{
					this.setState({orders: result.data, productTotalCount: result.data.length,
						pageCount: Math.ceil(result.data.length / this.state.showPerPage), currentPage: 1,loading: false,
						selectedIds: []})
				}
			})
		}
	}

	showDateRangeData(){
        let errors = {}
		let fromDate = this.state.fromDate
		let toDate = this.state.toDate
        if(!isEmpty(fromDate) && !isEmpty(toDate)){
            let d = new Date(fromDate)
            if(d.getFullYear() > 9999){
                errors.fromDate = "From Date must be less than 9999"
            }else if(d.getFullYear() < 1950){
                errors.fromDate = "From Date must be greater than 1950"
            }
            let d1 = new Date(toDate)
            if(d1.getFullYear() > 9999){
                errors.toDate = "To Date must be less than 9999"
            }else if(d1.getFullYear() < 1950){
                errors.toDate = "To Date must be greater than 1950"
            }
            if(isEmpty(errors)){
                const date1 = new Date(fromDate);
                const date2 = new Date(toDate);
                const diffTime = date2 - date1
                const diffDays = (diffTime / (1000 * 60 * 60 * 24))
                if(diffDays < 0)
                    errors.toDate = "End date needs to be after From date"
                else{
					let obj = {
						from: fromDate,
						to: toDate,
						status: document.getElementById("selectStatus").value,
						source: "orders"
					}
                    this.getOrders(1, this.state.showPerPage, JSON.stringify(obj))
                }
            }
            this.setState({errors})
        }else{
			let obj = {
				from: fromDate,
				to: toDate,
				status: document.getElementById("selectStatus").value,
				source: "orders"
			}
			this.getOrders(1, this.state.showPerPage, JSON.stringify(obj))
		}
    }

	handleCheckBox = e => {
		e.persist()
		if(e.target.checked){
			this.setState({selectedIds: this.state.selectedIds.concat(e.target.value)})
		}else{
			this.setState({selectedIds: this.state.selectedIds.filter(id => id !== e.target.value)})
		}
	}
	handleSelect = (e) => {
		if(e.target.value !== "Select Status")
			this.setState({ selectedStatus: e.target.value })
		else
			this.setState({ selectedStatus: "Select Status" })
	}

	handleStatus = () => {
		const formData = {
			ids: this.state.selectedIds,
			selectedStatus: this.state.selectedStatus,
			user: jwt_decode(localStorage.getItem('token')).email
		}
		if(!_.isEmpty(this.state.selectedStatus)){
			axios.put(`/sscapi/purchase-order`, formData)
			.then(res => {
				if (res.data.status)
					this.getOrders(1, this.state.showPerPage, {})
				else
					NotificationManager.error("Failed to update")
			})
			.catch(err => {
				console.log("Error",err)
				NotificationManager.error("Something went wrong, Please try again later")
			})
		}else{
			NotificationManager.error("Please choose Any one Status")
		}
	}
	renderComponent = () => {
		this.setState({ barcodeStatus: 1 })
	}

	handleSelectAll = e => {
		e.preventDefault()
		if(!e.target.checked){
			for(let i = 0; i < this.state.pageDataCount; i++){
				document.getElementById(i).checked = false
			}
			this.setState({selectedIds: []})
		}else{
			let selected = []
			for(let i = 0; i < this.state.pageDataCount; i++){
				document.getElementById(i).checked = true
				selected.push(document.getElementById(i).value)
			}
			this.setState({selectedIds: selected})
		}
	}

	handleSelectFilter = e => {
		e.preventDefault()
        this.setState({searchSku: ""})
		this.showDateRangeData()
	}

	render(){
		return(
			<div>
				<SyncLoader css={override} size={15} margin={6} color={"#3E3E3E"} loading={this.state.loading} />
				{
					this.state.barcodeStatus ? (
						<div>
							<BarcodePo selectedIds={this.state.selectedIds} />
						</div>
					) : (
						<div>
							<div className="container-fluid">
								<div className="card" style={{padding: "1em"}}>
									<div className="row">
										<div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
											<div className="form-group">
												<h6>&nbsp;</h6>
												<input 
													type="text"
													className="form-control"
													placeholder="Seacrh by SKU"
													onKeyPress={ this.triggerHanleSearch}
													onChange={(e) => {
														this.setState({searchSku: e.target.value, errors: {}, fromDate: "", toDate: ""})
                                                        document.getElementById("selectStatus").selectedIndex = 0
													}}
													id="searchOrder"
													value={this.state.searchSku}
													autoComplete="off"
												/>
											</div>
										</div>
										<div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
											<div className="form-group">
												<h6>&nbsp;</h6>
												<select className="form-control" id="selectStatus" onChange={this.handleSelectFilter}>
													<option value="Select Status">Select Status</option>
													{
                                                        this.state.loadStatus
                                                        &&
                                                            statusArray.map((status, i) => {
                                                                const { id, display } = status
                                                                return(
                                                                    this.state.type.includes(id.toString()) ? <option key={id} value={id}>{display}</option> : "vivek"
                                                                )
                                                            })
													}
												</select>
											</div>
										</div>
										<div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
											<div className="form-group">
												<h6>From Date &nbsp;<span onClick={() => document.getElementById("fromDate").value = ""} className="text-primary" style={{cursor: "pointer"}}>(Clear)</span></h6>
												<input 
													type="date"
													className="form-control"
													id="fromDate"
													max="9999-12-01"
													min="1950-12-31"
													placeholder="From Date"
													value={this.state.fromDate}
													onChange={ (e) => this.setState({fromDate: e.target.value, searchSku: ""}) }
												/>
												{
													!isEmpty(this.state.errors.fromDate)
													&&
														<span className="text-danger">{this.state.error.fromDate}</span>
												}
											</div>
										</div>
										<div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
											<div className="form-group">
												<h6>To Date &nbsp;<span onClick={() => document.getElementById("toDate").value = ""} className="text-primary" style={{cursor: "pointer"}}>(Clear)</span></h6>
												<input 
													type="date"
													className="form-control"
													id="toDate"
													max="9999-12-01"
													min="1950-12-31"
													placeholder="To Date"
													value={this.state.toDate}
													onChange={ (e) => this.setState({toDate: e.target.value, searchSku: ""}) }
												/>
												{
													!isEmpty(this.state.errors.toDate)
													&&
														<span className="text-danger">{this.state.errors.toDate}</span>
												}
											</div>
										</div>
										<div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
											<h6>&nbsp;</h6>
											<button className="btn btn-primary" onClick={() => this.showDateRangeData()}>Get</button>
										</div>
									</div>
								</div>

								{
								!_.isEmpty(this.state.selectedIds)
								&&
									<div className="card" style={{padding: "1em"}}>
										<div className="row">
											<div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
												<h6>&nbsp;</h6>
												<select className="form-control" onChange={this.handleSelect}>
													<option value="Select Status">Select Status</option>
													{
														this.state.loadStatus
                                                        &&
                                                            statusArray.map((status, i) => {
                                                                const { id, display } = status
                                                                return(
                                                                    this.state.type.includes(id.toString()) ? <option key={id} value={id}>{display}</option> : "vivek"
                                                                )
                                                            })
													}
												</select>
											</div>
											<div className="col-sm-12 col-md-12 col-lg-2 col-xl-2">
												<h6>&nbsp;</h6>
												<button className="btn btn-outline-primary" onClick={this.handleStatus}>Change Status</button>
											</div>
											<div className="col-sm-12 col-md-12 col-lg-2 col-xl-2">
												<h6>&nbsp;</h6>
												<button className="btn btn-outline-primary" onClick={this.renderComponent}>Generate Barcode</button>
											</div>
										</div>
										<br></br>
									</div>
								}
							</div>

							<div className="container-fluid">
								<div className="card" style={{padding: "1em"}}>
									<div className="row">
										<div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
											<div style={{cursor: "pointer"}} onClick={() => this.getOrders(1, this.state.showPerPage, {})}>
												<div className="form-group">
													<h5 className="text-bold">Total Orders: <span className="text-muted">{this.state.productTotalCount}</span>
														&nbsp;<i class="fa fa-refresh" aria-hidden="true"></i>
													</h5>
												</div>
											</div>
										</div>
									</div>
									<table className="table table-hover">
										<thead>
											<tr>
												<th scope="col">Sl.No
													<div>
														<input type="checkbox" id="selectAll" name="selectAll" onChange={this.handleSelectAll.bind(this)}></input>
													</div>
												</th>
												<th scope="col">Date</th>
												<th scope="col">Title</th>
												<th scope="col">SKU</th>
												<th scope="col">Quantity</th>
												<th scope="col">Status</th>
											</tr>
										</thead>
										<tbody>
											{
												!isEmpty(this.state.orders)
												?
													this.state.orders.map((order, i) => {
														const {date, title, sku, qty, status, _id} = order
														return (
														<tr key={i}>
															<td><input type="checkbox" id={i} value={_id} onChange={this.handleCheckBox.bind(this)} /></td>
															<td>{ date }</td>
															<td>{ title }</td>
															<td>{ sku }</td>
															<td>{ qty }</td>
															<td>{ 
																statusArray.map(status1 => {
																	const {id, value} = status1
																	return (
																		status === id ? value : ""
																	)
																})
															}</td>
														</tr>
														)
														
													})
												:
													<tr>
														<td colSpan="8" className="text-center"><h4>No Data Found</h4></td>
													</tr>
											}
										</tbody>
									</table>
									<ReactPaginate 
										previousLabel={'<'}
										nextLabel={'>'}
										breakLabel={'...'}
										breakClassName={'break-me'}
										pageCount={ this.state.pageCount }
										marginPagesDisplayed={3}
										pageRangeDisplayed={10}
										onPageChange={(e)=> this.handlePageClick(e)}
										containerClassName={'pagination'}
										subContainerClassName={'pages pagination'}
										activeClassName={'active'}
									/>
								</div>
							</div>
						</div>

					)
				}

				
			</div>
		)}
}