import jwt_decode from 'jwt-decode';
const priceUSD=75
const priceRupee=1

export const getPriceFormatter = (revenue) => {
    const token = localStorage.getItem('token');
    const decode = jwt_decode(token);
        switch(decode.origin) {
          case "china":
            return revenue ? `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                Number(revenue/priceUSD).toFixed(2)
              )}`
            : '$ 0';

            case "india":
              return revenue ? `₹ ${new Intl.NumberFormat('en-IN').format(
                Number(revenue).toFixed(2)
              )}`
            : '₹ 0';

          default:
            return revenue ? `₹ ${new Intl.NumberFormat('en-IN').format(
                Number(revenue).toFixed(2)
              )}`
            : '₹ 0';
        }
}

export const addPriceFormatter = (revenue,field) => {
    const token = localStorage.getItem('token');
    const decode = jwt_decode(token);
        switch(decode.origin) {
          case "china":
              console.log('priceFormatter',field,revenue,typeof revenue)
            return revenue ? (revenue/priceUSD).toFixed(2)
            : 0;
          default:
            console.log('priceFormatter',field,revenue,typeof revenue)
            return  revenue ? (revenue/priceRupee).toFixed(2)
            : 0;
        }
}


export const viewPriceFormatter = (revenue,field) => {
  const token = localStorage.getItem('token');
  const decode = jwt_decode(token);
      switch(decode.origin) {
        case "china":
            console.log('priceFormatter',field,revenue,typeof revenue)
          return revenue ? (revenue/priceUSD).toFixed(2)
          : 0;
        default:
          console.log('priceFormatter',field,revenue,typeof revenue)
          return  revenue ? (revenue/priceRupee).toFixed(2)
          : 0;
      }
}

