import React, { useState, useEffect } from "react";
import axios from "axios";
import isEmpty from "is-empty";
import ReactPaginate from "react-paginate";
import SyncLoader from "react-spinners/SyncLoader";
import { Link, useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import MerchantbulkUploadOrder from "../views/MerchantbulkUploadOrder";
import Modal from "react-responsive-modal";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { Button } from "react-bootstrap";
import "../index.css";

const override = `
    position: fixed;
    display: block;
    top: 45%;
    left: 50%;
    transform: translateX(-50%);
    transform: translate(-50%, -50%);
    z-index: 1;
`;

function OrderList() {
  const [showUpload, setShowUpload] = useState(true);
  const [orders, setOrders] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [errormessage, setErrorMessage] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [showPerPage, setShowPerPage] = useState(20);
  const [selectStore, setSelectStore] = useState("Select Store Name");
  const [storeData, setStoreData] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [tempCustomerData, setTempCustomerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setTodate] = useState("");
  const [error, setErrors] = useState({});
  const [defaultStore, setDefaultStore] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState("");
  const [open, setOpen] = useState(false);
  const [moreDesc, setMoreDesc] = useState(false);
  const [msg, setMsg] = useState("");
  const [singleProduct, setSingleProduct] = useState([]);
  const [fulfillstatus, setfulfillstatus] = useState();
  const [percentValue, setpercentValue] = useState();
  const [percentDate, setpercentDate] = useState("");
  let history = useHistory();
  useEffect(() => {
    let domain = jwt_decode(localStorage.getItem("token")).storeDomain;
    setLoading(false);
    setDefaultStore(domain);
    setSelectStore(domain);
    getOrders(domain, currentPage, showPerPage, {});
  }, []);
  const getOrders = (
    storeDomain,
    currentPage,
    showPerPage,
    searchparams = {}
  ) => {
    setLoading(true);
    const qs = Object.keys(searchparams)
      .map((key) => `${key}=${searchparams[key]}`)
      .join("&");
    axios
      .get(
        `/sscapi/orders?store=${storeDomain}&page=${currentPage}&limit=${showPerPage}&${qs}`,
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setLoading(false);
        if (!res.data.hasOwnProperty("message")) {
          setPageCount(Math.ceil(res.data.count / showPerPage));
          setOrders({ storeDomain, data: { currentPage: res.data } });
          setPageData(res.data.results);
          setTotalData(res.data.count);
        } else {
          setErrorMessage(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error", err);
      });
  };

  const handlePageClick = (e) => {
    setLoading(true);
    setCurrentPage(e.selected + 1);
    getOrders(selectStore, e.selected + 1, showPerPage, {});
  };

  const handleChange = (e) => {
    console.log("Came to handle Change", e.target.value);
    document.getElementById("searchOrder").value = "";
    setFromDate("");
    setTodate("");
    setSelectStore(e.target.value);
    getOrders(e.target.value, currentPage, showPerPage, {});
  };

  const handleSearchOrder = (e) => {
    setSearchString(e.target.value);
  };

  const handleFromdate = (fromDate) => {
    setFromDate(fromDate);
    setErrors({});
  };

  const handleToDate = (toDate) => {
    setTodate(toDate);
    setErrors({});
  };

  const handleNavigation = () => {
    setShowUpload(!showUpload);
  };
  function showDateRangeData() {
    let errors = {};
    console.log(fromDate, toDate);
    if (!isEmpty(fromDate) && !isEmpty(toDate)) {
      let d = new Date(fromDate);
      if (d.getFullYear() > 9999) {
        errors.fromDate = "From Date must be less than 9999";
      } else if (d.getFullYear() < 1950) {
        errors.fromDate = "From Date must be greater than 1950";
      }
      let d1 = new Date(toDate);
      if (d1.getFullYear() > 9999) {
        errors.toDate = "To Date must be less than 9999";
      } else if (d1.getFullYear() < 1950) {
        errors.toDate = "To Date must be greater than 1950";
      }
      if (isEmpty(errors)) {
        const date1 = new Date(fromDate);
        const date2 = new Date(toDate);
        const diffTime = date2 - date1;
        const diffDays = diffTime / (1000 * 60 * 60 * 24);
        if (diffDays < 0) {
          errors.toDate = "End date needs to be after From date";
        } else {
          getOrders(selectStore, 1, showPerPage, {
            fromDate,
            toDate,
            text: searchString,
          });
        }
      }
      setErrors(errors);
    } else if (searchString) {
      getOrders(selectStore, 1, showPerPage, { text: searchString });
    }
  }

  function downloadOrder() {
    let errors = {};
    if (!isEmpty(fromDate) && !isEmpty(toDate)) {
      let d = new Date(fromDate);
      if (d.getFullYear() > 9999) {
        errors.fromDate = "From Date must be less than 9999";
      } else if (d.getFullYear() < 1950) {
        errors.fromDate = "From Date must be greater than 1950";
      }
      let d1 = new Date(toDate);
      if (d1.getFullYear() > 9999) {
        errors.toDate = "To Date must be less than 9999";
      } else if (d1.getFullYear() < 1950) {
        errors.toDate = "To Date must be greater than 1950";
      }
      if (isEmpty(errors)) {
        const date1 = new Date(fromDate);
        const date2 = new Date(toDate);
        const diffTime = date2 - date1;
        const diffDays = diffTime / (1000 * 60 * 60 * 24);
        if (diffDays < 0) {
          errors.toDate = "End date needs to be after From date";
        } else {
          getDownloadOrders(selectStore, 1, showPerPage, {
            fromDate,
            toDate,
            text: searchString,
          });
        }
      }
      setErrors(errors);
    } else if (searchString) {
      getDownloadOrders(selectStore, 1, showPerPage, { text: searchString });
    } else {
      getDownloadOrders(selectStore, currentPage, showPerPage, {});
    }
  }
  const onCloseModal = () => {
    setOpen(false);
    setMsg("");
  };

  const statusArray = [
    {
      id: 1,
      value: "Pending",
    },
    {
      id: 3,
      value: "Processed",
    },
    {
      id: 14,
      value: "Shipped",
    },
  ];

  const callPurchesOrderApi = (orderNumber) => {
    axios.get("/po/trackOrderNumber/" + orderNumber).then((res) => {
      // setfulfillstatus(res.data)
      console.log(res.data);
      console.log("resonpsdfff");
      const fullfillstatusArray = res.data.data.map(
        (item) => item.fulfillmentStatus
      );
      const fullfillstatusDate = res.data.data.map(
        (item) => item.updatedAt.split("T")[0]
      );
      const finalDateIs = fullfillstatusDate.toString()
      setpercentDate(finalDateIs)
      const fullfillstatusvalue = fullfillstatusArray.toString();
      if(fullfillstatusvalue==1)
      {
        setpercentValue(0)
      }
      else if(fullfillstatusvalue==3)
      {
        setpercentValue(50)
      }
      else if(fullfillstatusvalue==14){
        setpercentValue(100)
      }
      setOpen(true);
      setfulfillstatus(fullfillstatusvalue);
    });
  };


  const getDownloadOrders = (
    storeDomain,
    currentPage,
    showPerPage,
    searchparams = {}
  ) => {
    setLoading(true);
    const qs = Object.keys(searchparams)
      .map((key) => `${key}=${searchparams[key]}`)
      .join("&");
    console.log(qs);
    axios
      .get(
        `/merchant/downloadOrderCsv?store=${storeDomain}&merchantId=${
          jwt_decode(localStorage.getItem("token")).id
        }&page=${currentPage}&limit=${showPerPage}&${qs}`
      )
      .then((data) => {
        let result = data.data;
        setLoading(false);
        if (result.error == 0) {
          window.open(result.response);
        } else if (result.error == 1) {
          alert(result.response);
        }
      });
  };

  return (
    <div>
      <Modal
      style={{borderRadius:"5px"}}
        open={open}
        onClose={() => {
          setOpen(false);
          setMoreDesc(false);
        }}
      >
        <div>
          <br />
          <br />
          
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <ProgressBar>
            <Step>
              {({}) => (
                <span style={{ left: "50%", position: "relative" }}>
                 Pending
                </span>
              )}
            </Step>
            <Step>{({}) => <span>Processed</span>}</Step>
            <Step>
              {({}) => (
                <span style={{ left: "-50%", position: "relative" }}>
                  Shipped
                </span>
              )}
            </Step>
          </ProgressBar>
          <br />
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <ProgressBar
            percent={percentValue}
            filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
          >
            <Step transition="scale">
              {({ accomplished }) => (
                <img
                  style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                  width="30"
                  src="https://vignette.wikia.nocookie.net/pkmnshuffle/images/9/9d/Pichu.png/revision/latest?cb=20170407222851"
                />
              )}
            </Step>
            <Step transition="scale">
              {({ accomplished }) => (
                <img
                  style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                  width="30"
                  src="https://vignette.wikia.nocookie.net/pkmnshuffle/images/9/97/Pikachu_%28Smiling%29.png/revision/latest?cb=20170410234508"
                />
              )}
            </Step>
            <Step transition="scale">
              {({ accomplished }) => (
                <img
                  style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                  width="30"
                  src="https://orig00.deviantart.net/493a/f/2017/095/5/4/raichu_icon_by_pokemonshuffle_icons-db4ryym.png"
                />
              )}
            </Step>
          </ProgressBar>
          <br />
          <br />
          {/* <ProgressBar
          percent={percentValue}
          filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"

          >
            <Step>
              {({}) => (
                <span style={{ left: "50%", position: "relative" }}>
                  {fulfillstatus==1?percentDate:""}
                </span>
              )}
            </Step>
            <Step>{({}) => <span>
            {fulfillstatus==3?percentDate:""}
              </span>}
              </Step>
            <Step>
              {({}) => (
                <span style={{ left: "-50%", position: "relative" }}>
                {fulfillstatus==14?percentDate:""}

                </span>
              )}
            </Step>
          </ProgressBar> */}
        </div>
        {/* <br />
        <br /> */}
      </Modal>
      <div className="uploadBtn">
        {showUpload ? (
          <button
            style={{
              background: "rgb(255, 160, 0)",
              borderColor: "rgb(255, 160, 0)",
              fontSize: "15px",
              fontWeight: "500",
            }}
            className="btn btn-primary btn-fill"
            onClick={handleNavigation}
          >
            BULK UPLOAD
          </button>
        ) : (
          <button
            style={{
              background: "rgb(255, 160, 0)",
              borderColor: "rgb(255, 160, 0)",
              fontSize: "15px",
              fontWeight: "500",
            }}
            className="btn btn-primary btn-fill"
            onClick={handleNavigation}
          >
            BACK TO ORDERS
          </button>
        )}
      </div>

      {showUpload ? (
        <div className="content bdycontent">
          <SyncLoader
            css={override}
            size={15}
            margin={6}
            color={"#3E3E3E"}
            loading={loading}
          />

          <div className="container-fluid">
            <div className="card" style={{ padding: "1em" }}>
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
                  <div className="form-group">
                    <label>Order Number</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search By Order Number"
                      onChange={handleSearchOrder}
                      id="searchOrder"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                  <div className="form-group">
                    <label>
                      From Date &nbsp;
                      <span
                        onClick={() =>
                          (document.getElementById("fromDate").value = "")
                        }
                        style={{ cursor: "pointer", color: "#42d0ed" }}
                      >
                        (Clear)
                      </span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="fromDate"
                      max="9999-12-01"
                      min="1950-12-31"
                      placeholder="From Date"
                      value={fromDate}
                      onChange={(e) => handleFromdate(e.target.value)}
                    />
                    {!isEmpty(error.fromDate) && (
                      <span className="text-danger">{error.fromDate}</span>
                    )}
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                  <div className="form-group">
                    <label>
                      To Date &nbsp;
                      <span
                        onClick={() =>
                          (document.getElementById("toDate").value = "")
                        }
                        style={{ cursor: "pointer", color: "#42d0ed" }}
                      >
                        (Clear)
                      </span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="toDate"
                      max="9999-12-01"
                      min="1950-12-31"
                      placeholder="To Date"
                      value={toDate}
                      onChange={(e) => handleToDate(e.target.value)}
                    />
                    {!isEmpty(error.toDate) && (
                      <span className="text-danger">{error.toDate}</span>
                    )}
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
                  <label>&nbsp;</label>
                  <button
                    style={{
                      background: "rgb(255, 160, 0)",
                      borderColor: "rgb(255, 160, 0)",
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                    className="btn btn-primary btn-fill"
                    onClick={() => showDateRangeData()}
                  >
                    GET
                  </button>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
                  <label>&nbsp;</label>
                  <button
                    className="btn btn-primary btn-fill"
                    style={{
                      background: "rgb(255, 160, 0)",
                      borderColor: "rgb(255, 160, 0)",
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                    onClick={() => downloadOrder()}
                  >
                    DOWNLOAD
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="card" style={{ padding: "1em" }}>
              <div
                style={{ cursor: "pointer" }}
                onClick={() =>
                  getOrders(selectStore, currentPage, showPerPage, {})
                }
              >
                <div className="form-group">
                  <h5 className="text-bold">
                    Total Orders:{" "}
                    <span className="text-muted">{totalData}</span>
                    &nbsp;<i className="fa fa-refresh" aria-hidden="true"></i>
                  </h5>
                </div>
              </div>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Order Number</th>
                    <th scope="col">Customer Name</th>
                    <th scope="col">Shopperr Amount</th>
                    <th scope="col">Reseller Amount</th>
                    <th scope="col">Total Items</th>
                    <th scope="col">Store Name</th>
                    <th scope="col">Order Date</th>
                    <th scope="col">View</th>
                    <th scope="col">Status</th>
                    <th scope="col">Track Order</th>
                  </tr>
                </thead>
                <tbody>
                  {!isEmpty(pageData) ? (
                    pageData.map((order) => {
                      let d = new Date(order.created_on);
                      return (
                        <tr key={order._id}>
                          <td scope="row">{order.orderNumber}</td>
                          <td>
                            {order.customer && order.customer.first_name}{" "}
                            {order.customer && order.customer.last_name}
                          </td>
                          <td>{order.price}</td>
                          <td>{order.resellerPrice}</td>
                          <td>{order.products.length}</td>
                          <td>{order.storeDomain}</td>
                          <td>{`${d.getDate()}-${
                            d.getMonth() + 1
                          }-${d.getFullYear()}`}</td>
                          <td>
                            <Link to={`/merchant/order/${order._id}`}>
                              <i className="fa fa-eye" aria-hidden="true"></i>
                            </Link>
                          </td>
                          <td>
                            {order.poProcess == 2 ? (
                              <i
                                className="fa fa-times-circle text-danger"
                                aria-hidden="true"
                              ></i>
                            ) : (
                              <i
                                className="fa fa-check text-success"
                                aria-hidden="true"
                              ></i>
                            )}
                          </td>
                          <td scope="row">
                            <Button
                              onClick={() =>
                                callPurchesOrderApi(order.orderNumber)
                              }
                            >
                              Track Order
                            </Button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-center">
                        <h4>No Data Found</h4>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={3}
                pageRangeDisplayed={10}
                onPageChange={(e) => handlePageClick(e)}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
      ) : (
        <MerchantbulkUploadOrder />
      )}
    </div>
  );
}
export default OrderList;
