import React, { useState, useEffect } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Modal from "react-responsive-modal";
import { NotificationManager } from "react-notifications";
import Card from "../components/Card/Card.jsx";
import "../assets/css/productList.css";
import _ from 'lodash'
import moment from 'moment'
import CustomButton from '../components/CustomButton/CustomButton';
// import sample1 from "../assets/csvfiles/orderUploadFile.csv";

// import sample2 from "../assets/csvfiles/updateOrder.csv";

const MerchantbulkUploadOrder = () => {
  const [productItems, setProductItems] = useState([]); 
  const token = localStorage.getItem("token");
  const decode = jwt_decode(token);
  const [csvData, setCsvData] = useState([]);
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [warranty, setWarranty] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [code, setCode] = useState("");
  const [itemId, setItemId] = useState("");
  const [open, setOpen] = useState(false);

  const modalStyle = {
    margin: "auto",
    position: "relative",
  };

  useEffect(() => {
    getProductData();
  }, []);

const getProductData = () => {
    let data = {
        'merchantId' : decode.id,
        'csvType' : [13,14]
    }
    axios.post("/merchant/getCsvByMerchant" , data).then((csv) => {
        console.log(csv)
        setProductItems(csv.data.data)
    });
};
//Add Product from CSV File
const addCsvOrder = (e) => {
    console.log("request come in addcsvorder");
    e.preventDefault();
    const scvdata = new FormData()
    scvdata.append('file', csvData[0])
    scvdata.append('merchantId', decode.id)
    scvdata.append('csvType', 13) 
    console.log("add scvdata is ");
    console.log(scvdata);
    axios
    .post('/merchant/bulkUploadOrder', scvdata)
    .then((res) => { 
        if (res.data.error == 0) {
            NotificationManager.success(res.data.response);
         //   window.location.reload();
        } else {
            NotificationManager.error(res.data.response);
        }
    }).catch((error) => {
        error
        ? NotificationManager.error(error.toString())
        : NotificationManager.error('There is a problem with this csv');
    })
};

// for update csv data
const updateCsvOrder = (e) => {
    e.preventDefault();
    const scvdata = new FormData()
    scvdata.append('file', csvData[0])
    scvdata.append('merchantId', decode.id)
    scvdata.append('csvType', 14) 
    axios
    .post('/merchant/bulkUploadOrder', scvdata)
    .then((res) => { 
        if (res.data.error == 0) {
            NotificationManager.success(res.data.response);
          //  window.location.reload();
        } else {
            NotificationManager.error(res.data.response);
        }
    }).catch((error) => {
        error
        ? NotificationManager.error(error.toString())
        : NotificationManager.error('There is a problem with this csv');
    })
};

 
return (
    <div>
    <br />
    <div className='container-fluid'>
        <div className='col-md-6'> 
            <form onSubmit={addCsvOrder}>
                <div className='card card-input' style = {{ marginBottom: '0px' , width : '100%'}}>
                    <p>Add Orders CSV File</p>
                    <div className='form-group'> 
                        <div id='fileContents'></div>
                        <input type='file' className='form-control text-center' name='avatar' onChange={(e) => {setCsvData(e.target.files) }} encType='multipart/form-data' accept='.csv'  />
                        <br />
                        <div className='card-button' style={{margin : "0px" }}>
                            <button  type='submit' className="btn btn-fill" style={{ textTransform: 'uppercase', background: "rgb(255, 160, 0)"  ,  borderColor: "rgb(255, 160, 0)" , fontSize: "15px" , fontWeight: "500"}}> Add Orders </button>
                        </div>
                        <a href="https://www.dropbox.com/s/ey3xh2ngvnuqwh1/shivrajbhati%20%283%29.csv?dl=0" target="_blank">Sample Csv
                        </a>
                    </div>
                </div>
            </form>
        </div>
        <div className='col-md-6'> 
            <form onSubmit={updateCsvOrder}>
                <div className='card card-input' style = {{ marginBottom: '0px' , width : '100%'}}>
                    <div className='form-group'>
                        <p>Update Orders CSV File</p>
                        <div id='fileContents'></div>
                        <input  type='file' className='form-control text-center'  name='avatar' onChange={(e) => {  setCsvData(e.target.files) }} encType='multipart/form-data' accept='.csv' />
                        <br />
                        <div className='card-button' style={{margin : "0px" }}>
                            <button  type='submit' className="btn  btn-fill" style={{textTransform: 'uppercase', background: "rgb(255, 160, 0)"  ,  borderColor: "rgb(255, 160, 0)" , fontSize: "15px" , fontWeight: "500"}}>  Update Orders </button>
                        </div>
                        <a 
                        // href={sample2}
                         target="_blank">Sample Csv</a>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <br /> 
    <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="CSV File Export / Import List"
                category={"Total Exports & Import :" + productItems.length}
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table striped hover>
                    <thead>
                        <tr>
                            <th className='text-center'>SNo.</th>
                            <th className='text-center' style={{width:"30%"}}>Status</th>
                            <th className='text-center' style={{width:"30%"}}>Action</th>
                            <th className='text-center' style={{width:"30%"}}>Date</th> 
                        </tr>
                    </thead>
                    <tbody>
                    	{
							!_.isEmpty(productItems) ?
	                      	productItems.map((item, key) => {
		                        return (
		                            <tr key={key}> 
		                                <td className='text-center' style={{width:"10%"}}>{key +1 }</td>
		                                <td  className='text-center' style={{color: (item.status == 1 ? 'Green' : 'orange' ) }}>{item.status == 1 ? "Success" : "Pendingg" || "NA"}</td>
		                                <td  className='text-center'><a href={ item.url }>{item.type == 13 ? "Add Order" : "Update Order" || "NA"}</a></td>
		                                <td  className='text-center'>{moment(item.date).format('DD-MM-YYYY HH:MM:SS') || "NA" }</td> 
		                            </tr>
		                        );
	                      	})
	                      	: 
	                      	<tr>
								<td colSpan="4" className="text-center" style={{fontSize: "20px"}}>No Data Found</td>
							</tr>
	                    }
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>  
    </div>
  );
};

export default MerchantbulkUploadOrder;
