import React, { Component } from "react";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import SyncLoader from "react-spinners/SyncLoader"
import _ from "lodash";
import { Redirect } from 'react-router-dom'

const imageExtensions = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/bmp']

const override = `
	position: fixed;
	display: block;
	top: 45%;
	left: 50%;
	transform: translateX(-50%);
	transform: translate(-50%, -50%);
	z-index: 9999;
`

export default class AddCategory extends Component{
	constructor(props){
		super(props)
		this.state = {
			loading: false,
			categoryName: "",
			subCategory: "",
			keyword: [],
			categoryImage: "",
			description: "",
			mobileFlag: "1",
			websiteFlag: "1",
			errors: {},
			redirect: false
		}
	}

	handleCategory = e => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                categoryName: ""
            },
            categoryName: e.target.value
        }))
    }

	handleSubCategory = e => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                subCategory: ""
            },
            subCategory: e.target.value
        }))
    }

	handleDescription = e => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                description: ""
            },
            description: e.target.value
        }))
    }

    uploadMultipleImages = (e) => {
        const readImage  = file => {
            if(imageExtensions.includes(file.type)){
                const reader = new FileReader();
                reader.addEventListener('load', (e1) => {
                    this.setState({ categoryImage: e1.target.result, errors: {}})
                })
                reader.readAsDataURL(file);
            }else{
                NotificationManager.error(`File not supported, Supported files are jpeg, png, webp, bmp, gif.`)
            }
        } 
        const files = e.target.files;
        if (!files || !files[0]) return alert('File upload not supported');
        [...files].forEach( readImage )
    }

	handleDeleteImage(){
		this.setState({categoryImage: ""})
    }

	handleAddCategory = e => {
		e.preventDefault()
		let obj = {
			categoryName: this.state.categoryName,
			subCategory: this.state.subCategory,
			description: this.state.description,
			keyword: this.state.keyword,
			mobileFlag: this.state.mobileFlag,
			websiteFlag: this.state.websiteFlag, 
			categoryImage: this.state.categoryImage
		}
		let validate = this.handleValidation(obj)
		if(validate.isValid){
			this.setState({loading: true})
			axios.post("/api/addCategory", obj)
			.then(result => {
				if(result.data === 1){
					NotificationManager.success("Category added successfully")
					this.setState({loading: false, redirect: true})
				}else if(result.data === 2){
					NotificationManager.success("Category Name already exist")
					this.setState({loading: false})
				}
			})
			.catch(err => {
				this.setState({loading: false})
				console.log(err)
				NotificationManager.error("Failed to add category")
			})
		}else{
			this.setState({errors: validate.errors})
		}
	}

	handleValidation(data){
		let errors = {}

		if(_.isEmpty(data.categoryName))
			errors.categoryName = "Category name is required"

		if(_.isEmpty(data.keyword))
			errors.keyword = "Keyword is required"

		if(data.mobileFlag === "1" && _.isEmpty(data.categoryImage))
			errors.categoryImage = "Image is required"

		return { errors, isValid: _.isEmpty(errors)}
	}

	render(){
		if(this.state.redirect){
			return(
				<Redirect to="/admin/categoryList"/>
			)
		}else{
			return(
				<div>
					<SyncLoader css={override} size={15} margin={6} color={"#3E3E3E"} loading={this.state.loading} />
					<br></br>
					<div className={this.state.loading ? "parentDisable" : ""}>
						<div className="container">
							<div className="card" style={{padding: "1em"}}>
								<div className="row">
									<div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
										<div className='form-group'>
											<label style={{fontSize: 15 }} htmlFor='category'>
												<span className="text-danger">* </span> Category Name &nbsp;
												<span className="custom-tooltip" data-tooltip="Category Name">
													<i class="fa fa-question-circle" aria-hidden="true"></i>
												</span>
											</label>
											<input
												type='text'
												value={this.state.categoryName}
												onChange={this.handleCategory.bind(this)}
												className='form-control'
												placeholder='Category Name'
												id="category"
												autoComplete="off"
											/>
											{
												this.state.errors.categoryName
												&&
												<span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.categoryName}</span>
											}
										</div> 
									</div>
									<div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
										<div className='form-group'>
											<label style={{fontSize: 15 }} htmlFor='subCategory'>
												Sub Category Name &nbsp;
												<span className="custom-tooltip" data-tooltip="Category Name">
													<i class="fa fa-question-circle" aria-hidden="true"></i>
												</span>
											</label>
											<input
												type='text'
												value={this.state.subCategory}
												onChange={this.handleSubCategory.bind(this)}
												className='form-control'
												placeholder='Sub Category Name'
												id="subCategory"
												autoComplete="off"
											/>
										</div>
										<br></br>
									</div>
								</div> 
								<div className="row">
									<div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
										<div className='form-group'>
											<label style={{fontSize: 15 }} htmlFor='desc'>
												Description &nbsp;
												<span className="custom-tooltip" data-tooltip="Description">
													<i class="fa fa-question-circle" aria-hidden="true"></i>
												</span>
											</label>
											<textarea value={this.state.description}
													className='form-control'
													placeholder='Description...'
													id="desc"
													autoComplete="off" 
													onChange={this.handleDescription.bind(this)}
													rows="1"/>
										</div> 
									</div>
									<div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
										<div className='form-group'>
											<label style={{fontSize: 15 }} htmlFor='keyword'>
												<span className="text-danger">* </span>Keyword &nbsp;
												<span className="custom-tooltip" data-tooltip="Keyword">
													<i class="fa fa-question-circle" aria-hidden="true"></i>
												</span>
											</label>
											<TagsInput 
												value={this.state.keyword}
												onChange={keyword => this.setState({keyword, errors: {}})}
												onlyUnique
												addKeys={[9,13,32,188]}
											/>
										</div>
										{
											this.state.errors.keyword
											&&
											<span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.keyword}</span>
										}
									</div>
								</div>
								
								<div className="row">
									<div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
										<h4>Mobile Flag&nbsp;
											<span className="custom-tooltip" data-tooltip="Mobile Flag">
												<i class="fa fa-question-circle" aria-hidden="true"></i>
											</span>
										</h4>
										<div class="form-check form-check-inline">
											&emsp;&emsp;<input class="form-check-input" 
															type="radio" 
															name="mobileFlag" 
															id="mobileFlag" 
															value="1" 
															checked={this.state.mobileFlag === "1"}
															onChange={(e) => this.setState({mobileFlag: e.target.value, errors: {}})}/>
											<label class="form-check-label" for="pdStatus">&nbsp;&nbsp;Active</label>
											&emsp;&emsp;<input class="form-check-input" 
															type="radio" 
															name="mobileFlag" 
															id="mobileFlag1"
															value="0" 
															checked={this.state.mobileFlag === "0"}
															onChange={(e) => this.setState({mobileFlag: e.target.value , errors: {}})}/>
											<label class="form-check-label" for="pdStatus1">&nbsp;&nbsp;In Active</label>
										</div>
									</div>
									<div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
										<h4>Website Flag&nbsp;
											<span className="custom-tooltip" data-tooltip="Website Flag">
												<i class="fa fa-question-circle" aria-hidden="true"></i>
											</span>
										</h4>
										<div class="form-check form-check-inline">
											<input class="form-check-input"  type="radio"  name="websiteFlag"  id="websiteFlag"  value="1"  checked={this.state.websiteFlag === "1"} onChange={(e) => this.setState({websiteFlag: e.target.value, errors: {}})}/>
											<label class="form-check-label" for="pdStatus">&nbsp;&nbsp;Active</label>
											<input class="form-check-input" type="radio"  name="websiteFlag"  id="websiteFlag1" value="0"  checked={this.state.websiteFlag === "0"}
													onChange={(e) => this.setState({websiteFlag: e.target.value, errors: {}})}/>
											<label class="form-check-label" for="pdStatus1">&nbsp;&nbsp;In Active</label>
										</div>
									</div>
								</div> 
								<div className="row">
									<div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
										<div className="form-group">
											<label htmlFor='categoryImage' style={{fontSize: 15 }}>Category Image upload &nbsp;
												<span className="custom-tooltip" data-tooltip="Category Image">
													<i class="fa fa-question-circle" aria-hidden="true"></i>
												</span>
											</label><br></br>
											<label className="btn">
												Choose Category Image
												<input
													type='file'
													id="categoryImage"
													className='hidden'
													onChange={this.uploadMultipleImages}
													accept='image/*'
												/>
											</label>
										</div>
										{
											this.state.errors.categoryImage
											&&
											<span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.categoryImage}</span>
										}
									</div>
									<div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
										<div className="img-overlay1"></div>
										{
											this.state.categoryImage
											&&
												<div className='col-md-3 show-image1'>
													<span className="closeButton1" onClick={() => this.handleDeleteImage()}>
														<i class="fa fa-times" aria-hidden="true"></i>
													</span> 
													<div className="img-overlay1"></div>
													<img
														src={this.state.categoryImage}
														alt='uploadImage' className='images'
													/>
												</div>
										}
									</div>
								</div> 
								<div className="row">
									<div className="col-sm-12 col-md-2 col-lg-2 col-xl-2">
										<button className="btn btn-outline-primary" style={{ 
											backgroundColor: 'rgb(255, 160, 0)', 
											color: 'rgb(255, 255, 255)', 
											fontWeight: '500',   
											height: "40px", 
											display: "flex", 
											alignItems: "center", 
											justifyContent: "center", 
											borderRadius: 4, 
											border:'0px', 
											'fontSize': '15px'
											 }} onClick={this.handleAddCategory}>Add Category
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		}
	}
}