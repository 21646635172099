import React, { useState, useEffect } from "react";
import CsvDownloader from "react-csv-downloader";
import { Grid, Row, Col, Table } from "react-bootstrap";
import axios from "axios";
import Modal from "react-responsive-modal";
import { NotificationManager } from "react-notifications";
import Card from "../../components/Card/Card.jsx";
import moment from "moment";
import { Link } from "react-router-dom";
import _ from 'lodash'
import ReactPaginate from 'react-paginate'
import SyncLoader from "react-spinners/SyncLoader"
const override = `
    position: fixed;
    display: block;
    top: 45%;
    left: 50%;
    transform: translateX(-50%);
    transform: translate(-50%, -50%);
    z-index: 1;
`
const statusArray = [
	{
		"id": 0,
		"display": "PO Pending",
		"value": "PO Pending"
	}, 
	{
		"id": 1,
		"display": "PO Created",
		"value": "PO Created"
	},
	{
		"id": 2,
		"display": "GRN Created",
		"value": "GRN Created"
	},
	{
		"id": 3,
		"display": "GRN Completed",
		"value": "GRN Completed"
	}, 
	{
		"id": 4,
		"display": "Picklist Created",
		"value": "Picklist Created"
	}, 
	{
		"id": 5,
		"display": "Done",
		"value": "Done"
	}
]
const UnicommercePicklist = () => {
    const [offshelf, setOffshelf] = useState([]);
    const [withoutSortsuppliers, setwithoutSortsuppliers] = useState([]);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [status, setStatus] = useState("");
    const [tempSupplier, setTempSupplier] = useState(""); 
    const [sRevenue, setSRevenue] = useState();
    const [sOrder, setSOrder] = useState();
    const [productCount, setProductCount] = useState();
    const [sort, setSort] = useState([]);
    const [search, setSearch] = useState("");
    const [currentPage, setcurrentPage] = useState(1);
    const [showPerPage, setshowPerPage] = useState(30);
    const [pageCount, setpageCount] = useState(0); 
    const [productTotalCount, setproductTotalCount] = useState(0); 
    let checkArray = [];
	let statusSearch = 0;
    useEffect(() => {
        getOffshelfData(currentPage, showPerPage, statusSearch);  
    }, []);   


    const getOffshelfData = async (page, perPage, statusSearch) => { 
       axios.get(`/api/offshelf/orders?page=${page}&limit=${perPage}&status=${statusSearch}`).then((res) => {
            setOffshelf(res.data.data);
            setwithoutSortsuppliers(res.data.data);
            setproductTotalCount(res.data.totalCount)
            setpageCount(Math.ceil(res.data.totalCount / showPerPage)) 
            setcurrentPage(page)
        }); 
    };

    
    const handlePageClick = (e) => {  
        getOffshelfData((e.selected + 1), showPerPage)
    }
    
    const handleSort = (e) => {
        e.preventDefault();
        console.log('dasdasf')   
        if (e.target.value === "product") { 
            setOffshelf( withoutSortsuppliers.sort((a, b) => parseFloat(b.products) - parseFloat(a.products)) );
        }else if (e.target.value === "order") {
            setOffshelf( withoutSortsuppliers.sort((a, b) => parseFloat(b.orders) - parseFloat(a.orders)) );
        }else if (e.target.value === "revenue") {
            setOffshelf(withoutSortsuppliers.sort( (a, b) => parseFloat(b.revenue) - parseFloat(a.revenue) ) );
        }else if (e.target.value === "nothing") {
            setOffshelf(withoutSortsuppliers)
        }
    };
	
	const handleSearch = (e) => {
		let statusKey = document.getElementById("status").value;
		getOffshelfData(1, showPerPage, statusKey)
	}
	

    return (
        <div className="content">
            <div className="info text-center" style={{ color: "red" }}>
                {status}
            </div>
            <div className='row' style={{ paddingLeft: '15px' }}>
                <div className='col-md-4'>
                    <select className="form-control" id="status" onChange={handleSearch}>
						<option value="Select Status" >Select Status</option>
						{
							statusArray.map(data => {
								const { id, value } = data
								return(
									<option key={id} value={id}>{value}</option>
								)
							})
						}
					</select>
                </div>
                
            </div>
            <br />
            <Grid fluid>
                <Row>
                    <Col md={12}>
                        <Card title="Unicommerce Picklist" category={"Total Picklist :" + productTotalCount}
                            ctTableFullWidth
                            ctTableResponsive
                            content={
                                <div className="table-responsive">
                                    <Table striped hover size="sm">
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>PO No.</th>
                                                <th>GRN No.</th>
                                                <th>Picklist No.</th>
                                                <th>Total Unit Count</th>
                                                <th>Total Qty Count</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {offshelf.map((item, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>{(showPerPage*(currentPage-1)) + (key+1)}</td>
                                                        <td>{item._id.offshelf_no}</td>
                                                        <td>{item._id.grn_uniware}</td>
                                                        <td>{item._id.picklist}</td>
                                                        <td>{item.offshelfCount}</td>
                                                        <td>{item.qtyCount}</td>
                                                        <td>{ 
																statusArray.map(data => {
																	const {id, value} = item._id.status
																	return (
																		item._id.status === data.id ? data.value : ""
																	)
																})
															}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                    {
                                        !_.isEmpty(offshelf)
                                        &&
                                           <ReactPaginate 
                                                previousLabel={'<'}
                                                nextLabel={'>'}
                                                breakLabel={'...'}
                                                breakClassName={'break-me'}
                                                pageCount={ pageCount }
                                                marginPagesDisplayed={3}
                                                pageRangeDisplayed={10}
                                                onPageChange={(e)=> handlePageClick(e)}
                                                containerClassName={'pagination'}
                                                subContainerClassName={'pages pagination'}
                                                activeClassName={'active'}
                                            />
                                    }
                                </div>
                            }
                        />
                    </Col>
                </Row>
            </Grid> 
        </div>
    );
}; 
export default UnicommercePicklist;
