import React, { Component } from 'react'
import SyncLoader from "react-spinners/SyncLoader"
import { Link } from "react-router-dom"
import _ from 'lodash'
import ReactPaginate from 'react-paginate'
import axios from 'axios'
import { NotificationManager } from 'react-notifications';

const override = `
	position: fixed;
	display: block;
	top: 45%;
	left: 50%;
	transform: translateX(-50%);
	transform: translate(-50%, -50%);
	z-index: 9999;
`

export default class LogisticUsersList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            usersData: [],
            currentPage: 1,
            showPerPage: 50,
            pageCount: 0,
            userTotalCount: 0,
            query: {}
        }
    }

    componentDidMount() {
        this.getLogisticUser(this.state.currentPage, this.state.showPerPage, this.state.query)
    }

    getLogisticUser(page, perPage, query) {
        this.setState({ loading: true })
        axios.get(`/api/getLogisticUsers?page=${page}&limit=${perPage}&query=${!_.isEmpty(query) ? query : "{}"}`)
            .then(result => {
                this.setState({
                    usersData: result.data.items, userTotalCount: result.data.totalCount, loading: false, query,
                    pageCount: Math.ceil(result.data.totalCount / this.state.showPerPage), currentPage: 1,
                })
            })
    }

    handlePageClick = e => {
        this.getLogisticUser((e.selected + 1), this.state.showPerPage, this.state.query)
    }

    storeUniqueId = item => {
        localStorage.setItem('code', item)
    }

    handleDeleteProduct = item => {
        axios.delete(`/api/deleteLogisticUser/${item}`)
            .then(result => {
                if (result.data === 1) {
                    NotificationManager.success("User Deleted Successfully")
                    this.getLogisticUser(1, this.state.showPerPage, {})
                } else {
                    NotificationManager.error("Sorry, something went wrong")
                }
            })
    }

    render() {
        return (
            <div>
                <br></br>
                <SyncLoader css={override} size={15} margin={6} color={"#3E3E3E"} loading={this.state.loading} />
                <div className="container-fluid" >
                    <div className="card" style={{ padding: "1em" }}>
                        <div style={{ cursor: "pointer" }} onClick={() => this.getLogisticUser(1, this.state.showPerPage, {})}>
                            <div className="form-group">
                                <h5 className="text-bold">Total Logistic Users: <span className="text-muted">{this.state.userTotalCount}</span>
                                    &nbsp;<i className="fa fa-refresh" aria-hidden="true"></i>
                                </h5>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-hover">
                                <thead>
                                    <tr className="d-flex">
                                        <th scope="col" >SL.NO</th>
                                        <th scope="col" >Username</th>
                                        <th scope="col" >Email Address</th>
                                        <th scope="col" >Type</th>
                                        <th scope="col" >Edit</th>
                                        <th scope="col" >Delete</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !_.isEmpty(this.state.usersData)
                                            ?
                                            this.state.usersData.map((item, index) => {
                                                const { email, type, name, _id } = item
                                                return (
                                                    <tr key={index} className="d-flex">
                                                        <td>{(index + 1)}</td>
                                                        <td>{name}</td>
                                                        <td >{email}</td>
                                                        <td >{type}</td>
                                                        <td>
                                                            <span >
                                                                <Link to="/admin/editLogisticUser">
                                                                    <i className="fa fa-pencil" style={{ cursor: "poiner", fontSize: "22px" }} aria-hidden="true" onClick={() => this.storeUniqueId(_id)}></i>
                                                                </Link>

                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span className="text-danger" style={{ cursor: "poiner" }}>
                                                                <i className="fa fa-trash" style={{ fontSize: "22px" }} aria-hidden="true" onClick={() => this.handleDeleteProduct(_id)}></i>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            :
                                            <tr>
                                                <td colSpan="11" className="text-center" style={{ fontSize: "20px" }}>No Product Found</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>

                        {
                            !_.isEmpty(this.state.usersData)
                            &&
                            <ReactPaginate
                                previousLabel={'<'}
                                nextLabel={'>'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={this.state.pageCount}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={10}
                                onPageChange={this.handlePageClick.bind(this)}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                            />
                        }
                    </div>
                </div>
            </div>
        )
    }
}