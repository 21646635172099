import React, { Component } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { NotificationManager } from 'react-notifications';
import isEmpty from 'is-empty'
import ReactPaginate from 'react-paginate'
import SyncLoader from "react-spinners/SyncLoader"
import _ from 'lodash'
import BarcodePo from '.././BarcodePo'

const override = `
	position: fixed;
	display: block;
	top: 45%;
	left: 50%;
	transform: translateX(-50%);
	transform: translate(-50%, -50%);
	z-index: 1;
`
const statusArray = [
	{
		"id": 1,
		"display": "Pending",
		"value": "Pending"
	}, 
	{
		"id": 3,
		"display": "Processed",
		"value": "Processed"
	}, 
	{
		"id": 14,
		"display": "Shipped",
		"value": "Shipped"
	}
]

export default class SupplierOrders extends Component{
	constructor(props){
		super(props)
		this.state = {
			pageDataCount: 0,
			loading: true,
			orders: [],
			currentPage: 1,
			showPerPage: 50,
			pageCount: 0,
			productTotalCount: 0,
			query: {},
			fromDate: "",
			toDate: "",
			errors: {},
			searchSku: "",
			selectedIds: [],
			selectedStatus: 0,
			barcodeStatus: 0,
			allSelected: false,
			csvFile : '',
			barcodeCount : 1,
			barcodeData : []
		}
	}

	componentDidMount(){
		this.getOrders(this.state.currentPage, this.state.showPerPage, this.state.query)
	}

	getOrders(page, perPage, query){
		this.setState({loading: true})
		axios.get(`/po/getOrders?&id=${jwt_decode(localStorage.getItem('token')).id}&page=${page}&limit=${perPage}&query=${!_.isEmpty(query) ? query : "{}"}`)
		.then(result => {
			// console.log('orders: ', result.data.items);
			this.setState({orders: result.data.items, productTotalCount: result.data.totalCount,
				
						pageCount: Math.ceil(result.data.totalCount / this.state.showPerPage), currentPage: page,
						loading: false, query, selectedIds: [], pageDataCount: result.data.items.length})
			if(_.isEmpty(query)){
				this.setState({fromDate: "", searchSku: "", toDate: ""})
			}
		})
	}

	handlePageClick = e => {
		this.getOrders((e.selected + 1), this.state.showPerPage, this.state.query)
	}

	triggerHanleSearch = e => {
		e.persist()
		if(e.key === 'Enter'){
			this.handleSearchSKU()
		}
	}

	handleSearchSKU(){
		this.setState({loading: true})
		if(_.isEmpty(this.state.searchSku)){
			this.getOrders(1, this.state.showPerPage, {})
		}else{
			axios.get(`/po/searchBySku/${this.state.searchSku}`)
			.then(result => {
				if(result.data === 2){
					NotificationManager.success("No Product found on this SKU")
					this.setState({loading: false})
				}else{
					this.setState({orders: result.data, productTotalCount: result.data.length,
						pageCount: Math.ceil(result.data.length / this.state.showPerPage), currentPage: 1,loading: false,
						selectedIds: []})
				}
			})
		}
	}

	showDateRangeData(){
        let errors = {}
		let fromDate = this.state.fromDate
		let toDate = this.state.toDate
        if(!isEmpty(fromDate) && !isEmpty(toDate)){
            let d = new Date(fromDate)
            if(d.getFullYear() > 9999){
                errors.fromDate = "From Date must be less than 9999"
            }else if(d.getFullYear() < 1950){
                errors.fromDate = "From Date must be greater than 1950"
            }
            let d1 = new Date(toDate)
            if(d1.getFullYear() > 9999){
                errors.toDate = "To Date must be less than 9999"
            }else if(d1.getFullYear() < 1950){
                errors.toDate = "To Date must be greater than 1950"
            }
            if(isEmpty(errors)){
                const date1 = new Date(fromDate);
                const date2 = new Date(toDate);
                const diffTime = date2 - date1
                const diffDays = (diffTime / (1000 * 60 * 60 * 24))
                if(diffDays < 0)
                    errors.toDate = "End date needs to be after From date"
                else{
					let obj = {
						from: fromDate,
						to: toDate,
						status: document.getElementById("selectStatus").value
					}
                    this.getOrders(1, this.state.showPerPage, JSON.stringify(obj))
                }
            }
            this.setState({errors})
        }else{
			let obj = {
				from: fromDate,
				to: toDate,
				status: document.getElementById("selectStatus").value
			}
			this.getOrders(1, this.state.showPerPage, JSON.stringify(obj))
		}
    }

	handleCheckBox = e => {
		e.persist()
		if(e.target.checked){
			this.setState({selectedIds: this.state.selectedIds.concat(e.target.value)})
		}else{
			this.setState({selectedIds: this.state.selectedIds.filter(id => id !== e.target.value)})
		}
	}
	handleSelect = (e) => {
		if(e.target.value !== "Select Status")
			this.setState({ selectedStatus: e.target.value })
		else
			this.setState({ selectedStatus: "" })
	}

	handleStatus = () => {
		const formData = {
			ids: this.state.selectedIds,
			selectedStatus: this.state.selectedStatus,
			user: jwt_decode(localStorage.getItem('token')).email
		}
		if(!_.isEmpty(this.state.selectedStatus)){
			axios.put(`/sscapi/purchase-order`, formData)
			.then(res => {
				if (res.data.status)
					this.getOrders(1, this.state.showPerPage, {})
				else
					NotificationManager.error("Failed to update")
			})
			.catch(err => {
				console.log("Error",err)
				NotificationManager.error("Something went wrong, Please try again later")
			})
		}else{
			NotificationManager.error("Please choose Any one Status")
		}
	}
	renderComponent = () => {
		// console.log(this.state.selectedIds);
		const formData = {
			ids: this.state.selectedIds, 
			count : this.state.barcodeCount
		}
		// console.log('this.state.barcodeCount 3: ', this.state.barcodeCount);
		if(!_.isEmpty(this.state.selectedIds)){
			// console.log('this.state.selectedIds: ', this.state.selectedIds);
			axios.post(`/sscapi/barcodeData`, formData)
			.then(res => {
				// console.log('res: ', res);
				// console.log('orders: ', this.state.orders);
				if(res.data.error == 0){ 
					let finalArray = []
					let barcodeResponse = res.data.response
					// console.log('barcodeResponse: ', barcodeResponse);
					for(let i = 0 ; i < barcodeResponse.length; i++){ 
					    for(let j = 0 ; j < barcodeResponse[i].length; j++){
							let sku =  barcodeResponse[i][j]['Sku'];
							// console.log('sku: ', sku);
							let orderData = this.state.orders.filter(ele => { 
								return ele.sku == sku;
							})
							// console.log('orderData: ', orderData);
							if(orderData[0]) {
								barcodeResponse[i][j]['orderNumber'] = orderData[0]['orderNumber'] || ""
							}
					        finalArray.push(barcodeResponse[i][j])
					    } 
					}
					// console.log('finalArray: ', finalArray);  

					this.setState({ barcodeStatus: 1 })
					this.setState({ barcodeData: finalArray})
					
				}else{
					NotificationManager.error("Something Went Wrong")
				}
			})
			.catch(err => {
				console.log("Error",err)
				NotificationManager.error("Something went wrong, Please try again later")
			})
		}else{
			NotificationManager.error("Please Select Any Order")
		} 
	}

	handleSelectAll = e => {
		e.preventDefault()
		if(!e.target.checked){
			for(let i = 0; i < this.state.pageDataCount; i++){
				document.getElementById(i).checked = false
			}
			this.setState({selectedIds: []})
		}else{
			let selected = []
			for(let i = 0; i < this.state.pageDataCount; i++){
				document.getElementById(i).checked = true
				selected.push(document.getElementById(i).value)
			}
			this.setState({selectedIds: selected})
		}
	}

	handleSelectFilter = e => {
		e.preventDefault()
        this.setState({searchSku: ""})
		this.showDateRangeData()
	} 

	bcCount = e => { 
		e.preventDefault()
		this.setState({barcodeCount: e.target.value})
	}

	downloadOrder(){
        let errors = {}
		let fromDate = this.state.fromDate
		let toDate = this.state.toDate
		let sku = document.getElementById("searchOrder").value
        if(!isEmpty(fromDate) && !isEmpty(toDate)){
            let d = new Date(fromDate)
            if(d.getFullYear() > 9999){
                errors.fromDate = "From Date must be less than 9999"
            }else if(d.getFullYear() < 1950){
                errors.fromDate = "From Date must be greater than 1950"
            }
            let d1 = new Date(toDate)
            if(d1.getFullYear() > 9999){
                errors.toDate = "To Date must be less than 9999"
            }else if(d1.getFullYear() < 1950){
                errors.toDate = "To Date must be greater than 1950"
            }
            if(isEmpty(errors)){
                const date1 = new Date(fromDate);
                const date2 = new Date(toDate);
                const diffTime = date2 - date1
                const diffDays = (diffTime / (1000 * 60 * 60 * 24))
                if(diffDays < 0)
                    errors.toDate = "End date needs to be after From date"
                else{
					let obj = {
						from 	: 	fromDate,
						to 		: 	toDate,
						status 	: 	document.getElementById("selectStatus").value,
						adminId : 	jwt_decode(localStorage.getItem('token')).id,
						page 	: 	this.state.currentPage, 
						limit 	: 	this.state.showPerPage
					}
                    this.setState({loading: true}) 
					axios.post(`/adminApi/downloadOrderCsv` , obj)
					.then(result => {
						// console.log('result: ', result);
						this.setState({ loading: false }) 
						// console.log(result.response);
						if(result.error == 0){
							window.open(result.response)
						}else if(result.error == 1){
							alert(result.response);
						}
					})
                }
            }
            this.setState({errors})
        }else if(!isEmpty(sku)){ 
        	let obj = { 
				sku: sku,
				adminId :jwt_decode(localStorage.getItem('token')).id,
				page 	: 	this.state.currentPage, 
				limit 	: 	this.state.showPerPage
			} 
            this.setState({loading: true}) 
			axios.post(`/adminApi/downloadOrderCsv` , obj)
			.then(data => {
				// console.log('data: ', data);
				let result = data.data;
				this.setState({ loading: false })  
				if(result.error == 0){
					window.open(result.response)
				}else if(result.error == 1){
					alert(result.response);
				} 
			}) 
        }else{
			let obj = {
				from: fromDate,
				to: toDate,
				status: document.getElementById("selectStatus").value,
				adminId :jwt_decode(localStorage.getItem('token')).id,
				page 	: 	this.state.currentPage, 
				limit 	: 	this.state.showPerPage
			} 
            this.setState({loading: true}) 
			axios.post(`/adminApi/downloadOrderCsv` , obj)
			.then(data => {
				// console.log('data: ', data);
				let result = data.data;
				this.setState({ loading: false })  
				if(result.error == 0){
					this.setState({ csvFile: result.response })
					window.open(result.response)
				}else if(result.error == 1){
					alert(result.response);
				} 
			}) 
		}
    }

	render(){
		return(
			<div>
				<SyncLoader css={override} size={15} margin={6} color={"#3E3E3E"} loading={this.state.loading} />
				{
					this.state.barcodeStatus ? (
						<div>
							<BarcodePo barcodeData={this.state.barcodeData} />
						</div>
					) : (
						<div>
							<div className="container-fluid">
								<div className="card" style={{padding: "1em" }}>
									<div className="row">
										<div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
											<div className="form-group">
												<h6>&nbsp;</h6>
												<input 
													type="text"
													className="form-control"
													placeholder="Seacrh by SKU"
													onKeyPress={ this.triggerHanleSearch}
													onChange={(e) => {
														this.setState({searchSku: e.target.value, errors: {}, fromDate: "", toDate: ""})
                                                        document.getElementById("selectStatus").selectedIndex = 0
													}}
													id="searchOrder"
													value={this.state.searchSku}
													autoComplete="off"
												/>
											</div>
										</div>
										<div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
											<div className="form-group">
												<h6>&nbsp;</h6>
												<select className="form-control" id="selectStatus" onChange={this.handleSelectFilter}>
													<option value="Select Status">Select Status</option>
													{
														statusArray.map((status, i) => {
															const { id, display } = status
															return(
																<option key={id} value={id}>{display}</option>
															)
														})
													}
												</select>
											</div>
										</div>
										<div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
											<div className="form-group">
												<h6>From Date &nbsp;<span onClick={() => document.getElementById("fromDate").value = ""} className="text-primary" style={{cursor: "pointer"}}>(Clear)</span></h6>
												<input 
													type="date"
													className="form-control"
													id="fromDate"
													max="9999-12-01"
													min="1950-12-31"
													placeholder="From Date"
													value={this.state.fromDate}
													onChange={ (e) => this.setState({fromDate: e.target.value, searchSku: ""}) }
												/>
												{
													!isEmpty(this.state.errors.fromDate)
													&&
														<span className="text-danger">{this.state.error.fromDate}</span>
												}
											</div>
										</div>
										<div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
											<div className="form-group">
												<h6>To Date &nbsp;<span onClick={() => document.getElementById("toDate").value = ""} className="text-primary" style={{cursor: "pointer"}}>(Clear)</span></h6>
												<input 
													type="date"
													className="form-control"
													id="toDate"
													max="9999-12-01"
													min="1950-12-31"
													placeholder="To Date"
													value={this.state.toDate}
													onChange={ (e) => this.setState({toDate: e.target.value, searchSku: ""}) }
												/>
												{
													!isEmpty(this.state.errors.toDate)
													&&
														<span className="text-danger">{this.state.errors.toDate}</span>
												}
											</div>
										</div>
										<div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
											<h6>&nbsp;</h6>
											<button className="btn btn-primary" style={{ backgroundColor: 'rgb(255, 160, 0)', color: 'rgb(255, 255, 255)', fontWeight: '500',    display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 4, border: '0px' ,'fontSize': '15px' , marginTop: '2px' ,   height: '40px'}} onClick={() => this.showDateRangeData()}>Get</button>
										</div>
										<div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
											<h6>&nbsp;</h6>
											<button className="btn btn-primary" style={{ backgroundColor: 'rgb(255, 160, 0)', color: 'rgb(255, 255, 255)', fontWeight: '500',    display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 4, border: '0px' ,'fontSize': '15px' , marginTop: '2px' ,   height: '40px'}} onClick={() => this.downloadOrder()}>Download</button>
											{
												this.state.csvFile ? (
													<div style={{ 'text-align': 'center', 'padding': '10px' }}> 
														 <a href={this.state.csvFile} target='_blank' title="If file not download automatically then click here">Click Here For File Download</a>
													</div>
												) : ''
											}
										</div>
									</div>
								</div>

								{
								!_.isEmpty(this.state.selectedIds)
								&&
									<div className="card" style={{padding: "1em" , paddingBottom : '0px'}}>
										<div className="row">
											<div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
												 <select className="form-control" onChange={this.handleSelect}>
													<option value="Select Status">Select Status</option>
													{
														statusArray.map((status, i) => {
															const { id, display } = status
															return(
																<option key={id} value={id}>{display}</option>
															)
														})
													}
												</select>
											</div>
											<div className="col-sm-12 col-md-12 col-lg-2 col-xl-2">
												 <button className="btn btn-outline-primary" style={{ backgroundColor: 'rgb(255, 160, 0)', color: 'rgb(255, 255, 255)', fontWeight: '500',    display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 4, border: '0px' ,'fontSize': '15px' }} onClick={this.handleStatus}>Change Status</button>
											</div>
											{/* <div className="col-sm-0 col-md-0 col-lg-4 col-xl-4">
												<div className="form-group"> 
													<input 
														type="text"
														className="form-control"
														placeholder="Barcode Count" 
														id="barcodeCount"
														onChange={this.bcCount}
														value={this.state.barcodeCount}
														autoComplete="off"
													/>
												</div>
											</div> */}
											<div className="col-sm-12 col-md-12 col-lg-2 col-xl-2">
												 <button className="btn btn-outline-primary" style={{ backgroundColor: 'rgb(255, 160, 0)', color: 'rgb(255, 255, 255)', fontWeight: '500',    display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 4, border: '0px' ,'fontSize': '15px' }} onClick={this.renderComponent}>Generate Barcode</button>
											</div> 
										</div> 
									</div>
								}
							</div>

							<div className="container-fluid">
								<div className="card" style={{padding: "1em"}}> 
									<div className="row">
										<div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
											<div style={{cursor: "pointer"}} onClick={() => this.getOrders(1, this.state.showPerPage, {})}>
												<div className="form-group">
													<h5 className="text-bold">Total Orders: <span className="text-muted">{this.state.productTotalCount}</span>
														&nbsp;<i className="fa fa-refresh" aria-hidden="true"></i>
													</h5>
												</div>
											</div>
										</div>
									</div>
									<table className="table table-hover">
										<thead>
											<tr>
												<th scope="col">Sl.No
													<div>
														<input type="checkbox" id="selectAll" name="selectAll" onChange={this.handleSelectAll.bind(this)}></input>
													</div>
												</th>
												<th scope="col">Date</th>
												<th scope="col">Supplier</th>
												<th scope="col">Title</th>
												<th scope="col">SKU</th> 
												<th scope="col">Status</th>
											</tr>
										</thead>
										<tbody>
											{
												!isEmpty(this.state.orders)
												?
													this.state.orders.map((order, i) => {
														const {date, title, sku, qty,supplier, status, _id} = order
														return (
														<tr key={i}>
															<td><input type="checkbox" id={i} value={_id} onChange={this.handleCheckBox.bind(this)} /></td>
															<td>{ date }</td>
															<td>{ supplier }</td>
															<td>{ title }</td>
															<td>{ sku }</td> 
															<td>{ 
																statusArray.map(status1 => {
																	const {id, value} = status1
																	return (
																		status === id ? value : ""
																	)
																})
															}</td>
														</tr>
														)
														
													})
												:
													<tr>
														<td colSpan="5" className="text-center"><h4>No Data Found</h4></td>
													</tr>
											}
										</tbody>
									</table>
									<ReactPaginate 
										previousLabel={'<'}
										nextLabel={'>'}
										breakLabel={'...'}
										breakClassName={'break-me'}
										pageCount={ this.state.pageCount }
										marginPagesDisplayed={3}
										pageRangeDisplayed={10}
										onPageChange={(e)=> this.handlePageClick(e)}
										containerClassName={'pagination'}
										subContainerClassName={'pages pagination'}
										activeClassName={'active'}
									/>
								</div>
							</div>
						</div> 
					)
				} 
			</div>
		)}
}