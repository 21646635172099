import React, {useEffect, useState} from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
function CustomerView(props) {
    const [customer, setCustomer] = useState({})

    useEffect(()=> { 
        axios.get(`/sscapi/customer/${props.match.params.id}`, {
            headers: {
                'auth-token': localStorage.getItem('token')
            }
        })
            .then(res => { 
                if (res.data) {
                    setCustomer(res.data)
                }
            })
            .catch(err => {
                console.log("Error",err)
            })
    },[])
    const handleVerify = () => { 
        const formdata = {
            companyName: customer.companyName,
            gstNumber: customer.gstNumber,
            mobileNumber: customer.mobileNumber
        }
        axios.put(`/sscapi/gst-verify/${props.match.params.id}`, formdata, {
            headers: {
                'auth-token': localStorage.getItem('token')
            }
        })
            .then(res => { 
                if (res.data.newData) {
                    setCustomer(res.data.newData)
                    Swal.fire({
                        icon: 'success',
                        title: 'Done',
                        text: res.data.message
                    })
                } else { 
                    Swal.fire(res.data)
                }
            })
            .catch(err => {
                console.log("Error",err)
            })
    }
    return (
        <div className="content bdycontent merchantStore">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div style = {{ padding: "10px" }}>
                                {/* <h4 className="card-title text-center">Customer Details</h4> */}
                                <table className="table table-striped table-hover">
                                    <thead>
                                        <tr><th scope="col" colSpan="2"><p>Basic Information</p></th></tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><b>First Name</b></td>
                                            <td>{ customer.firstName }</td>
                                        </tr>
                                        <tr>
                                            <td><b>Last Name</b></td>
                                            <td>{ customer.lastName }</td>
                                        </tr>
                                        <tr>
                                            <td><b>Email</b></td>
                                            <td>{ customer.email }</td>
                                        </tr>
                                        <tr>
                                            <td><b>GST Number</b></td>
                                            <td>{ 
                                                customer.gstNumber ? (
                                                    <p>{ customer.gstNumber.toUpperCase() } { !customer.isGstVerified && <Link onClick={()=> handleVerify()}> verify </Link>} </p>
                                                ) : (
                                                    <b>NA <Link to={`/merchant/edit-customer/${props.match.params.id}`}>Click here </Link>to add</b>
                                                )
                                            } 
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><b>Store Domain</b></td>
                                            <td>{ customer.storeDomain }</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="table table-striped table-hover">
                                    <thead>
                                        <tr><th scope="col" colSpan="2"><p>GST Information</p></th></tr>
                                    </thead>
                                        {
                                            customer.gstData ? (
                                                <tbody>
                                                    <tr>
                                                        <td><b>GSTIN of the Tax Payer</b></td>
                                                        <td>{ customer.gstData.gstin }</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>State Code</b></td>
                                                        <td>{ customer.gstData.stjCd }</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Legal Name of Business</b></td>
                                                        <td>{ customer.gstData.lgnm }</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>State Jurisdiction</b></td>
                                                        <td>{ customer.gstData.stj }</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Centre Jurisdiction</b></td>
                                                        <td>{ customer.gstData.ctj }</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Taxpayer Type</b></td>
                                                        <td>{ customer.gstData.dty }</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Date of Registration	</b></td>
                                                        <td>{ customer.gstData.rgdt }</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Last Update	</b></td>
                                                        <td>{ customer.gstData.lstupdt }</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Trade Name</b></td>
                                                        <td>{ customer.gstData.tradeNam }</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Principal Place of Business</b></td>
                                                        <td>{customer.gstData.pradr.addr.loc} ,{ customer.gstData.pradr.addr.st }, {customer.gstData.pradr.addr.dst},{customer.gstData.pradr.addr.stcd} - {customer.gstData.pradr.addr.pncd}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Constitution of Business</b></td>
                                                        <td>{ customer.gstData.ctb }</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Nature of Business</b></td>
                                                        <td><ul>{ customer.gstData.nba.map(data => <li key={Math.random()}>{data}</li>) }</ul></td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>GSTN Status</b></td>
                                                        <td>{ customer.gstData.sts }</td>
                                                    </tr>
                                                </tbody>
                                            ) : (
                                                <tbody>
                                                    <tr><td>There is no GST data for {customer.firstName} {customer.lastName} <Link to={`/merchant/edit-customer/${props.match.params.id}`}>Click here </Link>to add / edit</td></tr>
                                                </tbody>
                                            )
                                        }
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomerView