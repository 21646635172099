import React, { Component } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { NotificationManager } from 'react-notifications';
import isEmpty from 'is-empty'
import ReactPaginate from 'react-paginate'
import SyncLoader from "react-spinners/SyncLoader"
import _ from 'lodash'
// import BarcodePo from './BarcodePo'
import { Link, Redirect } from 'react-router-dom';
const override = `
	position: fixed;
	display: block;
	top: 45%;
	left: 50%;
	transform: translateX(-50%);
	transform: translate(-50%, -50%);
	z-index: 1;
`


export default class OrderProcess extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pageDataCount: 0,
            loading: true,
            orders: [],
            currentPage: 1,
            showPerPage: 1000,
            pageCount: 0,
            productTotalCount: 0,
            query: {},
            fromDate: "",
            toDate: "",
            errors: {},
            searchSku: "",
            selectedIds: [],
            selectedStatus: 0,
            barcodeStatus: 0,
            allSelected: false,
            csvFile: '',
            barcodeCount: 1,
            barcodeData: [],
            handovername:'',
            file:''
        }
    }

    componentDidMount() {
        this.getOrders(this.state.currentPage, this.state.showPerPage, this.state.query)
    }

    getOrders(page, perPage, query) {
        this.setState({ loading: true })
        axios.get(`/orderProcess/getallOrderProcess?&id=${jwt_decode(localStorage.getItem('token')).id}&page=${page}&limit=${perPage}&query=${!_.isEmpty(query) ? query : "{}"}`)
            .then(result => {
                // console.log('result: ', result);
                let orders = result.data.items;
                // console.log('orders: ', orders);
                // let mappedData = []
                // if(result && result.data && result.data.items && result.data.items.length > 0) {
                //     orders.forEach(element => {
                //         if(element['boxId'] && element['orderData'] &&  element['orderData'].length > 0){
                //             element['orderData'].forEach(element2 => {
                //                 let tempData = {
                //                     boxId:element.boxId,handovername:element.handoverName, ...element2
                //                 }
                //                 mappedData.push(tempData)
                //             });

                //         }
                //     });
                // }
                // console.log('mappedData: ', mappedData);
                this.setState({
                    orders:orders , productTotalCount: result.data.totalCount,
                    
                    pageCount: Math.ceil(result.data.totalCount / this.state.showPerPage), currentPage: page,
                    loading: false, query, selectedIds: [], pageDataCount: result.data.items.length
                })
                if (_.isEmpty(query)) {
                    this.setState({ fromDate: "", searchSku: "", toDate: "" })
                }
            })
    }

    handlePageClick = e => {
        this.getOrders((e.selected + 1), this.state.showPerPage, this.state.query)
    }




    // handleCheckBox = e => {
    //     e.persist()
    //     if (e.target.checked) {
    //         this.setState({ selectedIds: this.state.selectedIds.concat(e.target.value) })
    //     } else {
    //         this.setState({ selectedIds: this.state.selectedIds.filter(id => id !== e.target.value) })
    //     }
    // }
    renderComponent = () => {
        // console.log(this.state.selectedIds);
        const formData = {
            ids: this.state.selectedIds,
            count: this.state.barcodeCount
        }
        // console.log('this.state.barcodeCount 1 : ', this.state.barcodeCount);
        if (!_.isEmpty(this.state.selectedIds)) {
            // console.log('this.state.selectedIds: ', this.state.selectedIds);
            axios.post(`/sscapi/barcodeData`, formData)
                .then(res => {
                    // console.log('res: ', res);
                    if (res.data.error == 0) {
                        let finalArray = []
                        let barcodeResponse = res.data.response
                        // console.log('barcodeResponse: ', barcodeResponse);
                        for (let i = 0; i < barcodeResponse.length; i++) {
                            for (let j = 0; j < barcodeResponse[i].length; j++) {
                                finalArray.push(barcodeResponse[i][j])
                            }
                        }
                        this.setState({ barcodeStatus: 1 })
                        this.setState({ barcodeData: finalArray })
                    } else {
                        NotificationManager.error("Something Went Wrong")
                    }
                })
                .catch(err => {
                    console.log("Error", err)
                    NotificationManager.error("Something went wrong, Please try again later")
                })
        } else {
            NotificationManager.error("Please Select Any Order")
        }
    }

    handleSku = e => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                handovername: ""
            },
            handovername: e.target.value
        }))
    }

    handleVarientMultipleImages = e => {
        let file = e.target.files[0]
        // console.log(e.target.files);
        // console.log('file: ', file);
        this.setState({ file: file })
    }


    uploadCsv = () => {
        // console.log('Uploading CSV');
        let file = this.state.file
        // e.preventDefault();
        const scvdata = new FormData();
        this.setState({loading:true})
        scvdata.append('csv', file);
        scvdata.append('handoverName', this.state.handovername);
        axios
          .post('orderProcess/bulkAddOrderProcess', scvdata)
          .then((res) => {
            // console.log('res: ', res);
            if (res.data.status == 1) {
              NotificationManager.success('Records added');
            } else {
              res.data.error
                ? NotificationManager.error(res.data.error.toString())
                : NotificationManager.error('There is a problem with this csv');
            }
            this.setState({loading:false})
          })
          .catch((error) => {
            this.setState({loading:false})
            error
              ? NotificationManager.error(error.toString())
              : NotificationManager.error('There is a problem with this csv');
          });
    }



    render() {
        return (
            <div>
                <SyncLoader css={override} size={15} margin={6} color={"#3E3E3E"} loading={this.state.loading} />
                {
                        <div>
                            <div className="container-fluid">
                                <div className="card" style={{ padding: "1em" }}>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                        <div className='form-group'>
                                                    {/* <label style={{ fontSize: 15 }} htmlFor='handovername'>
                                                        SKU &nbsp;
                                                        <span className="custom-tooltip" data-tooltip="handovername">
                                                            <i class="fa fa-question-circle" aria-hidden="true"></i>
                                                        </span>
                                                    </label> */}
                                                    <input
                                                        type='text'
                                                        value={this.state.handovername}
                                                        onChange={this.handleSku.bind(this)}
                                                        className='form-control'
                                                        placeholder='handovername'
                                                        id="handovername"
                                                        autoComplete="off"
                                                    />
                                                </div>
                                        </div>
                                        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4" style={{ paddingRight: '0px' }}>
                                            {/* <label className="btn btn_new">
                                                Select CSV */}
                                                <input type="file"
                                                // className="hidden"
                                                single="true"
                                                encType='multipart/form-data'
                                                accept='.csv'
                                                onChange={(e) => this.handleVarientMultipleImages(e)}/>
                                            {/* </label> */}
                                        </div>
                                        {/* https://seller.shopperr.in/media/OrderProcessSample.csv */}
                                        <div className="col-sm-12 col-md-2 col-lg-2 col-xl-2" style={{ paddingLeft:'10px', paddingRight: '0px' }}>
                                            <a href="https://seller.shopperr.in/media/OrderProcessSample.csv" download>Sample CSV</a>
                                        </div>
                                        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4" style={{ paddingLeft: '0px' }}>

                                            <h4 >
                                                    <button onClick={() => this.uploadCsv()} className='btn btn-fill' style={{
                                                        backgroundColor: 'rgb(255, 160, 0)',
                                                        color: 'rgb(255, 255, 255)',
                                                        fontWeight: '500',
                                                        height: "40px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        borderRadius: 4,
                                                        border: '0px',
                                                        'fontSize': '15px'
                                                    }}>Upload Csv
                                                    </button>
                                            </h4>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="container-fluid">
                                <div className="card" style={{ padding: "1em" }}>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
                                            <div style={{ cursor: "pointer" }} onClick={() => this.getOrders(1, this.state.showPerPage, {})}>
                                                <div className="form-group">
                                                    <h5 className="text-bold">Total Order Process: <span className="text-muted">{this.state.productTotalCount}</span>
                                                        &nbsp;<i className="fa fa-refresh" aria-hidden="true"></i>
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">order Number</th>
                                                <th scope="col">description</th>
                                                <th scope="col">SKU</th>
                                                <th scope="col">amount</th>
                                                <th scope="col">handovername</th>
                                                <th scope="col">boxId</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                !isEmpty(this.state.orders)
                                                    ?
                                                    this.state.orders.map((order, i) => {
                                                        const {orderNumber,description, poId, variant, sku, qty, supplierId, productId, amount, handoverName, handoverAddress, boxId = "", _id } = order
                                                        return (
                                                            <tr key={i}>
                                                                {/* <td><input type="checkbox" id={i} value={_id} onChange={this.handleCheckBox.bind(this)} /></td> */}
                                                                <td>{orderNumber}</td>
                                                                <td>{description}</td>
                                                                <td>{sku}</td>
                                                                <td>{amount}</td>
                                                                <td>{handoverName}</td>
                                                                <td>{boxId}</td>
                                                            </tr>
                                                        )

                                                    })
                                                    :
                                                    <tr>
                                                        <td colSpan="5" className="text-center"><h4>No Data Found</h4></td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                    {/* <ReactPaginate 
										previousLabel={'<'}
										nextLabel={'>'}
										breakLabel={'...'}
										breakClassName={'break-me'}
										pageCount={ this.state.pageCount }
										marginPagesDisplayed={3}
										pageRangeDisplayed={10}
										onPageChange={(e)=> this.handlePageClick(e)}
										containerClassName={'pagination'}
										subContainerClassName={'pages pagination'}
										activeClassName={'active'}
									/> */}
                                </div>
                            </div>
                        </div>
                }
            </div>
        )
    }
}