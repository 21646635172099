import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {Link} from 'react-router-dom'
import { NotificationManager } from 'react-notifications'
import _ from 'lodash'

function ShowOrder(props) {
    const [order, setOrder] = useState({})
    const [PO, setPO] = useState([])
    const [s3Url, setS3Url] = useState("")
    const [poErrorStatus, setPOError] = useState("")
    useEffect(()=> {
        axios.get(`/sscapi/order/${props.match.params.id}`)
        .then(res=> {
            const order = res.data.order
            console.log(order)

            setOrder(order)
            setPO(res.data.poData)
            setS3Url(res.data.src)
            if(_.isEmpty(res.data.poData)){
                // console.log("here...")
                setPOError("Something Went Wrong - Product HSN or SKU not found. Please update product and request to generate the invoice again.")
                // if(order.poErrorStatus == 1) setPOError("Invoice generation was failed due to, SKU / HSN not found in the database")
                // else if(order.poErrorStatus == 2) setPOError("Invoice generation was failed due to, Merchant not exists")
            }
        })
    },[])

    const requestInvoice = () => {
        axios.put(`/sscapi/order/${props.match.params.id}`)
        .then(res => {
            NotificationManager.success("Request sent. Please wait for some time to get process")
            document.getElementById("alertMsg").style.display='none'
        }).catch(err => {
            console.log(err)
            NotificationManager.error("Failed to send request. Try after some some")
        })
    }


    return (
        <div className="container-fluid">
            {
                Object.keys(order).length && (
                    <div>
                        <div className="row">
                            {
                                poErrorStatus
                                &&
                                    <div class="alert alert-danger" role="alert" id="alertMsg">
                                        {poErrorStatus}.&emsp;
                                        <a href="#" onClick={requestInvoice}>Request to generate invoice</a>
                                    </div>
                            }
                        </div>
                        <div className="row ">
                            <div className="col-md-6"><br/>
                                <h5>Order { order.orderNumber }</h5>
                                <ul className="list-group list-group-flush card">
                                    <li className="list-group-item">Created on - { order.created_on }</li>
                                    <li className="list-group-item">Order Amount - { order.price }</li>
                                    <li className="list-group-item">Store - { order.storeDomain }</li>
                                    <li className="list-group-item">Payment Status - { order.pStatus ? order.pStatus : '' }</li> 
                                    <li className="list-group-item">Awb Number - { order.tracking_number ? order.tracking_number : '' }</li> 
                                    <li className="list-group-item">Billing Address - { order.billingAddress ? order.billingAddress.address1 : 'Null' }</li>
                                    <li className="list-group-item">Shipping Address - { order.shippingAddress ?  order.shippingAddress.address1 : 'Null' }</li>
                                </ul>
                            </div>
                            <div className="col-md-6 "><br/>
                                <h5>Customer - { order.customer.first_name } { order.customer.last_name }</h5  >
                                <ul className="list-group list-group-flush card">
                                    <li className="list-group-item">Email - { order.customer.email }</li>
                                    <li className="list-group-item">Total Spent - { order.customer.total_spent }</li>
                                    <li className="list-group-item">Note -  { order.customer.note }</li>
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h5>Products</h5>
                                <div className="card" style={{ margin: '4px, 4px',padding: '4px',textAlign: 'justify'} }>
                                    {
                                        order.products.length && (
                                            <div style={{overflow: 'scroll', height: "200px"}}>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">SKU</th>
                                                            <th scope="col">Title</th>
                                                            <th scope="col">Fulfillment Service</th>
                                                            <th scope="col">Quantity</th>
                                                            <th scope="col">Price</th>
                                                            <th scope="col">Total Price</th> 
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                        order.products.map(data=> {
                                                            return (
                                                                <tr key={Math.random()}>
                                                                    <td>{data.sku ? data.sku : ( data.code ? data.code : '')}</td>
                                                                    <td>{data.title}</td>
                                                                    <td>{data.fulfillment_service}</td>
                                                                    <td>{data.quantity}</td>
                                                                    <td>{data.price}</td>
                                                                    <td>{data.price * data.quantity}</td> 
                                                                </tr>
                                                            )
                                                        })
                                                        }
                                                    </tbody>
                                                </table>                         
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h5>Invoice PO</h5>
                                <div className="card" style={{ margin: '4px, 4px',padding: '4px',textAlign: 'justify'} }>
                                {
                                    PO && (
                                        <div style={{overflow: 'scroll', height: "200px"}}>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Order Number</th>
                                                        <th scope="col">SKU</th>
                                                        <th scope="col">PO Quantity</th>
                                                        <th scope="col">Quantity Ordered</th>
                                                        <th scope="col">Shipping Amount</th>
                                                        <th scope="col">Total Amount</th>
                                                        <th scope="col">Net Amount</th>
                                                        <th scope="col">HSN</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                    PO.map(data=> {
                                                        return (
                                                            <tr key={Math.random()}>
                                                                <td>{data.orderNumber}</td>
                                                                <td>{data.sku ? data.sku : ( data.code ? data.code : '')}</td>
                                                                <td>{data.orderAmt.quantity}</td>
                                                                <td>{data.qtyOrdered}</td>
                                                                <td>{data.orderAmt.totalShipping}</td>
                                                                <td>{data.orderAmt.totalAmount}</td>
                                                                <td>{data.orderAmt.totalNetAmount}</td>
                                                                <td>{data.hsn}</td>
                                                                <td>
                                                                    {
                                                                        !_.isEmpty(data.invoicePdf)
                                                                        &&
                                                                            <div class="dropdown show">
                                                                                <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                    Download Invoice
                                                                                </a>

                                                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                                    {/* <Link class="dropdown-item" target="_blank" to={`/pinvoice/${data._id}/original`}>Original</Link>
                                                                                    <Link class="dropdown-item" target="_blank" to={`/pinvoice/${data._id}/performa`}>Performa</Link>
                                                                                    <Link class="dropdown-item" target="_blank" to={`/pinvoice/${data._id}/duplicate`}>Duplicate</Link> */}
                                                                                    <li class="dropdown-item"><a target="_blank" href={`${s3Url}/${data.invoicePdf[0].original}`}>Original</a></li>
                                                                                    <li class="dropdown-item"><a target="_blank" href={`${s3Url}/${data.invoicePdf[1].performa}`}>Performa</a></li>
                                                                                    <li class="dropdown-item"><a target="_blank" href={`${s3Url}/${data.invoicePdf[2].duplicate}`}>Duplicate</a></li>
                                                                                </div>
                                                                            </div>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    }
                                                </tbody>
                                            </table>                         
                                        </div>
                                    )
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
           
        </div>
    )
}

export default ShowOrder