import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios'
import { NotificationManager } from 'react-notifications'
import jwt_decode from 'jwt-decode'
import _ from 'lodash'
import ReactPaginate from 'react-paginate'
import '../assets/css/logisticsInventory.css'
import SyncLoader from 'react-spinners/SyncLoader'
import { Route, Switch, Redirect, useHistory } from 'react-router-dom'
import CustomButton from '../components/CustomButton/CustomButton';
const moment = require('moment');
const override = `
	position: fixed;
	display: block;
	top: 45%;
	left: 50%;
	transform: translateX(-50%);
	transform: translate(-50%, -50%);`
const DetailsView = (props) => {
    const { wareCode, wareEmail } = props
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(5)
    const [pageCount, setPageCount] = useState(0)
    const [query, setQuery] = useState({})
    const [loading, setLoading] = useState(true)
    const [total, setTotal] = useState()
    const [modalShow, setModalShow] = useState(false)
    const [deleteid, setDeleteId] = useState('')
    const [rdata, setRedirect] = useState({ r: false, data: '' })
    useEffect(() => { getLogisticproducts(page, perPage, query) }, [])
    const getLogisticproducts = (page, perPage, query) => {
        axios.get(`/sscapi/viewwinventoryproducts?page=${page}&limit=${perPage}&query=${!_.isEmpty(query) ? query : "{}"}`)
            .then(result => {
                console.log('result : ', result)
                setData(result.data.items)
                setLoading(false)
                setTotal(result.data.totalCount)
                setPageCount(Math.ceil(result.data.totalCount / perPage))
                setPage(1)
            }).catch((err) => {
                console.log(err)
            })
    }
    const handlePageClick = e => {
        getLogisticproducts((e.selected + 1), perPage, query)
    }
    // {
    //     !_.isEmpty(data) ? data.map((ele, i) => {
    //         return (<tr key={i} className="d-flex">
    //             <td>{i}</td>
    //             <td>{ele.lPq}</td>
    //             <td>{ele.lpri}</td>
    //             <td>{ele.sku}</td>
    //             <td>{ele.updatedAt}</td>
    //             <td>{ele.createdAt}</td>
    //         </tr>)
    //     }) : ''
    // }


    return <>
        {/* <h1>Warehouse code {wareCode}</h1> */}
        <div className='container-fluid'>
            {(rdata.r) ? (<Redirect
                to={{
                    pathname: "/logisticUser/skuDetails",
                    state: rdata.data
                }}
            />) : ('')}
            <div className='card' style={{ padding: '1em' }}>
                <SyncLoader css={override} size={15} margin={6} color={"#3E3E3E"} loading={loading} />
                <div style={{ cursor: "pointer" }}>
                    <div className="form-group">
                        <h5 className="text-bold">Total Products in Inventory : <span className="text-muted">{total}</span>
                            &nbsp;<i class="fa fa-refresh" aria-hidden="true" onClick={() => getLogisticproducts(1, perPage, {})}></i>
                        </h5>
                    </div>
                </div>

                <div className="table-responsive">
                    <table className="table table-hover">
                        <thead>
                            <tr className="d-flex">
                                <th scope="col" >Sl no.</th>
                                <th scope="col" >Inventory Quantity</th>
                                <th scope="col" >Reserve Inventory</th>
                                <th scope="col" >Product SKU</th>
                                <th scope="col" >Warehouse ID</th>
                                <th scope="col" >Warehouse Code</th>
                                <th scope="col" >UpdatedAt</th>
                                <th scope="col" >CreatedAt</th>

                            </tr>
                        </thead>
                        <tbody>
                            {
                                !_.isEmpty(data) ? data.map((ele, i) => {
                                    return (<tr key={i} className="d-flex">
                                        <td>{i + 1}</td>
                                        <td>{ele.lpq}</td>
                                        <td>{ele.lpri}</td>
                                        <td>{ele.sku}</td>
                                        <td>{ele.pwid}</td>
                                        <td>{ele.wc}</td>
                                        <td>{moment(new Date(ele.updatedAt)).format('MM.DD.YYYY HH:mm')}</td>
                                        <td>{moment(new Date(ele.createdAt)).format('MM.DD.YYYY HH:mm')}</td>
                                        <td>
                                            <i className="fa fa-info-circle" style={{ cursor: "poiner", fontSize: "22px" }}
                                                aria-hidden="true" onClick={() => setRedirect({ r: true, data: ele })}></i>
                                        </td>
                                    </tr>)
                                }) : ''
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={3}
            pageRangeDisplayed={10}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
        />
    </>
}


const UploadProducts = (props) => {
    const { wareCode, wareEmail, wareCodeId } = props
    console.log('inside upload products', wareCodeId)
    const [productCsv, setProductCsv] = useState([])
    const upload = (e) => {
        e.preventDefault()
        console.log('inside upload')
        const uploadData = new FormData()
        uploadData.append('productCsv', productCsv[0])
        uploadData.append('wareCode', wareCode)
        uploadData.append('userEmail', wareEmail)
        uploadData.append('wareCodeId', wareCodeId)
        axios
            .post('/sscapi/uploadCsv', uploadData)
            .then((data) => {
                console.log(data.data)
                // alert('Warehouse added')
                NotificationManager.success('Warehouse added.', 'Succesfully Added');
            })
            .catch((err) => {
                console.log(err.message)
                NotificationManager.error(err.message)
            })
    }
    const clicktest = () => {
        let test = { test: "https://static-staging-sellercentral.shopperr.in/import_export/2021/5/uploadmu8rMm_jsunday17sumit@sumit.com.csv" }
        axios.post('/sscapi/testlog', test).then((response) => {
            console.log(response.data)
        }).catch((err) => {
            // alert(err.message)
        }, [])
    }
    return <>
        <div>
            <div className="labeldiv">
                <label className='label'>Upload Products Csv File</label>
            </div>
            <form onSubmit={upload}>
                <div id='formdiv'>
                    <div>
                        <input
                            required
                            type='file'
                            className='form-control text-center'
                            name='avatar'
                            onChange={(e) => {
                                setProductCsv(e.target.files)
                            }}
                            encType='multipart/form-data'
                            accept='.csv'
                        />
                    </div>
                    <div>
                        <div className='submit'>
                            <button className='btn btn-primary'>Upload</button>
                        </div>
                    </div>
                </div>
            </form>

            {/* <button className='btn btn-primary' onClick={clicktest}>test</button> */}
        </div>
    </>
}

const InventoryDetails = (props) => {
    const token = localStorage.getItem('token')
    let decode = jwt_decode(token)
    const [wareCode, setWareCode] = useState('')
    const [wareCodeId, setWareCodeId] = useState('')
    const [wareEmail, setEmail] = useState('')
    useEffect(() => {
        axios.get(`/sscapi/view/${decode.email}`).then((response) => {
            console.log('view user data', response.data)
            setEmail(response.data.email)
            setWareCode(response.data.warehouse)
            setWareCodeId(response.data.warehouseId)
        }).catch((err) => {
            console.log(err.message)
        }, [])
    }, [])
    return (
        <div id='parent'>
            <div>
                <UploadProducts wareCode={wareCode} wareEmail={wareEmail} wareCodeId={wareCodeId} />
            </div>
            <div>
                <DetailsView wareCode={wareCode} />
            </div>
        </div>)

}


export default InventoryDetails