import React, { useState, useEffect } from "react";

import axios from "axios";
import "../assets/css/Landing.css";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/owl.carousel/dist/assets/owl.carousel.css";
import "../../node_modules/owl.carousel/dist/assets/owl.theme.default.css";
import "../../node_modules/bootstrap/dist/js/bootstrap.js";
import * as Icon from "react-bootstrap-icons";
import "../assets/css/new_template.css";
import {
  Button,
} from "react-bootstrap";


const Landing1 = () => {
  const [searchInput, setSearchInput] = useState("");
  const [productData, setProductData] = useState([]);
  const [titleCheckbox, setTitleCheckbox] = useState(false);
  const [descriptionCheckbox, setDescriptionCheckbox] = useState(false);
  const [tagCheckbox, setTagCheckbox] = useState(false);

  useEffect(() => {
    getchPage();
  }, []);

  const getchPage = () => {};
  const fetchData = async (keyword) => {
    try {
      const data = await axios.post("/sscapi/search-solr-products", {
        keyword,
        limit: 10,
        page: 1,
        title: titleCheckbox,
        description: descriptionCheckbox,
        tags: tagCheckbox,
      });
      return {
        numFounds: data.numFounds,
        status: 200,
        result: data.data,
      };
    } catch (err) {
      return {
        numFounds: 0,
        status: 500,
        result: [],
      };
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();
    const { result, numFounds } = await fetchData(searchInput);
    setProductData(result.data);
    console.log(result.data);
  };

  const handleChangeInput = (event) => {
    setSearchInput(event.target.value);
  };

  const handleChangeTitleCheckBox = (event) => {
    setTitleCheckbox(event.target.checked);
  };

  const handleChangeDescriptionCheckbox = (event) => {
    setDescriptionCheckbox(event.target.checked);
  };

  const handleChangeTagCheckbox = (event) => {
    setTagCheckbox(event.target.checked);
  };

  return (
    <div style={{ overflow: "hidden" }}>
      {/*header starts*/}
      <div class="top-index">
        <div class="container">
          <nav class="navbar navbar-default" role="navigation" id="fixed-nav">
            <div class="container-fluid">
              <div class="navbar-header">
                <button
                  type="button"
                  class="navbar-toggle"
                  data-toggle="collapse"
                  data-target="#example-navbar-collapse"
                >
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
                </button>
                <a class="navbar-brand" href="#">
                  <img
                    src={process.env.REACT_APP_S3_URL.trim()+'/img/index-top-logo1.png'}
                    alt="Shopperr: India Dropshipping Supplier & Wholesaler"
                  />
                </a>
              </div>
              <div
                class="collapse navbar-collapse"
                id="example-navbar-collapse"
              >
                <ul class="nav navbar-nav">
                  <li>
                    <a onclick="sendEventToga(4,0)" href="/search/hotproducts">
                      store
                    </a>
                  </li>
                  <li>
                    <a onclick="sendEventToga(2,0)" href="/user/pricing">
                      pricing
                    </a>
                  </li>
                  <li>
                    <a onclick="sendEventToga(20,0)" href="/index/features">
                      Features
                    </a>
                  </li>
                  <li>
                    <a onclick="sendEventToga(3,0)" href="/index/help">
                      help
                    </a>
                  </li>
                  <li>
                    <em></em>
                  </li>
                  <li>
                    <div class="dropdown">
                      <button
                        class="sign_up dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Log In <Icon.ChevronDown className="ml-4" />
                      </button>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a class="dropdown-item" href="/login-admin">
                          <Icon.GearFill className="ml-4" /> Admin
                        </a>
                        <a class="dropdown-item" href="/login-supplier">
                          <Icon.People className="ml-4" /> Supplier
                        </a>
                        <a class="dropdown-item" href="/login-merchant">
                          <Icon.Shop className="ml-4" /> Merchant
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>

        <div class="fixnav-wrap fixnav-wrap-top">
          <nav class="fixnav" role="navigation" id="fixed-nav">
            <div class="container-fluid">
              <div class="navbar-header">
                <button
                  type="button"
                  class="navbar-toggle"
                  data-toggle="collapse"
                  data-target="#fixnav-btn"
                >
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
                </button>
                <a class="navbar-brand" href="/">
                  <img
                    src={process.env.REACT_APP_S3_URL.trim()+'/img/fixed_logo.png'}
                    alt="Shopperr: India Dropshipping Supplier & Wholesaler"
                  />
                </a>
              </div>
              <div class="collapse navbar-collapse" id="fixnav-btn">
                <ul class="nav navbar-nav">
                  <li>
                    <a onclick="sendEventToga(4,0)" href="/search/hotproducts">
                      store
                    </a>
                  </li>
                  <li>
                    <a onclick="sendEventToga(2,0)" href="/user/pricing">
                      pricing
                    </a>
                  </li>
                  <li>
                    <a onclick="sendEventToga(20,0)" href="/index/features">
                      Features
                    </a>
                  </li>
                  <li>
                    <a onclick="sendEventToga(3,0)" href="/index/help">
                      help
                    </a>
                  </li>
                  <li>
                    <em></em>
                  </li>
                  <li>
                    <a href="/dropship-member-sign-in.html">log in</a>
                  </li>
                  <li>
                    <a href="/free-dropship-membership.html" class="sign_up">
                      sign up
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
      {/*header ends*/}

      <div class="search-container">
        <div class="container">
          <div class="row">
            <form onSubmit={handleClick}>
              <fieldset>
                <legend>WHAT ARE YOU LOOKING FOR?</legend>
                <div class="inner-form">
                  <div class="input-field">
                    <div
                      class="choices"
                      data-type="text"
                      aria-haspopup="true"
                      aria-expanded="false"
                      dir="ltr"
                    >
                      <div class="choices__inner">
                        <input
                          type="search"
                          class="form-control  choices__input"
                          id="search-input"
                          placeholder="Search..."
                          aria-label="Search for..."
                          autocomplete="off"
                          spellcheck="false"
                          role="combobox"
                          aria-autocomplete="list"
                          aria-expanded="false"
                          aria-owns="algolia-autocomplete-listbox-0"
                          dir="auto"
                          name="search"
                          value={searchInput}
                          onChange={handleChangeInput}
                          required
                        />
                        <label for="title"> Title</label>
                        <input
                          type="checkbox"
                          id="title"
                          checked={titleCheckbox}
                          onChange={handleChangeTitleCheckBox}
                        />

                        <label for="description"> Description</label>
                        <input
                          type="checkbox"
                          id="description"
                          checked={descriptionCheckbox}
                          onChange={handleChangeDescriptionCheckbox}
                        />

                        <label for="tag"> Tags</label>
                        <input
                          type="checkbox"
                          id="tag"
                          checked={tagCheckbox}
                          onChange={handleChangeTagCheckbox}
                        />

                        <br></br>
                        <button type="submit" class="btn-search">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>

          <div class="row search-res">
            {productData.map((product) => (
              <div
                className="col-xl-3 col-lg-3 col-md-6 col-sm-6"
                key={product._id}
              >
                <div>
                  <div>
                    <img
                      src={
                        product.imgUrl.length > 0 ? product.imgUrl[0].src : ""
                      }
                      alt={product.title}
                    />
                  </div>
                  <div className="search-res-box">Title : {product.title}</div>
                  <div className="search-res-box">
                    Description : {product.description}
                  </div>
                  <div class="search-res-box">Tags : {product.tags}</div>
                  <div>
                    <Button>Take me There</Button>
                  </div>
                  <br />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div class="clear50"></div>
      <div class="clear50"></div>

      <div class="fix-right">
        <div class="mail-before">
          <a href="http://www.Shopperr.in/index/sendmessage/">
            <i class="icons mail-icon"></i>Contact us
          </a>
        </div>
        <div class="back-top">
          <a href="javascript:;">
            <i class="icons top-icon"></i>
          </a>
        </div>
      </div>

      {/*footer starts*/}
      <div class="clear50"></div>
      <div class="clear50"></div>
      <footer>
        <div class="clear50"></div>
        <div class="container">
          <div class="col-md-12">
            <div class="col-md-4">
              <div class="footerLogo">
                <img
                  src={process.env.REACT_APP_S3_URL.trim()+'/img/footer_logo.png'}
                  alt="Shopperr: India Dropshipping Supplier & Wholesaler"
                />
              </div>
              <div class="clear20"></div>
              <div class="footerLogoWord">
                Shopperr enables and facilitates Indian retailers to source from
                China-based drop ship wholesalers and manufacturers efficienfly
                and effectively.
              </div>
              <div class="clear20"></div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-3 footerWord">
              <dl>
                <dd>
                  <a href="/index/overview">About us</a>
                </dd>
                <dd>
                  <a href="/index/privacypolicy">Privacy policy</a>
                </dd>
                <dd>
                  <a href="/index/terms">Terms and conditions</a>
                </dd>
                <dd>
                  <a href="/index/delivery">Delivery and shipping</a>
                </dd>
                <dd>
                  <a href="/index/refund">
                    Return, Refund & Cancellation Policy
                  </a>
                </dd>
                <dd>
                  <a
                    href="/app/upload/shopperr-dropshipping-user-guide.pdf"
                    onclick="sendEventToga(19,0)"
                    target="_blank"
                  >
                    shopperr dropshipping user guide
                  </a>
                </dd>
              </dl>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-3 footerWord">
              <dl>
                <dt>Contact us:</dt>
                <dd>
                  Creationist Technovations Pvt. Ltd.5th And 6th Floor, Plot No
                  436 Phase 4, Udyog Vihar, Gurugram 122001 Gurugram, HR,
                  IN,India
                </dd>
                <dd>
                  <a
                    href="https://www.facebook.com/dropship.wholesaler/"
                    target="_blank"
                  >
                    <img
                      src={process.env.REACT_APP_S3_URL.trim()+'/img/facebook.jpg'}
                      alt="Official Shopperr Facebook Page"
                    />
                  </a>
                </dd>
              </dl>
            </div>
          </div>
          <div class="clear20"></div>
        </div>
        <div class="footer-copyright">
          Copyright@2018 Shopperr com All right reserved
        </div>
      </footer>
      {/*footer ends*/}
    </div>
  );
};
export default Landing1;
