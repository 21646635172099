import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import '../assets/css/logisticsInventory.css'
import { NotificationManager } from 'react-notifications'
const RtoOrders = (props) => {
    const token = localStorage.getItem('token')
    let decode = jwt_decode(token)
    const [selectArray, setArray] = useState(['Awb Number', 'OrderID'])
    const [selected, setSelected] = useState('OrderID')
    const [textArea, setText] = useState('')
    const [wareCode, setWareCode] = useState('')
    const [wareCodeId, setWareCodeId] = useState('')
    const [wareEmail, setEmail] = useState('')
    // console.log(selected)
    let selectOptions = selectArray?.map((ele) => {
        return { value: ele, label: `${ele}` }
    })
    useEffect(() => {
        axios.get(`/sscapi/view/${decode.email}`).then((response) => {
            console.log('view user data', response.data)
            setEmail(response.data.email)
            setWareCode(response.data.warehouse)
            setWareCodeId(response.data.warehouseId)
        }).catch((err) => {
            console.log(err.message)
        }, [])
    })
    const handleSubmitForm = (e) => {
        e.preventDefault()
        const formData = {
            "id": textArea,
            "wareid": wareCodeId
        }
        axios.post('/po/rtoOrder', formData)
            .then(result => {
                if (result.data === 'success') {
                    NotificationManager.success('Success', 'Succesfully Updated');
                } else {
                    NotificationManager.error("failed")
                }
            }).catch((err) => {
                NotificationManager.error(err.message)
                console.log(err)
            })
    }
    return <>
        <div id='parent'>
            <div>
               
                <form onSubmit={handleSubmitForm}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: "50%" }}>
                        <div>
                            <Select
                                options={selectOptions}
                                onChange={(opt) => { setSelected(opt.value) }}
                                isSearchable
                                placeholder={selected}
                            />
                        </div>
                        <br />
                        <div>
                            <textarea style={{ width: '100%', height: '200%' }} autofocus onChange={(e) => setText(e.target.value)} />
                        
                        </div>
                        <br />
                        <br />
                        <br />
                        <div style={{ width: '25%' }}>
                            <div className='submit'>
                                <button className='btn btn-primary' type='submit'>
								Submit
							</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </>
}
export default RtoOrders