import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import Swal from 'sweetalert2';
import '../assets/css/Login.css';
import { NotificationManager } from 'react-notifications';
const ResetPassword = ({ location }) => {
  	const [password, setPassword] = useState(''); 
  	const [conPassword, setConPassword] = useState(''); 
  	const [userType, setUserType] = useState(''); 
  	const [id, setUserId] = useState(''); 
  	const [errors, setErrors] = useState({});
  	const [success, setSuccess] = useState('');
  	useEffect(() => { 
 		setUserType(window.location.pathname.split('/')[1]) 
 		setUserId(window.location.search.split('token=')[1]) 
  	}, []);

  	const forgotPassword = (e) => {
    	e.preventDefault();
    	if(validateEmail()) {
	    	const obj = {
	      		password : password,
	      		userType : userType,
	      		id : id
	    	}; 
	    	axios.post('/api/update-password', obj).then((data) => { 
	         	if(data.data.status == 0){
	         		 setSuccess(1)
	         	}else{
	         		NotificationManager.error(data.data.response);
	         	}
	      	}).catch((err) => {
	        	NotificationManager.error('Something unusual happened');
	      	});
	    }
  	};

  	const validateEmail = () => {
        let errors = {};
        let isValid = true;
        if (!password) {
            isValid = false;
            errors["password"] = "Please enter your password."; 
        }  
        if (!conPassword) {
            isValid = false;
            errors["conPassword"] = "Please enter confirm password."; 
        }else if(password != conPassword){
        	isValid = false;
            errors["passmatch"] = "Password not matched.";
        }
        setErrors(errors) 
        return isValid;
    }
  	return ( 
  		<div>
  			{
  				success ? (
  					<div className='wrapper' id='wrapper-login'> 
			        	<div className='form-wrapper-login' id='form-wrapper-login'>
			        		<div style={{ margin : 'auto' }}><img style={{ width : '65px' }} src="https://static-sellercentral.shopperr.in/images/supplier/607e8d9394c4c6938baa94cd/2021/101/16357833801682N6Py7.png" alt="success" /></div>
			          		<h2 style={{ color: 'black' , fontSize: '24px' , fontWeight: '500' }}>Password Updated!</h2> 
			          		<p style={{ textAlign: 'justify' }}>Your password has been changed successfully. Use your new password to log in.</p>
			          		<a href="/login-supplier" style={{ textAlign : 'center' }}>Go To Login</a>
			        	</div> 
			    	</div>
				) : (
					<div className='wrapper' id='wrapper-login'> 
			        	<div className='form-wrapper-login' id='form-wrapper-login'>
			          		<h2><strong>Create Password</strong></h2>
			          		<form onSubmit={forgotPassword}>
				            	<div className='password'>
				              		<label htmlFor='password' style={{ textAlign: 'left', fontSize: '16px' }}>New Password</label>
				              		<input 
				                		value={password}
				                		onChange={(e) => setPassword(e.target.value)}
				                		type='password'
				                		placeholder='New Password'
				              		/>
				              		<div className="text-danger">{errors.password}</div>
				            	</div> 
				            	<div className='Confirm Password'>
				              		<label htmlFor='cpassword' style={{ textAlign: 'left', fontSize: '16px' }}>Confirm Password</label>
				              		<input 
				              		    style={{ width : '100%' }}
				                		value={conPassword}
				                		onChange={(e) => setConPassword(e.target.value)}
				                		type='password'
				                		placeholder='Confirm Password'
				              		/>
				              		<div className="text-danger">{errors.conPassword}</div>
				              		<div className="text-danger">{errors.passmatch}</div>
				            	</div> 
				            	<br /> 
					            <div  style={{ marginTop : '20px' }}>
					              	<button className='btn btn-fill' style={{ backgroundColor: 'rgb(255, 160, 0)', color: 'rgb(255, 255, 255)', fontWeight: '500', height: "40px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 4, border: '0px', 'fontSize': '15px' }} type='submit'>
					                	SUBMIT
					              	</button>
					            </div> 
				          	</form>
			        	</div> 
			    	</div>
				)
  			}
  		</div> 
  	);
};
export default ResetPassword;
