import React, { Component } from 'react'  
import * as am4core from "@amcharts/amcharts4/core";  
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated"; 
export default class Amcharts extends Component {   
    componentDidUpdate() {   
        am4core.useTheme(am4themes_animated);  
        am4core.addLicense("ch-custom-attribution");
        let chart = am4core.create("SalesChart", am4charts.XYChart); 
        // Add data  
        chart.data = this.props.amgraphData;  
  
        // Create axes  
        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());  
        dateAxis.renderer.grid.template.location = 0;  
        dateAxis.dateFormats.setKey("day", "dd");
        dateAxis.renderer.minGridDistance = 40;  
        dateAxis.renderer.axisFills.template.fillOpacity = 0.5;

        let valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());  
        valueAxis1.title.text = "Revanue";  
  
        let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());  
        valueAxis2.title.text = "Orders";  
        valueAxis2.renderer.opposite = true;  
        valueAxis2.renderer.grid.template.disabled = true;  
  
        // Create series  
        let series1 = chart.series.push(new am4charts.ColumnSeries());  
        series1.dataFields.valueY = "revenue";  
        series1.dataFields.dateX = "date";  
        series1.yAxis = valueAxis1;  
        series1.name = "Revanue";  
        series1.tooltipText = "{name}\n[bold font-size: 20]₹{valueY}[/]";  
        series1.fill = chart.colors.getIndex(0);  
        series1.strokeWidth = 0;  
        series1.clustered = false;    
        series1.columns.template.width = am4core.percent(40);   
        series1.tooltip.label.fill = am4core.color("#f00");

        let series3 = chart.series.push(new am4charts.LineSeries());  
        series3.dataFields.valueY = "order";  
        series3.dataFields.dateX = "date";  
        series3.name = "Orders";  
        series3.strokeWidth = 2;  
        series3.tensionX = 0.7;  
        series3.yAxis = valueAxis2;  
        series3.tooltipText = "{name}\n[bold font-size: 20]{valueY}[/]";  
  
        let bullet3 = series3.bullets.push(new am4charts.CircleBullet());  
        bullet3.circle.radius = 3;  
        bullet3.circle.strokeWidth = 2;  
        bullet3.circle.fill = am4core.color("#fff");  
        // Add cursor  
        chart.cursor = new am4charts.XYCursor();  
  
        // Add legend  
        chart.legend = new am4charts.Legend();  
        chart.legend.position = "top";   
        // Add scrollbar  
        chart.scrollbarX = new am4charts.XYChartScrollbar();  
        chart.scrollbarX.series.push(series1);  
        chart.scrollbarX.series.push(series3);  
        chart.scrollbarX.parent = chart.bottomAxesContainer;  
  
        this.chart = chart;  
    }  
  
    componentWillUnmount() {  
        if (this.chart) {  
            this.chart.dispose();  
        }  
    }  
    render() {  
        return (  
            <div>  
                <h4 className="title">Total Revenue & Orders</h4>
                <p className="category">24 Hours performance</p>
                <div id="SalesChart" style={{ width: "100%", height: "500px" }}></div>  
            </div>  
        )  
    }  
}  