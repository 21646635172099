import React, { Component } from 'react';
import { Grid, Row, Col, Table , Button, Modal} from 'react-bootstrap';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import CustomButton from '../components/CustomButton/CustomButton.jsx';
import Card from '../components/Card/Card.jsx';
import SyncLoader from "react-spinners/SyncLoader"
import { Link } from "react-router-dom"
import ReactPaginate from 'react-paginate'
import _ from 'lodash' 
import moment from 'moment';
import 'react-tagsinput/react-tagsinput.css' 
const override = `
	position: fixed;
	display: block;
	top: 45%;
	left: 50%;
	transform: translateX(-50%);
	transform: translate(-50%, -50%);
	z-index: 9999;
`
export default class walletSummary extends Component{ 
	constructor(){
		super()
		this.state = {
			loading: false,    
			currentBalance : 0,
			txnData : [],
			currentPage: 1,
			showPerPage: 50,
			pageCount: 0,
			tnxTotalCount : 0
	 	}
	}
 
	componentDidMount(){ 
		this.getMerchantBalance()
		this.getTnxData(this.state.currentPage, this.state.showPerPage)
		// console.log(this.getSupplier)
		 
	}

	getMerchantBalance(){
		let data = {
			'id' :  jwt_decode(localStorage.getItem('token')).id
		}
		axios.post("/merchant/getMerchant" , data)
		.then(response => {
			if(response.data.error === 0){ 
				this.setState({currentBalance : response.data.response.balance})
			}else{
			 	console.log(response);
			} 
		})
	}

	handlePageClick = e => { 
		this.getTnxData((e.selected + 1), this.state.showPerPage) 
	}
	getTnxData(page, perPage){
		this.setState({loading: true})
		axios.get(`/merchant/getPaymentHistory?page=${page}&limit=${perPage}&id=${jwt_decode(localStorage.getItem('token')).id}`)
		.then(result => {
			this.setState({txnData: result.data.items, tnxTotalCount: result.data.totalCount,
						 pageCount: Math.ceil(result.data.totalCount / this.state.showPerPage), 
						currentPage: page, loading: false})
		})
		.catch(err => {
			console.log(err)
			this.setState({loading: false})
		})
	} 
	render(){
		return(
			<div> 
				<SyncLoader css={override} size={15} margin={6} color={"#3E3E3E"} loading={this.state.loading} />
				<br></br>
				<div className="container-fluid">
					<div className="card" style={{padding: "1em"}}>
						<div className="row"> 
							<div className="col-sm-6 col-md-6 col-lg-6">
								Your Balance : { this.state.currentBalance }
							</div>
							<div className="col-sm-4 col-md-4 col-lg-4 text-right">
							</div>
							<div className="col-sm-2 col-md-2 col-lg-2 text-right"> 
								<Link to="/merchant/add-balance">
	                            	<button className="btn btn-fill" style={{textTransform: 'uppercase', background: "rgb(255, 160, 0)"  ,  borderColor: "rgb(255, 160, 0)" , fontSize: "15px" , fontWeight: "500"}}>Add Balance</button>
	                            </Link> 
							</div>
						</div>
					</div>
				</div>
				<div className="container-fluid"> 
						<div className="table-responsive">
							<table className="table table-hover ProductListTable">
								<thead>
									<tr className="d-flex">
										<th scope="col" className='text-center'>No</th> 
										<th scope="col" className='text-center'>Transaction Amount</th>
										<th scope="col" className='text-center'>Previous Amount</th>
										<th scope="col" className='text-center'>Final Amount</th>
										<th scope="col" className='text-center'>Transaction Type</th> 
										<th scope="col" className='text-center'>Transaction Date</th>  
									</tr>
								</thead>
								<tbody>
									{
										!_.isEmpty(this.state.txnData)
										?
											this.state.txnData.map((item, index) => { 
												return(
													<tr key={index} className="d-flex">
														<td className='text-center'>{((this.state.currentPage - 1) * this.state.showPerPage) + (index + 1)}</td>
														<td className='text-center'>{item.billAmt}</td>
														<td className='text-center'>{item.beforeAmt}</td>
														<td className='text-center'>{item.afterAmt}</td>
														<td className='text-center'>{item.billType === 1 ? "Credited" : item.billType === 2 ? "Debited" : "Refunded"}</td> 
														<td className='text-center'>{moment(item.crDate).format('DD-MM-YYYY')}</td> 
													</tr>
												)
											})
										:
											<tr>
												<td colSpan="6" className="text-center" style={{fontSize: "20px"}}>No Data Found</td>
											</tr>
									}
								</tbody>
							</table>
						</div>
						<ReactPaginate
							previousLabel={'<'}
							nextLabel={'>'}
							breakLabel={'...'}
							breakClassName={'break-me'}
							pageCount={this.state.pageCount}
							marginPagesDisplayed={3}
							pageRangeDisplayed={10}
							onPageChange={this.handlePageClick.bind(this)}
							containerClassName={'pagination'}
							subContainerClassName={'pages pagination'}
							activeClassName={'active'}
						/> 
				</div>
			</div>
		)
	}
}