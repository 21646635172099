import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Button, Modal } from 'react-bootstrap';
import { NotificationManager } from 'react-notifications'
import jwt_decode from 'jwt-decode'
import _ from 'lodash'
import ReactPaginate from 'react-paginate'
import '../assets/css/logisticsInventory.css'
import SyncLoader from 'react-spinners/SyncLoader'
import { Route, Switch, Redirect, useHistory } from 'react-router-dom'
import CustomButton from '../components/CustomButton/CustomButton';
const moment = require('moment');
const override = `
	position: fixed;
	display: block;
	top: 45%;
	left: 50%;
	transform: translateX(-50%);
	transform: translate(-50%, -50%);`
const SkuDetails = (props) => {
    const details = props?.location?.state
    const { wareCode, wareEmail } = props
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const [pageCount, setPageCount] = useState(0)
    const [query, setQuery] = useState({})
    const [loading, setLoading] = useState(true)
    const [total, setTotal] = useState()
    const [modalShow, setModalShow] = useState(false)
    const [deleteid, setDeleteId] = useState('')
    const [rdata, setRedirect] = useState({ r: false, data: '' })
    useEffect(() => { getLogisticproducts(page, perPage, query) }, [])
    const getLogisticproducts = (page, perPage, query) => {
        axios.get(`/sscapi/viewskudetails/${details.sku}?page=${page}&limit=${perPage}&query=${!_.isEmpty(query) ? query : "{}"}`)
            .then(result => {
                console.log('result : ', result.data.items)
                setData(result.data.items)
                setLoading(false)
                setTotal(result.data.totalCount)
                setPageCount(Math.ceil(result.data.totalCount / perPage))
                setPage(1)
            }).catch((err) => {
                console.log(err)
            })
    }
    const handlePageClick = e => {
        getLogisticproducts((e.selected + 1), perPage, query)
    }

    return <>
        <div className='container-fluid'>
            <div className='card' style={{ padding: '1em' }}>
                <SyncLoader css={override} size={15} margin={6} color={"#3E3E3E"} loading={loading} />
                <div style={{ cursor: "pointer" }}>
                    <div className="form-group">
                        <h5 className="text-bold">Total Products in Inventory : <span className="text-muted">{total}</span>
                            &nbsp;<i class="fa fa-refresh" aria-hidden="true" onClick={() => getLogisticproducts(1, perPage, {})}></i>
                        </h5>
                    </div>
                </div>

                <div className="table-responsive">
                    <table className="table table-hover">
                        <thead>
                            <tr className="d-flex">
                                <th scope="col" >Sl no.</th>
                                <th scope="col" >Description</th>
                                <th scope="col" >Product SKU</th>
                                <th scope="col" >Debited</th>
                                <th scope="col" >Credited</th>
                                <th scope="col" >Total</th>
                                <th scope="col" >UpdatedAt</th>
                                <th scope="col" >CreatedAt</th>

                            </tr>
                        </thead>
                        <tbody>
                            {
                                !_.isEmpty(data) ? data.map((ele, i) => {

                                    return (<tr key={i} className="d-flex">
                                        <td>{i + 1}</td>
                                        <td>
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr className="d-flex">
                                                        <th scope="col" >Comments</th>
                                                        <th scope="col" >Reserve Inventory</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{ele.lpc}</td>
                                                        <td>{ele.lpri}</td>
                                                    </tr>
                                                </tbody>
                                            </table>


                                        </td>
                                        <td>{ele.sku}</td>
                                        <td>{(ele.lpt === '1') ? (ele.lcsvq) : ('')}</td>
                                        <td>{(ele.lpt === '2') ? (ele.lcsvq) : ('')}</td>
                                        <td>{ele.lpq}</td>

                                        <td>{moment(new Date(ele.updatedAt)).format('MM.DD.YYYY HH:mm')}</td>
                                        <td>{moment(new Date(ele.createdAt)).format('MM.DD.YYYY HH:mm')}</td>
                                    </tr>)
                                }) : ''
                            }

                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={3}
            pageRangeDisplayed={10}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
        />
    </>
}
export default SkuDetails