import React, { useEffect, useState } from "react";
import ChartistGraph from "react-chartist";
import SyncLoader from "react-spinners/SyncLoader";
import { Grid, Row, Col, Table, Button } from "react-bootstrap";
import { Card2 } from "../../components/Card/Card2.jsx";
import { Card } from "../../components/Card/Card.jsx";
// import { NewCard } from "../../components/Card/NewCard.jsx";
import { StatsCard } from "../../components/StatsCard/StatsCard.jsx";
import jwt_decode from "jwt-decode";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  ChartData,
  ChartArea,
} from "chart.js";
import { Bar, Pie, Bubble } from "react-chartjs-2";
import { optionsSales, responsiveSales } from "../../variables/Variables.jsx";
import { getPriceFormatter } from "../../helpers/index.js";
import axios from "axios";
import faker from "faker";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  CategoryScale
);
var dates = [];

const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
    },
  },
};
export const optionsForRevenue = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
    },
  },
};
const override = `
	position: fixed;
	display: block;
	top: 45%;
	left: 50%;
	transform: translateX(-50%);
	transform: translate(-50%, -50%);
	z-index: 9999;
`;
const SupplierDashboard = () => {
  const [productCount, setProductCount] = useState(0);
  const [revenue, setRevenue] = useState(0);
  const [order, setOrder] = useState(0);
  const [graphPlot, setGraphPlot] = useState([]);
  const [topProducts, setTopProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [showPerPage, setShowPerPage] = useState(500);
  const [CustomerDetails, setCustomerDetails] = useState([]);
  const [query, setQuery] = useState({});
  const [orders, setorders] = useState([]);
  const token = localStorage.getItem("token");
  const decode = jwt_decode(token);
  const [KeyForCategory, setKeyForCategory] = useState([]);
  const [ValueForCategory, setValueForCategory] = useState([]);
  const [valueForMarket, setValueForMarket] = useState([]);
  const [keyForMarket, setKeyForMarket] = useState([]);
  const [SelectedMonth, setSelectedMonth] = useState("");
  const [orderDateWise, setOrderDateWise] = useState("");
  const [KeyForMarketPlace, setKeyForMarketPlace] = useState([]);
  const [ValueForMarketPlace, setValueForMarketPlace] = useState([]);
  const [Revenuedate, setRevenuedate] = useState([]);
  const [RevenueAmount, setRevenueAmount] = useState([]);
  useEffect(() => {
    getProductData();
    income();
    totalOrders();
    graphData();
    getOrders();
    getTotalCategory();
    getTotalMarketplace();
    // top();
  }, []);

  const getProductData = () => {
    axios
      .get(`/add/productCount/${decode.id}`)
      .then((result) => {
        const myArray = result.data.products;

        const OutPut = myArray.reduce((a, v) => {
          let c = a.find((f) => f.sku === v.sku);
          //do we have this SKU..
          if (!c) {
            //No lets add
            c = v;
            c.productInfo.quantity = [v.productInfo.quantity];
            a.push(c);
          } else {
            //Yes, lets add this OrderNo.
            c.productInfo.quantity.push(v.productInfo.quantity);
          }
          return a;
        }, []);

        let freshValue = [];
        OutPut.map((item) => {
          if (item.productInfo.quantity.length > 1) {
            freshValue.push(item);
          }
          else if(item.productInfo.quantity.length==1) {
            
            freshValue.push(item);
          }
        });
        console.log(freshValue);
        setTopProducts(freshValue.splice(0, 7));
        setProductCount(result.data.count);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //get Orders details
  const getOrders = () => {
    axios
      .get(
        `/po/supplierGetOrdersForDashboard?supplierUserId=${
          jwt_decode(localStorage.getItem("token")).id
        }`
      )
      .then((result) => {
        const groups = result.data.reduce((groups, datas) => {
          const date = datas.createdAt.split("T")[0];
          if (!groups[date]) {
            groups[date] = [];
          }
          groups[date].push(datas);
          return groups;
        }, {});

        // Edit: to add it in the array format instead
        const groupArrays = Object.keys(groups).map((date) => {
          return {
            date,
            games: groups[date],
          };
        });

        const NoOfOrders = groupArrays.map((item) => item.games.length);

        dates = groupArrays.map((item) => item.date);

        setorders(NoOfOrders);
        graphDataAllocation(dates, NoOfOrders);
        const keysForMarket = Object.keys(result.data);
        const keysWithOtherValueForCategory = keysForMarket.map((element) => {
          if (element === "") {
            return "others";
          } else {
            return element;
          }
        });
        setKeyForMarket(keysWithOtherValueForCategory);
        let marketvalue = Object.values(result.data);
        setValueForMarket(marketvalue);
        setCustomerDetails(result.data.splice(0, 7));
      });
  };

  function graphDataAllocation(dates, NoOfOrders) {
    const months = dates.map((dateString) => {
      const date = new Date(dateString);
      return date.getMonth() + 1; // Adding 1 to get the month in the range 1-12 instead of 0-11
    });
    let newArr = ["", "", "", "", "", "", "", "", "", "", "", ""];
    for (let i = 0; i < months.length; i++) {
      newArr[months[i] - 1] = NoOfOrders[i];
    }
    setorders(newArr);
  }

  const getTotalCategory = () => {
    axios
      .get(
        `/po/categoryForDashboard?id=${
          jwt_decode(localStorage.getItem("token")).id
        }`
      )
      .then((result) => {
        const results = result.data.reduce(
          (a, c) => ((a[c] = (a[c] || 0) + 1), a),
          Object.create(null)
        );
        const keysForCategory = Object.keys(results);
        const valueForCategory = Object.values(results);
        const keysWithOtherValueForCategory = keysForCategory.map((element) => {
          if (element === "") {
            return "others";
          } else {
            return element;
          }
        });
        setKeyForCategory(keysWithOtherValueForCategory);
        setValueForCategory(valueForCategory);
      });
  };

  const getTotalMarketplace = () => {
    axios
      .get(
        `/po/marketplaceForDashboard?id=${
          jwt_decode(localStorage.getItem("token")).id
        }`
      )
      .then((result) => {
        const results = result.data.reduce(
          (a, c) => ((a[c] = (a[c] || 0) + 1), a),
          Object.create(null)
        );
        const keysForCategory = Object.keys(results);
        const valueForCategory = Object.values(results);
        const keysWithOtherValueForCategory = keysForCategory.map((element) => {
          if (element === "") {
            return "others";
          } else {
            return element;
          }
        });
        setKeyForMarketPlace(keysWithOtherValueForCategory);
        setValueForMarketPlace(valueForCategory);
      });
  };

  const dataForMarketPlacePie = {
    labels: KeyForMarketPlace,
    datasets: [
      {
        label: "# of Votes",
        data: ValueForMarketPlace,
        backgroundColor: ["#99CCFF", "#336699	", "#999933"],
        borderColor: ["#FFFFFF"],
        borderWidth: 1,
      },
    ],
  };

  const dataForPie = {
    labels: KeyForCategory,
    datasets: [
      {
        label: "# of Votes",
        data: ValueForCategory,
        backgroundColor: ["#E69500", "#609f70	", "#008000"],
        borderColor: ["#FFFFFF"],
        borderWidth: 1,
      },
    ],
  };
  const dataForPieMarket = {
    labels: keyForMarket,
    datasets: [
      {
        label: "# of Votes",
        data: valueForMarket,
        backgroundColor: [
          "#336699",
          "#99CCFF",
          "#999933",
          "#666699",
          "#CC9933",
          "#006666",
        ],
        borderColor: ["white"],
        borderWidth: 1,
      },
    ],
  };
  // for bar graph
  const data = {
    labels,
    datasets: [
      {
        label: "Orders",
        data: orders,
        backgroundColor: "orange",
        barPercentage: 0.5,
      },
    ],
  };

  const dataForRevenue = {
    labels: Revenuedate,
    datasets: [
      {
        label: "Revenue",
        data: RevenueAmount,
        backgroundColor: "#336699",
        barPercentage: 0.2,
      },
    ],
  };
  //End of orders details

  //End graph for bubble
  const income = () => {
    axios.get("/supplierRevenue/" + decode.id).then((rev) => {
      setRevenue(rev.data);
    });
  };

  const totalOrders = () => {
    axios.get("/supplierOrders/" + decode.id).then((ord) => {
      setOrder(ord.data);
    });
  };

  const graphData = () => {
    axios.get("/supplierGraphRevenue/" + decode.id).then((response) => {
      setRevenuedate(response.data.date);

      setRevenueAmount(response.data.revenue);
      //  setGraphPlot(data);
    });
  };

  const top = () => {
    axios.get("/topProducts/" + decode.id).then((response) => {
      setTopProducts(response.data);
    });
  };

  //APi for Month wise data
  const getMonthWiseData = async (e) => {
    const dateIs = e.target.value;
    const result = await axios.get(
      "/supplierBarGraphMonth/" + decode.id + "/" + dateIs
    );

    let value = Object.entries(
      result.data.data.reduce((acc, obj) => {
        let date = obj.date;
        acc[date] = (acc[date] || 0) + 1;
        return acc;
      }, {})
    ).map(([date, count]) => ({ [date]: count }));
    setOrderDateWise(value);
  };

  function handlaShowData() {}

  return (
    <div>
      <br />
      <SyncLoader
        css={override}
        size={15}
        margin={6}
        color={"#3E3E3E"}
        loading={loading}
      />
      <div className={loading ? "parentDisable" : ""}>
        <Grid fluid>
          <Row>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={
                  <i className="pe-7s-server text-warning" id="color_white1" />
                }
                statsText="Total Products"
                statsValue={productCount}
                // statsIcon={<i className="fa fa-refresh" />}
                // statsIconText="Updated now"
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={
                  <i className="pe-7s-wallet text-success" id="color_white2" />
                }
                statsText="Total Revenue"
                statsValue={getPriceFormatter(revenue)}
                // statsIcon={<i className="fa fa-calendar-o" />}
                // statsIconText="Last day"
              />
            </Col>

            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={
                  <i className="fa fa-server text-info" id="color_white3" />
                }
                statsText="Total Orders"
                statsValue={order}
                // statsIcon={<i className="fa fa-refresh" color="white" />}
                // statsIconText="Updated now"
              />
            </Col>

            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={
                  <i className="fa fa-user text-info" id="color_white4" />
                }
                statsText="Total Customers"
                statsValue={order}
                // statsIcon={<i className="fa fa-refresh"/>}
                // statsIconText="Updated now"
              />
            </Col>
          </Row>

          <Row>
            <Col style={{ marginLeft: "20px" }} md={5}>
              Filter By Months :{" "}
              <input
                type="month"
                value={SelectedMonth}
                onChange={(e) => getMonthWiseData(e)}
              ></input>
            </Col>

            <Col md={7}>
              {orderDateWise === "" ? null : (
                <div>
                  <ul>
                    {orderDateWise.length === 0 ? (
                      <h3>No Orders Found</h3>
                    ) : (
                      <table>
                        <td style={{ width: "100px" }}>Date</td>
                        <th>No. of orders</th>
                      </table>
                    )}

                    {orderDateWise.map((item) => {
                      let date = Object.keys(item)[0];
                      let count = item[date];
                      return (
                        <div>
                          <table>
                            <td style={{ width: "100px" }}>{date}:</td>
                            <th> {count}</th>
                          </table>
                        </div>
                      );
                    })}
                  </ul>
                  <Button
                    variant="primary"
                    onClick={() => setOrderDateWise("")}
                  >
                    Clear
                  </Button>
                </div>
              )}
              <div>
                <Card
                  height="true"
                  statsIcon="fa fa-history"
                  id="chartHours"
                  title="Total Orders"
                  category="1 Month performance"
                  stats="Updated 3 minutes ago"
                  content={
                    <div>
                      <Bar options={options} data={data} />
                    </div>
                  }
                  // style={{boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"}}
                />
              </div>
            </Col>

            <Col md={5}>
              {/* <div >
              <Card
              height="true"
                statsIcon="fa fa-history"
                id="chartHours"
                title="Total Revenue"
                category="24 Hours performance"
                stats="Updated 3 minutes ago"
                content={
                  <div className="ct-chart">
                    <ChartistGraph
                      data={graphPlot}
                      type="Bar"
                      options={optionsSales}
                      responsiveOptions={responsiveSales}
                      style={{ backgroundColor: "#DAE7FF" }}
                    />    
                  </div>
                }
              />
              </div> */}

              <div>
                <Card
                  height="true"
                  statsIcon="fa fa-history"
                  id="chartHours"
                  title="Total Revenue"
                  category="24 Hours performance"
                  stats="Updated 3 minutes ago"
                  content={
                    <div>
                      <Bar options={optionsForRevenue} data={dataForRevenue} />
                    </div>
                  }
                  // style={{boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"}}
                />
              </div>
            </Col>
          </Row>
          {/*
            <Col md={4}>
              <Card
                statsIcon="fa fa-clock-o"
                title="Revenue by category"
                category="District Wise"
                stats="Campaign sent 2 days ago"
                content={
                  <div
                    id="chartPreferences"
                    className="ct-chart ct-perfect-fourth"
                  >
                    <ChartistGraph data={piePlot} type="Pie" />
                  </div>
                }

              />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Card
                id="chartActivity"
                title="2014 Sales"
                category="All products including Taxes"
                stats="Data information certified"
                statsIcon="fa fa-check"
                content={
                  <div className="ct-chart">
                    <ChartistGraph
                      data={dataBar}
                      type="Bar"
                      options={optionsBar}
                      responsiveOptions={responsiveBar}
                    />
                  </div>
                }
                legend={
                  <div className="legend">{this.createLegend(legendBar)}</div>
                }
              />
            </Col>

            <Col md={6}>
              <Card
                title="Tasks"
                category="Backend development"
                stats="Updated 3 minutes ago"
                statsIcon="fa fa-history"
                content={
                  <div className="table-full-width">
                    <table className="table">
                      <Tasks />
                    </table>
                  </div>
                }
              />
            </Col>
          </Row>
            */}
          <Row>
            <Col md={8}>
              <div>
                <Card
                  height="true"
                  title="Top Selling Products"
                  ctTableFullWidth
                  ctTableResponsive
                  content={
                    <Table striped hover size="sm">
                      <thead style={{ backgroundColor: "#4B49AC" }}>
                        <tr>
                          <th style={{ color: "#fff" }}>Sku</th>
                          <th style={{ color: "#fff" }}>Title</th>
                          <th style={{ color: "#fff" }}>Quantity</th>
                          <th style={{ width: "15%", color: "#fff" }}>Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {topProducts.map((item, key) => {
                          return (
                            <tr key={key}>
                              <td>
                                {item.sku
                                  ? item.sku
                                  : item.code
                                  ? item.code
                                  : ""}
                              </td>
                              <td>{item.productInfo.name}</td>
                              <td>
                                {item.productInfo.quantity.length
                                  ? item.productInfo.quantity.length
                                  : "1"}
                              </td>
                              <td>{getPriceFormatter(item.orderAmt.resellerAmount)}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  }
                ></Card>
              </div>
            </Col>
            <Col
              md={4}
              style={{
                boxShadow:
                  "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;",
              }}
            >
              <Card
                height="true"
                title="Products Category"
                content={<Pie data={dataForPie} width="50%" height="50%" />}
              ></Card>
            </Col>
            <Col md={8}>
              <Card
                className={"supplier_dashboard_card"}
                class="card"
                height="true"
                id="card"
                title="Top Customers"
                content={
                  <Table striped hover size="sm">
                    <thead style={{ backgroundColor: "#4B49AC" }}>
                      <tr>
                        <th style={{ color: "#fff" }}>No.</th>
                        <th style={{ color: "#fff" }}>First Name</th>
                        <th style={{ color: "#fff" }}>Email</th>
                        <th style={{ color: "#fff" }}>City</th>
                        <th style={{ width: "15%", color: "#fff" }}>State</th>
                      </tr>
                    </thead>
                    <tbody>
                      {CustomerDetails.map((item, key) => {
                        return (
                          <tr key={key}>
                            <td>{key + 1}</td>
                            <td>
                              {item.customer.firstName == ""
                                ? "NA"
                                : item.customer.firstName}
                            </td>
                            <td>
                              {item.customer.email == ""
                                ? "NA"
                                : item.customer.email}
                            </td>
                            <td>
                              {item.shippingAddress.city == ""
                                ? "NA"
                                : item.shippingAddress.city}
                            </td>
                            {/* <td>
                            <i className="fa fa-inr" aria-hidden="true">{item.price.toFixed(2)}</i>
                          </td> */}
                            <td>
                              {item.shippingAddress.province == ""
                                ? "NA"
                                : item.shippingAddress.province}
                            </td>

                            {/* <td className="text-center">{item.count}</td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                }
                mainHeader="Top Customers"
              />
            </Col>
            <Col
              md={4}
              style={{
                boxShadow:
                  "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;",
              }}
            >
              <Card
                height="true"
                title="Market Place"
                content={<Pie data={dataForMarketPlacePie} />}
              ></Card>
            </Col>
          </Row>
        </Grid>
      </div>
    </div>
  );
};

export default SupplierDashboard;
