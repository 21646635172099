import React, { useState, useEffect } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Modal from "react-responsive-modal";
import { NotificationManager } from "react-notifications";
import Card from "../../components/Card/Card.jsx";
import "../../assets/css/productList.css";
import CustomButton from '../../components/CustomButton/CustomButton';
const CsvProduct = () => {
  const [productItems, setProductItems] = useState([]); 
  const token = localStorage.getItem("token");
  const decode = jwt_decode(token);
  const [csvData, setCsvData] = useState([]);
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [warranty, setWarranty] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [code, setCode] = useState("");
  const [itemId, setItemId] = useState("");
  const [limit, setlimit] = useState(100);
  const [page, setPage] = useState([]);
  const [selectedPage, setSelectedPage] = useState("1");
  const [open, setOpen] = useState(false);

  const modalStyle = {
    margin: "auto",
    position: "relative",
  };

  useEffect(() => {
    getProductData();
  }, []);

  const getProductData = () => {
    axios.get("/web/getExportExcelDataBySupplier/"+decode.id).then((products) => {
        setProductItems(products.data.data);
        if(products.data.product > 0){
            let items = []
            for (var i = 0; i < Math.ceil(products.data.product/limit); i++) {
                items.push(i+1)
            } 
            setPage(items)
        }else{
            setPage([])
        } 
    });
  };

  const updateProduct = (item) => {
    console.log("updateProduct", item._id);
    setName(item.name);
    setPrice(item.price);
    setQuantity(item.quantity);
    setWarranty(item.warranty);
    setDescription(item.description);
    setCategory(item.category);
    setCode(item.code);
    setItemId(item._id);
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  const deleteProduct = (item) => {
    console.log("delete" + item._id);
    axios.delete("/api/csvProduct/" + item._id).then((data) => {
      if (data) {
        NotificationManager.success("Product Deleted");
        getProductData();
      }
      else {
        NotificationManager.error("Something Went Wrong")
      }
    });
  };

  const updateProductItem = (e) => {
    e.preventDefault();
    const object = {
      _id: itemId,
      name: name,
      price: price,
      quantity: quantity,
      warranty: warranty,
      description: description,
      category: category,
      code: code,
    };
    console.log(object);
    axios
      .patch("/api/product/update", object)
      .then((data) => {
        if (data) {
          NotificationManager.success("Product Updated Successfully"); 
          setName("");
          setPrice("");
          setQuantity("");
          setWarranty("");
          setDescription("");
          setCategory("");
          setCode("");
          setOpen(false);
          getProductData();
        }
      })

      .catch((err) => {
        NotificationManager.error("Product Updated Error");
      });
  };

//Add Product from CSV File
  const addCSvProduct = (e) => {
    e.preventDefault();
    const scvdata = new FormData();
    scvdata.append('file', csvData[0]);
    scvdata.append('supplier_id', decode.id);
    console.log(scvdata)
    axios
      .post('/web/uploadProductCsv', scvdata)
      .then((res) => {
        if (res.data.includes('success')) {
          NotificationManager.success('File uploaded Successfully');
        } else {
          res.data.error
            ? NotificationManager.error(res.data.error.toString())
            : NotificationManager.error('There is a problem with this csv');
        }
      })
      .catch((error) => {
        error
          ? NotificationManager.error(error.toString())
          : NotificationManager.error('There is a problem with this csv');
      });
  };

// Download Product CSV File
const downloadProductCsv = (e) => {
    e.preventDefault(); 
    const csvForm = new FormData();
    csvForm.append('page', selectedPage);
    csvForm.append('limit', limit);
    axios.post('/web/downloadProductCsv/'+decode.id , csvForm).then((res) => {
        if (res.data.includes('success')) {
            setSelectedPage("")
            NotificationManager.success('File Download Successfully');
        } else {
            res.data.error
            ? NotificationManager.error(res.data.error.toString())
            : NotificationManager.error('There is a problem with this csv');
        }
    }).catch((error) => {
        error
        ? NotificationManager.error(error.toString())
        : NotificationManager.error('There is a problem with this csv');
    });
};

 
return (
    <div className="mainBox">
    <br />
    <div className='container-fluid'>
    <div className='col-md-6'>
      <div className="card" style={{padding: "1em"}}>
        <label htmlFor='productImage' style={{fontSize: 15 }}>Update CSV File</label>
            <form onSubmit={addCSvProduct} style={{justifyContent: "flex-start", alignItems: "center", margin: "0"}}>
                <div className='form-group' style={{margin: "0"}}>        
                    <div id='fileContents'></div>
                    <input
                      type='file'
                      className='form-control text-center'
                      name='avatar'
                      onChange={(e) => { 
                        setCsvData(e.target.files);
                      }}
                      encType='multipart/form-data'
                      accept='.csv'
                    />
                  </div>
                  <div className='' style={{margin: "0 0 0 10px"}}>
                    <CustomButton fill type='submit'>
                      Update Products
                    </CustomButton>
                  </div>
				          <a href="https://static-sellercentral.shopperr.in/import_export/2021/10/upload8eQonE_605194af3da40def6172dfac.csv" target="_blank">Sample Csv</a>
            </form>
        </div>
        </div>
        <div className='col-md-6'> 
            <form onSubmit={downloadProductCsv}>
              <div className='card card-input' style={{width:"100%"}}>
                <div className='form-group'>
                <label htmlFor='productImage' style={{fontSize: 15 }}>Download CSV File</label>
                  <br />
                  <div className='card-button' style={{margin: "0"}}>
                    { (page.length > 0) ? 
                      <div>
                      <label style={{fontSize: 15 }} htmlFor='productTitle'>
                          Page <span className="custom-tooltip" data-tooltip="CSV file will have 500 products per page.">
                              <i className="fa fa-question-circle" aria-hidden="true"></i>
                          </span>
                      </label>
                        <select className='form-control'   onChange={e => { setSelectedPage(e.target.value); }} >
                            {page.map((item, key) => {  
                                return (
                                    <option key={key} value= {item} >{item}</option>
                                )
                            })}
                        </select> 
                        </div>
                        : '' 
                    }
                    <CustomButton fill type='submit'>
                        Download Products
                    </CustomButton>
                  </div>
                </div>
              </div>
            </form>
        </div>
    </div>
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="CSV File Export / Import List"
                category={"Total Exports & Import :" + productItems.length}
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table striped hover>
                    <thead>
                        <tr>
                            <th className='text-center'>S.No.</th>
                            <th className='text-center' style={{width:"30%"}}>Status</th>
                            <th className='text-center' style={{width:"30%"}}>Action</th>
                            <th className='text-center' style={{width:"30%"}}>Date</th> 
                        </tr>
                    </thead>
                    <tbody>
                      {productItems.map((item, key) => {
                        return (
                            <tr key={key}> 
                                <td className='text-center' style={{width:"10%"}}>{key +1 }</td>
                                <td  className='text-center' style={{color: (item.status == 'Success' ? 'Green' : 'orange' ) }} >{item.status||"NA"}</td>
                                <td  className='text-center'><a href={ item.url }>{item.action||"NA"}</a></td>
                                <td  className='text-center'>{item.date||"NA"}</td> 
                            </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid>
       
      
    </div>
  );
};

export default CsvProduct;
