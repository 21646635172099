import React, {useState, useEffect} from 'react'
import Barcode from 'react-barcode'

function BarcodePo(props) {
    const config = {
        width: 0.9,
        height: 60,
      };
    const printMe = () => {
        window.print();
    }
    const goBack = () => {
        window.location.reload()
    }
    return (
        <div className="container-fluid"> 
            <div id="printArea" className="card" style={{padding: "1em"}}>
                {/* <ol className="list-group list-group-flush">
                    {
                        props.barcodeData.map(barcodeValue => {
                            console.log('barcodeValue: ', barcodeValue);
                            return (

                                    <li key={barcodeValue.barcode}  > <Barcode value={barcodeValue.barcode} {...config}/>
                                        <div>{barcodeValue['orderNumber']}</div>
                                     </li>

                            )
                        })
                    }
                </ol> */}
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                        <label style={{paddingLeft: "3em"}}>Barcode</label>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                        <label style={{paddingLeft: "4em"}}>Order Number</label>
                    </div>
                </div>
                <div className="row">
                {
                    props.barcodeData.map(barcodeValue => {
                        return (
                            <>
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                        <div key={barcodeValue.barcode}  > <Barcode value={barcodeValue.barcode} {...config}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                        <div style={{height:"80px", textAlign:"center", display:"grid", alignItems:"center"}}>{barcodeValue['orderNumber']}</div>
                                    </div>
                                </div>
                            </>
                        )
                    })
                }
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                        <button className="btn btn-primary hidden-print" onClick={printMe}><span className="glyphicon glyphicon-print" aria-hidden="true"> Print </span></button>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                        <button className="btn btn-primary hidden-print" onClick={goBack}><span className="glyphicon glyphicon-arrow-left"> Back </span></button>
                    </div>
                </div>
                
            </div>
            
        </div>
    )
}
export default BarcodePo