import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import Select from 'react-select'
var countryData = require('../assets/Countries.json');

// json is in assets folder as Countries.json 
// if json data is missing please download from https://github.com/sagarshirbhate/Worlds-All-Country-State-City-Database/
// this component is not downloaded.
// will work with any json data with data structure such as given in the github json folder
// dependencies react-select and lodash

const SelectCountries = (props) => {
    const { citySetter, stateSetter, countrySetter, cplaceholder, cityplaceholder, splaceholder } = props
    let cData = countryData.Countries
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [city, setCity] = useState([])
    const [selectedCountry, setSelectedCountry] = useState('')
    const [selectedState, setSelectedState] = useState([])
    const [selectedCity, setSelectedCity] = useState([])
    const [options, setOptions] = useState({})
    const [value, setValue] = useState('')
    useEffect(() => {
        let country = cData?.map((ele) => {
            return { value: ele?.CountryName, label: `${ele?.CountryName}` }
        })
        setCountries(country)
    }, [])
    let stateOptions = states?.map((ele) => {
        return { value: ele?.StateName, label: `${ele?.StateName}` }
    })
    let cityOptions = city?.map((ele) => {
        return { value: ele, label: `${ele}` }
    })
    const handleCountrySelect = (opt) => {
        setSelectedCountry(opt.value)
        countrySetter(opt.value)
        let temp = opt.value
        let test = _.find(cData, function (o) { return o.CountryName === opt.value; });
        setStates(test.States)
    }
    const handleStateSelect = (opt) => {
        setSelectedState(opt.value)
        stateSetter(opt.value)
        let temp = opt.value
        let test = _.find(states, function (o) { return o.StateName === opt.value; });
        setCity(test.Cities)
        // console.log(test)
    }


    return (
        <>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <div >
                                <label>Country</label>
                                <Select
                                    options={countries}
                                    onChange={(opt) => handleCountrySelect(opt)}
                                    isSearchable
                                    placeholder={cplaceholder ? cplaceholder : 'Country'}
                                />
                            </div>
                        </td>
                        <td>
                            <div style={{ paddingLeft: '10px' }}>
                                <label>State</label>
                                <Select
                                    options={stateOptions}
                                    onChange={(opt) => handleStateSelect(opt)}
                                    isSearchable
                                    placeholder={splaceholder ? splaceholder : 'State'}
                                />
                            </div>
                        </td>
                        <td>
                            <div style={{ paddingLeft: '10px' }}>
                                <label>City</label>
                                <Select
                                    options={cityOptions}
                                    onChange={(opt) => citySetter(opt.value)}
                                    isSearchable
                                    placeholder={cityplaceholder ? cityplaceholder : 'City'}
                                />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}
export default SelectCountries