import React, {Component} from "react"
import axios from "axios";
import jwt_decode from "jwt-decode"
import { NotificationManager } from "react-notifications"
import "../../assets/css/productList.css";
import { Link } from "react-router-dom"
import _ from 'lodash'
import ReactPaginate from 'react-paginate'
import SyncLoader from "react-spinners/SyncLoader"
import { Modal, Button } from 'react-bootstrap'
import {getPriceFormatter} from '../../helpers/index.js';

const override = `
	position: fixed;
	display: block;
	top: 45%;
	left: 50%;
	transform: translateX(-50%);
	transform: translate(-50%, -50%);
	z-index: 9999;
`
export default class ProductList extends Component{
	constructor(){
		super()
		this.state = {
			productTotalCount: 0,
			productsData: [],
			isLoaded: false,
			productImageUrl: "",
      currentPage: parseInt(localStorage.getItem("p.currentpagea")) || 1,
			showPerPage: 50,
			pageCount: 0,
			searchName: "",
			searchSku: "",
			errors: {},
			loading: true,
			modalShow: false,
			deleteProductId: "",
			deleteProductName: ""
		}
	}

	componentDidMount(){
		this.getProductsData(this.state.currentPage, this.state.showPerPage)
	}

	getProductsData(page, perPage){
		this.setState({loading: true})
		axios.get(`/add/getProducts?page=${page}&limit=${perPage}&id=${jwt_decode(localStorage.getItem('token')).id}`)
		.then(result => {
			this.setState({isLoaded: true, productsData: result.data.items, productTotalCount: result.data.totalCount,
						productImageUrl: result.data.url, pageCount: Math.ceil(result.data.totalCount / this.state.showPerPage), 
						currentPage: page, searchName: "", searchSku: "", loading: false})
            localStorage.setItem("p.currentpagea",page);
		})
		.catch(err => {
			console.log(err)
			this.setState({loading: false})
		})
	}

	storeUniqueId = item => {
		localStorage.setItem('code' , item)
		localStorage.setItem('from', 'supplier')
	}

	handleDeleteProduct = item => {
		this.setState({deleteProductId: item._id, modalShow: true, deleteProductName: item.name})
	}

	hardDeleteProduct(){
		this.setState({loading: true, modalShow: false})
		axios.get(`/add/deleteProduct/${this.state.deleteProductId}`)
		.then(result => {
			if(result.data === 1){
				NotificationManager.success("Product deleted successfully")
				this.getProductsData(this.state.currentPage, this.state.showPerPage)
			}else NotificationManager.error("Falied to delete product, Try again")
		})
		.catch(err => {
			this.setState({loading: false})
			console.log(err)
			NotificationManager.error("Falied to delete product, Try again")
		})
	}

	softDeleteProduct(){
		this.setState({loading: true, modalShow: false})
		axios.get(`/add/softDeleteProduct/${this.state.deleteProductId}`)
		.then(result => {
			if(result.data === 1){
				NotificationManager.success("Product deleted successfully")
				this.getProductsData(this.state.currentPage, this.state.showPerPage)
			}else NotificationManager.error("Falied to delete product, Try again")
		})
		.catch(err => {
			this.setState({loading: false})
			console.log(err)
			NotificationManager.error("Falied to delete product, Try again")
		})
	}

	handlePageClick = e => {
		if(_.isEmpty(this.state.searchName))
			this.getProductsData((e.selected + 1), this.state.showPerPage)
		else
			this.findByName((e.selected + 1), this.state.showPerPage)
	}

	findByName(page, perPage){
		this.setState({loading: true})
		axios.get(`/add/findByName?name=${this.state.searchName}&page=${page}&limit=${perPage}&id=${jwt_decode(localStorage.getItem('token')).id}`)
		.then(result => {
			console.log(result)
				if(result.data === 1){
					NotificationManager.success("No Product Found on this Name")
					this.setState({loading: false})
				}else{
					this.setState({isLoaded: true, productsData: result.data.items, currentPage: page, 
						pageCount: Math.ceil(result.data.totalCount / this.state.showPerPage),loading: false,
						productTotalCount: result.data.totalCount})
				}
		})
		.catch(err => {
			console.log(err)
			this.setState({loading: false})
			NotificationManager.error("Failed to get product data")
		})
	}

	triggerHanleSearch = e => {
		e.persist()
		if(e.key === 'Enter'){
			this.searchFunc()
		}
	}

	searchFunc(){
		let errors = {}
		if(!_.isEmpty(this.state.searchName)){
			this.findByName(1, this.state.showPerPage)
		}else if(!_.isEmpty(this.state.searchSku)){
			this.setState({loading: true})
			axios.get(`/add/findBySku?sku=${this.state.searchSku}&id=${jwt_decode(localStorage.getItem('token')).id}`)
			.then(result => {
				console.log(result.data.length, result.data)
				if(result.data === 1){
					NotificationManager.success("No Product Found on this SKU")
					this.setState({loading: false})
				}else{
					let temp = []
					temp.push(result.data.obj)
					this.setState({isLoaded: true, productsData: temp, pageCount: Math.ceil(temp.length / this.state.showPerPage),
						currentPage: 1, loading: false, productTotalCount: temp.length})
            localStorage.setItem("p.currentpagea",1);
				}
			})
			.catch(err => {
				this.setState({loading: false})
				console.log(err)
				NotificationManager.error("Failed to get product data")
			})
		}else{
			// errors.err = "Please provide us any value to search"
			// this.setState({errors})
			this.getProductsData(1, this.state.showPerPage)
		}
	}

	handleSearch = e => {
		e.preventDefault()
		this.searchFunc()
	}

	handleShowPerPage = e => {
		e.preventDefault()
		this.setState({showPerPage: e.target.value})
		this.getProductsData(1, e.target.value)
	}

	render(){
		if(_.isEmpty(this.state.productsData) && this.state.isLoaded){
			return(
				<div>
					<br></br>
					<h4 className="text-center my-5">No Products Found</h4>
				</div>
			)
		}else{
			return(
				<div >
					<SyncLoader css={override} size={15} margin={6} color={"#3E3E3E"} loading={this.state.loading} />
					<br></br>
					<div className={this.state.loading ? "parentDisable" : ""}>
						<Modal show={this.state.modalShow} onHide={() => this.setState({modalShow: false})} animation={false}>
							<Modal.Header closeButton>
							<Modal.Title>Delete Product</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<h5>What kind of delete you want to make on this?</h5>
								<br></br>
								<p>{this.state.deleteProductName}</p>
								<br></br>
							</Modal.Body>
							<Modal.Footer>
							<Button variant="secondary" onClick={() => this.setState({modalShow: false})}>
								Cancel
							</Button>
							<Button variant="success" onClick={() => this.softDeleteProduct()}>
								Soft Delete
							</Button>
							<Button variant="danger" onClick={() => this.hardDeleteProduct()}>
								Hard Delete
							</Button>
							</Modal.Footer>
						</Modal>
						<div className="container-fluid">
							<div className="card" style={{padding: "1em"}}>
								<div className="row">
									<div className="col-sm-12">
										{
											this.state.errors.err
											&&
											<span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.err}</span>
										}
									</div>
								</div>
								<div className="row">
									<div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
										<div className="form-group text-center">
											<input type="text"
												placeholder="Search by name..."
												className="form-control"
												value={this.state.searchName}
												onKeyPress={this.triggerHanleSearch}
												onChange={(e) => this.setState({searchName: e.target.value, searchSku: "", errors: {}})}
												title="Hit Enter for the result"></input>
										</div>
									</div>
									<div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
										<div className="form-group text-center">
											<input type="text"
												placeholder="Search by sku..."
												className="form-control"
												value={this.state.searchSku}
												onKeyPress={this.triggerHanleSearch}
												onChange={(e) => this.setState({searchSku: e.target.value, searchName: "", errors: {}})}
												title="Hit Enter for the result"></input>
										</div>
									</div>
									{/* <div className="col-sm-2">
										<input type="button" value="Search" 
											className="btn btn-outline-primary"
											onClick={this.handleSearch}></input>
									</div> */}
								</div>
							</div>
						</div>

						<div className="container-fluid" >
						<div className="card" style={{padding: "1em"}}>
							<div className="row">
								<div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
									<div style={{cursor: "pointer"}} onClick={() => this.getProductsData(1, this.state.showPerPage)}>
										<div className="form-group">
											<h5 className="text-bold">Total Products: <span className="text-muted">{this.state.productTotalCount}</span>
												&nbsp;<i className="fa fa-refresh" aria-hidden="true"></i>
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
									<select className="form-control" style={{width: "100px", float: "right"}} onChange={(e) => this.handleShowPerPage(e)}>
										<option defaultValue={this.state.showPerPage == 5 && "selected"}>5</option>
										<option defaultValue={this.state.showPerPage == 25 && "selected"}>25</option>
										<option defaultValue={this.state.showPerPage == 50 && "selected"}>50</option>
										<option defaultValue={this.state.showPerPage == 100 && "selected"}>100</option>
										<option defaultValue={this.state.showPerPage == 200 && "selected"}>200</option>
									</select>
								</div>
							</div>
							<div className="table-responsive">
								<table className="table table-hover ProductListTable">
									<thead>
										<tr className="d-flex">
											<th scope="col" >S.No</th>
											<th scope="col" >Image</th>
											<th scope="col" >Name</th>
											<th scope="col" >SKU</th>
											<th scope="col" >Category</th>
											<th scope="col" >Ex Factory Price</th> 
											<th scope="col" >Action</th> 
										</tr>
									</thead>
									<tbody>
										{
											!_.isEmpty(this.state.productsData)
											?
												this.state.productsData.map((item, index) => {
													const {_id, image, name, invoiceDesc, code, category, price , productUrl} = item
													return(
														<tr key={index} className="d-flex">
															<td >{((this.state.currentPage - 1) * this.state.showPerPage) + (index + 1)}</td>
															<td style={{width: "12%"}}>
																{!_.isEmpty(item.image) 
																	? 
																		<img
																			style={{height: "90px", width: "100px"}}
																			src={`${this.state.productImageUrl}/${image}`}
																		/>
																	: 
																		"Image not Available"
																}
															</td>
															<td >{name}</td>
															
															<td >{code}</td>
															<td >{category}</td>
															{/* <td >
																<i className="fa fa-inr" aria-hidden="true">{price}</i>
															</td> */}
															<td >
															{getPriceFormatter(price)}
															</td>
															<td style={{'width' : '100px'}}>
																<span className="text-primary" >
																	<a href={productUrl} target="_blank">
																		<i className="fa fa-eye" style={{cursor: "pointer"}} aria-hidden="true"></i>
																	</a>
																</span> 
																<span className="text-primary" style={{'paddingLeft' : '10px'}}>
																	<Link to="/supplier/editProduct">
																		<i className="fa fa-pencil" style={{cursor: "pointer", fontSize: "20px"}} aria-hidden="true" onClick={() => this.storeUniqueId(code)}></i>
																	</Link>
																	
																</span>
																<span className="text-danger" >
																	<i className="fa fa-trash cursor-poiner" style={{fontSize: "22px" , cursor: "poiner" , paddingLeft:'10px'}} aria-hidden="true" onClick={() => this.handleDeleteProduct(item)}></i>
																</span>
															</td> 
														</tr>
													)
												})
											:
												<tr>
													<td colSpan="7" className="text-center" style={{fontSize: "20px"}}>No Product Found</td>
												</tr>
										}
									</tbody>
								</table>
							</div>
							<ReactPaginate
								previousLabel={'<'}
								nextLabel={'>'}
								breakLabel={'...'}
								breakClassName={'break-me'}
								pageCount={this.state.pageCount}
								marginPagesDisplayed={3}
								pageRangeDisplayed={10}
								onPageChange={this.handlePageClick.bind(this)}
								containerClassName={'pagination'}
								subContainerClassName={'pages pagination'}
								activeClassName={'active'}
							/>
						</div>
					</div>
					</div>
				</div>
			)
		}
	}
}
