/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { NavItem, Nav } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import jwt_decoder from "jwt-decode";

class AdminNavbarLinks extends Component {
  constructor() {
    super();
    let isLoggedIn = false;
    const token = localStorage.getItem("token");
    if (token) {
      isLoggedIn = true;
    }
    this.state = {
      email: "",
      name: "",
      isLoggedIn,
    };

    let decoder = jwt_decoder(token);
    // console.log({decoder})
    this.state.email = decoder.email;
    this.state.name = decoder.name;

    this.logout = this.logout.bind(this);
  }

  logout() {
    // localStorage.removeItem("token");
    // localStorage.removeItem("refreshToken");
    localStorage.clear();
    this.setState({
      isLoggedIn: false,
    });
  }
  render() {
    if (this.state.isLoggedIn === false) {
      return <Redirect to="/" />;
    }
    const imageLogo = localStorage.getItem("brandlogo");
    const supplierStatus=localStorage.getItem("loggedInUser")
    return (
      <div>
        <Nav pullRight>
          {imageLogo  && supplierStatus === "supplier" ? (
            <NavItem eventKey={1}>
            <img
              height="20px"
              width="70px"
              src={`https://static-sellercentral.shopperr.in/${imageLogo}`}
              alt=""
            />
          </NavItem>
          ) : null
        }
         
          <NavItem eventKey={2}>Welcome {this.state.name}</NavItem>
          <NavItem eventKey={3} onClick={this.logout}>
            Log out
          </NavItem>
        </Nav>
      </div>
    );
  }
}

export default AdminNavbarLinks;
