import React, { useState, useEffect } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Modal from "react-responsive-modal";
import CustomButton from "../components/CustomButton/CustomButton.jsx";
// import { Row, Col, Table } from "react-bootstrap";
// import Card from "../components/Card/Card.jsx";
import MerchantStoreApi from './MerchantStoreApi.jsx'
import Amazon from "../assets/video/Amazon.jpg"
import Shopify from "../assets/video/Shopify.jpg"
import Flipkart from "../assets/video/Flipkart.jpg"

import Swal from 'sweetalert2'
const Orders = () => {
  const [SellerId, setSellerId] = useState();
  const [MWSAuthToken, setMWSToken] = useState("");
  const [open, setOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [mwsDetails, setMWSDetaits] = useState(false);
  const [amazonConnectItems, setAmazonConnectItems] = useState([]);
  const [spDetails, setSPDetaits] = useState({ location: "/", isconnected: 0 });
  const tokenData = localStorage.getItem("token");
  let decode = jwt_decode(tokenData);
  // List states
  const [shopifyApiKey, setShopifyApiKey] = useState('');
  const [shopifySecretKey, setShopifySecretKey] = useState('');
  const [storeDomain, setStoreDomain] = useState('');
  const [storeData, setStoreData] = useState({});
  const [isValue, setIsValue] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    connectAWSAccount({ marchantId: decode.id }, 1);
    connectToAmazon();
    setLoading(true)
    axios.get('/sscapi/shopdata', {
        headers: {
            'auth-token': localStorage.getItem('token')
    }
}).then(res => {
    const shopData = res.data.storeData
    setStoreData(shopData)
    shopData.length && setIsValue(true)
    setLoading(false)
}).catch(err => {
    setLoading(false)
})
  }, []);

  const marketplaceAPI = () => {
    setOpen(true);
  };

  const modalStyle = {
    margin: "auto",
    position: "relative",
  };

  const handleClickTest = () => {
    connectAWSAccount({
      SellerId,
      MWSAuthToken,
      marchantId: decode.id,
      test: 1,
    });
  };

  const handleClick = () => {
    connectAWSAccount({
      SellerId,
      MWSAuthToken,
      marchantId: decode.id,
      test: 0,
    });
  };


  // List delete option function
  const handleDelete = (data) => {
    const confirm = window.confirm("Are you sure ?")
    if (confirm) {
      setLoading(true)
      axios.put('/sscapi/delete', data, {
        headers: {
          'auth-token': localStorage.getItem('token')
        }
      })
        .then(res => {
          if (!res.hasOwnProperty("error")) {
            const shopData = res.data 
            setStoreData(shopData.storeData)
            Swal.fire("Deleted Successfully")
          }
          setLoading(false)
        })
        .catch(err => {
          alert("Error Deleting data")
          setLoading(false)
        })
    }
  } 
  const connectToAmazon = () => {
    // window.location.href = 'https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.0f4ae59e-a495-4ae7-9a72-0fc6bd4fc8ab';

    return axios
      .post("https://api-mp.shopperr.in/amazon/connect", {
        marchantId: decode.id,
      })
      .then((data) => data.data)
      .then((data) => {
        if (!data.error) {
          if (data.isconnected) {
            setSPDetaits({ isconnected: 1, message: data.message });
          } else {
            setSPDetaits({ location: data.location });
          }
        }
      });
  };

  const connectToAmazonAccount=()=>
  {
    window.location.href="https://sellercentral.amazon.in/ap/signin?clientContext=257-7240219-3869215&openid.return_to=https%3A%2F%2Fsellercentral.amazon.in%2Fapps%2Fauthorize%2Fconsent%3Fapplication_id%3Damzn1.sp.solution.ee136a30-fcd8-4ac1-8df5-d8164219b358%26mId%3D60700ab4d3de306dab4badb4&openid.identity=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.assoc_handle=sc_in_amazon_v2&openid.mode=checkid_setup&openid.claimed_id=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.ns=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0&mons_redirect=sign_in&ssoResponse=eyJ6aXAiOiJERUYiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiQTI1NktXIn0.H4uvYYnn5M156Dxq_KMUPuTkDdkQMZzHxwI5J9dNR5GoCTd44FH29w.2bGgLPsvPsGwykyc.ZbkqYdejr_btfulSBKtBcojq9Tj4X2tOucdrpyIi5QSMJHYtiNPlDgqAHuzAnuuqzll4JQNO_628QBPZIj1adbby1Xf2QbEs_guIcwaXqyK0ru-tjdiPQlIV-pQN8hQYLaSAYl2tDrSzIfa9dnpsoxNGHkurdrafvmivaFF1axjIbD9pTjMoqO211K4FLCuP6ZKz0uQ6FQ.I1UQQQA5zM1fEC90TAvFVg";
  }
  const connectAWSAccount = (body, isGet = 0) => {
    return axios
      .post("https://api-mp.shopperr.in/amazon/connect", {
        ...body,
        get: isGet,
      })
      .then((data) => data.data)
      .then((res) => {
        if (isGet) {
          if (!res.error) {
            setMWSDetaits(res.body);
            setAmazonConnectItems(res.data);
          }
          return false;
        }
        if (res.error || res.test) {
          setErrorOpen(true);
          document.getElementById("error_message").innerHTML = res.message;
        } else {
          setOpen(false);
        }
      });
  };
  return (
    <div>
      <Modal style={{height:"300px"}} open={open} onClose={() => setOpen(false)}>
      <br />
      <h3 style={{ color: "#FFA000" }} className="text-center">
          Shopify seller API integration
      </h3>
      <br />
      <MerchantStoreApi/>
      </Modal>
      <div className="content bdycontent">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4">
            <div className="card" >
            <div className="platform" >
          <a  ><img src={Amazon} height='150px' /></a>
          </div>
              <CustomButton fill type="button" onClick={connectToAmazonAccount}>
                Connect to Amazon
              </CustomButton>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card" >
              <div className="platform" >
            <a  ><img src={Shopify} height='150px' /></a>
          </div>
                <CustomButton fill type="button" onClick={marketplaceAPI}>
                  Connect to Shopify
                </CustomButton>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card" >
              <div className="platform" >
              <a  ><img src={Flipkart} height='150px' /></a>
            </div>
              <CustomButton fill type="button" >
              Connect to Flipkart
            </CustomButton>
            </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 card">
            <table className="table table-striped">
            <thead>
                <tr>
                  <th scope="col">Store Domain</th>
                  <th className ='text-center' scope="col">API Key</th>
                  <th className ='text-center' scope="col">Secret Key</th>
                  <th className ='text-center' scope="col">Channel</th>
                  <th className ='text-center' scope="col">Action</th>
                </tr>
            </thead>
            <tbody>
                {
                storeData.length && storeData.map(data => {
                return (
                <tr key={data._id}>
                    <td scope="row"  >{ data.storeDomain || `--` }</td>
                    <td className ='text-center'>{ data.shopifyApiKey || `--` }</td>
                    <td className ='text-center'>{ data.shopifySecretKey || `--` }</td>
                    <td className ='text-center'>Shopify</td>
                    <td className ='text-center'> 
                        <span className="text-danger" onClick={() => handleDelete(data)}><i className="fa fa-trash" aria-hidden="true" style={{fontSize: '22px'}}></i></span>
                    </td>
                </tr>
                )
                })
                }
            </tbody>
            </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;
