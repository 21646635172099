import React, { Component } from "react";

export class Card2 extends Component {
  render() {
    return (
      <div className={"card" + (this.props.plain ? " card-plain" : "")}  style={{marginTop:"5px"}}>
        <h4>{this.props.mainHeader}</h4>
        <div className={"header" + (this.props.hCenter ? " text-center" : "")}>
          <h6 className="title">{this.props.title}</h6>
          <p className="category">{this.props.category}</p>
        </div>
      </div>
    );
  }
}

export default Card2;
