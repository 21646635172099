import React, {useState, useEffect} from 'react'
import axios from 'axios'

function PrintLabel() {
    const [poId, setPoId] = useState('')
    const [data, setData] = useState({})
    const [invoiceShow, setinvoiceShow] = useState("");
    useEffect(() => {
        // axios.get('/sscapi/purchase-order')
        //     .then(res => {
        //         console.log('data ',res.data)
        //     })
        //     .catch(err => {
        //         console.log("Error ",err)
        //     })
    },[])
    const handleSubmit = () => {
        console.log("Inside handle submit ", poId)
        if (poId) {
            axios.get(`/sscapi/purchase-order/${poId}`)
            .then(res => {
                if (res.data.poData) {
                    // const invoice = res.data.poData.invoicePdf
                    const invoice= res.data.logisticData.label?res.data.logisticData.label:""
                    if (invoice.length) {
                        // window.open(`https://static-staging-sellercentral.shopperr.in/${invoice[0].original}`)
                        setinvoiceShow(invoice)
                        const invoicepdf=invoice
                        window.open(invoicepdf)
                    }else{
                        console.log("inside else");
                        alert("No invoice found")
                    }
                    setData(res.data)
                } else {
                    alert("Unable to get data")
                    console.log("Unable to get data")
                }
            })
            .catch(err => {
                console.log("Error ",err)
            })
        } else {
            alert("PO id filed can't be blank")
        }
        
    }

    return (
        <div className="container-fluid">
            <div className="card" style={{padding: "1em"}}>
                <div className="row">
                    <div className="col-md-6">
                        <input type="text" className="form-control" placeholder="Enter Po id/sku" aria-label="Barcode number" aria-describedby="basic-addon2" onChange={(e)=> setPoId(e.target.value)} autoFocus />
                    </div>
                    <div className="col-md-2">
                        <button className="btn btn-primary" onClick={handleSubmit}><span className="glyphicon glyphicon-circle-arrow-right "> Get </span></button>
                    </div>
                    <embed src={invoiceShow} width="800px" height="2100px" />

                </div>
            </div>   
        </div>
    )
}

export default PrintLabel