import React, {Component  } from 'react';
import axios from 'axios';
import SyncLoader from "react-spinners/SyncLoader"

const override = `
	position: fixed;
	display: block;
	top: 45%;
	left: 50%;
	transform: translateX(-50%);
	transform: translate(-50%, -50%);
	z-index: 9999;
`
class amazonConnection extends Component {
    shopperHandle = (sId) => {
        return axios.post('https://sellercentral-staging.shopperr.in/web/shopperHandle',{id:sId}).then((data)=>data.data).then((data) => {
            if(!data.error){
                window.location.href = data.redirect_url;
            }
        });
    };

    render() {
        this.shopperHandle(this.props.match.params.id);
        return (
            <div>
                <div className='content'>
                    <div className="common-con-style common-con-style2 clearfix">
                        <SyncLoader css={override} size={15} margin={6} color={"#3E3E3E"} loading={true} />
                    </div>
                </div>
            </div>
        );
    }
};

export default amazonConnection;
