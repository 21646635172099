import React, { Component } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import "../../assets/css/addProductCatalog.css";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import "font-awesome/css/font-awesome.min.css";
import _ from "lodash";
import CustomButton from "../../components/CustomButton/CustomButton";
import jwt_decode from "jwt-decode";
import { NotificationManager } from "react-notifications";
import SyncLoader from "react-spinners/SyncLoader";
import validator from "validator";
import ReactPaginate from "react-paginate";
import { Modal, Button } from "react-bootstrap";

import "../../assets/css/productList.css";
import { Link } from "react-router-dom";

const override = `
    position: fixed;
    display: block; 
    top: 45%;
    left: 50%;
    transform: translateX(-50%);
    transform: translate(-50%, -50%);
    z-index: 9999;
`;
const imageExtensions = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/webp",
  "image/bmp",
];

export default class AddOnProductCatalog extends Component {
  constructor() {
    super();
    this.state = {
      imageArray: [],
      loading: false,
      imagesData: [],
      currentPage: 1,
      showPerPage: 50,
      pageCount: 0,
      imageTotalCount: 0,
    };
    this.uploadMultipleImages = this.uploadMultipleImages.bind(this);
  }

  componentDidMount() {
    this.getImagesData(this.state.currentPage, this.state.showPerPage);
  }

  getImagesData(page, perPage) {
    this.setState({ loading: true });
    axios
      .get(
        `/add/getimages?page=${page}&limit=${perPage}&id=${
          jwt_decode(localStorage.getItem("token")).id
        }`
      )
      .then((result) => {
        // console.log(result); return false;
        this.setState({
          imagesData: result.data.items,
          imageTotalCount: result.data.totalCount,
          pageCount: Math.ceil(result.data.totalCount / this.state.showPerPage),
          currentPage: page,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  }

  handlePageClick = (e) => {
    this.getImagesData(e.selected + 1, this.state.showPerPage);
  };

  uploadMultipleImages = (e) => {
    const readImage = (file) => {
      if (imageExtensions.includes(file.type)) {
        const reader = new FileReader();
        reader.addEventListener("load", (e1) => {
          this.setState({
            imageArray: this.state.imageArray.concat(e1.target.result),
          });
        });
        reader.readAsDataURL(file);
      } else {
        NotificationManager.error(
          `File not supported, Supported files are jpeg, png, webp, bmp, gif.`
        );
      }
    };

    const files = e.target.files;
    if (!files || !files[0]) return alert("File upload not supported");
    [...files].forEach(readImage);
  };

  handleDeleteImage = (index) => {
    this.setState({
      imageArray: this.state.imageArray.filter((img, i) => {
        return i !== index;
      }),
    });
  };
  handleAllInputs = (e) => {
    e.persist();
    this.setState({ [e.target.name]: e.target.value });
  };

  addImage = (e) => {
    e.preventDefault();
    let data = {
      imageArray: this.state.imageArray,
      supplierId: jwt_decode(localStorage.getItem("token")).id,
    };
    console.log(this.state.imageArray);
    console.log(this.state.imageArray.length);
    if (this.state.imageArray.length > 0) {
      this.setState({ loading: true });
      axios
        .post("/add/addImages", data)
        .then((result) => {
          if (result.data.error == 0) {
            NotificationManager.success(result.data.Response);
            this.setState({ loading: false });
            window.location.reload(false);
          } else {
            NotificationManager.success(result.data.Response);
            this.setState({ loading: false });
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
          document.getElementById("addProductBtn").disabled = false;
          NotificationManager.error(
            "Something went wrong, please try again later."
          );
        });
    } else {
      NotificationManager.success("Select Image.");
    }
  };

  render() {
    const Editor = {
      modules: {
        toolbar: [
          [{ header: [1, 2, false] }],
          ["bold"],
          ["italic"],
          ["underline"],
          ["strike"],
          ["blockquote"],
          [{ list: "ordered" }],
          [{ list: "bullet" }],
          [{ indent: "+1" }],
          [{ indent: "-1" }],
          ["link"],
          ["video"],
          ["image"],
        ],
      },
      formats: [
        "header",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video",
      ],
    };
    return (
      <div className="mainBox">
        <SyncLoader
          css={override}
          size={15}
          margin={6}
          color={"#3E3E3E"}
          loading={this.state.loading}
        />
        <br></br>
        <div className={this.state.loading ? "parentDisable" : ""}>
          <div className="container-fluid">
            <div className="card" style={{ padding: "1em" }}>
              <label htmlFor="productImage" style={{ fontSize: 15 }}>
                Image upload &nbsp;
                <span
                  className="custom-tooltip"
                  data-tooltip="Clear and informative images, which allow clear recognition of the product"
                >
                  <i className="fa fa-question-circle" aria-hidden="true"></i>
                </span>
              </label>
              <form
                style={{
                  justifyContent: "flex-start",
                  alignItems: "center",
                  margin: "0",
                }}
              >
                <div className="form-group" style={{ margin: "0" }}>
                  <label className="btn btn_new">
                    Choose Images
                    <input
                      type="file"
                      id="productImage"
                      className="hidden"
                      onChange={this.uploadMultipleImages}
                      multiple
                      accept="image/*"
                    />
                  </label>
                </div>
                <div className="">
                  <CustomButton
                    fill
                    type="submit"
                    className="btn_new"
                    id="addProductBtn"
                    onClick={this.addImage}
                  >
                    Add Image
                  </CustomButton>
                </div>
              </form>
              <div className="row">
                <div
                  className="form-group multi-preview"
                  style={{ marginTop: "20px" }}
                >
                  {this.state.imageArray.map((image, i) => {
                    return (
                      <div className="col-md-3 show-image1" key={i}>
                        <span
                          className="closeButton1"
                          onClick={() => this.handleDeleteImage(i)}
                        >
                          <i className="fa fa-times" aria-hidden="true"></i>
                        </span>

                        <div className="img-overlay1"></div>
                        <img src={image} alt="uploadImage" className="images" />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="card" style={{ padding: "1em" }}>
            <div className="table-responsive">
              <table className="table table-hover ProductListTable">
                <thead>
                  <tr className="d-flex">
                    <th scope="col">S.No</th>
                    <th scope="col">Image</th>
                    <th scope="col">Path</th>
                    <th scope="col">Created Date</th>
                  </tr>
                </thead>
                <tbody>
                  {!_.isEmpty(this.state.imagesData) ? (
                    this.state.imagesData.map((item, index) => {
                      const { _id, path, date } = item;
                      return (
                        <tr key={index} className="d-flex">
                          <td>
                            {(this.state.currentPage - 1) *
                              this.state.showPerPage +
                              (index + 1)}
                          </td>
                          <td style={{ width: "12%" }}>
                            <img
                              style={{ height: "90px", width: "100px" }}
                              src={`${path}`}
                            />
                          </td>
                          <td>{path}</td>
                          <td>{date}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan="3"
                        className="text-center"
                        style={{ fontSize: "20px" }}
                      >
                        Images Not Uploaded
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {!_.isEmpty(this.state.imagesData) && (
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCount}
                marginPagesDisplayed={3}
                pageRangeDisplayed={10}
                onPageChange={this.handlePageClick.bind(this)}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
