 import Dashboard from "./views/SupplireScreenRoutes/SupplierDashboard.jsx";
import AddOnProductCatalog from './views/SupplireScreenRoutes/AddProduct-Catalog.jsx'
// import AddOnProduct from "./views/AddProduct.jsx";
import ProductList from "./views/SupplireScreenRoutes/ProductList.jsx";
import EditProduct from "./views/SupplireScreenRoutes/EditProduct.jsx";
import CsvProduct from "./views/SupplireScreenRoutes/CsvProduct.jsx";
// import ShopifyProduct from "./views/ShopifyProduct.jsx";
// import ProductLayout from "./layouts/Products.jsx";
// import Orders from "./views/Orders.jsx";
// import FulfilledOrders from "./views/FulfilledOrders.jsx";
import SupplierOrders from "./views/SupplireScreenRoutes/SupplierOrders.jsx";
import Settings from "./views/SupplireScreenRoutes/Settings.jsx";
import SupplierPaymentDetails from "./views/SupplireScreenRoutes/SupplierPaymentDetails.jsx";
import BarcodePo from "./views/BarcodePo"
import EditPo from './views/SupplireScreenRoutes/SupplierEditPo.jsx'
import uploadImages from './views/SupplireScreenRoutes/uploadImages.jsx'


import uploadProducts from './views/SupplireScreenRoutes/uploadProducts'
import MerchantCustomerList from './views/SupplireScreenRoutes/MerchantCustomerList'
import UploadImportCsvFile from './views/SupplireScreenRoutes/UploadImportCsvFile'
import ReturnOrder from './views/SupplireScreenRoutes/ReturnOrders.jsx'

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "pe-7s-graph",
    component: Dashboard,
    layout: "/supplier",
  },
  {
    path: "/products",
    name: "Products",
    icon: "pe-7s-shopbag",
    component: ProductList,
    layout: "/supplier",
  },
  // {
  //   path: "/addProduct",
  //   name: "Add Product",
  //   icon: "pe-7s-upload",
  //   component: AddOnProduct,
  //   layout: "/supplier",
  // },
  {
    path: "/addProductCatalog",
    name: "Add Product",
    icon: "pe-7s-upload",
    component: AddOnProductCatalog,
    layout: "/supplier",
  },
  {
    path: "/upload-products",
    name: "Bulk Product Upload",
    icon: "pe-7s-credit",
    component: uploadProducts,
    layout: "/supplier",
  },
  {
    path: "/CSvProduct",
    name: "Bulk Product Update",
    icon: "pe-7s-ticket",
    component: CsvProduct,
    layout: "/supplier",
  },
  {
    path: "/SupplierOrders",
    name: "Orders",
    icon: "pe-7s-bell",
    component: SupplierOrders,
    layout: "/supplier",
  },
  {
    path: "/Settings",
    name: "Settings",
    icon: "pe-7s-config",
    component: Settings,
    layout: "/supplier",
  },
  // {
  //   path: "/PaymentSettings",
  //   name: "Payment Settings",
  //   icon: "pe-7s-credit",
  //   component: SupplierPaymentDetails,
  //   layout: "/supplier",
  // },
  {
    path: "/upload-image",
    name: "Upload Image",
    icon: "pe-7s-back-2",
    component: uploadImages,
    layout: "/supplier",
  }, 
  {
    path: "/customer-list",
    name: "Customers",
    icon: "pe-7s-users",
    component: MerchantCustomerList,
    layout: "/supplier",
  },
  {
    path: "/return-order",
    name: "Return Orders",
    icon: "pe-7s-repeat",
    component: ReturnOrder,
    layout: "/supplier",
  },
  // {
  //   path: "/UploadImportCsv",
  //   name: "Upload CSV File",
  //   icon: "pe-7s-upload",
  //   component: UploadImportCsvFile,
  //   layout: "/supplier",
  // },
  {
    path: "/editProduct",
    component: EditProduct,
    layout: "/supplier",
  },
  {
    path: "/barcode",
    component: BarcodePo,
    layout: "/supplier",
  },
  {
    path: "/poedit/:id",
    component: EditPo,
    layout: "/supplier"
  }
];
export default dashboardRoutes;
