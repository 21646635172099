import React, { Component } from 'react';
import { Grid, Row, Col, Table , Button, Modal} from 'react-bootstrap';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { NotificationManager } from 'react-notifications';
import CustomButton from '../../components/CustomButton/CustomButton.jsx';
import Card from '../../components/Card/Card.jsx';
import SyncLoader from "react-spinners/SyncLoader"
import { Link } from "react-router-dom"
import _ from 'lodash' 
import 'react-tagsinput/react-tagsinput.css' 
const override = `
	position: fixed;
	display: block;
	top: 45%;
	left: 50%;
	transform: translateX(-50%);
	transform: translate(-50%, -50%);
	z-index: 9999;
`
export default class SubAdminProduct extends Component{ 
	constructor(){
		super()
		this.state = {
			loading: false,    
			supllierList: [],   
			setName : '',
			setEmail : '',
			setMobile : '',
			setOrigin : '',
			setPassword : '',
			setMenu : [],
			getSupplier : [],
			errors: {}, 
			adminMenu : [
				{'Name' : 'Dashboard' , 'Url' : 'dashboard'} , 
				{'Name' : 'Suppliers', 'Url' : 'supplier'} , 
				{'Name' : 'Logistic Users', 'Url' : 'logisticUsersList'} , 
				{'Name' : 'Add Supplier', 'Url' : 'addSupplier'} , 
				{'Name' : 'Add Logistic User', 'Url' : 'addLogisticUser'} , 
				{'Name' : 'Products', 'Url' : 'product'} , 
				{'Name' : 'Product Category', 'Url' : 'categoryList'},
				{'Name' : 'Add Sub Admin', 'Url' : 'sub-admin'},
				{'Name' : 'Auto Margin', 'Url' : 'AutoMargin'},
				{'Name' : 'Settings', 'Url' : 'profile'},
				{'Name' : 'Merchants', 'Url' : 'merchant-detail'},
				{'Name' : 'Orders', 'Url': 'orders'  },
			    {'Name' : 'Purchase Orders', 'Url': 'purchaseOrders'},
			    {'Name' : 'Payments' , 'Url': 'payments'},
			    {'Name' : 'Transaction History' , 'Url': 'transaction'},
			    {'Name' : 'Requested Product' , 'Url': 'requested-product'},
			    {'Name' : 'Add Warehouse' , 'Url': 'addwarehouse'},
			    {'Name' : 'Edit Product','Url': 'editProduct' },
			    {'Name' : 'Edit Supplier','Url': 'editSupplier' },
			    {'Name' : 'Add Category','Url': 'AddCategory' },
				{'Name' : 'Order Process','Url': 'orderProcess' }  
			]	
		}
	}

	componentDidMount(){ 
		this.getSupplierList()
		// this.getSupplierProfile(localStorage.getItem("code"))
		this.getAdminProfile(localStorage.getItem("code")) 
		 
	}

	getAdminProfile = (searchId) => {
	    axios.get("/adminApi/adminProfile" + searchId).then((res) => {
	      	console.log(res.data)
	      	if(res.data.error == 0){
	      		this.setState({setName: res.data.items.name})
	          	this.setState({setEmail: res.data.items.email})
	          	this.setState({setMobile: res.data.items.phoneNo}) 
				this.setState({setOrigin: res.data.items.origin?res.data.items.origin:"India"}) 
	          	this.setState({setMenu: res.data.items.menu})
	          	this.setState({getSupplier: res.data.items.supplier})
	      	}else{
	      		NotificationManager.error("Something went wrong, while getting data")
	      	} 
	    }) 
	}

	getSupplierList(){ 
		axios.get("/adminApi/supplierList")
		.then(response => {
			this.setState({supllierList: response.data})
		})
	}

	addName = (e) => {
	    this.setState({setName : e.target.value})
	}

	addEmail = (e) => {
	    this.setState({setEmail : e.target.value})
	}

	addMobile = (e) => {
	    this.setState({setMobile : e.target.value})
	}

	addPwd = (e) => {
	    this.setState({setPassword : e.target.value})
	}

	setOrigin = (e) => {
	    this.setState({setOrigin : e.target.value})
	}

	addMenu = (e) => {  
		let checkUrl = this.state.setMenu.find(menu => menu.Url === e.target.value)
		if(checkUrl){
			NotificationManager.error('Menu already selected.');
		}else{
			let Name = this.state.adminMenu.find(o => o.Url === e.target.value)
	 		let finaldata = {'Name' : Name.Name , 'Url' : e.target.value}
		    this.state.setMenu.push( finaldata )
	 		this.setState({setMenu: this.state.setMenu})
		} 
 		console.log(this.state.setMenu)
	}
	 
 	setSupplier = (e) => {  
 		let checkSupplier = this.state.getSupplier.find(supp => supp === e.target.value)
 		if(checkSupplier){
			NotificationManager.error('Supplier already selected.');
		}else{
	 		this.state.getSupplier.push( e.target.value)
	 		this.setState({getSupplier: this.state.getSupplier})
	 		console.log(this.state.getSupplier)
	 	}
 	}

 	handleSupplierListChange =  (e) => { 
	    let index = e.target.dataset.index 
	    var array = [...this.state.getSupplier]
	    array.splice(index, 1)
	    this.setState({getSupplier: array}) 
	}
	handlemenuListChange =  (e) => { 
	    let index = e.target.dataset.index 
	    var array = [...this.state.setMenu]
	    array.splice(index, 1)
	    this.setState({setMenu: array}) 
	}

	updateSubAdmin = (e) => { 
		if(this.validate()){
		    const obj = {
			    name: this.state.setName,
			    email: this.state.setEmail,
			    mobile: this.state.setMobile,
			    origin:this.state.setOrigin,
			    menu: this.state.setMenu,
			    supplier : this.state.getSupplier,
			    id : localStorage.getItem("code")
		    }; 
		    console.log(obj);
		    axios.post('/adminApi/updateSubAdmin', obj).then((res) => {
		    	let data = res.data;
		    	console.log(data);
		        if (data.error == '0') { 
		          	NotificationManager.success(data.response);  
		        }else{
		        	NotificationManager.success(data.response);
		        }
		    })
		    .catch((err) => {
		        NotificationManager.error('Something Went Wrong.');
		    });
		}
	} 

	validate(){ 
	    let errors = {};
	    let isValid = true;
	  	if (!this.state.setName) {
	        isValid = false;
	        errors["name"] = "Please enter your name.";
	    }
	    if (this.state.setMenu.length === 0) {
	        isValid = false;
	        errors["menu"] = "Please Select Menu.";
	    }
	    if (this.state.getSupplier.length === 0) {
	        isValid = false;
	        errors["supplier"] = "Please Select Supplier.";
	    }
	  
	    if (!this.state.setEmail) {
	        isValid = false;
	        errors["email"] = "Please enter your email Address.";
	    }

		if (!this.state.setOrigin) {
            isValid = false;
            errors["origin"] = "Please select origin.";
        }
	  
	    if (typeof  this.state.setEmail !== "undefined") {
	          
	        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
	        if (!pattern.test(this.state.setEmail)) {
	          	isValid = false;
	          	errors["email"] = "Please enter valid email address.";
	        }
	    }
	  
	    if (!this.state.setMobile) {
	        isValid = false;
	        errors["mobile"] = "Please enter your mobile number.";
	    } else if (typeof this.state.setMobile !== "undefined") {
	          
	        var pattern = new RegExp(/^[0-9\b]+$/);
	        if (!pattern.test(this.state.setMobile)) {
	          	isValid = false;
	          	errors["mobile"] = "Please enter only number.";
	        }else if(this.state.setMobile.toString().length != 10){ 
	          	isValid = false;
	          	errors["mobile"] = "Please enter valid mobile number.";
	        }
	    }
	  
	    // if (!this.state.setPassword) {
	    //     isValid = false;
	    //     errors["password"] = "Please enter your password.";
	    // }else  if (typeof this.state.setPassword !== "undefined") {
	    // 	if(this.state.setPassword.length < 6){
	    //       	isValid = false;
	    //       	errors["password"] = "Password must be greater than five.";
	    //     }
	    // }
	  
	    this.setState({
	        errors: errors
	    });
	  
	    return isValid;
	}

	render(){
		return(
			<div>
				<SyncLoader css={override} size={15} margin={6} color={"#3E3E3E"} loading={this.state.loading} />
				<br></br>
				<div className="container-fluid">
					<div className="card" style={{padding: "1em"}}>
						<div className="row">
							<div className="form-group col-sm-12 col-md-12 col-lg-12"> 
								<label htmlFor='fullName'>Username</label>
								<input
								    className = 'form-control'
					              	type='text'
					              	name='fullName'
					              	value={this.state.setName}
					              	onChange={this.addName}
					            />
					            <div className="text-danger">{this.state.errors.name}</div>
							</div>

							<div className="form-group col-sm-12 col-md-12 col-lg-12">
								<label htmlFor='fullName'>Email</label>
								<input
									className = 'form-control'
						            type='email'
						            name='email'
						            value={this.state.setEmail}
						            onChange={this.addEmail}
					            />
					            <div className="text-danger">{this.state.errors.email}</div>
							</div>
							<div className="form-group col-sm-12 col-md-12 col-lg-12">
								<label htmlFor='fullName'>Mobile</label>
								<input
									className = 'form-control'
						            type='mobile'
						            name='mobile'
						            value={this.state.setMobile}
						            onChange={this.addMobile}
					            />
					            <div className="text-danger">{this.state.errors.mobile}</div>
							</div> 

							<div className="form-group col-sm-12 col-md-12 col-lg-12">
                            <label htmlFor='origin'>Origin</label>
                            <select className='form-control' name='origin' onChange={ this.setOrigin} placeholder='Enter type' value={this.state.setOrigin} required >
                                <option>Select Origin</option>
                                <option value="china">China</option>
                                <option value="india">India</option>
                            </select>
							<div className="text-danger">{this.state.errors.origin}</div>
                        </div>

							<div className="form-group col-sm-12 col-md-12 col-lg-12">
								{	
									this.state.getSupplier && 

									this.state.getSupplier.map((data , index) => {
										return (
											<> 
										        <div className="col-sm-3 col-md-3 col-lg-3" style={{ padding : '0px'}}  key={index} >
									            	{data} <label data-user={data} data-index={index} onClick= {this.handleSupplierListChange} >X</label>
									            </div>  
									        </>
									    )
									})
								}
								<label className="col-sm-12 col-md-12 col-lg-12" style={{ padding : '0px' , marginTop : '10px'}} htmlFor='fullName'>Add Supplier</label>  
								<select className="form-control" id="supplierList" onChange={ this.setSupplier }>
									<option value="Select Supplier" >Select Supplier</option>
									{	
										this.state.supllierList.map(data => {
											const { _id, supplier_id } = data 
											return(
												<option value={supplier_id}>{supplier_id}</option>
											)
										})
									}
								</select>
								<div className="text-danger">{this.state.errors.supplier}</div>
							</div> 
							<div className="form-group col-sm-12 col-md-12 col-lg-12">
								{	
									this.state.setMenu && 

									this.state.setMenu.map((data , index) => {
										return (
											<> 
										        <div className="col-sm-3 col-md-3 col-lg-3" style={{ padding : '0px'}}  key={index} >
									            	{data.Name} <label data-name={data.Name} data-url={data.Url} data-index={index} onClick= {this.handlemenuListChange} >X</label>
									            </div>  
									        </>
									    )
									})
								}
								<label className="col-sm-12 col-md-12 col-lg-12" style={{ padding : '0px' , marginTop : '10px'}} htmlFor='fullName'>Add menu</label> 
					            <select className="form-control" id="menuList" onChange={ this.addMenu }>
									<option value="Select Menu" >Select Menu</option>
									{	
										this.state.adminMenu.map( menu => { 
											return(
												<option value={ menu.Url } data-name={menu.Name}>{ menu.Name }</option>
											)
										})
									}
								</select>
								<div className="text-danger">{this.state.errors.menu}</div>
							</div>
							<div className="col-sm-2 col-md-2 col-lg-2 text-right">
								<button className='btn btn-fill' style={{ 
									backgroundColor: 'rgb(255, 160, 0)', 
									color: 'rgb(255, 255, 255)', 
									fontWeight: '500', 
									height: "40px", 
									display: "flex", 
									alignItems: "center", 
									justifyContent: "center", 
									borderRadius: 4, 
									border:'0px', 
									'fontSize': '15px'
								}}  onClick={() => this.updateSubAdmin()}>
									Update Sub Admin
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
} 