import React, { useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import '../assets/css/addProduct.css';
import { NotificationManager } from 'react-notifications';
import CustomButton from '../components/CustomButton/CustomButton';

const AddProduct = () => {
  const [name, setName] = useState('');
  const [subtitle, settitlename] = useState('');
  const [setinvoicedesc, setinvoiceDescname] = useState('');
  const [price, setPrice] = useState('');
  const [mrp_price, setPriceselling] = useState('');
  const [quantity, setQuantity] = useState(''); 
  const [warranty, setWarranty] = useState('');
  const [weight, setWeight] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [productImage, setProductImage] = useState([]);
  const [productImage0, setProductImage0] = useState([]);
  const [productImage1, setProductImage1] = useState([]);
  const [productImage2, setProductImage2] = useState([]);
  const [productImage3, setProductImage3] = useState([]);
  const [productImage4, setProductImage4] = useState([]);
  const [productImage5, setProductImage5] = useState([]);
  const [productImage6, setProductImage6] = useState([]);
  const [productImage7, setProductImage7] = useState([]);
  const [productImage8, setProductImage8] = useState([]);
  const [productImage9, setProductImage9] = useState([]);
  const [productImage10, setProductImage10] = useState([]);
  const [productImage11, setProductImage11] = useState([]);
  const [productImage12, setProductImage12] = useState([]);
  const [productImage13, setProductImage13] = useState([]);
  const [productImage14, setProductImage14] = useState([]);
  const [productImage15, setProductImage15] = useState([]);
  const [productImage16, setProductImage16] = useState([]);
  const [productImage17, setProductImage17] = useState([]);
  const [productImage18, setProductImage18] = useState([]);
  const [productImage19, setProductImage19] = useState([]);
  const [productImage20, setProductImage20] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [code, setCode] = useState('');
  const [hsn, setHsn] = useState('');
  const [status, setStatus] = useState('');
  const [categoryList, setCategoryList] = useState([]);
  const [size, setSize] = useState('');
  const [tags, setTags] = useState([]);
  const [tag0, setTag0] = useState([]);
  const [tag1, setTag1] = useState([]);
  const [tag2, setTag2] = useState([]);
  const [varien, setVarien] = useState(false);
  const [option1, setOption1] = useState('color');
  const [option2, setOption2] = useState('size');
  const [option3, setOption3] = useState('material');
  const token = localStorage.getItem('token');
  const decode = jwt_decode(token);

  const [moreOption, setMoreOption] = useState(false);
  const [moreOption1, setMoreOption1] = useState(false);
  const [combo, setCombo] = useState([]);
  const [varients, setVarients] = useState([]);
  const [shippingDetails, setShippingDetails] = useState('');
  const [usa, setUsa] = useState();
  const [canada, setCanada] = useState();
  const [uk, setUk] = useState();
  const [australia, setAustralia] = useState();
  const [international, setInternational] = useState();

  const [length, setLength] = useState();

  const [multerImage, setMulterImage] = useState([]);
  const [multerImage1, setMulterImage1] = useState([]);
  const [multerImage2, setMulterImage2] = useState([]);
  const [multerImage3, setMulterImage3] = useState([]);
  const [multerImage4, setMulterImage4] = useState([]);
  const [multerImage5, setMulterImage5] = useState([]);
  const [multerImage6, setMulterImage6] = useState([]);
  const [multerImage7, setMulterImage7] = useState([]);
  const [multerImage8, setMulterImage8] = useState([]);
  const [multerImage9, setMulterImage9] = useState([]);
  const [multerImage10, setMulterImage10] = useState([]);
  const [multerImage11, setMulterImage11] = useState([]);
  const [multerImage12, setMulterImage12] = useState([]);
  const [multerImage13, setMulterImage13] = useState([]);
  const [multerImage14, setMulterImage14] = useState([]);
  const [multerImage15, setMulterImage15] = useState([]);
  const [multerImage16, setMulterImage16] = useState([]);
  const [multerImage17, setMulterImage17] = useState([]);
  const [multerImage18, setMulterImage18] = useState([]);
  const [multerImage19, setMulterImage19] = useState([]);
  const [multerImage20, setMulterImage20] = useState([]); 
  const [autoMargin, setAutoMargin] = useState();

  useEffect(() => {
    getCategoryList();
  }, []);

  let Editor = {};
  Editor.modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold'],
      ['italic'],
      ['underline'],
      ['strike'],
      ['blockquote'],
      [{ list: 'ordered' }],
      [{ list: 'bullet' }],
      [{ indent: '+1' }],
      [{ indent: '-1' }],
      ['link'],
      ['video'],
      ['image'],
    ],
  };

  Editor.formats = [
    'header',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
  ];

  //Add Product
  const addProduct = async (e) => {
    e.preventDefault();
    // this.disabled = true;

    if (varien) {
      setQuantity(document.getElementById(`varientQuantity0`).value);
      setPrice(document.getElementById(`varientPrice0`).value);
      setCode(document.getElementById(`varientSku0`).value);
    }
    let margin = '';

    console.log('margin', autoMargin);
    
    let options = [
      { name: option1, values: tag0 },
      { name: option2, values: tag1 },
      { name: option3, values: tag2 },
    ];

    let tempVarientArray = [];
    console.log('length is', length);
    if(length>0) {
      for (var i = 0; i < length; i++) {
        if(document.getElementById(`varientName${i}`)  && document.getElementById(`varientPrice${i}`) && document.getElementById(`varientQuantity${i}`)) {
          let obj = {
            varient: document.getElementById(`varientName${i}`).value,
            price: document.getElementById(`varientPrice${i}`).value, 
            mrp: document.getElementById(`varientMrp${i}`).value,
            weight: document.getElementById(`product_waight`).value,
            quantity: document.getElementById(`varientQuantity${i}`).value,
            sku: document.getElementById(`varientSku${i}`).value,
            selliingPrice:
              parseFloat(document.getElementById(`varientPrice${i}`).value) +
              parseFloat(
                document.getElementById(`varientPrice${i}`).value) * parseFloat(autoMargin) /
                  100
              ,
          };
          tempVarientArray.push(obj);
        }
    }
    
      // console.log({
      //   varient: document.getElementById(`varientName${i}`).value,
      //   price: document.getElementById(`varientPrice${i}`).value,
      //   quantity: document.getElementById(`varientQuantity${i}`).value,
      //   sku: document.getElementById(`varientSku${i}`).value
      // });
      //     tempVarientArray.push(obj)
      //
      // }
      //
      //       };
      // console.log({
      //   varient: document.getElementById(`varientName${i}`).value,
      //   price: document.getElementById(`varientPrice${i}`).value,
      //   quantity: document.getElementById(`varientQuantity${i}`).value,
      //   sku: document.getElementById(`varientSku${i}`).value
      // });
      
    }
    console.log("tempVarientArray", tempVarientArray);
    if (name===''|| description===''|| category===''||shippingDetails==='') {
      NotificationManager.error('Please fill all the details')
    }
    else{
        const data = await new FormData();
        console.log(productImage, 'add images');
        data.append('productImage0', productImage0[0]);
        data.append('productImage0', productImage0[1]);
        data.append('productImage0', productImage0[2]);
        data.append('productImage0', productImage0[3]);
        data.append('productImage0', productImage0[4]);
        data.append('productImage0', productImage0[5]);
        data.append('productImage0', productImage0[6]);

        data.append('productImage1', productImage1[0]);
        data.append('productImage1', productImage1[1]);
        data.append('productImage1', productImage1[2]);
        data.append('productImage1', productImage1[3]);
        data.append('productImage1', productImage1[4]);
        data.append('productImage1', productImage1[5]);
        data.append('productImage1', productImage1[6]);

        data.append('productImage2', productImage2[0]);
        data.append('productImage2', productImage2[1]);
        data.append('productImage2', productImage2[2]);
        data.append('productImage2', productImage2[3]);
        data.append('productImage2', productImage2[4]);
        data.append('productImage2', productImage2[5]);
        data.append('productImage2', productImage2[6]);

        data.append('productImage3', productImage3[0]);
        data.append('productImage3', productImage3[1]);
        data.append('productImage3', productImage3[2]);
        data.append('productImage3', productImage3[3]);
        data.append('productImage3', productImage3[4]);
        data.append('productImage3', productImage3[5]);
        data.append('productImage3', productImage3[6]);

        data.append('productImage4', productImage4[0]);
        data.append('productImage4', productImage4[1]);
        data.append('productImage4', productImage4[2]);
        data.append('productImage4', productImage4[3]);
        data.append('productImage4', productImage4[4]);
        data.append('productImage4', productImage4[5]);
        data.append('productImage4', productImage4[6]);

        data.append('productImage5', productImage5[0]);
        data.append('productImage5', productImage5[1]);
        data.append('productImage5', productImage5[2]);
        data.append('productImage5', productImage5[3]);
        data.append('productImage5', productImage5[4]);
        data.append('productImage5', productImage5[5]);
        data.append('productImage5', productImage5[6]);

        data.append('productImage6', productImage6[0]);
        data.append('productImage6', productImage6[1]);
        data.append('productImage6', productImage6[2]);
        data.append('productImage6', productImage6[3]);
        data.append('productImage6', productImage6[4]);
        data.append('productImage6', productImage6[5]);
        data.append('productImage6', productImage6[6]);

        data.append('productImage7', productImage7[0]);
        data.append('productImage7', productImage7[1]);
        data.append('productImage7', productImage7[2]);
        data.append('productImage7', productImage7[3]);
        data.append('productImage7', productImage7[4]);
        data.append('productImage7', productImage7[5]);
        data.append('productImage7', productImage7[6]);

        data.append('productImage8', productImage8[0]);
        data.append('productImage8', productImage8[1]);
        data.append('productImage8', productImage8[2]);
        data.append('productImage8', productImage8[3]);
        data.append('productImage8', productImage8[4]);
        data.append('productImage8', productImage8[5]);
        data.append('productImage8', productImage8[6]);

        data.append('productImage9', productImage9[0]);
        data.append('productImage9', productImage9[1]);
        data.append('productImage9', productImage9[2]);
        data.append('productImage9', productImage9[3]);
        data.append('productImage9', productImage9[4]);
        data.append('productImage9', productImage9[5]);
        data.append('productImage9', productImage9[6]);

        data.append('productImage10', productImage10[0]);
        data.append('productImage10', productImage10[1]);
        data.append('productImage10', productImage10[2]);
        data.append('productImage10', productImage10[3]);
        data.append('productImage10', productImage10[4]);
        data.append('productImage10', productImage10[5]);
        data.append('productImage10', productImage10[6]);

        data.append('productImage11', productImage11[0]);
        data.append('productImage11', productImage11[1]);
        data.append('productImage11', productImage11[2]);
        data.append('productImage11', productImage11[3]);
        data.append('productImage11', productImage11[4]);
        data.append('productImage11', productImage11[5]);
        data.append('productImage11', productImage11[6]);

        data.append('productImage12', productImage12[0]);
        data.append('productImage12', productImage12[1]);
        data.append('productImage12', productImage12[2]);
        data.append('productImage12', productImage12[3]);
        data.append('productImage12', productImage12[4]);
        data.append('productImage12', productImage12[5]);
        data.append('productImage12', productImage12[6]);

        data.append('productImage13', productImage13[0]);
        data.append('productImage13', productImage13[1]);
        data.append('productImage13', productImage13[2]);
        data.append('productImage13', productImage13[3]);
        data.append('productImage13', productImage13[4]);
        data.append('productImage13', productImage13[5]);
        data.append('productImage13', productImage13[6]);

        data.append('productImage14', productImage14[0]);
        data.append('productImage14', productImage14[1]);
        data.append('productImage14', productImage14[2]);
        data.append('productImage14', productImage14[3]);
        data.append('productImage14', productImage14[4]);
        data.append('productImage14', productImage14[5]);
        data.append('productImage14', productImage14[6]);

        data.append('productImage15', productImage15[0]);
        data.append('productImage15', productImage15[1]);
        data.append('productImage15', productImage15[2]);
        data.append('productImage15', productImage15[3]);
        data.append('productImage15', productImage15[4]);
        data.append('productImage15', productImage15[5]);
        data.append('productImage15', productImage15[6]);

        data.append('productImage16', productImage16[0]);
        data.append('productImage16', productImage16[1]);
        data.append('productImage16', productImage16[2]);
        data.append('productImage16', productImage16[3]);
        data.append('productImage16', productImage16[4]);
        data.append('productImage16', productImage16[5]);
        data.append('productImage16', productImage16[6]);

        data.append('productImage17', productImage17[0]);
        data.append('productImage17', productImage17[1]);
        data.append('productImage17', productImage17[2]);
        data.append('productImage17', productImage17[3]);
        data.append('productImage17', productImage17[4]);
        data.append('productImage17', productImage17[5]);
        data.append('productImage17', productImage17[6]);

        data.append('productImage18', productImage18[0]);
        data.append('productImage18', productImage18[1]);
        data.append('productImage18', productImage18[2]);
        data.append('productImage18', productImage18[3]);
        data.append('productImage18', productImage18[4]);
        data.append('productImage18', productImage18[5]);
        data.append('productImage18', productImage18[6]);

        data.append('productImage19', productImage19[0]);
        data.append('productImage19', productImage19[1]);
        data.append('productImage19', productImage19[2]);
        data.append('productImage19', productImage19[3]);
        data.append('productImage19', productImage19[4]);
        data.append('productImage19', productImage19[5]);
        data.append('productImage19', productImage19[6]);

        data.append('productImage20', productImage20[0]);
        data.append('productImage20', productImage20[1]);
        data.append('productImage20', productImage20[2]);
        data.append('productImage20', productImage20[3]);
        data.append('productImage20', productImage20[4]);
        data.append('productImage20', productImage20[5]);
        data.append('productImage20', productImage20[6]);

        console.log(tags);
        data.append('supplier_id', decode.id);
        data.append('name', name);
        data.append('subtitle', subtitle);
        data.append('setinvoicedesc', setinvoicedesc);
        data.append('mrp_price',  varien ? 0 : mrp_price)
        data.append('price', varien ? 0 : price);
        data.append('quantity', varien ? 0 : quantity);
        // data.append('mrp', varien ? 0 : mrp);
        data.append('warranty', warranty);
        data.append('mainTag', tags);
        data.append('weight', weight);
        data.append('description', description);
        data.append('category', category);
        data.append('code', varien ? 0 : code);
        data.append('hsn', hsn);
        data.append('size', size);
        data.append('varients', JSON.stringify(varients));
        data.append('options', JSON.stringify(options));
        data.append('method', shippingDetails);
        data.append('usa', usa);
        data.append('canada', canada);
        data.append('uk', uk);
        data.append('australia', australia);
        data.append('international', international);
        data.append('varientArray', JSON.stringify(tempVarientArray));
        // console.log(data); return false;
      axios.post('/api/addProduct', data)
      .then((res) => {
        console.log(res.data);
        if (res.data.includes('Success')) {
          NotificationManager.success('Product Added Successfully');
          setName('');
          settitlename('');
          setinvoiceDescname('');
          setPrice('');
          setQuantity('');
          setWarranty('');
          setDescription('');
          setCategory('');
          setCode('');
          setHsn('');
          setProductImage([]);
          setVarients([]);
          setCanada('');
          setAustralia('');
          setInternational('');
          setUsa('');
          setUk('');
        }else if(res.data.includes('Duplicate')){
          NotificationManager.success('Duplicate Product.');
          setName('');
          settitlename('');
          setinvoiceDescname('');
          setPrice('');
          setQuantity('');
          setWarranty('');
          setDescription('');
          setCategory('');
          setCode('');
          setHsn('');
          setProductImage([]);
          setVarients([]);
          setCanada('');
          setAustralia('');
          setInternational('');
          setUsa('');
          setUk('');
        } else {
          res.data.error
            ? NotificationManager.error(res.data.error.toString())
            : NotificationManager.error('There is a problem with your entries');
        }
      })
      .catch((err) => {
        err
          ? NotificationManager.error(err.toString())
          : NotificationManager.error('There is a problem with your entries');
      });
  }
  };

  //Add Product from CSV File
  const addCSvProduct = (e) => {
    e.preventDefault();
    const scvdata = new FormData();
    scvdata.append('file', csvData[0]);
    scvdata.append('supplier_id', decode.id);
    axios
      .post('/api/product/csv', scvdata)
      .then((res) => {
        if (res.data.includes('success')) {
          NotificationManager.success('File uploaded Successfully');
        } else {
          res.data.error
            ? NotificationManager.error(res.data.error.toString())
            : NotificationManager.error('There is a problem with this csv');
        }
      })
      .catch((error) => {
        error
          ? NotificationManager.error(error.toString())
          : NotificationManager.error('There is a problem with this csv');
      });
  };

  //making commination from tags ,tag1,tag2 array
  function makeCombo() {
    var r = [],
      arg = arguments,
      max = arg.length - 1;
    function helper(arr, i) {
      for (var j = 0, l = arg[i].length; j < l; j++) {
        var a = arr.slice(0); // clone arr
        a.push(arg[i][j]);
        if (i === max) r.push(a);
        else helper(a, i + 1);
      }
    }
    helper([], 0);

    let res = [];
    for (let i = 0; i < r.length; i++) {
      const e = r[i];
      let str = '';
      for (let j = 0; j < e.length; j++) {
        const element = e[j];
        if (str === '') {
          str = element;
        } else str = str + ' / ' + element;
      }
      r[i] = str;
      res = r;
    }
    setLength(res.length);
    return res;
  }

  //Fetch category List
  const getCategoryList = () => {
    axios.get('/api/totalCategory').then((data) => {
      setCategoryList(data.data);
    });
  };
  //tags
  useEffect(() => {
    handelDelete();
  }, [tag0]);
  useEffect(() => {
    handelDelete();
  }, [tag1]);
  useEffect(() => {
    handelDelete();
  }, [tag2]);


 
 
  const removeTags = (indexToRemove) => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
  };
  const addTags = (event) => {
    let value = event.target.value.replace(/,/g, '');
    if (value === '') {
      return NotificationManager.error('Blank tags not allowed');
    }
    else {
      if (tags.length!==0) {
        let found = tags.some(el => el === value);
        if (!found) {
          setTags([...tags, value]);
          selectedTags([...tags, value]);
          event.target.value = '';
        }
        else {
          return NotificationManager.error('Duplicate Tag');
        }
      }
      else {
        setTags([...tags, value]);
        selectedTags([...tags, value]);
        event.target.value = '';  
      }
    }
  };

  const removeTag0 = (indexToRemove) => {
    setTag0([...tag0.filter((_, index) => index !== indexToRemove)]);
  };
  const addTag0 = (event) => {
    let value = event.target.value.replace(/,/g, '');
    if (value === '') {
      return NotificationManager.error('Blank varient name not allowed');
    }
    else {
      if (tag0.length!==0) {
        let found = tag0.some(el => el === value);
        if (!found) {
          setTag0([...tag0, value]);
          selectedTags([...tag0, value]);
          event.target.value = '';
        }
        else {
          return NotificationManager.error('Duplicate Name');
        }
      }
      else {
        setTag0([...tag0, value]);
        selectedTags([...tag0, value]);
        event.target.value = '';  
      }
    }
  };



  const removeTag1 = (indexToRemove) => {
    setTag1([...tag1.filter((_, index) => index !== indexToRemove)]);
  };



  const addTag1 = (event) => {
    let value = event.target.value.replace(/,/g, '');

    if (value === '') {
      return NotificationManager.error('Blank varient name not allowed');
    }

    else{

      if (tag1.length!==0) {

        let found1 = tag1.some(el => el === value);
        if (!found1) {
          setTag1([...tag1, value]);
          selectedTag1([...tag1, value]);
          event.target.value = '';

        }
        else {
        return NotificationManager.error('Duplicate Name');
        }


}
  else {

    setTag1([...tag1, value]);
    selectedTag1([...tag1, value]);
    event.target.value = '';

  }
    }
  };



  const removeTag2 = (indexToRemove) => {
    setTag2([...tag2.filter((_, index) => index !== indexToRemove)]);
  };



  const addTag2 = (event) => {
    let value = event.target.value.replace(/,/g, '');

    if (value === '') {
      return NotificationManager.error('Blank varient name not allowed');
    }

    else{

      if (tag2.length!==0) {

        let found2 = tag2.some(el => el === value);
        if (!found2) {
          setTag2([...tag2, value]);
          selectedTag2([...tag2, value]);
          event.target.value = '';

        }
        else {
        return NotificationManager.error('Duplicate Name');
        }


}
  else {

    setTag2([...tag2, value]);
    selectedTag2([...tag2, value]);
    event.target.value = '';

  }
    }
  };

  const handelDelete = () => {
    //! normal user flow
    if (tag0.length != 0 && tag1.length === 0 && tag2.length === 0) {
      let r = makeCombo(tag0);
      setCombo(r);
    }
    if (tag0.length != 0 && tag1.length != 0 && tag2.length === 0) {
      let r = makeCombo(tag0, tag1);
      setCombo(r);
    }
    if (tag0.length != 0 && tag1.length != 0 && tag2.length != 0) {
      let r = makeCombo(tag0, tag1, tag2);
      setCombo(r);
    }
    //! one empty array two not empty
    if (tag0.length === 0 && tag1.length != 0 && tag2.length != 0) {
      let r = makeCombo(tag1, tag2);
      setCombo(r);
    }
    if (tag0.length != 0 && tag1.length === 0 && tag2.length != 0) {
      let r = makeCombo(tag0, tag2);
      setCombo(r);
    }
    if (tag0.length != 0 && tag1.length != 0 && tag2.length === 0) {
      let r = makeCombo(tag0, tag1);
      setCombo(r);
    }
    //! two empty array one not empty
    if (tag0.length === 0 && tag1.length != 0 && tag2.length === 0) {
      let r = makeCombo(tag1);
      setCombo(r);
    }
    if (tag0.length === 0 && tag1.length === 0 && tag2.length != 0) {
      let r = makeCombo(tag2);
      setCombo(r);
    }
    if (tag0.length != 0 && tag1.length === 0 && tag2.length === 0) {
      let r = makeCombo(tag0);
      setCombo(r);
    }
    //! all empty array
    if (tag0.length === 0 && tag1.length === 0 && tag2.length === 0) {
      let r = makeCombo([]);
      setCombo(r);
    }
  };

  const selectedTags = (tag) => {
    if (!moreOption || !moreOption1) {
      if (tag.length != 0 && tag1.length === 0 && tag2.length === 0) {
        let r = makeCombo(tag);
        setCombo(r);
      }
      if (tag0.length != 0 && tag1.length != 0 && tag2.length === 0) {
        let r = makeCombo(tag0, tag1);
        setCombo(r);
      }
      if (tag0.length != 0 && tag1.length != 0 && tag2.length != 0) {
        let r = makeCombo(tag0, tag1, tag2);
        setCombo(r);
      }
    }
  };
  const selectedTag1 = (tag1) => {
    if (!moreOption1) {
      if (tag0.length != 0 && tag1.length === 0 && tag2.length === 0) {
        let r = makeCombo(tag0);
        setCombo(r);
      }
      if (tag0.length != 0 && tag1.length != 0 && tag2.length === 0) {
        let r = makeCombo(tag0, tag1);
        setCombo(r);
      }
      if (tag0.length != 0 && tag1.length != 0 && tag2.length != 0) {
        let r = makeCombo(tag0, tag1, tag2);
        setCombo(r);
      }
    }
  };
  const selectedTag2 = (tag2) => {
    if (tag0.length != 0 && tag1.length === 0 && tag2.length === 0) {
      let r = makeCombo(tag0);
      setCombo(r);
    }
    if (tag0.length != 0 && tag1.length != 0 && tag2.length === 0) {
      let r = makeCombo(tag0, tag1);
      setCombo(r);
    }
    if (tag0.length != 0 && tag1.length != 0 && tag2.length != 0) {
      let r = makeCombo(tag0, tag1, tag2);
      setCombo(r);
    }
  };

  const showImage = (e) => {
    e.preventDefault();
    console.log('pLength', e.target.files);
    let images = [];
    let images1 = [];
    for (var i = 0; i < e.target.files.length; i++) {
      images1.push(e.target.files[i]);
      images.push({
        url: URL.createObjectURL(e.target.files[i]),
        name: e.target.files[i].name,
      });
    }
    setProductImage(images1);
    setMulterImage(images);
    console.log(productImage, 'pImage array');
  };

  const showImage1 = (e) => {
    e.preventDefault();
    console.log('pLength', e.target.files);
    let images = [];
    let images1 = [];
    for (var i = 0; i < e.target.files.length; i++) {
      images1.push(e.target.files[i]);
      images.push({
        url: URL.createObjectURL(e.target.files[i]),
        name: e.target.files[i].name,
      });
    }
    setProductImage1(images1);
    setMulterImage1(images);
    console.log(productImage1, 'pImage array');
  };
  const showImage2 = (e) => {
    e.preventDefault();
    console.log('pLength', e.target.files);
    let images = [];
    let images1 = [];
    for (var i = 0; i < e.target.files.length; i++) {
      images1.push(e.target.files[i]);
      images.push({
        url: URL.createObjectURL(e.target.files[i]),
        name: e.target.files[i].name,
      });
    }
    setProductImage2(images1);
    setMulterImage2(images);
    console.log(images, 'pImage array');
  };

const showImage3 = (e) => {
    e.preventDefault();
    console.log('pLength', e.target.files);
    let images = [];
    let images1 = [];
    for (var i = 0; i < e.target.files.length; i++) {
        images1.push(e.target.files[i]);
        images.push({
            url: URL.createObjectURL(e.target.files[i]),
            name: e.target.files[i].name,
        });
    }
    setProductImage3(images1);
    setMulterImage3(images); 
};
const showImage4 = (e) => {
    e.preventDefault();
    console.log('pLength', e.target.files);
    let images = [];
    let images1 = [];
    for (var i = 0; i < e.target.files.length; i++) {
        images1.push(e.target.files[i]);
        images.push({
            url: URL.createObjectURL(e.target.files[i]),
            name: e.target.files[i].name,
        });
    }
    setProductImage4(images1);
    setMulterImage4(images); 
};
const showImage5 = (e) => {
    e.preventDefault();
    console.log('pLength', e.target.files);
    let images = [];
    let images1 = [];
    for (var i = 0; i < e.target.files.length; i++) {
        images1.push(e.target.files[i]);
        images.push({
            url: URL.createObjectURL(e.target.files[i]),
            name: e.target.files[i].name,
        });
    }
    setProductImage5(images1);
    setMulterImage5(images); 
};
const showImage6 = (e) => {
    e.preventDefault();
    console.log('pLength', e.target.files);
    let images = [];
    let images1 = [];
    for (var i = 0; i < e.target.files.length; i++) {
        images1.push(e.target.files[i]);
        images.push({
            url: URL.createObjectURL(e.target.files[i]),
            name: e.target.files[i].name,
        });
    }
    setProductImage6(images1);
    setMulterImage6(images); 
};
const showImage7 = (e) => {
    e.preventDefault();
    console.log('pLength', e.target.files);
    let images = [];
    let images1 = [];
    for (var i = 0; i < e.target.files.length; i++) {
        images1.push(e.target.files[i]);
        images.push({
            url: URL.createObjectURL(e.target.files[i]),
            name: e.target.files[i].name,
        });
    }
    setProductImage7(images1);
    setMulterImage7(images); 
};
const showImage8 = (e) => {
    e.preventDefault();
    console.log('pLength', e.target.files);
    let images = [];
    let images1 = [];
    for (var i = 0; i < e.target.files.length; i++) {
        images1.push(e.target.files[i]);
        images.push({
            url: URL.createObjectURL(e.target.files[i]),
            name: e.target.files[i].name,
        });
    }
    setProductImage8(images1);
    setMulterImage8(images); 
};
const showImage9 = (e) => {
    e.preventDefault();
    console.log('pLength', e.target.files);
    let images = [];
    let images1 = [];
    for (var i = 0; i < e.target.files.length; i++) {
        images1.push(e.target.files[i]);
        images.push({
            url: URL.createObjectURL(e.target.files[i]),
            name: e.target.files[i].name,
        });
    }
    setProductImage9(images1);
    setMulterImage9(images); 
};
const showImage10 = (e) => {
    e.preventDefault();
    console.log('pLength', e.target.files);
    let images = [];
    let images1 = [];
    for (var i = 0; i < e.target.files.length; i++) {
        images1.push(e.target.files[i]);
        images.push({
            url: URL.createObjectURL(e.target.files[i]),
            name: e.target.files[i].name,
        });
    }
    setProductImage10(images1);
    setMulterImage10(images); 
};
const showImage11 = (e) => {
    e.preventDefault();
    console.log('pLength', e.target.files);
    let images = [];
    let images1 = [];
    for (var i = 0; i < e.target.files.length; i++) {
        images1.push(e.target.files[i]);
        images.push({
            url: URL.createObjectURL(e.target.files[i]),
            name: e.target.files[i].name,
        });
    }
    setProductImage11(images1);
    setMulterImage11(images); 
};
const showImage12 = (e) => {
    e.preventDefault();
    console.log('pLength', e.target.files);
    let images = [];
    let images1 = [];
    for (var i = 0; i < e.target.files.length; i++) {
        images1.push(e.target.files[i]);
        images.push({
            url: URL.createObjectURL(e.target.files[i]),
            name: e.target.files[i].name,
        });
    }
    setProductImage12(images1);
    setMulterImage12(images); 
};
const showImage13 = (e) => {
    e.preventDefault();
    console.log('pLength', e.target.files);
    let images = [];
    let images1 = [];
    for (var i = 0; i < e.target.files.length; i++) {
        images1.push(e.target.files[i]);
        images.push({
            url: URL.createObjectURL(e.target.files[i]),
            name: e.target.files[i].name,
        });
    }
    setProductImage13(images1);
    setMulterImage13(images); 
};
const showImage14 = (e) => {
    e.preventDefault();
    console.log('pLength', e.target.files);
    let images = [];
    let images1 = [];
    for (var i = 0; i < e.target.files.length; i++) {
        images1.push(e.target.files[i]);
        images.push({
            url: URL.createObjectURL(e.target.files[i]),
            name: e.target.files[i].name,
        });
    }
    setProductImage14(images1);
    setMulterImage14(images); 
};
const showImage15 = (e) => {
    e.preventDefault();
    console.log('pLength', e.target.files);
    let images = [];
    let images1 = [];
    for (var i = 0; i < e.target.files.length; i++) {
        images1.push(e.target.files[i]);
        images.push({
            url: URL.createObjectURL(e.target.files[i]),
            name: e.target.files[i].name,
        });
    }
    setProductImage15(images1);
    setMulterImage15(images); 
};
const showImage16 = (e) => {
    e.preventDefault();
    console.log('pLength', e.target.files);
    let images = [];
    let images1 = [];
    for (var i = 0; i < e.target.files.length; i++) {
        images1.push(e.target.files[i]);
        images.push({
            url: URL.createObjectURL(e.target.files[i]),
            name: e.target.files[i].name,
        });
    }
    setProductImage16(images1);
    setMulterImage16(images); 
};
const showImage17 = (e) => {
    e.preventDefault();
    console.log('pLength', e.target.files);
    let images = [];
    let images1 = [];
    for (var i = 0; i < e.target.files.length; i++) {
        images1.push(e.target.files[i]);
        images.push({
            url: URL.createObjectURL(e.target.files[i]),
            name: e.target.files[i].name,
        });
    }
    setProductImage17(images1);
    setMulterImage17(images); 
};
const showImage18 = (e) => {
    e.preventDefault();
    console.log('pLength', e.target.files);
    let images = [];
    let images1 = [];
    for (var i = 0; i < e.target.files.length; i++) {
        images1.push(e.target.files[i]);
        images.push({
            url: URL.createObjectURL(e.target.files[i]),
            name: e.target.files[i].name,
        });
    }
    setProductImage18(images1);
    setMulterImage18(images); 
};
const showImage19 = (e) => {
    e.preventDefault();
    console.log('pLength', e.target.files);
    let images = [];
    let images1 = [];
    for (var i = 0; i < e.target.files.length; i++) {
        images1.push(e.target.files[i]);
        images.push({
            url: URL.createObjectURL(e.target.files[i]),
            name: e.target.files[i].name,
        });
    }
    setProductImage19(images1);
    setMulterImage19(images); 
};
const showImage20 = (e) => {
    e.preventDefault();
    console.log('pLength', e.target.files);
    let images = [];
    let images1 = [];
    for (var i = 0; i < e.target.files.length; i++) {
        images1.push(e.target.files[i]);
        images.push({
            url: URL.createObjectURL(e.target.files[i]),
            name: e.target.files[i].name,
        });
    }
    setProductImage20(images1);
    setMulterImage20(images); 
};

  const handleDeleteImage = (data, indexToRemove) => {
    setMulterImage([
      ...multerImage.filter((_, index) => index !== indexToRemove),
    ]);
    setProductImage([
      ...productImage.filter((_, index) => index !== indexToRemove),
    ]);
    setProductImage1([
        ...productImage1.filter((_, index) => index !== indexToRemove),
      ]);
    setProductImage2([
    ...productImage2.filter((_, index) => index !== indexToRemove),
    ]);
    setProductImage3([
    ...productImage3.filter((_, index) => index !== indexToRemove),
    ]);
    setProductImage4([
    ...productImage4.filter((_, index) => index !== indexToRemove),
    ]);
    setProductImage5([
    ...productImage5.filter((_, index) => index !== indexToRemove),
    ]);
    setProductImage6([
    ...productImage6.filter((_, index) => index !== indexToRemove),
    ]);
    setProductImage7([
    ...productImage7.filter((_, index) => index !== indexToRemove),
    ]);
    setProductImage8([
    ...productImage8.filter((_, index) => index !== indexToRemove),
    ]);
    setProductImage9([
    ...productImage9.filter((_, index) => index !== indexToRemove),
    ]);
    setProductImage10([
    ...productImage10.filter((_, index) => index !== indexToRemove),
    ]);
    setProductImage11([
    ...productImage11.filter((_, index) => index !== indexToRemove),
    ]);
    setProductImage12([
    ...productImage12.filter((_, index) => index !== indexToRemove),
    ]);
    setProductImage13([
    ...productImage13.filter((_, index) => index !== indexToRemove),
    ]);
    setProductImage14([
    ...productImage14.filter((_, index) => index !== indexToRemove),
    ]);
    setProductImage15([
    ...productImage15.filter((_, index) => index !== indexToRemove),
    ]);
    setProductImage16([
    ...productImage16.filter((_, index) => index !== indexToRemove),
    ]);
    setProductImage17([
    ...productImage17.filter((_, index) => index !== indexToRemove),
    ]);
    setProductImage18([
    ...productImage18.filter((_, index) => index !== indexToRemove),
    ]);
    setProductImage19([
    ...productImage19.filter((_, index) => index !== indexToRemove),
    ]);
    setProductImage20([
    ...productImage20.filter((_, index) => index !== indexToRemove),
    ]);
    // let tempArray = []
    // multerImage.forEach((image, i) => {
    //   productImage.forEach((item, j) => {
    //     console.log({item});
    //     if (multerImage[i].name === productImage[j].name) {
    //         tempArray.push(productImage[j])
    //     }
    //   });
    //
    //   });
    //   setProductImage(tempArray)
  };

  return (
    <div className='container-fluid'>
      <br />
      <form onSubmit={addProduct}>
        <div className='card card-input'>
          <div className='form-group'>
            <label for='product_name'>Title</label>
            <input
              type='text'
              value={name}
              onChange={(e) => setName(e.target.value)}
              className='form-control'
              id='product_name'
              placeholder='Enter Title of Product'
            />
          </div>
          <div className='form-group'>
            <label for='product_subtitle'>Sub Title</label>
            <input
              type='text'
              value={subtitle}
              onChange={(e) => settitlename(e.target.value)}
              className='form-control'
              id='product_subtitle'
              placeholder='Enter Sub Title of Product'
            />
          </div>
          <div className='form-group'>
            <label for='invoice_description'>Invoice Description</label>
            <input
              type='text'
              value={setinvoicedesc}
              onChange={(e) => setinvoiceDescname(e.target.value)}
              className='form-control'
              id='invoice_subtitle'
              placeholder='Enter Invoice Description'
            />
          </div>
          <div className='form-group'>
            <label for='product_description'>Detail Description</label>
            <ReactQuill
              theme={'snow'}
              onChange={(value) => setDescription(value)}
              style={{ height: '18em', marginBottom: 20 }}
              value={description}
              modules={Editor.modules}
              formats={Editor.formats}
              placeholder={'Write something'}
            />
            <br />
            <br />
          </div>

           

          <div className='tags-input'>
              <ul id='tags-main'>
                {tags.map((tag, index) => (
                  <li key={index} className='tag'>
                    <span className='tag-title'>{tag}</span>
                    <span
                      className='tag-close-icon'
                      onClick={() => removeTags(index)}
                    >
                      x
                    </span>
                  </li>
                ))}
              </ul>
              <input
                type='text'
                id='myAnchor'
                onKeyUp={(event) =>
                  event.key === ',' ? addTags(event) : null
                }
                placeholder="Press ',' to add tags"
              />
            </div>

          <div className='form-group'>
            <label for='productImage0'>Image upload</label>
            <input
              type='file'
              name='productImage0'
              className='form-control'
              onChange={(e) => {
                setProductImage0(e.target.files);
                showImage(e);
              }}
              multiple
              accept='image/*'
            />
          </div>
          {multerImage.length !== 0 ? (
            <div className='image-preview'>
              {multerImage.map((image, i) => {
                return (
                  <div className='col-md-4'>
                    <button
                      style={{ display: 'flex' }}
                      type='button'
                      className='close'
                      aria-label='Close'
                      onClick={() => handleDeleteImage(image, i)}
                    >
                      <span aria-hidden='true'>&times;</span>
                    </button>
                    <img
                      src={image.url}
                      alt='upload-image'
                      className='process_Image'
                    />
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
        <div className='card card-input py-0 ll'>
          <h4 className='text-left' style={{ margin: 0, marginBottom: 15 }}>
            Variants
          </h4>
          <label class='containerr'>
            This product has multiple options, like different sizes or colors
            <input
              type='checkbox'
              value={varien}
              onChange={() => {
                if (varien) {
                  setVarien(false);
                  setTag0([]);
                  setTag1([]);
                  setTag2([]);
                } else {
                  setVarien(true);
                  setTag0([]);
                  setTag1([]);
                  setTag2([]);
                }
              }}
            />
            <span class='checkmarkk'></span>
          </label>
          {varien ? (
            <>
              <div
                style={{
                  maxWidth: 480,
                  marginTop: 30,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                  
              </div>
              <div>
                <div>
                  <h5>Option 1</h5>
                  <input
                    type='text'
                    name='city'
                    list='variantOptions'
                    onChange={(e) => setOption1(e.target.value)}
                    style={{
                      width: 150,
                      height: 40,
                      border: '1px solid grey',
                      borderRadius: 5,
                      marginBottom: 13,
                    }}
                  />
                  <datalist id='variantOptions'>
                    <option value='color'>Color</option>
                    <option value='size'>Size</option>
                    <option value='style'>Style</option>
                    <option value='material'>Material</option>
                    <option value='title'>Title</option>
                  </datalist>
                  {/* </div> */}
                </div>
                <div className='tags-input'>
                  <ul id='tags'>
                    {tag0.map((tag, index) => (
                      <li key={index} className='tag'>
                        <span className='tag-title'>{tag}</span>
                        <span
                          className='tag-close-icon'
                          onClick={() => removeTag0(index)}
                        >
                          x
                        </span>
                      </li>
                    ))}
                  </ul>
                  <input
                    type='text'
                    id='myAnchor'
                    onKeyUp={(event) =>
                      event.key === ',' ? addTag0(event) : null
                    }
                    placeholder="Press ',' to add tags"
                  />
                </div>
                
                
                 
              </div>

              {moreOption ? (
                <>
                  <div
                    style={{
                      maxWidth: 480,
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <h5>Option 2</h5>
                    <h5
                      onClick={() => {
                        setMoreOption(false);
                        setTag1([]);
                      }}
                    >
                      cancel
                    </h5>
                  </div>
                  <div>
                    <div>
                      <input
                        type='text'
                        name='city'
                        list='variantOptions'
                        onChange={(e) => setOption2(e.target.value)}
                        style={{
                          width: 150,
                          height: 40,
                          border: '1px solid grey',
                          borderRadius: 5,
                          marginBottom: 13,
                        }}
                      />
                    </div>
                    <div className='tags-input'>
                      <ul id='tags'>
                        {tag1.map((tag, index) => (
                          <li key={index} className='tag'>
                            <span className='tag-title'>{tag}</span>
                            <span
                              className='tag-close-icon'
                              onClick={() => removeTag1(index)}
                            >
                              {' '}
                              x
                            </span>
                          </li>
                        ))}
                      </ul>
                      <input
                        type='text'
                        id='myAnchor1'
                        onKeyUp={(event) =>
                          event.key === ',' ? addTag1(event) : null
                        }
                        placeholder="Press ',' to add tags"
                      />
                    </div>

                     
                     
                  </div>
                </>
              ) : null}
              {moreOption1 ? (
                <>
                  <div
                    style={{
                      maxWidth: 480,
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <h5>Option 3</h5>
                    <h5
                      onClick={() => {
                        setMoreOption1(false);
                        setTag2([]);
                      }}
                    >
                      cancel
                    </h5>
                  </div>
                  <div>
                    <div>
                      <input
                        type='text'
                        name='city'
                        list='variantOptions'
                        onChange={(e) => setOption3(e.target.value)}
                        style={{
                          width: 150,
                          height: 40,
                          border: '1px solid grey',
                          borderRadius: 5,
                          marginBottom: 13,
                        }}
                      />
                    </div>
                    <div className='tags-input'>
                      <ul id='tags'>
                        {tag2.map((tag, index) => (
                          <li key={index} className='tag'>
                            <span className='tag-title'>{tag}</span>
                            <span
                              className='tag-close-icon'
                              onClick={() => removeTag2(index)}
                            >
                              x
                            </span>
                          </li>
                        ))}
                      </ul>
                      <input
                        type='text'
                        id='myAnchor2'
                        onKeyUp={(event) =>
                          event.key === ',' ? addTag2(event) : null
                        }
                        placeholder="Press ',' to add tags"
                      />
                    </div>

                     
                    
                  </div>
                </>
              ) : null}
              {!moreOption1 ? (
                <div
                  onClick={() => {
                    if (moreOption) {
                      setMoreOption1(true);
                    } else {
                      setMoreOption(true);
                    }
                  }}
                  className='meraButton'
                >
                  More Option
                </div>
              ) : null}
              {!combo ? null : (
                <div>
                  <h4>Preview</h4>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <h5
                      style={{
                        flex: 1,
                      }}
                    >
                      Variant
                    </h5>
                    <h5
                      style={{
                        flex: 1,
                        marginLeft: -28,
                      }}
                    >
                      Price
                    </h5>
                    <h5
                      style={{
                        flex: 1,
                        marginLeft: -30,
                      }}
                    >
                      MRP
                    </h5>
                    <h5
                      style={{
                        flex: 1,
                        marginLeft: -30,
                      }}
                    >
                      Quantity
                    </h5>
                    <h5
                      style={{
                        flex: 1,
                        marginRight: -50,
                      }}
                    >
                      SKU
                    </h5>
                  </div>
                  {combo.map((item, index) => (
                    <div key={index}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          flexDirection: 'row',
                        }}
                      >
                        <input
                          id={`varientName${index}`}
                          type='text'
                          style={{
                            flex: 1,
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                          }}
                          value={item}
                          disabled
                          className='form-control'
                        />
                        <div style={{ display: 'flex', flex: 1 }}>
                          <h5
                            style={{
                              border: '1px solid #E3E3E3',
                              width: 20,
                              height: 40,
                              marginTop: 0,
                              borderRightWidth: 0,
                              borderRadius: '4px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                            }}
                          >
                            <i class="input-icon fa fa-inr"></i>
                          </h5>
                          <input
                            id={`varientPrice${index}`}
                            type='number'
                            min='0'
                            step=".01"
                            maxLength="10"
                            style={{
                              flex: 1,
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                            }}
                            value={item.price}
                            className='form-control'
                          />
                        </div>
                        <input
                          type='number'
                          min='0'
                          step="any"
                          maxLength="10"
                          value={item.mrp}
                          className='form-control'
                          id={`varientMrp${index}`}
                          style={{ flex: 1 }}
                        />
                        <input
                          type='number'
                          min='0'
                          step="any"
                          maxLength="10"
                          value={item.quantity}
                          className='form-control'
                          id={`varientQuantity${index}`}
                          style={{ flex: 1 }}
                        />
                        <input
                          type='text'
                          value={item.sku}
                          className='form-control'
                          id={`varientSku${index}`}
                          style={{ flex: 1 }}
                        />
                      </div>

                    {index == 0 ? (
                        <div>    
                            <div className='form-group'>
                                <label for='productImage'>Variation {index+1} Image Upload</label>
                                <input
                                type='file'
                                name={`productImage${index+1}`}
                                className='form-control'
                                id={`productImage${index}`}
                                onChange={(e) => {
                                    setProductImage1(e.target.files);
                                    showImage1(e);
                                }}
                                multiple
                                accept='image/*'
                                />
                            </div>
                            <div >
                                {multerImage1.length !== 0 ? (
                                    <div className='image-preview' style={{ display: 'flex' ,  margin: '20px 10px' }}>
                                        {multerImage1.map((image, i) => {
                                            return (
                                                <div  >
                                                    <button 
                                                        style={{ display: 'flex' }}
                                                        type='button'
                                                        className='close'
                                                        aria-label='Close'
                                                        onClick={() => handleDeleteImage(image, i)}
                                                    >
                                                        <span aria-hidden='true'>&times;</span>
                                                    </button>
                                                    <img
                                                        style={{ height: 200 }}
                                                        src={image.url}
                                                        alt='upload-image'
                                                        className='process_Image'
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        ) :null
                    } 
                    {index == 1 ? (
                        <div>    
                            <div className='form-group'>
                                <label for='productImage'>Variation {index+1} Image Upload</label>
                                <input
                                type='file'
                                name={`productImage${index+1}`}
                                className='form-control'
                                id={`productImage${index}`}
                                onChange={(e) => {
                                    setProductImage2(e.target.files);
                                    showImage2(e);
                                }}
                                multiple
                                accept='image/*'
                                />
                            </div>
                            <div >
                                {multerImage2.length !== 0 ? (
                                    <div className='image-preview' style={{ display: 'flex' ,  margin: '20px 10px' }}>
                                        {multerImage2.map((image, i) => {
                                            return (
                                                <div  >
                                                    <button 
                                                        style={{ display: 'flex' }}
                                                        type='button'
                                                        className='close'
                                                        aria-label='Close'
                                                        onClick={() => handleDeleteImage(image, i)}
                                                    >
                                                        <span aria-hidden='true'>&times;</span>
                                                    </button>
                                                    <img
                                                        style={{ height: 200 }}
                                                        src={image.url}
                                                        alt='upload-image'
                                                        className='process_Image'
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        ) :null
                    } 
                    {index == 2 ? (
                        <div>    
                            <div className='form-group'>
                                <label for='productImage'>Variation {index+1} Image Upload</label>
                                <input
                                type='file'
                                name={`productImage${index+1}`}
                                className='form-control'
                                id={`productImage${index}`}
                                onChange={(e) => {
                                    setProductImage3(e.target.files);
                                    showImage3(e);
                                }}
                                multiple
                                accept='image/*'
                                />
                            </div>
                            <div >
                                {multerImage3.length !== 0 ? (
                                    <div className='image-preview' style={{ display: 'flex' ,  margin: '20px 10px' }}>
                                        {multerImage3.map((image, i) => {
                                            return (
                                                <div  >
                                                    <button 
                                                        style={{ display: 'flex' }}
                                                        type='button'
                                                        className='close'
                                                        aria-label='Close'
                                                        onClick={() => handleDeleteImage(image, i)}
                                                    >
                                                        <span aria-hidden='true'>&times;</span>
                                                    </button>
                                                    <img
                                                        style={{ height: 200 }}
                                                        src={image.url}
                                                        alt='upload-image'
                                                        className='process_Image'
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        ) :null
                    }
                    {index == 3 ? (
                        <div>    
                            <div className='form-group'>
                                <label for='productImage'>Variation {index+1} Image Upload</label>
                                <input
                                type='file'
                                name={`productImage${index+1}`}
                                className='form-control'
                                id={`productImage${index}`}
                                onChange={(e) => {
                                    setProductImage4(e.target.files);
                                    showImage4(e);
                                }}
                                multiple
                                accept='image/*'
                                />
                            </div>
                            <div >
                                {multerImage4.length !== 0 ? (
                                    <div className='image-preview' style={{ display: 'flex' ,  margin: '20px 10px' }}>
                                        {multerImage4.map((image, i) => {
                                            return (
                                                <div  >
                                                    <button 
                                                        style={{ display: 'flex' }}
                                                        type='button'
                                                        className='close'
                                                        aria-label='Close'
                                                        onClick={() => handleDeleteImage(image, i)}
                                                    >
                                                        <span aria-hidden='true'>&times;</span>
                                                    </button>
                                                    <img
                                                        style={{ height: 200 }}
                                                        src={image.url}
                                                        alt='upload-image'
                                                        className='process_Image'
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        ) :null
                    } 
                    {index == 4 ? (
                        <div>    
                            <div className='form-group'>
                                <label for='productImage'>Variation {index+1} Image Upload</label>
                                <input
                                type='file'
                                name={`productImage${index+1}`}
                                className='form-control'
                                id={`productImage${index}`}
                                onChange={(e) => {
                                    setProductImage5(e.target.files);
                                    showImage5(e);
                                }}
                                multiple
                                accept='image/*'
                                />
                            </div>
                            <div >
                                {multerImage5.length !== 0 ? (
                                    <div className='image-preview' style={{ display: 'flex' ,  margin: '20px 10px' }}>
                                        {multerImage5.map((image, i) => {
                                            return (
                                                <div  >
                                                    <button 
                                                        style={{ display: 'flex' }}
                                                        type='button'
                                                        className='close'
                                                        aria-label='Close'
                                                        onClick={() => handleDeleteImage(image, i)}
                                                    >
                                                        <span aria-hidden='true'>&times;</span>
                                                    </button>
                                                    <img
                                                        style={{ height: 200 }}
                                                        src={image.url}
                                                        alt='upload-image'
                                                        className='process_Image'
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        ) :null
                    } 
                    {index == 5 ? (
                        <div>    
                            <div className='form-group'>
                                <label for='productImage'>Variation {index+1} Image Upload</label>
                                <input
                                type='file'
                                name={`productImage${index+1}`}
                                className='form-control'
                                id={`productImage${index}`}
                                onChange={(e) => {
                                    setProductImage6(e.target.files);
                                    showImage6(e);
                                }}
                                multiple
                                accept='image/*'
                                />
                            </div>
                            <div >
                                {multerImage6.length !== 0 ? (
                                    <div className='image-preview' style={{ display: 'flex' ,  margin: '20px 10px' }}>
                                        {multerImage6.map((image, i) => {
                                            return (
                                                <div  >
                                                    <button 
                                                        style={{ display: 'flex' }}
                                                        type='button'
                                                        className='close'
                                                        aria-label='Close'
                                                        onClick={() => handleDeleteImage(image, i)}
                                                    >
                                                        <span aria-hidden='true'>&times;</span>
                                                    </button>
                                                    <img
                                                        style={{ height: 200 }}
                                                        src={image.url}
                                                        alt='upload-image'
                                                        className='process_Image'
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        ) :null
                    } 
                    {index == 6 ? (
                        <div>    
                            <div className='form-group'>
                                <label for='productImage'>Variation {index+1} Image Upload</label>
                                <input
                                type='file'
                                name={`productImage${index+1}`}
                                className='form-control'
                                id={`productImage${index}`}
                                onChange={(e) => {
                                    setProductImage7(e.target.files);
                                    showImage7(e);
                                }}
                                multiple
                                accept='image/*'
                                />
                            </div>
                            <div >
                                {multerImage7.length !== 0 ? (
                                    <div className='image-preview' style={{ display: 'flex' ,  margin: '20px 10px' }}>
                                        {multerImage7.map((image, i) => {
                                            return (
                                                <div  >
                                                    <button 
                                                        style={{ display: 'flex' }}
                                                        type='button'
                                                        className='close'
                                                        aria-label='Close'
                                                        onClick={() => handleDeleteImage(image, i)}
                                                    >
                                                        <span aria-hidden='true'>&times;</span>
                                                    </button>
                                                    <img
                                                        style={{ height: 200 }}
                                                        src={image.url}
                                                        alt='upload-image'
                                                        className='process_Image'
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        ) :null
                    } 
                    {index == 7 ? (
                        <div>    
                            <div className='form-group'>
                                <label for='productImage'>Variation {index+1} Image Upload</label>
                                <input
                                type='file'
                                name={`productImage${index+1}`}
                                className='form-control'
                                id={`productImage${index}`}
                                onChange={(e) => {
                                    setProductImage8(e.target.files);
                                    showImage8(e);
                                }}
                                multiple
                                accept='image/*'
                                />
                            </div>
                            <div >
                                {multerImage8.length !== 0 ? (
                                    <div className='image-preview' style={{ display: 'flex' ,  margin: '20px 10px' }}>
                                        {multerImage8.map((image, i) => {
                                            return (
                                                <div  >
                                                    <button 
                                                        style={{ display: 'flex' }}
                                                        type='button'
                                                        className='close'
                                                        aria-label='Close'
                                                        onClick={() => handleDeleteImage(image, i)}
                                                    >
                                                        <span aria-hidden='true'>&times;</span>
                                                    </button>
                                                    <img
                                                        style={{ height: 200 }}
                                                        src={image.url}
                                                        alt='upload-image'
                                                        className='process_Image'
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        ) :null
                    } 
                    {index == 8 ? (
                        <div>    
                            <div className='form-group'>
                                <label for='productImage'>Variation {index+1} Image Upload</label>
                                <input
                                type='file'
                                name={`productImage${index+1}`}
                                className='form-control'
                                id={`productImage${index}`}
                                onChange={(e) => {
                                    setProductImage9(e.target.files);
                                    showImage9(e);
                                }}
                                multiple
                                accept='image/*'
                                />
                            </div>
                            <div >
                                {multerImage9.length !== 0 ? (
                                    <div className='image-preview' style={{ display: 'flex' ,  margin: '20px 10px' }}>
                                        {multerImage9.map((image, i) => {
                                            return (
                                                <div  >
                                                    <button 
                                                        style={{ display: 'flex' }}
                                                        type='button'
                                                        className='close'
                                                        aria-label='Close'
                                                        onClick={() => handleDeleteImage(image, i)}
                                                    >
                                                        <span aria-hidden='true'>&times;</span>
                                                    </button>
                                                    <img
                                                        style={{ height: 200 }}
                                                        src={image.url}
                                                        alt='upload-image'
                                                        className='process_Image'
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        ) :null
                    } 
                    {index == 9 ? (
                        <div>    
                            <div className='form-group'>
                                <label for='productImage'>Variation {index+1} Image Upload</label>
                                <input
                                type='file'
                                name={`productImage${index+1}`}
                                className='form-control'
                                id={`productImage${index}`}
                                onChange={(e) => {
                                    setProductImage10(e.target.files);
                                    showImage10(e);
                                }}
                                multiple
                                accept='image/*'
                                />
                            </div>
                            <div >
                                {multerImage10.length !== 0 ? (
                                    <div className='image-preview' style={{ display: 'flex' ,  margin: '20px 10px' }}>
                                        {multerImage10.map((image, i) => {
                                            return (
                                                <div  >
                                                    <button 
                                                        style={{ display: 'flex' }}
                                                        type='button'
                                                        className='close'
                                                        aria-label='Close'
                                                        onClick={() => handleDeleteImage(image, i)}
                                                    >
                                                        <span aria-hidden='true'>&times;</span>
                                                    </button>
                                                    <img
                                                        style={{ height: 200 }}
                                                        src={image.url}
                                                        alt='upload-image'
                                                        className='process_Image'
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        ) :null
                    } 
                    {index == 10 ? (
                        <div>    
                            <div className='form-group'>
                                <label for='productImage'>Variation {index+1} Image Upload</label>
                                <input
                                type='file'
                                name={`productImage${index+1}`}
                                className='form-control'
                                id={`productImage${index}`}
                                onChange={(e) => {
                                    setProductImage11(e.target.files);
                                    showImage11(e);
                                }}
                                multiple
                                accept='image/*'
                                />
                            </div>
                            <div >
                                {multerImage11.length !== 0 ? (
                                    <div className='image-preview' style={{ display: 'flex' ,  margin: '20px 10px' }}>
                                        {multerImage11.map((image, i) => {
                                            return (
                                                <div  >
                                                    <button 
                                                        style={{ display: 'flex' }}
                                                        type='button'
                                                        className='close'
                                                        aria-label='Close'
                                                        onClick={() => handleDeleteImage(image, i)}
                                                    >
                                                        <span aria-hidden='true'>&times;</span>
                                                    </button>
                                                    <img
                                                        style={{ height: 200 }}
                                                        src={image.url}
                                                        alt='upload-image'
                                                        className='process_Image'
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        ) :null
                    } 
                    {index == 11 ? (
                        <div>    
                            <div className='form-group'>
                                <label for='productImage'>Variation {index+1} Image Upload</label>
                                <input
                                type='file'
                                name={`productImage${index+1}`}
                                className='form-control'
                                id={`productImage${index}`}
                                onChange={(e) => {
                                    setProductImage12(e.target.files);
                                    showImage12(e);
                                }}
                                multiple
                                accept='image/*'
                                />
                            </div>
                            <div >
                                {multerImage12.length !== 0 ? (
                                    <div className='image-preview' style={{ display: 'flex' ,  margin: '20px 10px' }}>
                                        {multerImage12.map((image, i) => {
                                            return (
                                                <div  >
                                                    <button 
                                                        style={{ display: 'flex' }}
                                                        type='button'
                                                        className='close'
                                                        aria-label='Close'
                                                        onClick={() => handleDeleteImage(image, i)}
                                                    >
                                                        <span aria-hidden='true'>&times;</span>
                                                    </button>
                                                    <img
                                                        style={{ height: 200 }}
                                                        src={image.url}
                                                        alt='upload-image'
                                                        className='process_Image'
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        ) :null
                    }
                    {index == 12 ? (
                        <div>    
                            <div className='form-group'>
                                <label for='productImage'>Variation {index+1} Image Upload</label>
                                <input
                                type='file'
                                name={`productImage${index+1}`}
                                className='form-control'
                                id={`productImage${index}`}
                                onChange={(e) => {
                                    setProductImage13(e.target.files);
                                    showImage13(e);
                                }}
                                multiple
                                accept='image/*'
                                />
                            </div>
                            <div >
                                {multerImage13.length !== 0 ? (
                                    <div className='image-preview' style={{ display: 'flex' ,  margin: '20px 10px' }}>
                                        {multerImage13.map((image, i) => {
                                            return (
                                                <div  >
                                                    <button 
                                                        style={{ display: 'flex' }}
                                                        type='button'
                                                        className='close'
                                                        aria-label='Close'
                                                        onClick={() => handleDeleteImage(image, i)}
                                                    >
                                                        <span aria-hidden='true'>&times;</span>
                                                    </button>
                                                    <img
                                                        style={{ height: 200 }}
                                                        src={image.url}
                                                        alt='upload-image'
                                                        className='process_Image'
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        ) :null
                    } 
                    {index == 13 ? (
                        <div>    
                            <div className='form-group'>
                                <label for='productImage'>Variation {index+1} Image Upload</label>
                                <input
                                type='file'
                                name={`productImage${index+1}`}
                                className='form-control'
                                id={`productImage${index}`}
                                onChange={(e) => {
                                    setProductImage14(e.target.files);
                                    showImage14(e);
                                }}
                                multiple
                                accept='image/*'
                                />
                            </div>
                            <div >
                                {multerImage14.length !== 0 ? (
                                    <div className='image-preview' style={{ display: 'flex' ,  margin: '20px 10px' }}>
                                        {multerImage14.map((image, i) => {
                                            return (
                                                <div  >
                                                    <button 
                                                        style={{ display: 'flex' }}
                                                        type='button'
                                                        className='close'
                                                        aria-label='Close'
                                                        onClick={() => handleDeleteImage(image, i)}
                                                    >
                                                        <span aria-hidden='true'>&times;</span>
                                                    </button>
                                                    <img
                                                        style={{ height: 200 }}
                                                        src={image.url}
                                                        alt='upload-image'
                                                        className='process_Image'
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        ) :null
                    } 
                    {index == 14 ? (
                        <div>    
                            <div className='form-group'>
                                <label for='productImage'>Variation {index+1} Image Upload</label>
                                <input
                                type='file'
                                name={`productImage${index+1}`}
                                className='form-control'
                                id={`productImage${index}`}
                                onChange={(e) => {
                                    setProductImage15(e.target.files);
                                    showImage15(e);
                                }}
                                multiple
                                accept='image/*'
                                />
                            </div>
                            <div >
                                {multerImage15.length !== 0 ? (
                                    <div className='image-preview' style={{ display: 'flex' ,  margin: '20px 10px' }}>
                                        {multerImage15.map((image, i) => {
                                            return (
                                                <div  >
                                                    <button 
                                                        style={{ display: 'flex' }}
                                                        type='button'
                                                        className='close'
                                                        aria-label='Close'
                                                        onClick={() => handleDeleteImage(image, i)}
                                                    >
                                                        <span aria-hidden='true'>&times;</span>
                                                    </button>
                                                    <img
                                                        style={{ height: 200 }}
                                                        src={image.url}
                                                        alt='upload-image'
                                                        className='process_Image'
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        ) :null
                    } 
                    {index == 15 ? (
                        <div>    
                            <div className='form-group'>
                                <label for='productImage'>Variation {index+1} Image Upload</label>
                                <input
                                type='file'
                                name={`productImage${index+1}`}
                                className='form-control'
                                id={`productImage${index}`}
                                onChange={(e) => {
                                    setProductImage16(e.target.files);
                                    showImage16(e);
                                }}
                                multiple
                                accept='image/*'
                                />
                            </div>
                            <div >
                                {multerImage16.length !== 0 ? (
                                    <div className='image-preview' style={{ display: 'flex' ,  margin: '20px 10px' }}>
                                        {multerImage16.map((image, i) => {
                                            return (
                                                <div  >
                                                    <button 
                                                        style={{ display: 'flex' }}
                                                        type='button'
                                                        className='close'
                                                        aria-label='Close'
                                                        onClick={() => handleDeleteImage(image, i)}
                                                    >
                                                        <span aria-hidden='true'>&times;</span>
                                                    </button>
                                                    <img
                                                        style={{ height: 200 }}
                                                        src={image.url}
                                                        alt='upload-image'
                                                        className='process_Image'
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        ) :null
                    } 
                    {index == 16 ? (
                        <div>    
                            <div className='form-group'>
                                <label for='productImage'>Variation {index+1} Image Upload</label>
                                <input
                                type='file'
                                name={`productImage${index+1}`}
                                className='form-control'
                                id={`productImage${index}`}
                                onChange={(e) => {
                                    setProductImage17(e.target.files);
                                    showImage17(e);
                                }}
                                multiple
                                accept='image/*'
                                />
                            </div>
                            <div >
                                {multerImage17.length !== 0 ? (
                                    <div className='image-preview' style={{ display: 'flex' ,  margin: '20px 10px' }}>
                                        {multerImage17.map((image, i) => {
                                            return (
                                                <div  >
                                                    <button 
                                                        style={{ display: 'flex' }}
                                                        type='button'
                                                        className='close'
                                                        aria-label='Close'
                                                        onClick={() => handleDeleteImage(image, i)}
                                                    >
                                                        <span aria-hidden='true'>&times;</span>
                                                    </button>
                                                    <img
                                                        style={{ height: 200 }}
                                                        src={image.url}
                                                        alt='upload-image'
                                                        className='process_Image'
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        ) :null
                    } 
                    {index == 17 ? (
                        <div>    
                            <div className='form-group'>
                                <label for='productImage'>Variation {index+1} Image Upload</label>
                                <input
                                type='file'
                                name={`productImage${index+1}`}
                                className='form-control'
                                id={`productImage${index}`}
                                onChange={(e) => {
                                    setProductImage18(e.target.files);
                                    showImage18(e);
                                }}
                                multiple
                                accept='image/*'
                                />
                            </div>
                            <div >
                                {multerImage18.length !== 0 ? (
                                    <div className='image-preview' style={{ display: 'flex' ,  margin: '20px 10px' }}>
                                        {multerImage18.map((image, i) => {
                                            return (
                                                <div  >
                                                    <button 
                                                        style={{ display: 'flex' }}
                                                        type='button'
                                                        className='close'
                                                        aria-label='Close'
                                                        onClick={() => handleDeleteImage(image, i)}
                                                    >
                                                        <span aria-hidden='true'>&times;</span>
                                                    </button>
                                                    <img
                                                        style={{ height: 200 }}
                                                        src={image.url}
                                                        alt='upload-image'
                                                        className='process_Image'
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        ) :null
                    } 
                    {index == 18 ? (
                        <div>    
                            <div className='form-group'>
                                <label for='productImage'>Variation {index+1} Image Upload</label>
                                <input
                                type='file'
                                name={`productImage${index+1}`}
                                className='form-control'
                                id={`productImage${index}`}
                                onChange={(e) => {
                                    setProductImage19(e.target.files);
                                    showImage19(e);
                                }}
                                multiple
                                accept='image/*'
                                />
                            </div>
                            <div >
                                {multerImage19.length !== 0 ? (
                                    <div className='image-preview' style={{ display: 'flex' ,  margin: '20px 10px' }}>
                                        {multerImage19.map((image, i) => {
                                            return (
                                                <div  >
                                                    <button 
                                                        style={{ display: 'flex' }}
                                                        type='button'
                                                        className='close'
                                                        aria-label='Close'
                                                        onClick={() => handleDeleteImage(image, i)}
                                                    >
                                                        <span aria-hidden='true'>&times;</span>
                                                    </button>
                                                    <img
                                                        style={{ height: 200 }}
                                                        src={image.url}
                                                        alt='upload-image'
                                                        className='process_Image'
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        ) :null
                    } 
                    {index == 19 ? (
                        <div>    
                            <div className='form-group'>
                                <label for='productImage'>Variation {index+1} Image Upload</label>
                                <input
                                type='file'
                                name={`productImage${index+1}`}
                                className='form-control'
                                id={`productImage${index}`}
                                onChange={(e) => {
                                    setProductImage20(e.target.files);
                                    showImage20(e);
                                }}
                                multiple
                                accept='image/*'
                                />
                            </div>
                            <div >
                                {multerImage20.length !== 0 ? (
                                    <div className='image-preview' style={{ display: 'flex' ,  margin: '20px 10px' }}>
                                        {multerImage20.map((image, i) => {
                                            return (
                                                <div  >
                                                    <button 
                                                        style={{ display: 'flex' }}
                                                        type='button'
                                                        className='close'
                                                        aria-label='Close'
                                                        onClick={() => handleDeleteImage(image, i)}
                                                    >
                                                        <span aria-hidden='true'>&times;</span>
                                                    </button>
                                                    <img
                                                        style={{ height: 200 }}
                                                        src={image.url}
                                                        alt='upload-image'
                                                        className='process_Image'
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        ) :null
                    } 
                    </div>
                  ))}
                </div>
              )}
            </>
          ) : null}
        </div>
        {varien ? null : (
          <div className='card card-input'>
            <div className='form-group'>
              <label for='product_id'>ID/SKU</label>
              <input
                type='text'
                value={code}
                onChange={(e) => setCode(e.target.value)}
                className='form-control'
                id='product_id'
                placeholder='Enter Unique Id of Product'
              />
            </div>
            
            <div className='form-group'>
              <p style={{ marginBottom: 4, fontSize: 15 }}>Ex Factory Price</p>
              <div
                class='form-control '
                style={{
                  border: '1px solid #ddd',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <span class='icon-wrapp'>
                  <i class='input-icon fa fa-inr'></i>
                </span>
                <input
                  class='input-with-icon'
                  id='form-name'
                  type='number'
                  min='0'
                  step=".01"
                  maxLength="10"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  id='product_price'
                  style={{ border: 'none', width: '48vw' }}
                  placeholder='Enter Price'
                />
              </div>
            </div>
            <div className='form-group'>
              <p style={{ marginBottom: 4, fontSize: 15 }}>MRP</p>
              <div
                class='form-control '
                style={{
                  border: '1px solid #ddd',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <span class='icon-wrapp'>
                  <i class='input-icon fa fa-inr'></i>
                </span>
                <input
                  class='input-with-icon'
                  id='form-name'
                  type='number'
                  min='0'
                  step=".01"
                  maxLength="10"
                  value={mrp_price}
                  onChange={(e) => setPriceselling(e.target.value)}
                  id='product_price_selling'
                  style={{ border: 'none', width: '48vw' }}
                  placeholder='Enter MRP'
                />
              </div>
            </div>
            <div className='form-group'>
              <label for='product_quantity'>Quantity</label>
              <input
              type='number'
              min='0'
              step="any"
              maxLength="10"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}

                className='form-control'
                id='product_quantity'
                placeholder='Enter Available Quanity of Product'
              />
            </div>
          </div>
        )}

        <div className='card card-input'>
          <div className='form-group'>
            <label for='product_category'>Category</label>
            <select
              className='form-control'
              id='product_category'
              onChange={(e) => {
                setCategory(e.target.value);
                categoryList.forEach((item, i) => {
                  if (item.category === e.target.value) {
                    if (item.margin !== undefined || null) {
                      setAutoMargin(item.margin);
                    } else {
                      setAutoMargin(0);
                    }
                  }
                });
              }}
              placeholder='Enter category'
              required
            >
              <option>Select Category</option>
              {categoryList.map((item, i) => {
                return (
                  <option key={i} value={item.category}>
                    {item.category}
                  </option>
                );
              })}
            </select>
          </div>
           <div className='form-group'>
              <label for='product_hsn'>HSN</label>
              <input
                type='text'
                value={hsn}
                onChange={(e) => setHsn(e.target.value)}
                className='form-control'
                id='product_hsn'
                placeholder='Enter HSN of Product'
              />
            </div>
          <div className='form-group'>
            <label for='product_waight'>Weight</label>
            <input
              type='text'
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
              className='form-control'
              id='product_waight'
              placeholder='Enter Weight of Product in Grams'
            />
          </div>
          <div className='form-group'>
            <label for='product_warranty'>Warranty</label>
            <input
              type='text'
              value={warranty}
              onChange={(e) => setWarranty(e.target.value)}
              className='form-control'
              id='product_warranty'
              placeholder='Enter Available warranty of Product'
            />
          </div>
          <div className='form-group' style={{ marginTop: 20 }}>
            <label for='product_size'>Size</label>
            <input
              type='text'
              value={size}
              onChange={(e) => setSize(e.target.value)}
              className='form-control'
              id='product_size'
              placeholder="Enter Different sizes seperated by ',' commas"
            />
          </div>

          <div className='form-group'>
            <label className='productImage'>Shipping Details</label>
            <div className='custom-control custom-checkbox'>
              <input
                className='form-check-input'
                type='radio'
                name='exampleRadios'
                id='exampleRadios1'
                style={{ marginRight: '10px' }}
                value='freeShipping'
                onChange={(e) => { return (setShippingDetails(e.target.value),
                  setUsa(2.5),
                  setAustralia(2.5),
                  setCanada(2.5),
                  setUk(2.5),
                  setInternational(2.5)
                )}}
              />
              <label
                className='form-check-label shippinglabel'
                for='exampleRadios1'
              >
                Free ePacket Shipping
              </label>
            </div>
            <div className='custom-control custom-checkbox'>
              <input
                className='form-check-input'
                type='radio'
                name='exampleRadios'
                id='exampleRadios2'
                style={{ marginRight: '10px' }}
                value='standardShipping'
                onChange={(e) => setShippingDetails(e.target.value)}
              />
              <label
                className='form-check-label shippinglabel'
                for='exampleRadios2'
              >
                Standard ePacket Shipping
              </label>
            </div>
            <br />
            {shippingDetails === 'standardShipping' ? (
              <div>
                <h5>Shipping Price</h5>
                <div className='form-group row'>
                  <label className='col-sm-2 col-form-label'>USA</label>
                  <div className='col-sm-10'>
                    <div
                      class='form-control '
                      style={{
                        border: '1px solid #ddd',
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <span class='icon-wrapp'>
                        <i class='input-icon fa fa-inr'></i>
                      </span>
                      <input
                        class='input-with-icon'
                        id='form-name'
                        type='number'
                        min='0'
                        step=".01"
                        maxLength="10"
                        value={usa}
                        onChange={(e) => setUsa(e.target.value)}
                        id='product_price'
                        style={{ border: 'none' }}
                        placeholder='Enter Shipping Charges.'
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className='form-group row'>
                  <label className='col-sm-2 col-form-label'>Canada</label>
                  <div className='col-sm-10'>
                    <div
                      class='form-control '
                      style={{
                        border: '1px solid #ddd',
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <span class='icon-wrapp'>
                        <i class='input-icon fa fa-inr'></i>
                      </span>
                      <input
                        class='input-with-icon'
                        id='form-name'
                        type='number'
                        min='0'
                        step=".01"
                        maxLength="10"
                        value={canada}
                        onChange={(e) => setCanada(e.target.value)}
                        id='product_price'
                        style={{ border: 'none' }}
                        placeholder='Enter Shipping Charges.'
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className='form-group row'>
                  <label className='col-sm-2 col-form-label'>
                    United Kingdom
                  </label>
                  <div className='col-sm-10'>
                    <div
                      class='form-control '
                      style={{
                        border: '1px solid #ddd',
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <span class='icon-wrapp'>
                        <i class='input-icon fa fa-inr'></i>
                      </span>
                      <input
                        class='input-with-icon'
                        id='form-name'
                        type='number'
                        min='0'
                        step=".01"
                        maxLength="10"
                        value={uk}
                        onChange={(e) => setUk(e.target.value)}
                        id='product_price'
                        style={{ border: 'none' }}
                        placeholder='Enter Shipping Charges.'
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className='form-group row'>
                  <label className='col-sm-2 col-form-label'>Australia</label>
                  <div className='col-sm-10'>
                    <div
                      class='form-control '
                      style={{
                        border: '1px solid #ddd',
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <span class='icon-wrapp'>
                        <i class='input-icon fa fa-inr'></i>
                      </span>
                      <input
                        class='input-with-icon'
                        id='form-name'
                        type='number'
                        min='0'
                        step=".01"
                        maxLength="10"
                        value={australia}
                        onChange={(e) => setAustralia(e.target.value)}
                        id='product_price'
                        style={{ border: 'none' }}
                        placeholder='Enter Shipping Charges.'
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className='form-group row'>
                  <label className='col-sm-2 col-form-label'>
                    International
                  </label>
                  <div className='col-sm-10'>
                    <div
                      class='form-control '
                      style={{
                        border: '1px solid #ddd',
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <span class='icon-wrapp'>
                        <i class='input-icon fa fa-inr'></i>
                      </span>
                      <input
                        class='input-with-icon'
                        id='form-name'
                        type='number'
                        min='0'
                        step=".01"
                        maxLength="10"
                        value={international}
                        onChange={(e) => setInternational(e.target.value)}
                        id='product_price'
                        style={{ border: 'none' }}
                        placeholder='Enter Shipping Charges.'
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className='card-button' style={{ marginTop: 20 }}>
          <CustomButton round fill type='submit'>
            Save Product
          </CustomButton>
        </div>
      </form>
      <br />
      
       
    </div>
  );
};

export default AddProduct;

 