import React, { Component, useEffect, useState } from 'react';
import ChartistGraph from 'react-chartist';
import { Grid, Row, Col, Table } from 'react-bootstrap';
import { Card } from '../../components/Card/Card.jsx';
import { StatsCard } from '../../components/StatsCard/StatsCard.jsx';
import { Tasks } from '../../components/Tasks/Tasks.jsx';
import SalesChart from '../../components/Charts/amcharts'; 
import {
    dataPie,
    legendPie,
    optionsSales,
    responsiveSales,
    legendSales,
    dataBar,
    optionsBar,
    responsiveBar,
    legendBar,
} from '../../variables/Variables.jsx';
import axios from 'axios';
import moment from "moment";
import SyncLoader from "react-spinners/SyncLoader" 
import {getPriceFormatter} from '../../helpers/index.js';
const override = `
    position: fixed;
    display: block;
    top: 45%;
    left: 50%;
    transform: translateX(-50%);
    transform: translate(-50%, -50%);
    z-index: 9999;
`  
const CopyDashboard = () => {
    const [count, setCount] = useState('');
    const [revenue, setRevenue] = useState('');
    const [graphPlot, setGraphPlot] = useState({});
    const [piePlot, setPiePlot] = useState({});
    const [mCount, setMCount] = useState('');
    const [categoryPie, setCategoryPie] = useState({});
    const [orderCount, setOrderCount] = useState({});
    const [stateOrder, setStateOrder] = useState({});
    const [sellingProducts, setSellingProducts] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [isLoaded, setLoader] = useState(true); 
    const [amgraphData, setAmgraphData] = useState([]); 
    const [startDate, setStartDate] = useState(
        moment().subtract(3, 'days').format("Y-MM-DD")
    );
    const [endDate, setEndDate] = useState(moment().format("Y-MM-DD"));
    useEffect(() => {
        fetchData(); 
        fetchingRevenueGraph(); 
        merchantCount();  
        topProducts(); 
    }, []); 

    const sortByDate = async () => { 
        setLoader(true)
        fetchData()
        fetchingRevenueGraph()
        merchantCount() 
    };
 
    const fetchData = () => {
        axios.get("/adminApi/ordersData/"+ startDate + "/" + endDate).then((data) => {
            setCount(data.data); 
        });
    }; 
    const merchantCount = () => {
        axios.get("/adminApi/merchant/"+ startDate + "/" + endDate).then((data) => { 
            setMCount(data.data);
        });
    };
    const test = () => {
        return false;
    }
    const topProducts = () => { 
        axios.get("/adminApi/merchantTopProducts").then((response) => {
            setSellingProducts(response.data);
        });
    };  
    const fetchingRevenueGraph = () => {
        axios.get("/adminApi/revenueGraph/"+ startDate + "/" + endDate).then((response) => { 
             
            let income = response.data.revenue.revenue.reduce((a, b) => a + b, 0);
            let data1 = {
                labels: response.data.revenue.state,
                series: [response.data.revenue.stateOrder],
            };  
            // console.log(data1)
            setStateOrder(data1);
            setRevenue(income.toFixed(2)); 
            setAmgraphData(response.data.amgraphData);  
            setLoader(false)
        });
    };
    var optionsGraphPlot = {
        high: 100000,
        low: 10,
        axisX: {
            labelInterpolationFnc: function (value, index) {
                return index % 10 === 0 ? value : null;
            },
        },
    };

    var orderCountGraphPlot = {
        high: 40,
        low: 0,
        axisX: {
            labelInterpolationFnc: function (value, index) {
                return index % 1 === 0 ? value : null;
            },
        },
    };

    var stateOrderCountGraphPlot = {
        high: 300,
        low: 0,
        axisX: {
            labelInterpolationFnc: function (value, index) {
                return index % 20 === 0 ? value : null;
            },
        },
    }; 
    return (
        <div>
            <SyncLoader css={override} size={15} margin={6} color={"#3E3E3E"} loading={isLoaded } />
            <br></br>
            <div className='content'> 
                <Grid fluid>
                    <Row> 
                        <div className="row" style={{ padding: '0px 15px 30px' }}>
                            <div className="col-md-4">
                                <input  required className="form-control"
                                    id="date" type="date"  placeholder="Start from" autoComplete="bday-day"
                                    max={new Date()} min={new Date("20-02-2019")} value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}  style={{ height: 45 }} />
                            </div>
                            <div className="col-md-4">
                                <input required placeholder="To date"  className="form-control"
                                    id="date" type="date"  autoComplete="bday-day" max={new Date()}
                                    min={new Date("20-02-2019")} value={endDate} onChange={(e) => setEndDate(e.target.value)}
                                    style={{ height: 45,  marginLeft: "20px", }} />
                            </div>
                            <div className="col-md-2">
                                <div style={{ backgroundColor: "#ffa000", width: "140px", height: "45px", display: "flex", alignItems: "center", justifyContent: "center",  borderRadius: 4, marginLeft: "20px",  cursor: "pointer", color: "#fff", fontSize: "15px", fontWeight: "bold", textTransform: "uppercase"}}
                                    onClick={() => sortByDate()} > Get </div>
                            </div>
                        </div>
                        <Col lg={4} sm={6}>
                            <StatsCard bigIcon={<i className='pe-7s-display1' />} statsText='Total Orders'  statsValue={count}  statsIcon={<i className='fa fa-refresh' />} func ={ test } statsIconText='Updated now' />
                        </Col>
                        <Col lg={4} sm={6}>
                            <StatsCard
                                bigIcon={<i className='pe-7s-wallet' />}
                                statsText='Total Revenue'
                                statsValue={getPriceFormatter(revenue)}
                                statsIcon={<i className='fa fa-refresh' />}
                                statsIconText='Updated now'
                                func ={ test }
                            />
                        </Col>

                        <Col lg={4} sm={6}>
                            <StatsCard
                                bigIcon={<i className='pe-7s-cart' style={{color:"#fff"}}/>} statsText='Merchants' statsValue={mCount}
                                 statsIcon={<i className='fa fa-refresh' />} func ={ test } statsIconText='Updated now'
                            />
                        </Col>
                    </Row>
                    <Row>
                    { amgraphData.length ? (
                        <div>
                            <SalesChart amgraphData={amgraphData} />
                        </div> ) : (<div> </div>) 
                    }
                    </Row>
                    {
                    // <Row>
                    //     <Col md={12}>
                    //         <Card
                    //             statsIcon='fa fa-history' id='chartHours' title='Total Revenue' category='24 Hours performance'
                    //             stats='Updated 3 minutes ago'
                    //             content={
                    //                 <div className='ct-chart'>
                    //                     <ChartistGraph
                    //                         data={graphPlot}
                    //                         type='Bar'
                    //                         options={optionsSales}
                    //                         responsiveOptions={responsiveSales}
                    //                     />
                    //                 </div>
                    //             }
                    //         />
                    //     </Col>  
                    // </Row>
                    // <Row> 
                    //     <Col md={12}>
                    //         <Card statsIcon='fa fa-history' id='chartHours'  title='Orders' category='Day Wise' stats='Updated 3 minutes ago'
                    //             content={
                    //                 <div className='ct-chart'>
                    //                     <ChartistGraph data={orderCount} type='Line' options={orderCountGraphPlot} responsiveOptions={responsiveSales} />
                    //                 </div>
                    //             }
                    //         />
                    //     </Col>
                    // </Row> 
                    }
                    <Row>
                        <Col md={12}>
                            <Card statsIcon='fa fa-history' id='chartHours' title='Orders' category='State Wise' stats='Updated 3 minutes ago'
                                content={
                                    <div className='ct-chart '>
                                        <ChartistGraph data={stateOrder} type='Line' options={stateOrderCountGraphPlot} responsiveOptions={responsiveSales} />
                                    </div>
                                }
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <Card title='Top Selling Products' ctTableFullWidth ctTableResponsive
                                content={
                                    <Table striped hover size='sm'>
                                        <thead>
                                            <tr>
                                                <th>Sku</th>
                                                <th>Title</th>
                                                <th>Price</th> 
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sellingProducts.map((item, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>{item.sku}</td>
                                                        <td>{item.name}</td> 
                                                        <td>{getPriceFormatter(item.price)}</td> 
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                }
                            />
                        </Col>
                    </Row> 
                </Grid>
            </div>
        </div>
    );
};

export default CopyDashboard;
