import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'

function EditCustomer(props) {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [note, setNote] = useState('')
    const [storeDomain, setStoreDomain] = useState('')
    const [company, setCompany] = useState('')
    const [gstNumber, setGstNumber] = useState('')
    const [mobileNumber, setMobileNumber] = useState('')

    useEffect(()=> {
        const id = props.match.params.id
        axios.get(`/sscapi/customer/${id}`, {
            headers: {
                'auth-token': localStorage.getItem('token')
            }
        })
            .then(res => {
                console.log("Res - ", res.data)
                const customerData = res.data
                if (customerData) {
                    setFirstName(customerData.firstName)
                    setLastName(customerData.lastName)
                    setEmail(customerData.email)
                    setNote(customerData.note)
                    setStoreDomain(customerData.storeDomain)
                    setCompany(customerData.companyName)
                    setGstNumber(customerData.gstNumber)
                    setMobileNumber(customerData.mobileNumber)
                }
            })
            .catch(err => {
                console.log("error - ",err)
            })
    },[])


    const handleSubmit = (e) => {
        e.preventDefault()
        const formdata = {
            companyName: company,
            gstNumber: gstNumber,
            mobileNumber: mobileNumber
        }
        axios.put(`/sscapi/gst-verify/${props.match.params.id}`, formdata, {
            headers: {
                'auth-token': localStorage.getItem('token')
            }
        })
            .then(res => {
                console.log("Response ", res.data.newData)
                if (res.data.newData) {
                    console.log("Came to if")
                    // Swal.fire(res.data.message)
                    Swal.fire({
                        icon: 'success',
                        title: 'Done',
                        text: res.data.message
                      })
                    props.history.push(`/merchant/customer/${props.match.params.id}`)
                } else {
                    console.log("err",res.data)
                    Swal.fire(res.data)
                }
            })
            .catch(err => {
                console.log("Error",err)
            })
    }
    return (
        <div class="content bdycontent">
        <div className="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div style = {{ padding: '10px' }}> 
                            <form onSubmit={(e)=> handleSubmit(e)}>
                                <div className="form-group col-sm-6 col-md-6 col-lg-6" style={{paddingLeft : '7px'}}> 
                                    <label htmlFor='fullName'>First Name</label> 
                                    <input type="text" disabled className="form-control"  value={firstName} /> 
                                </div> 

                                <div className="form-group col-sm-6 col-md-6 col-lg-6"> 
                                    <label htmlFor='fullName'>LAST NAME</label>   
                                    <input type="text" disabled className="form-control" value={lastName} />
                                </div>  

                                <div className="form-group col-sm-6 col-md-6 col-lg-6"> 
                                    <label htmlFor='fullName'>EMAIL</label>   
                                    <input type ="text" disabled className="form-control" value={email} />
                                </div>   

                                <div className="form-group col-sm-6 col-md-6 col-lg-6"> 
                                    <label htmlFor='fullName'>Note</label>   
                                    <textarea className="form-control" disabled value={note} onChange={(e) => setNote(e.target.value) }></textarea>
                                </div>   

                                <div className="form-group col-sm-6 col-md-6 col-lg-6"> 
                                    <label htmlFor='fullName'>Store Name </label>   
                                    <input type="text" className="form-control" disabled value={storeDomain}/>
                                </div>    

                                <div className="form-group col-sm-6 col-md-6 col-lg-6"> 
                                    <label htmlFor='fullName'>Company</label>   
                                    <input type="text" value={company} className="form-control" onChange={(e) => setCompany(e.target.value) } />
                                </div>      

                                <div className="form-group col-sm-6 col-md-6 col-lg-6"> 
                                    <label htmlFor='fullName'>GST No</label>   
                                    <input type="text" className="form-control" value={gstNumber} onChange={(e) => setGstNumber(e.target.value) } />
                                </div>      

                                <div className="form-group col-sm-6 col-md-6 col-lg-6"> 
                                    <label htmlFor='fullName'>Mobile</label>   
                                    <input type="text" className="form-control" maxLength="10" minLength="10" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value) } />
                                </div>  
                                <div style={{paddingLeft : '7px'}}>
                                    <input type="submit" style={{marginTop: '45px'}} className="form-group btn   btn-fill" style={{ textTransform: 'uppercase', background: "rgb(255, 160, 0)"  ,  borderColor: "rgb(255, 160, 0)" , fontSize: "15px" , fontWeight: "500"}} value="Edit" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default EditCustomer