import React, { useState, useEffect, useMemo } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import axios from "axios";
import jwt_decode from "jwt-decode";
import "../../assets/css/settings.css";
import { NotificationManager } from "react-notifications";
import Card from "../../components/Card/Card.jsx";
import CustomButton from "../../components/CustomButton/CustomButton";
import { Redirect } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
import Select from "react-select";
import countryList from "react-select-country-list";
const override = `
	position: fixed;
	display: block;
	top: 45%;
	left: 50%;
	transform: translateX(-50%);
	transform: translate(-50%, -50%);
	z-index: 9999;
`;
const imageExtensions = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/webp",
  "image/bmp",
];
const SupplierOrders = () => {
  const [id, setId] = useState("");
  const [mongoId, setMongoId] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [Description, setDescription] = useState("");
  const [priceAlgo, setPriceAlgo] = useState("");
  const [redirectTo, setRedirectTo] = useState(false);
  const [loading, setLoading] = useState(true);
  const [origin, setOrigin] = useState("");
  const token = localStorage.getItem("token");
  const decode = jwt_decode(token);
  const [details, setDetails] = useState("");
  const [bankDetails, setBankDetails] = useState("");
  const [BusinessDetails, setBusinessDetails] = useState("");
  const [bankAuth, setBankAuth] = useState("");
  const [brandLogo, setBrandLogo] = useState([]);
  const [Trademark, setTrademark] = useState([]);
  const [sizeChart, setsizeChart] = useState("");
  const [packageMaterial, setpackageMaterial] = useState("");
  const [Address, setAddress] = useState("");
  const [Pin, setPin] = useState("");
  const [pickup, setPickup] = useState("");
  const [AccountNo, setAccountNo] = useState("");
  const [IFSC, setIFSC] = useState("");
  const [bankAddress, setBankAddress] = useState("");
  const [holderName, setholderName] = useState("");
  const [businessName, setbusinessName] = useState("");
  const [Tan, setTan] = useState("");
  const [GSTIN, setGSTIN] = useState("");
  const [RbusinessAddress, setRbusinessAddress] = useState("");
  const [Pan, setPan] = useState("");
  useEffect(() => {
    if (localStorage.getItem("editSupplierFrom") === "admin")
      getSupplierProfile(localStorage.getItem("code"));
    else getSupplierProfile(decode.id);
  }, []);

  const options = useMemo(() => countryList().getData(), []);

  const getSupplierProfile = (searchId) => {
    axios.get("/supplierProfile" + searchId).then((res) => {
      console.log("All-data", res.data);
      const {
        details,
        bankDetails,
        BusinessDetails,
        BrandAuthorization,
        BrandLogo,
        TRADEMARKCERTIFICATE,
        ImageOfTheSizeChart,
        ImagePackagingMaterial,
        BusinessDescription,
        pickupADDRESS,
        pickupPinCode,
        pickupCITY,
        bankAccountNumber,
        bankIfscCode,
        bankAddress,
        bankAccountHolderName,
        BusinessName,
        tan,
        GstinNumber,
        registerBusinessAddress,
        pan,
      } = res.data;
      setEmail(res.data.email);
      setId(res.data.supplier_id);
      setMongoId(res.data._id);
      setPriceAlgo(res.data.price_algo);
      setOrigin(res.data.origin);
      setLoading(false);
      // setDescription(Description);
      setDetails(details);
      setDescription(BusinessDescription);
      setBankDetails(bankDetails);
      setBusinessDetails(BusinessDetails);
      setBankAuth(BrandAuthorization);

      setBrandLogo(BrandLogo);
      localStorage.setItem("brandlogo",BrandLogo)
      setTrademark(TRADEMARKCERTIFICATE);
      setsizeChart(ImageOfTheSizeChart);
      setpackageMaterial(ImagePackagingMaterial);
      setAddress(pickupADDRESS);
      setPin(pickupPinCode);
      setPickup(pickupCITY);
      setAccountNo(bankAccountNumber);
      setIFSC(bankIfscCode);
      setBankAddress(bankAddress);
      setholderName(bankAccountHolderName);
      setbusinessName(BusinessName);
      setTan(tan);
      setGSTIN(GstinNumber);
      setRbusinessAddress(registerBusinessAddress);
      setPan(pan);
    });
  };

  // image upload function
  const uploadMultipleImages1 = (e) => {
    const readImage = (file) => {
      if (imageExtensions.includes(file.type)) {
        const reader = new FileReader();
        reader.addEventListener("load", (e1) => {
          // this.setState({
          //   imageArray: this.state.imageArray.concat(e1.target.result),
          // });
          setBrandLogo(e1.target.result);
        });
        reader.readAsDataURL(file);
      } else {
        NotificationManager.error(
          `File not supported, Supported files are jpeg, png, webp, bmp, gif.`
        );
      }
    };

    const files = e.target.files;
    if (!files || !files[0]) return alert("File upload not supported");
    [...files].forEach(readImage);
  };
  const uploadMultipleImages2 = (e) => {
    const readImage = (file) => {
      if (imageExtensions.includes(file.type)) {
        const reader = new FileReader();
        reader.addEventListener("load", (e1) => {
          // this.setState({
          //   imageArray: this.state.imageArray.concat(e1.target.result),
          // });
          setTrademark(e1.target.result);
        });
        reader.readAsDataURL(file);
      } else {
        NotificationManager.error(
          `File not supported, Supported files are jpeg, png, webp, bmp, gif.`
        );
      }
    };

    const files = e.target.files;
    if (!files || !files[0]) return alert("File upload not supported");
    [...files].forEach(readImage);
  };

  const updateSettings = async (e) => {
    e.preventDefault();
    console.log("setting update api's get hit");
    try {
      setLoading(true);
      var obj = {};
      if (password == "") {
        console.log("request inside if statements");
        obj = {
          origin: origin.label,
          priceAlgo: priceAlgo,
          supplier_id: mongoId,
          BrandAuthorization: bankAuth,
          BrandLogo: brandLogo,
          Description: Description,
          TRADEMARKCERTIFICATE: Trademark,
          ImageOfTheSizeChart: sizeChart,
          ImagePackagingMaterial: packageMaterial,
          pickupADDRESS: Address,
          pickupPinCode: Pin,
          pickupCITY: pickup,
          bankAccountNumber: AccountNo,
          bankIfscCode: IFSC,
          bankAddress: bankAddress,
          bankAccountHolderName: holderName,
          BusinessName: businessName,
          tan: Tan,
          GstinNumber: GSTIN,
          registerBusinessAddress: RbusinessAddress,
          pan: Pan,
        };
        // console.log("obj data is");
        // console.log(obj);
      } else {
        console.log("request inside else statements");

        obj = {
          origin: origin.label,
          priceAlgo: priceAlgo,
          password: password,
          supplier_id: mongoId,
          Description: Description,
          BrandAuthorization: bankAuth,
          BrandLogo: brandLogo,
          TRADEMARKCERTIFICATE: Trademark,
          ImageOfTheSizeChart: sizeChart,
          ImagePackagingMaterial: packageMaterial,
          pickupADDRESS: Address,
          pickupPinCode: Pin,
          pickupCITY: pickup,
          bankAccountNumber: AccountNo,
          bankIfscCode: IFSC,
          bankAddress: bankAddress,
          bankAccountHolderName: holderName,
          BusinessName: businessName,
          tan: Tan,
          GstinNumber: GSTIN,
          registerBusinessAddress: RbusinessAddress,
          pan: Pan,
        };
      }
      console.log("request ome hereeee");
      console.log("object", obj);
      await axios.post("/settingsUpdate", obj).then((result) => {
        setLoading(false);
        if (result.data.status != 1) {
          NotificationManager.success("Something Went Wrong!");
        } else {
          setPassword("");
          NotificationManager.success(result.data.response);
          console.log('result.data.response',result.data.response)
          if (localStorage.getItem("editSupplierFrom") === "admin") {
            setRedirectTo(true);
          }
        }
      });
    } catch (error) {
      NotificationManager.error("something unusual happened");
    }
  };

  if (redirectTo) {
    return (
      localStorage.getItem("editSupplierFrom") === "admin" && (
        <Redirect to="/admin/supplier" />
      )
    );
  } else {
    return (
      <div className="mainBox">
        <br />
        <SyncLoader
          css={override}
          size={15}
          margin={6}
          color={"#3E3E3E"}
          loading={loading}
        />
        <div className={loading ? "parentDisable" : ""}>
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Card
                  title="Edit Supplier"
                  ctTableFullWidth
                  ctTableResponsive
                  content={
                    <form onSubmit={updateSettings} style={{ marginBottom: 0 }}>
                      <div
                        className="card card-input"
                        style={{ width: "100%", marginTop: 0, marginBottom: 0 }}
                      >
                        <div
                          className="col-md-6"
                          style={{ paddingLeft: "8px" }}
                        >
                          <div className="form-group">
                            <label htmlFor="product_quantity">
                              Supplier ID
                            </label>
                            <input
                              type="text"
                              value={id}
                              onChange={(e) => setId(e.target.value)}
                              min="0"
                              className="form-control"
                              id="product_id"
                              placeholder="@id"
                              disabled="disabled"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="product_email">Email</label>
                            <input
                              type="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              className="form-control"
                              id="product_email"
                              placeholder="example@any.com"
                              disabled="disabled"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="Origin">Origin</label>

                            <Select
                              options={options}
                              value={origin}
                              onChange={(e) => {
                                setOrigin(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="priceAlgoType">
                              Price Algo Type
                            </label>
                            <select
                              value={priceAlgo}
                              className="form-control"
                              id="price_algo_type"
                              onChange={(e) => {
                                setPriceAlgo(e.target.value);
                              }}
                              placeholder="Enter type"
                              required
                            >
                              <option>Select Type</option>
                              <option value="1">Qnu</option>
                              <option value="2">Jv</option>
                              <option value="3">Lyla/loomtree</option>
                              <option value="4">Sfc</option>
                              <option value="5">Cld</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="product_quantity">Password</label>
                            <input
                              type="text"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              min="0"
                              className="form-control"
                              id="supplierPassword"
                              placeholder="Enter New Password"
                            />
                            <br />
                            <div className="col-md-12">
                              <div className="form-group">
                                <label htmlFor="product_quantity">
                                  Business Description
                                </label>
                                <input
                                  type="text"
                                  value={Description}
                                  onChange={(e) =>
                                    setDescription(e.target.value)
                                  }
                                  min="0"
                                  className="form-control"
                                  id="AccountDetails"
                                  placeholder="Enter your description"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <h3 style={{ textAlign: "center" }}>
                              Brand details
                            </h3>
                            <br />
                            <div className="form-group">
                              <label>Brand Authorization </label>
                              <input
                                type="text"
                                value={bankAuth}
                                onChange={(e) => setBankAuth(e.target.value)}
                                min="0"
                                className="form-control"
                                id="supplierPassword"
                                placeholder="Enter your Address"
                              />
                              <br /> <label>Brand Logo </label>
                              <div
                                className="form-group"
                                style={{ margin: "0" }}
                              >
                                <label className="btn btn_new">
                                  Choose Images
                                  <input
                                    type="file"
                                    value={""}
                                    id="productImage"
                                    className="hidden"
                                    onChange={uploadMultipleImages1}
                                    multiple
                                    accept="image/*"
                                  />
                                </label>
                              </div>
                              {brandLogo ? (
                                <img
                                  src={
                                    "https://static-sellercentral.shopperr.in/" +
                                    brandLogo
                                  }
                                  alt="img"
                                />
                              ) : (
                                ""
                              )}
                              {/*   <input
                                type="file"
                                value={brandLogo}
                                onChange={(e) => setBrandLogo(e.target.files[0])}
                                
                                accept="image/*"
                                className="form-control"
                                id="supplierPassword"
                            
                              />
                           input
                              type="file"
                              name="photo"
                              accept="image/*"
                              onChange={(e) => setPhoto(e.target.files[0])}
                              hidden
                                />*/}
                              <br />
                              <label>Trademark certificate</label>
                              <div
                                className="form-group"
                                style={{ margin: "0" }}
                              >
                                <label className="btn btn_new">
                                  Choose Images
                                  <input
                                    value={""}
                                    type="file"
                                    id="productImage"
                                    className="hidden"
                                    onChange={uploadMultipleImages2}
                                    multiple
                                    accept="image/*"
                                  />
                                </label>
                              </div>
                              {Trademark ? (
                                <img
                                  src={
                                    "https://static-sellercentral.shopperr.in/" +
                                    Trademark
                                  }
                                  alt="img"
                                />
                              ) : (
                                ""
                              )}
                              <br />
                              <label>Image of the size chart</label>
                              <input
                                type="text"
                                value={sizeChart}
                                onChange={(e) => setsizeChart(e.target.value)}
                                min="0"
                                className="form-control"
                                id="supplierPassword"
                                placeholder="url"
                              />
                              <br />
                              <label>Images of the packaging material</label>
                              <input
                                type="text"
                                value={packageMaterial}
                                onChange={(e) =>
                                  setpackageMaterial(e.target.value)
                                }
                                min="0"
                                className="form-control"
                                id="supplierPassword"
                                placeholder=" packaging material"
                              />
                              <br />{" "}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <h3 style={{ textAlign: "center" }}>
                              Pick up Address
                            </h3>
                            <br />
                            <div className="form-group">
                              <label>Address</label>
                              <input
                                type="text"
                                value={Address}
                                onChange={(e) => setAddress(e.target.value)}
                                min="0"
                                className="form-control"
                                id="supplierPassword"
                                placeholder="Enter your Address"
                              />
                              <br /> <label>Pin code</label>
                              <input
                                type="text"
                                value={Pin}
                                onChange={(e) => setPin(e.target.value)}
                                min="0"
                                className="form-control"
                                id="supplierPassword"
                                placeholder="Pin code"
                              />
                              <br />
                              <label>pickup city</label>
                              <input
                                type="text"
                                value={pickup}
                                onChange={(e) => setPickup(e.target.value)}
                                min="0"
                                className="form-control"
                                id="supplierPassword"
                                placeholder="Your pickup city"
                              />{" "}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <h3 style={{ textAlign: "center" }}>Bank Details</h3>
                          <br />
                          <div className="form-group">
                            <label>Account Number</label>
                            <input
                              type="text"
                              value={AccountNo}
                              onChange={(e) => setAccountNo(e.target.value)}
                              min="0"
                              className="form-control"
                              id="supplierPassword"
                              placeholder="Enter your Address"
                            />
                            <br /> <label>IFSC Code</label>
                            <input
                              type="text"
                              value={IFSC}
                              onChange={(e) => setIFSC(e.target.value)}
                              min="0"
                              className="form-control"
                              id="supplierPassword"
                              placeholder="IFSC"
                            />
                            <br />
                            <label>Bank Address</label>
                            <input
                              type="text"
                              value={bankAddress}
                              onChange={(e) => setBankAddress(e.target.value)}
                              min="0"
                              className="form-control"
                              id="supplierPassword"
                              placeholder="bank address"
                            />{" "}
                            <br />
                            <label>Account Holder's Name</label>
                            <input
                              type="text"
                              value={holderName}
                              onChange={(e) => setholderName(e.target.value)}
                              min="0"
                              className="form-control"
                              id="supplierPassword"
                              placeholder="holder's name"
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <h3 style={{ textAlign: "center" }}>
                            Business Details
                          </h3>
                          <br />
                          <div className="form-group">
                            <label>Business Name</label>
                            <input
                              type="text"
                              value={businessName}
                              onChange={(e) => setbusinessName(e.target.value)}
                              min="0"
                              className="form-control"
                              id="supplierPassword"
                              placeholder="Business Name"
                            />
                            <br /> <label>TAN</label>
                            <input
                              type="text"
                              value={Tan}
                              onChange={(e) => setTan(e.target.value)}
                              min="0"
                              className="form-control"
                              id="supplierPassword"
                              placeholder="TAN"
                            />
                            <br />
                            <label>GSTIN / Provisional ID Number*</label>
                            <input
                              type="text"
                              value={GSTIN}
                              onChange={(e) => setGSTIN(e.target.value)}
                              min="0"
                              className="form-control"
                              id="supplierPassword"
                              placeholder="GSTIN"
                            />{" "}
                            <br />
                            <label>Registered business address</label>
                            <input
                              type="text"
                              value={RbusinessAddress}
                              onChange={(e) =>
                                setRbusinessAddress(e.target.value)
                              }
                              min="0"
                              className="form-control"
                              id="supplierPassword"
                              placeholder="reg. business address"
                            />
                            <br />
                            <label>PAN</label>
                            <br />
                            <input
                              type="text"
                              value={Pan}
                              onChange={(e) => setPan(e.target.value)}
                              min="0"
                              className="form-control"
                              id="supplierPassword"
                              placeholder="PAN"
                            />
                          </div>
                        </div>
                        <div className="" style={{ paddingLeft: "7px" }}>
                          <div className="pull-left">
                            <CustomButton fill type="submit" name="button">
                              {" "}
                              Update Profile{" "}
                            </CustomButton>
                          </div>
                        </div>
                      </div>
                    </form>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
};

export default SupplierOrders;
