import React, { Component } from 'react';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import isEmpty from 'is-empty'
import ReactPaginate from 'react-paginate'
import SyncLoader from "react-spinners/SyncLoader"
import _ from 'lodash'

const override = `
	position: fixed;
	display: block;
	top: 45%;
	left: 50%;
	transform: translateX(-50%);
	transform: translate(-50%, -50%);
	z-index: 1;
`
const statusArray = [
	{
		"id": 1,
		"display": "Pending",
		"value": "Pending"
	}, 
	{
		"id": 3,
		"display": "Processed",
		"value": "Processed"
	}, 
	{
		"id": 14,
		"display": "Shipped",
		"value": "Shipped"
	}
]

export default class SupplierOrders extends Component{
	constructor(props){
		super(props)
		this.state = {
			pageDataCount: 0,
			loading: true,
			leads: [],
			currentPage: 1,
			showPerPage: 50,
			pageCount: 0,
			totalCount: 0,
			query: {},
			fromDate: "",
			toDate: "",
			errors: {},
			q: "",
			selectedIds: [],
			selectedStatus: 0,
		}
	}

	componentDidMount(){
		this.getLeads(this.state.currentPage, this.state.showPerPage, this.state.query)
	}

	getLeads(page, perPage, query){
		this.setState({loading: true})
		axios.get(`/sscapi/getleads?page=${page}&limit=${perPage}&${!_.isEmpty(query) ? new URLSearchParams(query).toString() : "{}"}`)
		.then(result => {
			this.setState({leads: result.data.results, totalCount: result.data.count,
						pageCount: Math.ceil(result.data.count / this.state.showPerPage), currentPage: page,
						loading: false, query, pageDataCount: result.data.results.length});
			if(_.isEmpty(query)){
				this.setState({fromDate: "", q: "", toDate: ""})
			}
		})
	}

	handlePageClick = e => {
		this.getLeads((e.selected + 1), this.state.showPerPage, this.state.query)
	}

	triggerHanleSearch = e => {
		e.persist()
		if(e.key === 'Enter'){
			this.handleq()
		}
	}

	handleq(){
		this.setState({loading: true})
		this.getLeads(1, this.state.showPerPage, {q:this.state.q})
	}

	showDateRangeData(){
        let errors = {}
		let fromDate = this.state.fromDate
		let toDate = this.state.toDate
        if(!isEmpty(fromDate) && !isEmpty(toDate)){
            let d = new Date(fromDate)
            if(d.getFullYear() > 9999){
                errors.fromDate = "From Date must be less than 9999"
            }else if(d.getFullYear() < 1950){
                errors.fromDate = "From Date must be greater than 1950"
            }
            let d1 = new Date(toDate)
            if(d1.getFullYear() > 9999){
                errors.toDate = "To Date must be less than 9999"
            }else if(d1.getFullYear() < 1950){
                errors.toDate = "To Date must be greater than 1950"
            }
            if(isEmpty(errors)){
                const date1 = new Date(fromDate);
                const date2 = new Date(toDate);
                const diffTime = date2 - date1
                const diffDays = (diffTime / (1000 * 60 * 60 * 24))
                if(diffDays < 0)
                    errors.toDate = "End date needs to be after From date"
                else{
					let obj = {
						fromDate: fromDate,
						toDate: toDate,
					}
                    this.getLeads(1, this.state.showPerPage, obj)
                }
            }
            this.setState({errors})
        }else{
			let obj = {
				fromDate: fromDate,
				toDate: toDate,
			}
			this.getLeads(1, this.state.showPerPage,obj)
		}
    }

	handleCheckBox = e => {
		e.persist()
		if(e.target.checked){
			this.setState({selectedIds: this.state.selectedIds.concat(e.target.value)})
		}else{
			this.setState({selectedIds: this.state.selectedIds.filter(id => id !== e.target.value)})
		}
	}
	handleSelect = (e) => {
		if(e.target.value !== "Select Status")
			this.setState({ selectedStatus: e.target.value })
		else
			this.setState({ selectedStatus: "" })
	}

	render(){
		return(
			<div>
				<SyncLoader css={override} size={15} margin={6} color={"#3E3E3E"} loading={this.state.loading} />
                <div>
                    <div className="container-fluid">
                        <div className="card" style={{padding: "1em" }}>
                            <div className="row">                                
                                <div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
                                    <div className="form-group">
                                        <h6>&nbsp;</h6>
                                        <input 
                                            type="text"
                                            className="form-control"
                                            placeholder="Seacrh"
                                            onKeyPress={ this.triggerHanleSearch}
                                            onChange={(e) => {
                                                this.setState({q: e.target.value, errors: {}, fromDate: "", toDate: ""})
                                            }}
                                            id="searchOrder"
                                            value={this.state.q}
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
                                    <div className="form-group">
                                        <h6>From Date &nbsp;<span onClick={() => document.getElementById("fromDate").value = ""} className="text-primary" style={{cursor: "pointer"}}>(Clear)</span></h6>
                                        <input 
                                            type="date"
                                            className="form-control"
                                            id="fromDate"
                                            max="9999-12-01"
                                            min="1950-12-31"
                                            placeholder="From Date"
                                            value={this.state.fromDate}
                                            onChange={ (e) => this.setState({fromDate: e.target.value, q: ""}) }
                                        />
                                        {
                                            !isEmpty(this.state.errors.fromDate)
                                            &&
                                                <span className="text-danger">{this.state.error.fromDate}</span>
                                        }
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
                                    <div className="form-group">
                                        <h6>To Date &nbsp;<span onClick={() => document.getElementById("toDate").value = ""} className="text-primary" style={{cursor: "pointer"}}>(Clear)</span></h6>
                                        <input 
                                            type="date"
                                            className="form-control"
                                            id="toDate"
                                            max="9999-12-01"
                                            min="1950-12-31"
                                            placeholder="To Date"
                                            value={this.state.toDate}
                                            onChange={ (e) => this.setState({toDate: e.target.value, q: ""}) }
                                        />
                                        {
                                            !isEmpty(this.state.errors.toDate)
                                            &&
                                                <span className="text-danger">{this.state.errors.toDate}</span>
                                        }
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
                                    <h6>&nbsp;</h6>
                                    <button className="btn btn-primary" style={{ backgroundColor: 'rgb(255, 160, 0)', color: 'rgb(255, 255, 255)', fontWeight: '500',    display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 4, border: '0px' ,'fontSize': '15px' , marginTop: '2px' ,   height: '40px'}} onClick={() => this.showDateRangeData()}>Get</button>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="container-fluid">
                        <div className="card" style={{padding: "1em"}}> 
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
                                    <div style={{cursor: "pointer"}} onClick={() => this.getLeads(1, this.state.showPerPage, {})}>
                                        <div className="form-group">
                                            <h5 className="text-bold">Total Orders: <span className="text-muted">{this.state.totalCount}</span>
                                                &nbsp;<i className="fa fa-refresh" aria-hidden="true"></i>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">Sl.No</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Business Name</th> 
                                        <th scope="col">City</th>
                                        <th scope="col">Phone</th>
                                        <th scope="col">Message</th>
                                        <th scope="col">Created Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !isEmpty(this.state.leads)
                                        ?
                                            this.state.leads.map((lead, i) => {
                                    
                                                return (
                                                    <tr key={i}>
                                                        <td>{i}</td>
                                                        <td>{ lead.name }</td>
                                                        <td>{ lead.email }</td>
                                                        <td>{ lead.bname }</td> 
                                                        <td>{ lead.city }</td>
                                                        <td>{ lead.phone }</td>
                                                        <td>{ lead.Message }</td>
                                                        <td>{ new Date(lead.createdAt).toDateString() }</td>
                                                    </tr>
                                                )
                                                
                                            })
                                        :
                                            <tr>
                                                <td colSpan="7" className="text-center"><h4>No Data Found</h4></td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                            <ReactPaginate 
                                previousLabel={'<'}
                                nextLabel={'>'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={ this.state.pageCount }
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={10}
                                onPageChange={(e)=> this.handlePageClick(e)}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                            />
                        </div>
                    </div>
                </div> 			 
		    </div>
    )}
}