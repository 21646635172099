import React, { useState } from "react";
import "../assets/css/SupplierForm.css";
import axios from "axios";

import $ from "jquery";
import "bootstrap/dist/css/bootstrap.min.css";
import Swal from "sweetalert2";
import "../assets/css/merchantSignup.css";
import "../assets/supplier.js";
import { NotificationManager } from "react-notifications";
function SupplierForm(props) {
  const [name, setName] = useState("");
  const [supplierId, setSupplierId] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [email, setEmail] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [website, setWebsite] = useState("");
  const [vat, setVat] = useState("");
  const [categories, setCategories] = useState("");
  const [warehouse, setWarehouse] = useState("");
  const [ePacket, setePacket] = useState("");
  const [processingTime, setprocessingTime] = useState("");
  const [nonEpacCountry, setNonEpacCountry] = useState("");
  const [epacCountry, setEpacCountry] = useState("");
  const [fastShipping, setFastShipping] = useState("");
  const [invoice, setInvoice] = useState("");
  const [brandPackage, setBrandPackage] = useState("");
  const [minOrder, setMinOrder] = useState("");
  const [returnDetail, setReturnDetail] = useState("");
  const [aggrement, setAggrement] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [otp, setotp] = useState("");
  const [userOtp, setUserOtp] = useState("");
  const submitOtp = async (e) => {
    e.preventDefault();
    if (validateOtp()) {
      if (otp == userOtp) {
        const obj = {
          name: name,
          phoneNo: phoneNo,
          email: email,
          businessName: businessName,
          website: website,
          vat: vat,
          categories: categories,
          warehouse: warehouse,
          ePacket: ePacket,
          processingTime: processingTime,
          nonEpacCountry: nonEpacCountry,
          epacCountry: epacCountry,
          fastShipping: fastShipping,
          invoice: invoice,
          brandPackage: brandPackage,
          minOrder: minOrder,
          returnDetail: returnDetail,
          aggrement: aggrement,
          nonEpacCountry: nonEpacCountry,
          password: password,
          supplierId: supplierId,
        };
        try {
          let res = await axios.post("/api/signUpSupplier", obj);
          if (res.data.status == 1) {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Registered successsfully",
              showConfirmButton: false,
              timer: 1500,
            });
            setName("");
            setPhoneNo("");
            setEmail("");
            setBusinessName("");
            setWebsite("");
            setVat("");
            setCategories("");
            setWarehouse("");
            setePacket("");
            setprocessingTime("");
            setNonEpacCountry("");
            setEpacCountry("");
            setFastShipping("");
            setInvoice("");
            setBrandPackage("");
            setMinOrder("");
            setReturnDetail("");
            setAggrement("");
            setPassword("");
            setSupplierId("");
            props.history.push("/login-supplier");
          } else {
            NotificationManager.error(res.data);
          }
        } catch (error) {
          NotificationManager.error("Something unusual happened");
        }
      } else {
        NotificationManager.error("Invalid Otp");
      }
    }
  };
  const formSubmit = async (e) => {
    e.preventDefault();
   
      if (mainValidation()) {
        const obj = {
          name: name,
          phoneNo: phoneNo,
          email: email,
          businessName: businessName,
          website: website,
          vat: vat,
          categories: categories,
          warehouse: warehouse,
          ePacket: ePacket,
          processingTime: processingTime,
          nonEpacCountry: nonEpacCountry,
          epacCountry: epacCountry,
          fastShipping: fastShipping,
          invoice: invoice,
          brandPackage: brandPackage,
          minOrder: minOrder,
          returnDetail: returnDetail,
          aggrement: aggrement,
          nonEpacCountry: nonEpacCountry,
          password: password,
          supplierId: supplierId,
        };
        try {
          let res = await axios.post("/api/signUpSupplier", obj);
          if (res.data.status == 1) {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Registered successsfully",
              showConfirmButton: false,
              timer: 1500,
            });
            setName("");
            setPhoneNo("");
            setEmail("");
            setBusinessName("");
            setWebsite("");
            setVat("");
            setCategories("");
            setWarehouse("");
            setePacket("");
            setprocessingTime("");
            setNonEpacCountry("");
            setEpacCountry("");
            setFastShipping("");
            setInvoice("");
            setBrandPackage("");
            setMinOrder("");
            setReturnDetail("");
            setAggrement("");
            setPassword("");
            setSupplierId("");
            props.history.push("/login-supplier");
          } else {
            NotificationManager.error(res.data);
          }
        } catch (error) {
          NotificationManager.error("Something unusual happened");
        }
      }
    
  };
  const addSupplier = async (e) => {
    e.preventDefault();
    if (mainValidation()) {
      const obj = {
        supplierId: supplierId,
        email: email,
        type: "supplier",
      };
      try {
        let res = await axios.post("/api/signUpOtp", obj);
        if (res.data.status == 1) {
          setotp(res.data.Otp);
        } else {
          NotificationManager.error(res.data);
        }
      } catch (error) {
        NotificationManager.error("Something unusual happened");
      }
    }
  };

  const validateOtp = () => {
    let errors = {};
    let isValid = true;
    if (!userOtp) {
      isValid = false;
      errors["userOtp"] = "Please enter OTP.";
    }
    setErrors(errors);
    return isValid;
  };

  async function stepOneValidation() {
    if (validation1()) {
      try {
        let errors = {};
        const obj = {
          supplierId: supplierId,
          phoneNo: phoneNo,
          email: email,
        };
        let res = await axios.post("/api/verifySupplierId", obj);
        if (res.data.status != 1) {
          document.querySelector(".next-1").click();
        } else {
          return NotificationManager.error(res.data.message);
        }
      } catch (error) {
        return NotificationManager.error("Something unusual happened");
      }
    }
  }
  function stepSecondValidation() {
    if (validate2()) {
      document.querySelector(".next-2").click();
    }
  }
  function stepThirdValidation() {
    if (validate3()) {
      document.querySelector(".next-3").click();
    }
  }

  const validation1 = () => {
    let errors = {};
    let isValid = true;
    if (!(name || supplierId || email || password)) {
      return NotificationManager.error("Please Fill All the Fields");
    }

    if (email) {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        return NotificationManager.error("Please enter valid email address.");
      }
    }

    if (phoneNo && typeof phoneNo !== "undefined") {
      var pattern = new RegExp(/^[0-9\b]+$/);
      if (!pattern.test(phoneNo)) {
        return NotificationManager.error("Please enter only number.");
      }
    }
    setErrors(errors);

    return isValid;
  };

  const validate1 = () => {
    let errors = {};
    let isValid = true;
    if (!name) {
      isValid = false;
      errors["name"] = "Please enter your First Name.";
    }
    const validate1 = () => {
      let errors = {};
      let isValid = true;
      if (!name) {
        isValid = false;
        errors["name"] = "Please enter your First Name.";
      }
      if (!supplierId) {
        isValid = false;
        errors["supplierId"] = "Please enter supplier Id.";
      }
      if (!email) {
        isValid = false;
        errors["email"] = "Please enter your email Address.";
      }
      if (email) {
        var pattern = new RegExp(
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (!pattern.test(email)) {
          isValid = false;
          errors["email"] = "Please enter valid email address.";
        }
      }
      if (!password) {
        isValid = false;
        errors["password"] = "Please enter your Password.";
      }
      // if (!phoneNo) {
      //     isValid = false;
      //     errors["phoneNo"] = "Please enter your mobile number.";
      // } else
      if (phoneNo && typeof phoneNo !== "undefined") {
        var pattern = new RegExp(/^[0-9\b]+$/);
        if (!pattern.test(phoneNo)) {
          isValid = false;
          errors["phoneNo"] = "Please enter only number.";
        } else if (phoneNo.length != 10) {
          isValid = false;
          errors["phoneNo"] = "Please enter valid mobile number.";
        }
      }
      setErrors(errors);
      return isValid;
    };
    const validate2 = () => {
      let errors = {};
      let isValid = true;
      if (!businessName) {
        isValid = false;
        errors["businessName"] = "Please enter your business name.";
      }
      if (!website) {
        isValid = false;
        errors["website"] = "Please enter your website.";
      }
      if (!vat) {
        isValid = false;
        errors["vat"] = "Please enter your vat or tax.";
      }
      if (!categories) {
        isValid = false;
        errors["categories"] = "Please enter your categories.";
      }
      if (!warehouse) {
        isValid = false;
        errors["warehouse"] = "Please enter warehouse.";
      }
      setErrors(errors);
      return isValid;
    };
    if (!email) {
      isValid = false;
      errors["email"] = "Please enter your email Address.";
    }
    if (email) {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        isValid = false;
        errors["email"] = "Please enter valid email address.";
      }
    }
    if (!password) {
      isValid = false;
      errors["password"] = "Please enter your Password.";
    }
    if (!phoneNo) {
      isValid = false;
      errors["phoneNo"] = "Please enter your mobile number.";
    } else if (typeof phoneNo !== "undefined") {
      var pattern = new RegExp(/^[0-9\b]+$/);
      if (!pattern.test(phoneNo)) {
        isValid = false;
        errors["phoneNo"] = "Please enter only number.";
      } else if (phoneNo.length != 10) {
        isValid = false;
        errors["phoneNo"] = "Please enter valid mobile number.";
      }
    }
    setErrors(errors);
    return isValid;
  };


  const mainValidation = () => {
    let errors = {};
    let isValid = true;
    if (!supplierId) {
      
      return NotificationManager.error("Please enter your business name.");
    }
    if (!name) {
      
      return NotificationManager.error("Please enter your name.");
    }
    if (!email) {
      
      return NotificationManager.error("Please enter your email.");
    }
    if (!password) {
    
      return NotificationManager.error("Please enter your password.");
    }
    if (!aggrement) {
    
      return NotificationManager.error("Please tick the checkbox.");
    }
   
    return isValid;
  };
  const validate2 = () => {
    let errors = {};
    let isValid = true;
    if (!businessName) {
      isValid = false;
      errors["businessName"] = "Please enter your business name.";
    }
    if (!website) {
      isValid = false;
      errors["website"] = "Please enter your website.";
    }
    if (!vat) {
      isValid = false;
      errors["vat"] = "Please enter your vat or tax.";
    }
    if (!categories) {
      isValid = false;
      errors["categories"] = "Please enter your categories.";
    }
    if (!warehouse) {
      isValid = false;
      errors["warehouse"] = "Please enter warehouse.";
    }
    setErrors(errors);
    return isValid;
  };
  const validate3 = () => {
    let errors = {};
    let isValid = true;
    // if (!ePacket) {
    //     isValid = false;
    //     errors["ePacket"] = "Please enter E-packet.";
    // }
    if (!processingTime) {
      isValid = false;
      errors["processingTime"] = "Please enter processing time.";
    }
    // if (!nonEpacCountry) {
    //     isValid = false;
    //     errors["nonEpacCountry"] = "Please enter non e-packet country.";
    // }
    // if (!epacCountry) {
    //     isValid = false;
    //     errors["epacCountry"] = "Please enter non e-packet country.";
    // }
    if (!fastShipping) {
      isValid = false;
      errors["fastShipping"] = "Select fast shipping.";
    }
    setErrors(errors);
    return isValid;
  };
  const validate4 = () => {
    let errors = {};
    let isValid = true;
    if (!invoice) {
      isValid = false;
      errors["invoice"] = "Please enter invoice.";
    }
    if (!brandPackage) {
      isValid = false;
      errors["brandPackage"] = "Please enter brand package.";
    }
    if (!minOrder) {
      isValid = false;
      errors["minOrder"] = "Please enter min order.";
    }
    if (!returnDetail) {
      isValid = false;
      errors["returnDetail"] = "Please enter return detail.";
    }
    if (!aggrement) {
      isValid = false;
      errors["aggrement"] = "Select aggrement.";
    }
    setErrors(errors);
    return isValid;
  };

  return (
    <div className="signup-form" style={{ padding: "0 auto" }}>
      {otp ? (
        <div className="sign-up-form" style={{ margin: "0 auto" }}>
          <h1 style={{ margin: "10px 0 0 10px" }}>OTP</h1>
          <form onSubmit={(e) => submitOtp(e)}>
            <input
              type="text"
              className="input-box"
              placeholder="OTP"
              value={userOtp}
              onChange={(e) => setUserOtp(e.target.value)}
            />
            <div className="text-danger">{errors.userOtp}</div>
            <p className="text-center">
              Please check your email and enter the OTP.
            </p>
            <button
              type="submit"
              className="btn btn-fill"
              style={{
                backgroundColor: "rgb(255, 160, 0)",
                color: "rgb(255, 255, 255)",
                fontWeight: "500",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 4,
                border: "0px",
                fontSize: "15px",
              }}
            >
              SUBMIT
            </button>
          </form>
        </div>
      ) : (
        <div className="container-fluid">
          <div>
            <div>
              <div className="sign-up-form" style={{ margin: "20px auto" }}>
                <form
                  id="msform"
                  autoComplete="off"
                  onSubmit={(e) => formSubmit(e)}
                >
                 {/*<ul id="progressbar">
                    <li className="active" id="account">
                      <strong>Step 1</strong>
                    </li>
                    <li id="personal">
                      <strong>Step 2</strong>
                    </li>
                    <li id="payment">
                      <strong>Step 3</strong>
                    </li>
                    <li id="confirm">
                      <strong>Step 4</strong>
                    </li>
                  </ul>
      */}
                  <fieldset>
                    <div>
                      <div className="row">
                      
                        <h2 style={{ margin: "10px",textAlign:"center" }}>Sign Up</h2>
                       
                      {/* <div className="col-md-5">
                          <h2 className="steps">Step 1 - 4</h2>
                        </div>
    */}
                      </div>

                      
                      <div className="pos-rel">
                        {/*    <label className="fieldlabels">Full Name</label> */}
                        <input
                          type="text"
                          className="input-box"
                          placeholder="Enter Brand Name"
                          value={supplierId}
                          onChange={(e) => setSupplierId(e.target.value)}
                        />
                        <div className="text-danger pos-abs-msg">
                          {errors.supplierId}
                        </div>
                      </div> 

                      
                      <div className="pos-rel">
                        {/*    <label className="fieldlabels">Full Name</label> */}
                        <input
                          type="text"
                          className="input-box"
                          placeholder="Enter Your Name"
                          value={name}
                          autoComplete="off"
                          onChange={(e) => setName(e.target.value)}
                        />
                        <div className="text-danger pos-abs-msg">
                          {errors.name}
                        </div>
                      </div>


                      <div className="pos-rel">
                        {/*    <label className="fieldlabels">Full Name</label> */}
                        <input
                        type="email"
                        className="input-box"
                        placeholder="Enter Your Email"
                        value={email}
                        autoComplete="off"
                        onChange={(e) => setEmail(e.target.value)}
                        />
                        <div className="text-danger pos-abs-msg">
                          {errors.email}
                        </div>
                      </div>

                      
                      <div className="pos-rel">
                        {/*    <label className="fieldlabels">Full Name</label> */}
                        <input
                        type="password"
                        placeholder="Enter Your Password"
                        className="input-box"
                        value={password}
                        autoComplete="off"
                        onChange={(e) => setPassword(e.target.value)}
                        />
                        <div className="text-danger pos-abs-msg">
                          {errors.password}
                        </div>
                      </div>

                      
                      <div className="pos-rel">
                        {/*    <label className="fieldlabels">Full Name</label> */}
                        <input
                          type="text"
                          className="input-box"
                          placeholder="Enter Your Phone No.(Optional)"
                          value={phoneNo}
                          autoComplete="off"
                          onChange={(e) => setPhoneNo(e.target.value)}
                        />
                        <div className="text-danger pos-abs-msg">
                          {errors.phoneNo}
                        </div>
                      </div>
                      {/*  -------------------------------------------------------- 
                      <div className="pos-rel">
                          <label className="fieldlabels">Full Name</label> 
                        <input
                          type="text"
                          className="input-box"
                          placeholder="Enter Name"
                          value={name}
                          autoComplete="off"
                          onChange={(e) => setName(e.target.value)}
                        />
                        <div className="text-danger pos-abs-msg">
                          {errors.name}
                        </div>
                      </div>
                       --------------------------------------------------------  
                      <div className="pos-rel">
                              <label className="fieldlabels">Supplier Id</label> 
                        <input
                          type="text"
                          className="input-box"
                          placeholder="Enter Supplier Id"
                          value={supplierId}
                          autoComplete="off"
                          onChange={(e) => setSupplierId(e.target.value)}
                        />
                       
                      </div>

                      <div className="pos-rel">
                           <label className="fieldlabels">Email Address</label> 
                        <input
                          type="email"
                          className="input-box"
                          placeholder="Enter Email"
                          value={email}
                          autoComplete="off"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      
                      </div>

                      <div className="pos-rel">
                          <label className="fieldlabels">Phone Number</label> 
                        <input
                          type="text"
                          className="input-box"
                          placeholder="Enter Phone Number"
                          value={phoneNo}
                          autoComplete="off"
                          onChange={(e) => setPhoneNo(e.target.value)}
                        />
                        
                      </div>

                      <div className="pos-rel">
                        <label className="fieldlabels">Password</label> 
                        <input
                          type="password"
                          placeholder="Enter Password"
                          className="input-box"
                          value={password}
                          autoComplete="off"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                       
                      </div>
                    */}
                    </div>
                    <div className="row justify-content-center">
                    <div className="col-9 custom-control custom-checkbox pos-rel">
                      <input
                        style={{ width: "auto", marginRight: "10px" }}
                        type="checkbox"
                        value="yes"
                        onChange={(e) => setAggrement(e.target.value)}
                      />
                      <label className="custom-control-label" htmlFor="item11">
                        I agree to the terms & conditions
                      </label>
                      <div className="pos-abs-msg text-danger">
                        {errors.aggrement}
                      </div>
                    </div>
                  </div>
                  {/*
                    <input
                      type="button"
                      name="next1"
                      className="action-button"
                      onClick={function () {
                        addSupplier();
                      }}
                      value="Sign Up"
                    />
                    <input
                      type="button"
                      name="next"
                      className="next action-button next-1"
                      value="Next"
                      style={{ display: "none" }}
                    />
                    */}
                    <button
                    type="submit"
                    className="btn btn-fill"
                   
                    style={{
                      backgroundColor: "rgb(255, 160, 0)",
                      color: "rgb(255, 255, 255)",
                      fontWeight: "500",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 4,
                      border: "0px",
                      fontSize: "15px",
                    }}
                  >
                    SIGN UP
                  </button>
                  <p style={{ marginTop: "5px" }}>
                  Do you have an account ?{" "}
                  <a href="login-supplier" style={{ color: "#ffa000" }}>
                    Sign In
                  </a>
                </p>
                  </fieldset>
                  <fieldset>
                    <div>
                      <div className="row">
                        <div className="col-md-7">
                          <h2 className="fs-title">Company Details:</h2>
                        </div>
                        <div className="col-md-5">
                          <h2 className="steps">Step 2 - 4</h2>
                        </div>
                      </div>

                      <div className="sign-up-form-div ">
                        {/*    <label className="fieldlabels">Business Name</label> */}
                        <input
                          type="text"
                          placeholder="Enter Business Name"
                          value={businessName}
                          onChange={(e) => setBusinessName(e.target.value)}
                        />
                        <input
                          type="text"
                          placeholder="Enter Website"
                          value={website}
                          onChange={(e) => setWebsite(e.target.value)}
                        />
                        {/*}  <div className="text-danger pos-abs-msg">
                          {errors.businessName}
                    </div> */}
                      </div>

                      <div className="pos-rel">
                        {/*    <label className="fieldlabels">VAT or Tax ID</label> */}
                        <input
                          type="text"
                          placeholder="Enter VAT or Tax ID"
                          value={vat}
                          onChange={(e) => setVat(e.target.value)}
                        />
                        <div className="text-danger pos-abs-msg">
                          {errors.vat}
                        </div>
                      </div>

                      <div className="pos-rel">
                        <label className="fieldlabels">
                          What and all categories of product you are dealing
                          with?
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Categories"
                          value={categories}
                          onChange={(e) => setCategories(e.target.value)}
                        />
                        <div className="text-danger pos-abs-msg">
                          {errors.categories}
                        </div>
                      </div>

                      <div className="pos-rel">
                        <label className="fieldlabels">
                          Please tell us your different warehouse locations
                          around the world
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Warehouse Locations"
                          value={warehouse}
                          onChange={(e) => setWarehouse(e.target.value)}
                        />
                        <div className="text-danger pos-abs-msg">
                          {errors.warehouse}
                        </div>
                      </div>
                    </div>
                    <input
                      type="button"
                      name="next2"
                      className="action-button"
                      onClick={function (e) {
                        stepSecondValidation();
                      }}
                      value="Next"
                    />
                    <input
                      type="button"
                      style={{ display: "none" }}
                      name="next"
                      className="next action-button next-2"
                      value="Next"
                    />
                    <input
                      type="button"
                      name="previous"
                      className="previous action-button-previous"
                      value="Previous"
                    />
                  </fieldset>
                  <fieldset>
                    <div>
                      <div className="row">
                        <div className="col-md-7">
                          <h2 className="fs-title">Shipping Details:</h2>
                        </div>
                        <div className="col-md-5">
                          <h2 className="steps">Step 3 - 4</h2>
                        </div>
                      </div>

                      <div className="pos-rel">
                        <label className="fieldlabels">
                          Do you use e-packet shipping? (Yes/No)
                        </label>
                        <input
                          type="text"
                          placeholder="Yes or No"
                          value={ePacket}
                          onChange={(e) => setePacket(e.target.value)}
                        />
                        <div className="text-danger pos-abs-msg">
                          {errors.ePacket}
                        </div>
                      </div>

                      <div className="pos-rel">
                        <label className="fieldlabels">
                          Please tell us the approximate processing time of the
                          order. (We are only looking for suppliers who can
                          agree to process the order in 1-3 days)
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Processing Time"
                          value={processingTime}
                          onChange={(e) => setprocessingTime(e.target.value)}
                        />
                        <div className="text-danger pos-abs-msg">
                          {errors.processingTime}
                        </div>
                      </div>

                      <div className="pos-rel">
                        <label className="fieldlabels">
                          Approximate number of days for delivery to e-packet
                          countries- Non e-packet countries-
                        </label>
                        <input
                          type="text"
                          placeholder="Enter delivery time to e-packet countries"
                          value={epacCountry}
                          onChange={(e) => setEpacCountry(e.target.value)}
                        />
                        <div className="text-danger pos-abs-msg">
                          {errors.epacCountry}
                        </div>
                      </div>

                      <div className="pos-rel">
                        <input
                          type="text"
                          placeholder="Enter delivery time to non-epacket countries"
                          value={nonEpacCountry}
                          onChange={(e) => setNonEpacCountry(e.target.value)}
                        />
                        <div className="text-danger pos-abs-msg">
                          {errors.nonEpacCountry}
                        </div>
                      </div>

                      <div className="pos-rel">
                        <label className="fieldlabels">
                          Do you provide fastest shipping or express shipping to
                          US? Please tell us the shipping courier name, shipping
                          charges and approximate days of delivery.
                        </label>
                        <div className="input-group">
                          <div
                            className="d-inline-block custom-control custom-radio mr-1"
                            style={{ display: "inline-block", width: "100px" }}
                          >
                            <input
                              style={{
                                float: "left",
                                width: "auto",
                                marginRight: "10px",
                              }}
                              type="radio"
                              className="custom-control-input"
                              value="yes"
                              id="yes"
                              name="yes"
                              onChange={(e) => setFastShipping(e.target.value)}
                            />
                            <label className="custom-control-label" htmlFor="yes">
                              Yes
                            </label>
                          </div>
                          <div
                            className="d-inline-block custom-control custom-radio"
                            style={{ display: "inline-block", width: "100px" }}
                          >
                            <input
                              style={{
                                float: "left",
                                width: "auto",
                                marginRight: "10px",
                              }}
                              type="radio"
                              className="custom-control-input"
                              value="no"
                              name="yes"
                              id="no"
                              onChange={(e) => setFastShipping(e.target.value)}
                            />
                            <label className="custom-control-label" htmlFor="no">
                              No
                            </label>
                          </div>
                          <div className="text-danger pos-abs-msg">
                            {errors.fastShipping}
                          </div>
                        </div>
                      </div>
                    </div>
                    <input
                      type="button"
                      name="next"
                      className="action-button"
                      onClick={function (e) {
                        stepThirdValidation();
                      }}
                      value="Next"
                    />
                    <input
                      type="button"
                      name="next"
                      className="next action-button next-3"
                      value="Next"
                      style={{ display: "none" }}
                    />
                    <input
                      type="button"
                      name="previous"
                      className="previous action-button-previous"
                      value="Previous"
                    />
                  </fieldset>
                  <fieldset>
                    <div className="form-card">
                      <div className="row">
                        <div className="col-md-8">
                          <h2 className="fs-title">Branding and Returns:</h2>
                        </div>
                        <div className="col-md-4">
                          <h2 className="steps">Step 4 - 4</h2>
                        </div>
                      </div>

                      <div className="pos-rel">
                        <label className="fieldlabels">
                          Will you be able to provide branded invoicing?
                          (Yes/No)
                        </label>
                        <input
                          type="text"
                          placeholder="Yes or No"
                          value={invoice}
                          onChange={(e) => setInvoice(e.target.value)}
                        />
                        <div className="text-danger pos-abs-msg">
                          {errors.invoice}
                        </div>
                      </div>

                      <div className="pos-rel">
                        <label className="fieldlabels">
                          Will you be able to branded packaging? (Yes/No)
                        </label>
                        <input
                          type="text"
                          placeholder="Yes or No"
                          value={brandPackage}
                          onChange={(e) => setBrandPackage(e.target.value)}
                        />
                        <div className="text-danger pos-abs-msg">
                          {errors.brandPackage}
                        </div>
                      </div>

                      <div className="pos-rel">
                        <label className="fieldlabels">
                          What is the minimum number of orders you require for
                          branded packaging?
                        </label>
                        <input
                          type="text"
                          placeholder="Enter minimum orders"
                          value={minOrder}
                          onChange={(e) => setMinOrder(e.target.value)}
                        />
                        <div className="text-danger pos-abs-msg">
                          {errors.minOrder}
                        </div>
                      </div>

                      <div className="pos-rel">
                        <label className="fieldlabels">
                          If we have any return in case of damage or any other
                          reason. Will you buy back or replace it and let us
                          know your return management.
                        </label>
                        <input
                          type="text"
                          placeholder="Enter return management details"
                          value={returnDetail}
                          onChange={(e) => setReturnDetail(e.target.value)}
                        />
                        <div className="text-danger pos-abs-msg">
                          {errors.returnDetail}
                        </div>
                      </div>

                      <div className="row justify-content-center">
                        <div className="col-9">
                          Please read our Supplier Aggrement from{" "}
                          <a style={{ color: "rgb(255, 160, 0)" }} href="#">
                            here
                          </a>
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-9 custom-control custom-checkbox pos-rel">
                          <input
                            style={{ width: "auto", marginRight: "10px" }}
                            type="checkbox"
                            value="yes"
                            onChange={(e) => setAggrement(e.target.value)}
                          />
                          <label className="custom-control-label" htmlFor="item11">
                            I agree to the terms & conditions
                          </label>
                          <div className="pos-abs-msg text-danger">
                            {errors.aggrement}
                          </div>
                        </div>
                      </div>
                    </div>
                    <input
                      type="submit"
                      name="next"
                      className="action-button"
                      value="Submit"
                    />
                    <input
                      type="button"
                      name="previous"
                      className="previous action-button-previous"
                      value="Previous"
                    />
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

$(document).ready(function () {
  var current_fs, next_fs, previous_fs; //fieldsets
  var opacity;
  var current = 1;
  var steps = $("fieldset").length;
  setProgressBar(current);
  $(".next").click(function () {
    current_fs = $(this).parent();
    next_fs = $(this).parent().next();
    //Add Class Active
    $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");
    //show the next fieldset
    next_fs.show();
    //hide the current fieldset with style
    current_fs.animate(
      {
        opacity: 0,
      },
      {
        step: function (now) {
          // for making fielset appear animation
          opacity = 1 - now;
          current_fs.css({
            display: "none",
            position: "relative",
          });
          next_fs.css({
            opacity: opacity,
          });
        },
        duration: 500,
      }
    );
    setProgressBar(++current);
  });
  $(".previous").click(function () {
    current_fs = $(this).parent();
    previous_fs = $(this).parent().prev();
    //Remove class active
    $("#progressbar li")
      .eq($("fieldset").index(current_fs))
      .removeClass("active");
    //show the previous fieldset
    previous_fs.show();
    //hide the current fieldset with style
    current_fs.animate(
      {
        opacity: 0,
      },
      {
        step: function (now) {
          // for making fielset appear animation
          opacity = 1 - now;
          current_fs.css({
            display: "none",
            position: "relative",
          });
          previous_fs.css({
            opacity: opacity,
          });
        },
        duration: 500,
      }
    );
    setProgressBar(--current);
  });

  function setProgressBar(curStep) {
    var percent = parseFloat(100 / steps) * curStep;
    percent = percent.toFixed();
    $(".progress-bar").css("width", percent + "%");
  }
  $(".submit").click(function () {
    return false;
  });
});

export default SupplierForm;
