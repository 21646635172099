import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import '../assets/css/landingPage.css'
export default class LandingPage extends Component{
    constructor(){
        super()
        this.state = {

        }
    }

    render(){
        return(
            <div className="LandingPage">
                <div className="header1">
                    <nav className="navbar navbar-expand-md navbar-light bg-light p-lg-3 p-md-3 fixed-top">
                        <div className="d-flex flex-grow-1">
                            <Link className="navbar-brand" to="/">SHOPPERR</Link>
                        </div>
                        <button className="navbar-toggler order-0" type="button" data-toggle="collapse" data-target="#navbar7">
                            <i className="fa fa-bars" aria-hidden="true"></i>
                        </button>
                        <div className="navbar-collapse collapse flex-shrink-0 flex-grow-0 order-last" id="navbar7">
                            <ul className="navbar-nav">
                                <li className="nav-item mr-2 dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Home
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <Link className="nav-link" to="/mobileAndTablets">I Phone</Link>
                                            <Link className="nav-link" to="/mobileAndTablets">Redmi</Link>
                                            <Link className="nav-link" to="/mobileAndTablets">One Plus</Link>
                                            <Link className="nav-link" to="/mobileAndTablets">LG</Link>
                                            <Link className="nav-link" to="/mobileAndTablets">Oppo</Link>
                                            <Link className="nav-link" to="/mobileAndTablets">Micromax</Link>
                                            <Link className="nav-link" to="/mobileAndTablets">Nokia</Link>
                                            <Link className="nav-link" to="/mobileAndTablets">Moto</Link>
                                        </div>
                                </li>
                                <li className="nav-item mr-2">
                                    <Link className="nav-link" to="/mobileAccesories">Products</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/services">Services</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/contactUs">Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        )
    }
}