import Dashboard from "./views/logisticUserDashborad";
import OrderList from "./views/logisticUserOrders"
import InventoryDetails from "./views/LogisticsInventory.js"
import SkuDetails from "./views/InventoryDetails.js"
import PrintLabel from "./views/LogisticPrintLabel"
import Manifest from "./views/LogisticManifest"
import RtoOrders from "./views/RtoOrders.js"
import Packaging from "./views/LogisticPackaging"

const dashboardRoutes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: "pe-7s-graph",
        component: Dashboard,
        layout: "/logisticUser",
    },
    {
        path: "/orders",
        name: "Orders",
        icon: "pe-7s-note",
        component: OrderList,
        layout: "/logisticUser",
    },
    {
        path: "/inventory",
        name: "Inventory",
        icon: "pe-7s-plus",
        component: InventoryDetails,
        layout: "/logisticUser",
    },
    {
        path: "/print-label",
        name: "Print Label",
        icon: "pe-7s-keypad",
        component: PrintLabel,
        layout: "/logisticUser",
    },
    {
        path: "/amnifest",
        name: "Manifest",
        icon: "pe-7s-note2",
        component: Manifest,
    },
    {
        path: "/rtoOrders",
        name: "Return to Origin Orders",
        icon: "pe-7s-shopbag",
        component: RtoOrders,
        layout: "/logisticUser",
    },
    {
        path: "/packaging",
        name: "Packaging",
        icon: "pe-7s-id",
        component: Packaging,
        layout: "/logisticUser",
    }
]
export const nonDashBoardLinks = [
    {
        path: "/skuDetails",
        name: "Inventory",
        icon: "pe-7s-id",
        component: SkuDetails,
        layout: "/logisticUser",
    }

]
export default dashboardRoutes;

