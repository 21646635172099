import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { NotificationManager } from 'react-notifications'
import '../../assets/css/warehouse.css'
// import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import _ from 'lodash'
import Select from 'react-select'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import SelectCountries from '../../assets/SelectCountries.js'
var countryData = require('../../assets/Countries.json');
const AddWarehouse = () => {

	const [wareName, setWarename] = useState('')
	const [wareAddress, setWareAddress] = useState('')
	const [wareCity, setWareCity] = useState('')
	const [wareState, setwareState] = useState('')
	const [wareCountry, setWareCountry] = useState('')
	const [wareStatus, setWareStatus] = useState('')
	const [warePriority, setWarePriority] = useState(0)
	const [wareMobile, setWareMobile] = useState('')
	const [warePhone, setWarePhone] = useState('')
	const [areaCode, setAreaCode] = useState('')
	const [wareCode, setWareCode] = useState('')
	const [warePincode, setWarePincode] = useState([])
	const [errors, setErrors] = useState({})
	const [pincodeFlag, setPincodeFlag] = useState(0) 
	const addWare = (e) => {
		e.preventDefault()
		let error = {}
		// console.log(error)
		let codeExp = /((^[0-9]+[a-z]+)|(^[a-z]+[0-9]+))+[0-9a-z]+$/i
		let phoneExp = /^\d{3}([ -]\d\d|\d[ -]\d|\d\d[ -])\d{6}$/
		let completePhone = areaCode + '-' + warePhone

		if (!wareCode.match(codeExp)) {
			error.code = 'Warehouse code must contain 2 numbers and 2 alphabets.'
		}
		if (!completePhone.match(phoneExp)) {
			console.log(completePhone)
			error.phone = 'Not a valid landline number'
		}
		if (!isPossiblePhoneNumber(wareMobile)) {
			error.mobile = 'Mobile number is incorrect'
		}
		setErrors(error)
		if (Object.keys(error).length === 0) {
			const scvdata = new FormData()
			scvdata.append('name', wareName)
			scvdata.append('address', wareAddress)
			scvdata.append('city', wareCity)
			scvdata.append('state', wareState)
			scvdata.append('country', wareCountry)
			scvdata.append('status', wareStatus)
			scvdata.append('priority', warePriority)
			scvdata.append('mobile', wareMobile)
			scvdata.append('phone', completePhone)
			scvdata.append('code', wareCode)
			scvdata.append('pincode', warePincode[0])
			scvdata.append('pincodeflag', pincodeFlag)
			// console.log(completePhone)
			axios
				.post('/api/addwarehouse', scvdata)
				.then((data) => {
					if (data.data.errors) {
						console.log(data.data)
						// alert('Warehouse code already present.')
						NotificationManager.error('Warehouse code already present.', 'Code Exists!');

					} else {
						console.log(data.data)
						setErrors({})
						// alert('Warehouse added')
						NotificationManager.success('Warehouse added.', 'Succesfully Added');
					}


					// console.log(data.data)
					// 	setErrors({})

				})
				.catch((err) => {
					console.log(err.message)
					NotificationManager.error(err.message)
				})
		} else {
			NotificationManager.error('something went wrong', 'sorry!');
			console.log('errors no axios call', error)
		}
		 
	}
	return (
		<div className='addware-wrapper' style={{ margin: '20px', background: 'white', padding: '5px 25px' }}>
 			<div className=' '> 
				<form onSubmit={addWare}>
					<div id='nameandaddress'>
						<div className='fullName form-group' style={{ margin: '0px', padding: '10px 0px' }}>
							<label>Warehouse Name**</label>
							<input
								className='form-control'
								placeholder='Enter warehouse name'
								required
								type='text'
								name='Name'
								value={wareName}
								onChange={(e) => setWarename(e.target.value)}
							/>
						</div>
						<div className='fullName form-group' style={{ margin: '0px',   padding: '10px 0px 10px 10px' }}>
							<label>Warehouse Address</label>
							<input
								// required
								className='form-control'
								placeholder='Enter warehouse address'
								type='text'
								name='address'
								value={wareAddress}
								onChange={(e) => setWareAddress(e.target.value)}
							/>
						</div>
					</div>


					<div className='fullName form-group' style={{ margin: '0px', padding: '10px 0px' }}>
						<SelectCountries countrySetter={setWareCountry} stateSetter={setwareState} citySetter={setWareCity} />
					</div> 
					<div id='nameandaddress'>
						<div className='fullName form-group' style={{ margin: '0px', padding: '10px 0px' }}>
							<label>Priority - {warePriority}</label>
							<input
								className='form-control'
								type='range'
								min='0'
								max='99'
								value={warePriority}
								onChange={(e) => {
									setWarePriority(e.target.value)
								}}
							/>
						</div>
						<div className='fullName'>
							<table>
								<tbody>
									<tr>
										<td><label>Status :  &nbsp;&nbsp;&nbsp;&nbsp;</label></td>
										<td>
											<div>
												<input
													type='radio'
													value='1'
													name='status'
													onChange={(e) => setWareStatus(e.target.value)}
												/>
									&nbsp;&nbsp;
									<label>Active</label>
									 &nbsp;
									<input
													type='radio'
													value='0'
													name='status'
													onChange={(e) => setWareStatus(e.target.value)}
												/>
									&nbsp;&nbsp;
									<label>Inactive</label>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>


					<div id='nameandaddress'>
						<div className='fullName form-group' style={{ margin: '0px', padding: '10px 0px' }}>
							<label>Warehouse Mobile*</label>
							<PhoneInput
								placeholder='Enter mobile number'
								// className='input-box'
								value={wareMobile}
								onChange={setWareMobile}
								required
							/>
							<label>
								<p style={{ color: 'red' }}>{errors.mobile ? errors.mobile : ''}</p>
							</label>
						</div>
						<div className='fullName' style={{ margin: '0px', padding: '10px 0px' }}>
							<label>Warehouse Phone</label>
							<div id='nameandaddress'>
								<div className='form-group'>
									<input
										className='form-control'
										type='number'
										name='areacode'
										placeholder='Enter area code'
										maxLength='8'
										value={areaCode}
										onChange={(e) => setAreaCode(e.target.value)}
									/>
								</div>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								<div >
									<input
										className='form-control'
										type='number'
										name='phone'
										placeholder='Enter landline number'
										maxLength='4'
										value={warePhone}
										onChange={(e) => setWarePhone(e.target.value)}
									/>
								</div>
							</div>
							<label><p style={{ color: 'red' }}>{errors.phone ? errors.phone : ''}</p></label>
						</div>
					</div>

					<div id='nameandaddress'>
						<div className='fullName form-group' style={{ margin: '0px', padding: '10px 0px' }}>
							<label>Warehouse Code ** [It is non editable.] </label> 
							<label>[Note: Code must contain atleast two number and letters.]</label>
							<input
								className='form-control'
								placeholder='warehouse code Ex:jk13'
								type='text'
								name='code'
								required
								value={wareCode}
								onChange={(e) => setWareCode(e.target.value)}
							/> 
							<label><p style={{ color: 'red' }}>{errors.code ? errors.code : ''}</p></label>
						</div> 
						<div className='fullName form-group' style={{ margin: '0px', padding: '10px 0px' }}>
							<label>Upload Zipcode File</label>
							<br />
							<input
								type='file'
								className='form-control text-center'
								name='avatar'
								onChange={(e) => {
									setWarePincode(e.target.files)
									setPincodeFlag(1)
								}}
								encType='multipart/form-data'
								accept='.csv'
							/>
						</div>
					</div>
					<br /> 
					<div id='button'>
						<div className='submit form-group'>
							<button className='btn btn-fill' style={{ background: "rgb(255, 160, 0)"  ,  borderColor: "rgb(255, 160, 0)" , fontSize: "15px" , fontWeight: "500"}}>Create</button>
						</div>
					</div>
				</form>
			</div>

		</div >
	)
}
export default AddWarehouse
