import React, {useState, useEffect} from 'react'
import axios from 'axios'

function LogisticUserDashborad() {
    return (
        <div>
            <h2>Welcomne to Logistic Dashboard</h2>
        </div>
    )
}

export default LogisticUserDashborad