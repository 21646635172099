 import React, {useState, useEffect} from 'react'
import axios from 'axios'

function Packaging() {
    const [offshelf, setoffshelf] = useState('')
    const [sku, setSku] = useState('')
    const [data, setData] = useState({})
    const [label, setLabel] = useState('')
    
    useEffect(() => { 
        setLabel('');  
    },[]) 

    const handleSubmit = () => { 
        if (offshelf) {
        	if(sku){
        		let data = {
        			'offshelf' : offshelf,
        			'sku' : sku
        		}
        		axios.post(`/sscapi/packaging` , data)
	            .then(res => {
	                console.log(res.data)
	                if (res.data) {
	                	if(res.data.error == 0){
	                		setLabel(res.data.Response);  
	                		window.open(res.data.Response.label)
	                	}else if(res.data.error == 1){
	                		alert(res.data.Response);

	                	}  
	                } else {
	                    console.log("Unable to get data")
	                }
	            })
	            .catch(err => {
	                console.log("Error ",err)
	            })
        	}else{
        		alert("Enter Sku")
        	} 
        } else {
            alert("Enter Offshelf.")
        }
        
    } 

    return (
    	<div>
	        <div className="container-fluid">
	            <div className="card" style={{padding: "1em"}}>
	                <div className="row">
	                    <div className="col-md-4">
	                        <input type="text" className="form-control" placeholder="Offshelf Number" aria-label="Offshelf Number" aria-describedby="basic-addon2" autoFocus onChange={(e)=> setoffshelf(e.target.value)}  />
	                    </div>
	                    <div className="col-md-4">
	                        <input type="text" className="form-control" placeholder="Sku" aria-label="Sku" aria-describedby="basic-addon2" onChange={(e)=> setSku(e.target.value)}  />
	                    </div>
	                    <div className="col-md-2">
	                        <button className="btn btn-primary" onClick={handleSubmit}><span className="glyphicon glyphicon-circle-arrow-right "> Get </span></button>
	                    </div>
	                </div>
	            </div>   
	        </div> 
	        <div className="container-fluid">
				<div className="card" style={{padding: "1em"}}> 
					<table className="table table-hover"> 
						<tbody>    
								{ label != '' ?
								<tr>
									<td>Label : </td>
									<td colSpan="2" className="text-center">{ label.label }</td> 
								</tr> 
									: <div className="text-center">No data </div>
								}
								
						</tbody>
					</table>  
				</div>
			</div> 
		</div>
    )
}

export default Packaging