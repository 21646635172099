import React, { Component } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import axios from 'axios'
import '../../assets/css/addProductCatalog.css'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import 'font-awesome/css/font-awesome.min.css'
import _ from 'lodash'
import CustomButton from '../../components/CustomButton/CustomButton'
import jwt_decode from 'jwt-decode'
import { NotificationManager } from 'react-notifications'
import SyncLoader from "react-spinners/SyncLoader"
import $ from 'jquery'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

const override = `
	position: fixed;
	display: block; 
	top: 45%;
	left: 50%;
	transform: translateX(-50%);
	transform: translate(-50%, -50%);
	z-index: 9999;
`
const imageExtensions = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/bmp']

export default class AddOnProductCatalog extends Component{
    constructor(){
        super()
        this.state = {
            productTitleCharLength: 0,
            productTitleCharMaxLength: 160,
            productTitle : "",
            productSubTitleCharLength: 0,
            productSubTitleCharMaxLength: 160,
            productSubTitle: "",
            invoiceDescriptionCharLength: 0,
            invoiceDescriptionCharMaxLength: 100,
            invoiceDescription: "",
            detailDescription: "",
            marketPlaceUrl: "",
            marketPlacePrice: "",
            marketPlaceMrp: "",
            tags: [],
            imageArray: [],
            isVarien: false,
            varientArray: {},
            varientArrayCombo: {},
            idOrSku: "",
            factoryPrice: "",
            mrp: "",
            quantity: "",
            cbm:"",
            categoryList: [],
            category: "Select Category",
            margin: 0,
            hsn: "",
            weight: 0.0,
            weightLabel: "g",
            warranty: 0,
            height: 0.0,
            width: 0.0,
            length: 0.0,
            bcd: "",
            igst: "",
            swc: "",
            shippingMode: "",
            usaShippingPrice: 0,
            canadaShippingPrice: 0,
            ausShippingPrice: 0,
            ukShippingPrice: 0,
            interShippingPrice: 0,
            pdStatus: "0",
            isDisplay: "0",
            errors: {},
            loading: false,
            supplierList: [],
            selectedSupplier: "Select Supplier",
            priority: "",
            subSupplierList: [],
            editMode: false,
            selectedSupplierId: "",
            selectedSupplierIndex: "",
            slabOne: 0,
            slabTwo: 0,
            slabThree: 0,
            slabFour: 0,
            deliveryDaysTo: 0,
            deliveryDaysFrom: 0,
            isBattery:"0",
            isLiquid:"0",
            numberOfItems: "",
            packageIncludes: "",
            yearOfManufacturer:"",
            productExpiry:"",
            browseNode:"",
            bulletpoints:[],
            categoryId:"",
            customfields:[]
        }
        this.state.open = false;
        this.state.options = [];
        this.state.loading = this.state.open && this.state.options.length === 0
        this.uploadMultipleImages = this.uploadMultipleImages.bind(this)
        this.nodesData = {};
    }

    changeCatData(e){
      let text = e.target.value;
      if(this.nodesData && this.nodesData[text]){
        this.setState({options:this.nodesData[text]});
        return true;
      }
      axios.get('/api/getBrowseNode?text='+text)
      .then((result)=>{
        this.nodesData[text] = result.data.Response.nodes;
        this.setState({options:this.nodesData[text]});
      });
    }

    onBrowseNodeSelect(e,option){
      this.setState({browseNode:option.node});
    }
    componentDidMount(){
        this.getCategoryList()
        this.getSupplierList()
        document.getElementById("showOnStandardShipping").hidden = true
        // document.getElementById("pdStatus1").click()
        // document.getElementById("isDisplay1").click()
        this.addBulletField();
        this.addCustomFields();
    }

    getCategoryList(){
        axios.get('/api/totalCategory')
        .then((data) => {
            this.setState({categoryList: data.data})
        })
        .catch(err => {
            console.log(err)
            NotificationManager.error("Failed to get category list")
        })
    }

    getSupplierList(){
        axios.get('/api/totalSupplier')
        .then((data) => {
            this.setState({supplierList: data.data})
        })
        .catch(err => {
            console.log(err)
            NotificationManager.error("Failed to get supplier list")
        })
    }

    handleProductTitleInput = e => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                productTitle: ""
            },
            productTitle: e.target.value,
            productTitleCharLength: e.target.value.length
        }))
    }

    handleProductSubTitle = e => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                productSubTitle: ""
            },
            productSubTitle: e.target.value,
            productSubTitleCharLength: e.target.value.length
        }))
    }

    handleInvoiceDescription = e => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                invoiceDescription: ""
            },
            invoiceDescription: e.target.value,
            invoiceDescriptionCharLength: e.target.value.length
        }))
    }

    handleReactQuillInput = (e) => {
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                detailDescription: ""
            },
            detailDescription: e
        }))
    }

    handleMarketPlaceUrl = e => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                marketPlaceUrl: ""
            },
            marketPlaceUrl: e.target.value
        }))
    }

    handleMarketPlacePrice = e => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                marketPlacePrice: ""
            },
            marketPlacePrice: e.target.value
        }))
    }

    handleMarketPlaceMrp = e => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                marketPlaceMrp: ""
            },
            marketPlaceMrp: e.target.value
        }))
    }

    uploadMultipleImages = (e) => {
        const readImage  = file => {
            if(imageExtensions.includes(file.type)){
                const reader = new FileReader();
                reader.addEventListener('load', (e1) => {
                    this.setState({ imageArray: this.state.imageArray.concat(e1.target.result)})
                })
                reader.readAsDataURL(file);
            }else{
                NotificationManager.error(`File not supported, Supported files are jpeg, png, webp, bmp, gif.`)
            }
        }

        const files = e.target.files;
        if (!files || !files[0]) return alert('File upload not supported');
        [...files].forEach( readImage )
    }

    handleDeleteImage = index => {
        this.setState({imageArray : this.state.imageArray.filter((img , i) => { return i !== index })})
    }

    handleIsVarien = e => {
        e.persist()
        if(e.target.checked) this.setState({isVarien: true, varientArray: [], varientArrayCombo: [], idOrSku: "", factoryPrice: "",mrp: "",quantity: "", weight:"0",height: "0",weightLabel:"g",length: "0",width: "0",errors: {}})
        else this.setState({isVarien: false, varientArray: [], varientArrayCombo: [], idOrSku: "", factoryPrice: "",mrp: "",quantity: "0", weight:"0",height: "0",weightLabel:"g",length: "0",width: "0", errors: {}})
    }

    handleAddVarient(e){
        let obj = {
            "name": "",
            "values": []
        }
        
        this.setState({varientArray: [...this.state.varientArray.concat(obj)], errors: {}}, () => {
            let onlyVarientsArray = this.state.varientArray.map(varient => {
                return varient.values
            })
            !_.isEmpty(onlyVarientsArray) &&  this.makeArraysCombo(onlyVarientsArray)
        })
    }

    handleVarientOption(e,index){
        this.setState({
            varientArray: [
                ...this.state.varientArray.slice(0,index),
                Object.assign({}, this.state.varientArray[index], {name: e.target.value}),
                ...this.state.varientArray.slice(index + 1)
            ],
            errors: {}
        })
    }

    handleRemoveVarient(e, index){
        e.preventDefault()
        this.setState({varientArray : this.state.varientArray.filter((varient , i) => { return i !== index })} , () => {
            let onlyVarientsArray = this.state.varientArray.map(varient => {
                return varient.values
            })
            !_.isEmpty(onlyVarientsArray) ? this.makeArraysCombo(onlyVarientsArray) : this.setState({varientArrayCombo: []})
        })
    }

    handleVarientTag(tag, index){
        this.setState({
            varientArray: [
                ...this.state.varientArray.slice(0,index),
                Object.assign({}, this.state.varientArray[index], {values: tag}),
                ...this.state.varientArray.slice(index + 1)
            ]
        }, () => {
            let onlyVarientsArray = this.state.varientArray.map(varient => {
                return varient.values
            })
            !_.isEmpty(onlyVarientsArray) && this.makeArraysCombo(onlyVarientsArray)
        })
    }

    makeArraysCombo = args => {
      args = args.filter((item)=> {
        return (!item || (item && item.length))
      });
      if(!args || (args && !args.length)){
        return false;
      }
      var r = [], max = args.length-1;
      function helper(arr, i) {
          for (var j=0, l=args[i].length; j<l; j++) {
              var a = arr.slice(0); // clone arr
              a.push(args[i][j]);
              if (i==max)
                  r.push(a);
              else
                  helper(a, i+1);
          }
      }
      helper([], 0);
      this.concatWithSlash(r)
    }

    concatWithSlash = arrCombo => {
        let tempArr = []
        arrCombo.map(singleArray => {
            let varient = singleArray.map(e => e).join("/")
            let obj = {
                "varient": varient.toString(),
                "price": "0",
                "mrp": "0",
                "quantity": "0",
                "sku": "",
                "selliingPrice": "0",
                "height": "0",
                "width": "0",
                "length": "0",
                "weight": "0",
                "weightLabel": "g",
                "cbm": "0",
                "imagesArray": []
            }
            tempArr.push(obj)
        })
        this.setState({varientArrayCombo: tempArr})
    }

    handleVarientMultipleImages(e, index){
        let temp1 = []
        const images = e.target.files
        const pushImage = file => {
            if(imageExtensions.includes(file.type)){
                const reader = new FileReader();
                reader.addEventListener('load', (e1) => {
                    temp1.push(reader.result)
                })
                reader.readAsDataURL(file);
            }else{
                NotificationManager.error(`File not supported, Supported files are jpeg,png,webp,bmp,gif.`)
            }
        }
        [...images].forEach( pushImage )

        setTimeout(() => {
            let selectedImageArrayObj = this.state.varientArrayCombo.filter((obj, i) => { return i === index })
            let previousImages = selectedImageArrayObj[0].imagesArray

            this.setState({
                varientArrayCombo: [
                    ...this.state.varientArrayCombo.slice(0,index),
                    Object.assign({}, this.state.varientArrayCombo[index], {imagesArray: [...previousImages.concat(temp1)]}),
                    ...this.state.varientArrayCombo.slice(index + 1)
                ]
            }) 
        }, 1500)
    }

    handleVarientImageDelete(objId, imageId){
        let obj = this.state.varientArrayCombo.filter((obj, i) => { return i === objId })
        let tempArr1 =  obj[0].imagesArray.filter((img , i) => { return i !== imageId })

        this.setState({
            varientArrayCombo: [
                ...this.state.varientArrayCombo.slice(0,objId),
                Object.assign({}, this.state.varientArrayCombo[objId], {imagesArray: tempArr1}),
                ...this.state.varientArrayCombo.slice(objId + 1)
            ]
        })
    }

    handleVarientInput(e, index){
        this.setState({
            varientArrayCombo: [
                ...this.state.varientArrayCombo.slice(0,index),
                Object.assign({}, this.state.varientArrayCombo[index], {[e.target.name]: e.target.value}),
                ...this.state.varientArrayCombo.slice(index + 1)
            ],
            errors: {}
        })
    }

    handleIdOrSku = (e) => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                idOrSku: ""
            },
            idOrSku: e.target.value
        }))
    }

    handleFactoryPrice = (e) => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                factoryPrice: ""
            },
            factoryPrice: e.target.value
        }))
    }
    
    handleNumberOfItems = (e) => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                numberOfItems: ""
            },
            numberOfItems: e.target.value
        }))
    }

    handlePackageIncludes = (e) => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                packageIncludes: ""
            },
            packageIncludes: e.target.value
        }))
    }

    handleYearOfManufacturer = (e) => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                yearOfManufacturer: ""
            },
            yearOfManufacturer: e.target.value
        }))
    }

    handleProductExpiry = (e) => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                productExpiry: ""
            },
            productExpiry: e.target.value
        }))
    }
    

    handleIsLiquid = (e) => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                isLiquid: ""
            },
            isLiquid: e.target.value
        }))
    }

    handleIsBattery = (e) => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                isBattery: ""
            },
            isBattery: e.target.value
        }))
    }

    handleMrp = (e) => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                mrp: ""
            },
            mrp: e.target.value
        }))
    }

    handleQuantity = (e) => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                quantity: ""
            },
            quantity: e.target.value
        }))
    }

    handleCbm = (e) => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                cbm: ""
            },
            cbm: e.target.value
        }))
    }

    handleSelectedCategory = e => {
        e.persist()
        let margin = 0
        let categoryId = "";
        this.state.categoryList.forEach(item => {
            if(item.category === e.target.value){
              categoryId = item._id;
              if(item.margin !== undefined || null)
                  margin = item.margin
            }
        })
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                category: ""
            },
            category: e.target.value,
            margin
        }))
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
            },
            categoryId: categoryId,
        }))
    }

    handleHsn = e => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                hsn: ""
            },
            hsn: e.target.value
        }))
    }

    handleWeight = e => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                weight: ""
            },
            weight: e.target.value
        }))
    }

    handleWeightMeasure = e => {
        e.persist()
        this.setState({weightLabel: e.target.value})
    }

    handleWarrenty = e => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                warranty: ""
            },
            warranty: e.target.value
        }))
    }

    handleHeight = e => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                hwlError: ""
            },
            height: e.target.value
        }))
    }

    handleWidth = e => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                hwlError: ""
            },
            width: e.target.value
        }))
    }

    handleLength = e => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                hwlError: ""
            },
            length: e.target.value
        }))
    }

    handleBcd = e => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                bcd: ""
            },
            bcd: e.target.value
        }))
    }

    handleIgst = e => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                igst: ""
            },
            igst: e.target.value
        }))
    }

    handleSwc = e => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                swc: ""
            },
            swc: e.target.value
        }))
    }

    handleShipping = e => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                shippingMode: ""
            },
            shippingMode: e.target.value
        }))
        if(e.target.value === 'standardShipping'){
            document.getElementById("showOnStandardShipping").hidden = false
        }else{
            document.getElementById("showOnStandardShipping").hidden = true
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    usaShippingPrice: "",
                    ukShippingPrice: "",
                    ausShippingPrice: "",
                    canadaShippingPrice: "",
                    interShippingPrice: ""
                },
                usaShippingPrice: 0,
                ukShippingPrice: 0,
                ausShippingPrice: 0,
                canadaShippingPrice: 0,
                interShippingPrice: 0
            }))
        }
    }

    handleUsaShippingPrice = e => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                usaShippingPrice: ""
            },
            usaShippingPrice: e.target.value
        }))
    }

    handleCanadaShippingPrice = e => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                canadaShippingPrice: ""
            },
            canadaShippingPrice: e.target.value
        }))
    }

    handleUkShippingPrice = e => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                ukShippingPrice: ""
            },
            ukShippingPrice: e.target.value
        }))
    }

    handleAusShippingPrice = e => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                ausShippingPrice: ""
            },
            ausShippingPrice: e.target.value
        }))
    }

    handleInterShippingPrice = e => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                interShippingPrice: ""
            },
            interShippingPrice: e.target.value
        }))
    }

    handlePdStatus = e => {
        e.persist()
        if(e.target.value === 'active'){
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    productStatus: ""
                },
                pdStatus: '1'
            }))
        }else{
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    productStatus: ""
                },
                pdStatus: '0'
            }))
        }
    }

    handleDisplayStatus = e => {
        e.persist()
        if(e.target.value === 'active'){
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    productStatus: ""
                },
                isDisplay: '1'
            }))
        }else{
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    productStatus: ""
                },
                isDisplay: '0'
            }))
        }
    }

    handleAllInputs = e => {
        e.persist()
        this.setState({[e.target.name] : e.target.value})
    }

    addProduct = e => {
        e.preventDefault()
        let data = {
            "productTitle": this.state.productTitle,
            "productSubTitle": this.state.productSubTitle,
            "invoiceDescription": this.state.invoiceDescription,
            "detailDescription": this.state.detailDescription,
            "marketPlaceUrl": this.state.marketPlaceUrl,
            "marketPlacePrice": this.state.marketPlacePrice,
            "marketPlaceMrp": this.state.marketPlaceMrp,
            "isVarien": this.state.isVarien,
            "idOrSku": this.state.idOrSku,
            "factoryPrice": this.state.factoryPrice,
            "mrp": this.state.mrp,
            "quantity": this.state.quantity,
            "cbm": this.state.cbm,
            "category": this.state.category,
            "margin": this.state.margin,
            "hsn": this.state.hsn,
            "weight": this.state.weight,
            "weightLabel": this.state.weightLabel,
            "warranty": this.state.warranty,
            "height": this.state.height,
            "width": this.state.width,
            "length": this.state.length,
            "bcd": this.state.bcd,
            "igst": this.state.igst,
            "swc": this.state.swc,
            "shippingMode": this.state.shippingMode, 
            "usaShippingPrice": this.state.usaShippingPrice,
            "canadaShippingPrice": this.state.canadaShippingPrice,
            "ausShippingPrice": this.state.ausShippingPrice,
            "ukShippingPrice": this.state.ukShippingPrice,
            "interShippingPrice": this.state.interShippingPrice,
            "supplierId": jwt_decode(localStorage.getItem('token')).id,
            "tags": this.state.tags,
            "imageArray": this.state.imageArray,
            "varientArray": this.state.varientArray,
            "varientArrayCombo": this.state.varientArrayCombo,
            "pdStatus": this.state.pdStatus,
            "isDisplay": this.state.isDisplay,
            "taggedSuppliers": this.state.subSupplierList,
            "slabOne": this.state.slabOne,
            "slabTwo": this.state.slabTwo,
            "slabThree": this.state.slabThree,
            "slabFour": this.state.slabFour,
            "deliveryDaysFrom": this.state.deliveryDaysFrom,
            "deliveryDaysTo": this.state.deliveryDaysTo,
            "yearOfManufacturer": this.state.yearOfManufacturer,
            "productExpiry":this.state.productExpiry,
            "numberOfItems":this.state.numberOfItems,
            "packageIncludes": this.state.packageIncludes,
            "isLiquid": this.state.isLiquid,
            "isBattery": this.state.isBattery,
            "browseNode": this.state.browseNode,
            "categoryId":this.state.categoryId
        }
        data.bulletpoints = $(".bullet-points-input").map((index,el)=>{
          return $(el).val();
        }).toArray();

        let customfieldsvalues = $(".custom-field-values-input").map((index,el)=>{
          return $(el).val();
        }).toArray();


        data.customfields = $(".custom-field-keys-input").map((index,el)=>{
          return {[$(el).val()]:customfieldsvalues[index]};
        }).toArray();

        let validation = this.validateAllInput(data)
        if(validation.isValid){
            document.getElementById("addProductBtn").disabled = true
            this.setState({loading: true})
            this.convertToWeightMeasurement(data , (data1) => {
                axios.post('/add/addProduct', data1)
                .then(result => {
                    if(result.data === 1){
                        NotificationManager.success('Product Added Successfully')
                        this.setState({productTitleCharLength: 0,productTitle : "",productSubTitleCharLength: 0,productSubTitle: "",
                            invoiceDescriptionCharLength: 0,invoiceDescription: "",detailDescription: "",tags: [],imageArray: [],isVarien: false,
                            varientArray: {},varientArrayCombo: {},idOrSku: "",factoryPrice: "",cbm:"", mrp: "",quantity: "",category: "Select Category",
                            margin: 0,hsn: "",weight: 0.0, weightLabel: "g",warranty: 0,height: 0.0,width: 0.0,length: 0.0,shippingMode: "",
                            usaShippingPrice: 0, canadaShippingPrice: 0,ausShippingPrice: 0,ukShippingPrice: 0,interShippingPrice: 0,
                            pdStatus: 0,isDisplay: 0,marketPlaceUrl: "",bcd: "",igst: "",swc: "", errors: {}, loading: false,
                            marketPlaceMrp: "", marketPlacePrice: "", selectedSupplier: "Select Supplier", priority: "", 
                            selectedSupplierId: "", selectedSupplierIndex: "", subSupplierList: [], slabOne: 0, slabTwo: 0, slabThree: 0, slabFour: 0})
                        document.getElementById("showOnStandardShipping").hidden = true
                        document.getElementById("selectCategory").selectedIndex = 0
                        document.getElementById("weightLabel").selectedIndex = 0
                        document.getElementById("defaultCheck1").checked = false
                        document.getElementById("freeShipping").checked = false
                        document.getElementById("standardShipping").checked = false
                        // document.getElementById("pdStatus").checked = false
                        // document.getElementById("isDisplay").checked = false
                        // document.getElementById("pdStatus1").checked = false
                        // document.getElementById("isDisplay1").checked = false
                        document.getElementById("productImage").value = ""
                        document.getElementById("addProductBtn").disabled = false
                    }else if(result.data === 2){
                        NotificationManager.success('Enter unique Id of the Product')
                        this.setState({loading: false})
                        document.getElementById("addProductBtn").disabled = false
                    }else if(result.data === 3){
                        NotificationManager.error('Failed to add product !!!')
                        this.setState({loading: false})
                        document.getElementById("addProductBtn").disabled = false
                    }else{
                        NotificationManager.error('Failed to add product, Please check your entries and try again')
                        this.setState({loading: false})
                        document.getElementById("addProductBtn").disabled = false
                    }
                })
                .catch(err => {
                    this.setState({loading: false})
                    document.getElementById("addProductBtn").disabled = false
                    console.log(err)
                    NotificationManager.error('Something went wrong, please try again later.')
                })
            })
        }else{
            console.log(validation.errors)
            this.setState({errors : validation.errors})
        }
    }

    convertToWeightMeasurement(data, callback){
        console.log("data",data);
         if(data.weightLabel === "kg"){
            data.weight = parseFloat(data.weight) * 1000
            
        }else if(data.weightLabel === "lb"){
            data.weight = parseFloat(data.weight) * 453.59237
           
        }else if(data.weightLabel === "oz"){
            data.weight = parseFloat(data.weight) / 0.035274
          
        }
        if(data.varientArrayCombo>0){
        data.varientArrayCombo.forEach(element =>{
            if(element.weightLabel === "kg"){
                element.weight = parseFloat(element.weight) * 1000
                
            }else if(element.weightLabel === "lb"){
                element.weight = parseFloat(element.weight) * 453.59237
              
            }else if(element.weightLabel === "oz"){
                element.weight = parseFloat(element.weight) / 0.035274
              
            }
        })
    }
        callback(data)
    }
    groupBy = (array = [], key) => {
      // Return the end result
      return array.reduce((result, currentValue) => {
        // If an array already present for key, push it to the array. Else create an array and push the object
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue
        );
        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return result;
      }, {}); // empty object is the initial value for result object
    };

    validateAllInput = data => {
        let errors = {}

        if(_.isEmpty(data.productTitle)){
            errors.productTitle = "Product Title should not be empty"
        }

        if(_.isEmpty(data.detailDescription) || (data.detailDescription && data.detailDescription.replace( /(<([^>]+)>)/ig, '').length < 100)){
            errors.detailDescription = "Product Description should not be empty or less than 100 characters"
        }
        let variants = [];
        if(data.varientArray && data.varientArray.map)
           variants = this.groupBy(data.varientArray,"name");
        
        if(variants && Math.max(...Object.values(variants).map((item)=>item.length)) > 1){
          errors.varientArray = "Name of Option is not unique"
        }
        // if(_.isEmpty(data.invoiceDescription)){
        //     errors.invoiceDescription = "Invoice Description should not be empty"
        // }

        // if(!_.isEmpty(data.marketPlaceUrl)){
        //     if(!validator.isURL(data.marketPlaceUrl))
        //         errors.marketPlaceUrl = "Invalid Market Place URL"
        // }

        // if(!_.isEmpty(data.marketPlaceMrp)){
        //     if(parseFloat(data.marketPlaceMrp) <= 0){
        //         errors.marketPlaceMrp = "Invalid Market Place Mrp"
        //     }
        // }

        // if(!_.isEmpty(data.marketPlacePrice)){
        //     if(parseFloat(data.marketPlacePrice) <= 0){
        //         errors.marketPlacePrice = "Invalid Market Place Price"
        //     }
        // }

        if(!_.isEmpty(data.slabOne) && data.slabOne < 0){
            errors.slabError = "Invalid Slab Price"
        }else if(!_.isEmpty(data.slabTwo) && data.slabTwo < 0)
            errors.slabError = "Invalid Slab Price"
        else if(!_.isEmpty(data.slabThree) && data.slabThree < 0)
            errors.slabError = "Invalid Slab Price"
        else if(!_.isEmpty(data.slabFour) && data.slabFour < 0)
            errors.slabError = "Invalid Slab Price"

        if(_.isEmpty(data.slabOne))
            errors.slabError = "First Slab should not be empty"

        if(!_.isEmpty(data.deliveryDaysFrom) && data.deliveryDaysFrom < 0)
            errors.deliveryTimeError = "Invalid Number"
        else if(!_.isEmpty(data.deliveryDaysTo) && data.deliveryDaysTo < 0)
            errors.deliveryTimeError = "Invalid Number"
        else if(data.deliveryDaysFrom > data.deliveryDaysTo)
            errors.deliveryTimeError = "From days should be less than To days"

        if(!data.isVarien){
            if(_.isEmpty(data.factoryPrice)){
                errors.factoryPrice = "Factory price should not be empty"
            }else if(parseFloat(data.factoryPrice) < 0){
                errors.factoryPrice = "Factory price should be greater than 0"
            }else if(isNaN(data.factoryPrice)){
                errors.factoryPrice = "Factory price should be a number"
            }
        }

        // if(!_.isEmpty(data.mrp) && !data.isVarien){
        //     if(parseFloat(data.mrp) < 0){
        //         errors.mrp = "MRP should be greater than 0"
        //     }else if(isNaN(data.mrp)){
        //         errors.mrp = "MRP should be a number"
        //     }
        // }

        if(!data.isVarien){
            if(_.isEmpty(data.quantity))
                errors.quantity = "Quantity should not be empty"
            else if(data.quantity % 1 !== 0)
                errors.quantity = "Quantity should be an integer"
            else if(data.quantity < 0)
                errors.quantity = "Quantity should be greater than 0"
        }

        if(data.isVarien && _.isEmpty(data.varientArray)){
            errors.emptyVarient = "Variant Product should not be empty"
        }

        if(data.isVarien){
            data.varientArrayCombo.map(varientObj => {
                if(_.isEmpty(varientObj.price))
                    errors.emptyVarient = "Price should not be empty"
                else if(varientObj.price < 0)
                    errors.emptyVarient = "Price should be greater than 0"
                
                if(_.isEmpty(varientObj.quantity))
                    errors.emptyVarient = "Quantity should not be empty"
                else if(varientObj.quantity < 0)
                    errors.emptyVarient = "Quantity should be greater than 0"
                else if(varientObj.quantity % 1 !== 0)
                    errors.emptyVarient = "Quantity should be an integer"


                if(_.isEmpty(varientObj.weight)){
                    errors.emptyVarient = "Weight should not be empty"
                }else if(varientObj.weight < 0){
                    errors.emptyVarient = "Invalid weight"
                }else if(isNaN(varientObj.weight)){
                    errors.emptyVarient = "weight should be a number"
                }
        
                if(!_.isEmpty(varientObj.height) && (varientObj.height < 0)){
                    errors.emptyVarient = "Height value should be greater than 0"
                }else if(!_.isEmpty(varientObj.width) && (varientObj.width < 0)){
                    errors.emptyVarient = "Width value should be greater than 0"
                }else if(!_.isEmpty(varientObj.length) && (varientObj.length < 0)){
                    errors.emptyVarient = "Length value should be greater than 0"
                }
                
                // if(!_.isEmpty(varientObj.mrp) && varientObj.mrp < 0)
                //     errors.emptyVarient = "Mrp should be greater than 0"
            })

            data.varientArray.map(varientObj => {
                if(varientObj.name === "" || _.isEmpty(varientObj.values))
                    errors.emptyVarient = "Please fill all the variant records"
            })
        }

        if(data.category === "Select Category"){
            errors.category = "Please select any one category"
        }

        // if(_.isEmpty(data.hsn)){
        //     errors.hsn = "HSN should not be empty"
        // }
        if(!data.isVarien){
        if(_.isEmpty(data.weight)){
            errors.weight = "Weight should not be empty"
        }else if(data.weight < 0){
            errors.weight = "Invalid weight"
        }else if(isNaN(data.weight)){
            errors.weight = "weight should be a number"
        }

        if(!_.isEmpty(data.height) && (data.height < 0)){
            errors.hwlError = "Height value should be greater than 0"
        }else if(!_.isEmpty(data.width) && (data.width < 0)){
            errors.hwlError = "Width value should be greater than 0"
        }else if(!_.isEmpty(data.length) && (data.length < 0)){
            errors.hwlError = "Length value should be greater than 0"
        }

        if(_.isEmpty(data.cbm)){
            errors.cbm = "CBM should not be empty"
        }else if(data.cbm < 0){
            errors.cbm = "Invalid CBM"
        }else if(isNaN(data.cbm)){
            errors.weight = "CBM should be a number"
        }
        }

        // if(!_.isEmpty(data.bcd) && (parseInt(data.bcd) <= 0)){
        //     errors.bcd = "BCD value should be greater than 0"
        // }

        // if(!_.isEmpty(data.igst) && (parseInt(data.igst) <= 0)){
        //     errors.igst = "IGST value should be greater than 0"
        // }

        // if(!_.isEmpty(data.swc) && (parseInt(data.swc) <= 0)){
        //     errors.swc = "SWC value should be greater than 0"
        // }

        if(_.isEmpty(data.shippingMode)){
            errors.shippingMode = "Please choose any one option"
        }else{
            if(data.shippingMode === 'standardShipping'){
                if(_.isEmpty(data.usaShippingPrice)){
                    errors.usaShippingPrice = "should not be empty"
                }else if(parseInt(data.usaShippingPrice) < 0){
                    errors.usaShippingPrice = "should be greater than 0"
                }

                if(_.isEmpty(data.ukShippingPrice)){
                    errors.ukShippingPrice = "should not be empty"
                }else if(parseInt(data.ukShippingPrice) < 0){
                    errors.ukShippingPrice = "should be greater than 0"
                }

                if(_.isEmpty(data.canadaShippingPrice)){
                    errors.canadaShippingPrice = "should not be empty"
                }else if(parseInt(data.canadaShippingPrice) < 0){
                    errors.canadaShippingPrice = "should be greater than 0"
                }

                if(_.isEmpty(data.ausShippingPrice)){
                    errors.ausShippingPrice = "should not be empty"
                }else if(parseInt(data.ausShippingPrice) < 0){
                    errors.ausShippingPrice = "should be greater than 0"
                }

                if(_.isEmpty(data.interShippingPrice)){
                    errors.interShippingPrice = "should not be empty"
                }else if(parseInt(data.interShippingPrice) < 0){
                    errors.interShippingPrice = "should be greater than 0"
                }
            }
        }

        // if(data.pdStatus === '' || data.isDisplay === ''){
        //     errors.productStatus = "Please choose any one option"
        // }

        return { errors , isValid: _.isEmpty(errors)}
    }

    handleSubSupplier = e => {
        e.preventDefault()
        this.validateSubSupplierObjectAndAdd()
    }

    validateSubSupplierObjectAndAdd(){
        let errors = {}
        if(_.isEmpty(this.state.selectedSupplier) || this.state.selectedSupplier === "Select Supplier"){
            errors.subSupplierError = "Please select any one supplier"
            this.setState({errors})
        }else if(_.isEmpty(this.state.priority)){
            errors.subSupplierError = "Please provide priority"
            this.setState({errors})
        }else if(parseInt(this.state.priority) <= 0 || parseInt(this.state.priority) > 100){
            errors.subSupplierError = "Priority should be greater than 0 and less than 100"
            this.setState({errors})
        }else{
            this.setState({errors: {}})
            this.findRedundentId((redundentId) => {
                if(_.isEmpty(redundentId)){
                    this.checkPriorityExists(priorityResponse => {
                        if(_.isEmpty(priorityResponse)){
                            !this.state.editMode
                            ?
                                this.getSupplierName((supplierNameResponse) => {
                                    this.setState({subSupplierList: this.state.subSupplierList.concat({
                                        priority: this.state.priority,
                                        sid: this.state.selectedSupplierId,
                                        supplierName: supplierNameResponse
                                    }),
                                    selectedSupplier: "Select Supplier",
                                    priority: "",
                                    editMode: false})
                                })
                            :
                                this.getSupplierName((supplierNameResponse) => {
                                    this.setState({
                                        subSupplierList: [
                                            ...this.state.subSupplierList.slice(0,parseInt(this.state.selectedSupplierIndex)),
                                            Object.assign({}, this.state.subSupplierList[parseInt(this.state.selectedSupplierIndex)], {sid: this.state.selectedSupplierId, supplierName: supplierNameResponse, priority: this.state.priority}),
                                            ...this.state.subSupplierList.slice(parseInt(this.state.selectedSupplierIndex) + 1)
                                        ],
                                        selectedSupplier: "Select Supplier",
                                        priority: "",
                                        editMode: false
                                    })
                                })
                                
                            document.getElementById("subSupplier").selectedIndex = 0
                        }else{
                            errors.subSupplierError = "Priority has already been assigned"
                            this.setState({errors})
                        }
                    })
                }else{
                    errors.subSupplierError = "Supplier has already been added to the list"
                    this.setState({errors})
                }
            })
        }
    }

    getSupplierName(callback){
        if(this.state.selectedSupplierId === "Select Supplier") callback("")
        else {
            const supplierName = this.state.supplierList.filter(supplier => {
                return this.state.selectedSupplierId === supplier._id
            })
            callback(supplierName[0].supplier_id)
        }
    }

    findRedundentId(callback){
        const redundentId = this.state.subSupplierList.filter(supplier => {
            return this.state.selectedSupplierId === supplier.sid
        })
        if(!this.state.editMode || redundentId.length > 1) callback(redundentId)
        else callback([])
    }

    checkPriorityExists(callback){
        const priority = this.state.subSupplierList.filter(supplier => {
            return this.state.priority === supplier.priority
        })
        if(!this.state.editMode || priority.length > 1) callback(priority)
        else callback([])
    }

    handleSubSupplierDelete(id){
        this.setState({subSupplierList: this.state.subSupplierList.filter(supplier => {
            return supplier.sid !== id
        })})
    }

    handleSubSupplierEdit(id, index){
        this.getSelectedSupplierData(id,(response) => {
            this.setState({editMode: true, selectedSupplier: response[0].supplierName, priority: response[0].priority,
                        selectedSupplierId: id, selectedSupplierIndex: index})
        })
    }

    getSelectedSupplierData(id, callback){
        const selectedSupplier = this.state.subSupplierList.filter(supplier => {
            return supplier.sid === id
        })
        callback(selectedSupplier)
    }

    handleOnchangeSubSupplier = e => {
        e.preventDefault()
        this.setState({selectedSupplierId: e.target.value} , () => {
            this.getSupplierName((response) => {
                this.setState({selectedSupplier: response, errors: {}})
            })
        })
    }
    changeForm = e =>{
      e.preventDefault()
      let id = e.target.getAttribute("id");
      if(id == "basicFormButton"){
        document.getElementById("basicFormButton").style.backgroundColor = "";
        document.getElementById("basicFormButton").style.background = "";
        document.getElementById("basicForm").style.display = "block";
        document.getElementById("amazonTemplateForm").style.display = "none";
      } else {
        document.getElementById("amazonTemplateFormButton").style.backgroundColor = "";
        document.getElementById("amazonTemplateFormButton").style.background = "";
        document.getElementById("basicForm").style.display = "none";
        document.getElementById("amazonTemplateForm").style.display = "block";
      }
    }

    addBulletField = (ele)=>{
      $("#basicFormButton").css("background-color","");
      $(".add-new-bullet").on("click",function(){
        let html = $(".bullet-container .row:first").clone();
        html.find(".button").html('<a href="javascript:;" class="remove-bullet"><i class="fa fa-close" style="font-size:36px"></i></a>');
        $(".bullet-container").append(html);
        $(document).off().on("click",".remove-bullet",function(){
            $(this).closest(".row").remove();
        });
      });
    }

    addCustomFields = (ele)=>{
      $(".add-new-field").off().on("click",function(){
        let html = $(".custom-field-container .row:first").clone();
        html.find(".custom-field").remove();
        html.find(".button").html('<a href="javascript:;" class="remove-custom-field"><i class="fa fa-close" style="font-size:36px"></i></a>');
        $(".custom-field-container").append(html);
      });
      $(document).off().on("click",".remove-custom-field",function(){
        $(this).closest(".row").remove();
      });
    }

    render(){
        const Editor = {
            modules : {
                toolbar: [
                [{ header: [1, 2, false] }],
                ['bold'],
                ['italic'],
                ['underline'],
                ['strike'],
                ['blockquote'],
                [{ list: 'ordered' }],
                [{ list: 'bullet' }],
                [{ indent: '+1' }],
                [{ indent: '-1' }],
                ['link'],
                ['video'],
                ['image'],
                ],
            },formats : [
                'header',
                'size',
                'bold',
                'italic',
                'underline',
                'strike',
                'blockquote',
                'list',
                'bullet',
                'indent',
                'link',
                'image',
                'video',
            ]
        }            
        return(
            <div>
                <SyncLoader css={override} size={15} margin={6} color={"#3E3E3E"} loading={this.state.loading} />
                <br></br>
                <div>
                    <div className="container-fluid">
                        <ul className="nav nav-tabs" role="tablist">
                            <li role="presentation" className="active"><a href="#basicForm" aria-controls="home" role="tab" data-toggle="tab" style={{"marginLeft": "20px"}} id="basicFormButton" onClick={this.changeForm}>BASIC FORM</a></li>
                            <li role="presentation"><a href="#amazonForm" aria-controls="profile" role="tab" data-toggle="tab" id="amazonTemplateFormButton" onClick={this.changeForm}>AMAZON TEMPLATE FORM</a></li>
                        </ul>
                    </div>

                    <div className="container-fluid">
                    <div className="tab-content"> 
                        {/* basic form starts */}
                        <div role="tabpanel" className="tab-pane fade in active" id="basicForm">
                        <div id="basicForm">
                          <div className="card" style={{padding: "3em 3em 5px", marginBottom:"0"}}>
                              <div className='form-group'>
                                  <label style={{fontSize: 15 }} htmlFor='productTitle'>
                                      <span className="text-danger">* </span> Title &nbsp;
                                      <span className="custom-tooltip" data-tooltip="Convey what we are selling ">
                                          <i className="fa fa-question-circle" aria-hidden="true"></i>
                                      </span>
                                  </label>
                                  <small style={{float: 'right'}}>{`${this.state.productTitleCharLength} / ${this.state.productTitleCharMaxLength}`}</small>
                                  <input
                                      type='text'
                                      value={this.state.productTitle}
                                      onChange={this.handleProductTitleInput.bind(this)}
                                      className='form-control'
                                      placeholder='Enter Title of Product'
                                      id="productTitle"
                                      maxLength={this.state.productTitleCharMaxLength}
                                      autoComplete="off"
                                  />
                                  {
                                      this.state.errors.productTitle
                                      &&
                                      <span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.productTitle}</span>
                                  }
                              </div>
                              <br></br>
                              <div className="form-group">
                                  <label style={{fontSize: 15 }} htmlFor='productSubTitle'>Sub Title <span className="text-muted"> (Optional)</span>&nbsp;
                                      <span className="custom-tooltip" data-tooltip="Short text displayed on the product list">
                                          <i className="fa fa-question-circle" aria-hidden="true"></i>
                                      </span>
                                  </label>
                                  <small style={{float: 'right'}}>{`${this.state.productSubTitleCharLength} / ${this.state.productSubTitleCharMaxLength}`}</small>
                                  <input
                                      type='text'
                                      value={this.state.productSubTitle}
                                      onChange={this.handleProductSubTitle.bind(this)}
                                      className='form-control'
                                      placeholder='Enter Sub Title of Product'
                                      id="productSubTitle"
                                      maxLength={this.state.productSubTitleCharMaxLength}
                                      autoComplete="off"
                                  />
                              </div>
                              {/* <br></br>
                              <div className='form-group'>
                                  <label style={{fontSize: 15 }} htmlFor='invoiceDescription'><span className="text-danger">* </span> Invoice Description &nbsp;
                                      <span className="custom-tooltip" data-tooltip="Keep track of quantity and payment of the product">
                                          <i className="fa fa-question-circle" aria-hidden="true"></i>
                                      </span>
                                  </label>
                                  <small style={{float: 'right'}}>{`${this.state.invoiceDescriptionCharLength} / ${this.state.invoiceDescriptionCharMaxLength}`}</small>
                                  <input
                                      type='text'
                                      value={this.state.invoiceDescription}
                                      onChange={this.handleInvoiceDescription.bind(this)}
                                      className='form-control'
                                      placeholder='Enter Invoice Description'
                                      id="invoiceDescription"
                                      maxLength={this.state.invoiceDescriptionCharMaxLength}
                                      autoComplete="off"
                                  />
                                  {
                                      this.state.errors.invoiceDescription
                                      &&
                                      <span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.invoiceDescription}</span>
                                  }
                              </div> */}
                              <br></br>
                              <div className='form-group'>
                                  <label style={{fontSize: 15 }} htmlFor='detailDescription'><span className="text-danger">*</span>Detail Description &nbsp;
                                      <span className="custom-tooltip" data-tooltip="Detailed description of the product ">
                                          <i className="fa fa-question-circle" aria-hidden="true"></i>
                                      </span>
                                  </label>
                                  <ReactQuill
                                      theme={'snow'}
                                      onChange={this.handleReactQuillInput.bind(this)}
                                      value={this.state.detailDescription}
                                      modules={Editor.modules}
                                      formats={Editor.formats}
                                      placeholder={'Write something...'}
                                  />
                                  <br />
                                  {
                                      this.state.errors.detailDescription
                                      &&
                                      <span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.detailDescription}</span>
                                  }

                              </div>
                          </div>
                          

                          <div className="card" style={{padding: "0 3em", marginBottom:"0"}}>
                              {/* <div className="container-fluid">
                                  <div className="row">
                                      <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                          <div className="form-group">
                                              <label style={{fontSize: 15 }}>Market Place URL &nbsp;
                                                  <span className="custom-tooltip" data-tooltip="hosts products of individual sellers on the same domain">
                                                      <i className="fa fa-question-circle" aria-hidden="true"></i>
                                                  </span>
                                              </label>
                                              <br></br>
                                              <input type="text"
                                                      placeholder="Market Place URL"
                                                      className="form-control"
                                                      value={this.state.marketPlaceUrl}
                                                      onChange={this.handleMarketPlaceUrl.bind(this)} />
                                              {
                                                  this.state.errors.marketPlaceUrl
                                                  &&
                                                  <span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.marketPlaceUrl}</span>
                                              }
                                          </div>
                                      </div>
                                      <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                          <div className="form-group">
                                              <label style={{fontSize: 15 }}>Market Place Price &nbsp;
                                                  <span className="custom-tooltip" data-tooltip="Market Place Price">
                                                      <i className="fa fa-question-circle" aria-hidden="true"></i>
                                                  </span>
                                              </label>
                                              <br></br>
                                              <input type="number"
                                                      placeholder="Market Place Price"
                                                      className="form-control"
                                                      value={this.state.marketPlacePrice}
                                                      onChange={this.handleMarketPlacePrice.bind(this)} />
                                              {
                                                  this.state.errors.marketPlacePrice
                                                  &&
                                                  <span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.marketPlacePrice}</span>
                                              }
                                          </div>
                                      </div>
                                      <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                          <div className="form-group">
                                              <label style={{fontSize: 15 }}>Market Place MRP &nbsp;
                                                  <span className="custom-tooltip" data-tooltip="Market Place MRP">
                                                      <i className="fa fa-question-circle" aria-hidden="true"></i>
                                                  </span>
                                              </label>
                                              <br></br>
                                              <input type="number"
                                                      placeholder="Market Place Mrp"
                                                      className="form-control"
                                                      value={this.state.marketPlaceMrp}
                                                      onChange={this.handleMarketPlaceMrp.bind(this)} />
                                              {
                                                  this.state.errors.marketPlaceMrp
                                                  &&
                                                  <span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.marketPlaceMrp}</span>
                                              }
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <br></br>*/}
                              <div className="form-group">
                                  <label style={{fontSize: 15 }}> Tags/Keywords &nbsp;
                                      <span className="custom-tooltip" data-tooltip="Descriptors assigned to specific products to organize and track their progress">
                                          <i className="fa fa-question-circle" aria-hidden="true"></i>
                                      </span>
                                  </label>
                                  <TagsInput 
                                      value={this.state.tags}
                                      onChange={tags => this.setState({tags})}
                                      onlyUnique
                                      addKeys={[9,13,32,188]}
                                  />
                              </div>
                              <br></br> 
                              <div className="">
                                  <label htmlFor='productImage' style={{fontSize: 15 }}>Slabs &nbsp;
                                      <span className="custom-tooltip" data-tooltip="It is costing based on quantity">
                                          <i className="fa fa-question-circle" aria-hidden="true"></i>
                                      </span>
                                  </label><br></br>
                                  {
                                      this.state.errors.slabError
                                      &&
                                      <span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.slabError}</span>
                                  }
                                  <div className="row">
                                      <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                          <div className="form-group">
                                              <label>1 - 5 pieces</label>
                                              <input 
                                                  type="number"
                                                  value={this.state.slabOne}
                                                  placeholder="Slab One Price"
                                                  className="form-control"
                                                  min="0"
                                                  onChange={(e) => this.setState({slabOne: e.target.value}) }
                                              />
                                          </div>
                                      </div>
                                      <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                          <div className="form-group">
                                              <label>6 - 20 pieces</label>
                                              <input 
                                                  type="number"
                                                  value={this.state.slabTwo}
                                                  placeholder="Slab One Price"
                                                  className="form-control"
                                                  min="0"
                                                  onChange={(e) => this.setState({slabTwo: e.target.value}) }
                                              />
                                          </div>
                                      </div>
                                      <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                          <div className="form-group">
                                              <label>21 - 100 pieces</label>
                                              <input 
                                                  type="number"
                                                  value={this.state.slabThree}
                                                  placeholder="Slab One Price"
                                                  className="form-control"
                                                  min="0"
                                                  onChange={(e) => this.setState({slabThree: e.target.value}) }
                                              />
                                          </div>
                                      </div>
                                      <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                          <div className="form-group">
                                              <label>100 and Above</label>
                                              <input 
                                                  type="number"
                                                  value={this.state.slabFour}
                                                  placeholder="Slab One Price"
                                                  className="form-control"
                                                  min="0"
                                                  onChange={(e) => this.setState({slabFour: e.target.value}) }
                                              />
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <br></br>
                              <div className="">
                                  {
                                      this.state.errors.deliveryTimeError
                                      &&
                                      <span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.deliveryTimeError}</span>
                                  }
                                  <div className="row">
                                      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                          <label htmlFor='productImage' style={{fontSize: 15 }}>Delivery Time From (In Days) &nbsp;
                                              <span className="custom-tooltip" data-tooltip="It is the handover time required by you as brand to our logistic ">
                                                  <i className="fa fa-question-circle" aria-hidden="true"></i>
                                              </span>
                                          </label><br></br>
                                          <input 
                                              type="number"
                                              placeholder="Delivery Time From"
                                              value={this.state.deliveryDaysFrom}
                                              onChange={(e) => this.setState({deliveryDaysFrom: e.target.value})}
                                              className="form-control"
                                              min="0"
                                          />
                                      </div>
                                      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                          <label htmlFor='productImage' style={{fontSize: 15 }}>Delivery Time To (In Days) &nbsp;
                                              <span className="custom-tooltip" data-tooltip="Delivery Time To">
                                                  <i className="fa fa-question-circle" aria-hidden="true"></i>
                                              </span>
                                          </label><br></br>
                                          <input 
                                              type="number"
                                              placeholder="Delivery Time To"
                                              value={this.state.deliveryDaysTo}
                                              onChange={(e) => this.setState({deliveryDaysTo: e.target.value})}
                                              className="form-control"
                                              min="0"
                                          />
                                      </div>
                                  </div>
                              </div>
                              <br></br>
                              <div className="form-group">
                                  <label htmlFor='productImage' style={{fontSize: 15 }}>Image upload &nbsp;
                                      <span className="custom-tooltip" data-tooltip="Clear and informative images, which allow clear recognition of the product">
                                          <i className="fa fa-question-circle" aria-hidden="true"></i>
                                      </span>
                                  </label><br></br>
                                  <label className="btn btn_new">
                                      Choose Images
                                      <input
                                          type='file'
                                          id="productImage"
                                          className='hidden'
                                          onChange={this.uploadMultipleImages}
                                          multiple
                                          accept='image/*'
                                      />
                                  </label>
                              </div>
                              <br></br>
                              <div className="">
                                  <div className="row">
                                      <div className="form-group multi-preview">
                                          {this.state.imageArray.map((image, i) => {
                                              return (
                                                  <div className='col-md-3 show-image1' key={i}>
                                                      <span className="closeButton1" onClick={() => this.handleDeleteImage(i)}>
                                                          <i className="fa fa-times" aria-hidden="true"></i>
                                                      </span>
                                                      
                                                      <div className="img-overlay1"></div>
                                                      <img
                                                          src={image}
                                                          alt='uploadImage'
                                                          className='images'
                                                      />
                                                  </div>
                                              )
                                          })}
                                      </div>
                                  </div>
                              </div>
                          </div>
                          

                          <div className="card" style={{padding: "3em 3em 15px", marginBottom:"0"}}>
                              {
                                this.state.errors.varientArray
                                &&
                                    <span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.varientArray}</span>
                              }
                              <div className="form-group">
                                  <div className="form-check">
                                      <input className="form-check-input" type="checkbox" value={this.state.isVarien} onChange={this.handleIsVarien.bind(this)} id="defaultCheck1" />
                                      <label className="form-check-label" htmlFor="defaultCheck1">
                                          &nbsp;&nbsp;This product has multiple options, like different sizes or colors
                                      </label>
                                  </div>
                              </div>

                              {
                                  this.state.errors.emptyVarient
                                  &&
                                      <span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.emptyVarient}</span>
                              }

                              {
                                  this.state.isVarien
                                  &&
                                      <div className="">
                                          <div className="row">
                                              <div className="col-md-4">
                                                  {
                                                      this.state.varientArray.map((varient , i) => {
                                                          const { name, values } = varient
                                                          return(
                                                              <div key={i} className="form-group">
                                                                  <input
                                                                      value={name}
                                                                      type='text'
                                                                      list='variantOptions'
                                                                      onChange={(e) => this.handleVarientOption(e, i)}
                                                                      placeholder="Select an option"
                                                                      className="mb-3"
                                                                  />
                                                                  <h5 onClick={(e) => this.handleRemoveVarient(e, i)}
                                                                      style={{float: "right", cursor: "pointer", fontWeight: "bold"}}
                                                                      className="text-danger">
                                                                      X
                                                                  </h5>
                                                                  <datalist id='variantOptions'>
                                                                      <option value='color'>Color</option>
                                                                      <option value='size'>Size</option>
                                                                      <option value='style'>Style</option>
                                                                      <option value='material'>Material</option>
                                                                      <option value='title'>Title</option>
                                                                  </datalist>
                                                                  <TagsInput 
                                                                      value={values}
                                                                      onChange={(tags) => this.handleVarientTag(tags, i)}
                                                                      onlyUnique
                                                                      addKeys={[9,13,32,188]}
                                                                      label={"Tags/Keywords"}
                                                                  />
                                                                  <hr></hr>
                                                              </div>
                                                          )
                                                      })
                                                  }
                                                  <div className="">
                                                      <button className="btn btn_new btn-primary-outline" 
                                                              onClick={this.handleAddVarient.bind(this)}>
                                                          Add Variant
                                                      </button>
                                                  </div>
                                              </div>
                                              <div className="col-md-8">
                                                  {
                                                      this.state.varientArrayCombo.map((singleVarient , i) => {
                                                          const { varient, price, mrp,height,width,length,cbm,weight, quantity, sku,weightLabel } = singleVarient
                                                          return(
                                                              <div key={i} className="form-group">
                                                                  <div className="formgroup" style={{width: "180px", float: "left"}}>
                                                                      <label>Variant</label><br></br>
                                                                      <input type="text"
                                                                          className="form-control"
                                                                          value={varient}
                                                                          disabled/>
                                                                  </div>
                                                                  <div className="formgroup" style={{width: "100px", float: "left"}}>
                                                                      <label>Price</label><br></br>
                                                                      <input type="number"
                                                                          className="form-control"
                                                                          value={price} 
                                                                          name="price"
                                                                          min="0"
                                                                          placeholder="Price"
                                                                          onChange={(e) => this.handleVarientInput(e, i)}/>
                                                                  </div>
                                                                  {/* <div className="formgroup" style={{width: "100px", float: "left"}}>
                                                                      <label>MRP</label><br></br>
                                                                      <input type="number"
                                                                          className="form-control"
                                                                          value={mrp}
                                                                          name="mrp"
                                                                          min="0"
                                                                          placeholder="Mrp"
                                                                          onChange={(e) => this.handleVarientInput(e, i)}/>
                                                                  </div> */}
                                                                  <div className="formgroup" style={{width: "100px", float: "left"}}>
                                                                      <label>Quantity</label><br></br>
                                                                      <input type="number"
                                                                          className="form-control"
                                                                          value={quantity}
                                                                          name="quantity"
                                                                          min="0"
                                                                          placeholder="Quantity"
                                                                          
                                                                          onChange={(e) => this.handleVarientInput(e, i)}/>
                                                                  </div>
                                                                  <div className="formgroup" style={{width: "100px", float: "left"}}>
                                                                      <label>SKU</label>
                                                                      <input type="text"
                                                                          className="form-control"
                                                                          value={sku}
                                                                          name="sku"
                                                                          placeholder="SKU"
                                                                          autoComplete="off"
                                                                          onChange={(e) => this.handleVarientInput(e, i)}/>
                                                                  </div>
                                                                  <div className="formgroup" style={{width: "100px", float: "left"}}>
                                                                      <label>CBM</label><br></br>
                                                                      <input type="number"
                                                                          className="form-control"
                                                                          value={cbm}
                                                                          name="cbm"
                                                                          min="0"
                                                                          placeholder="CBM"
                                                                          
                                                                          onChange={(e) => this.handleVarientInput(e, i)}/>
                                                                  </div>
                                                                  <div className="formgroup" style={{marginTop:"10px",width: "120px", float: "left"}}>
                                                                  
                                                                          <label>Length (In CM)</label><br></br>
                                                                      <input type="number"
                                                                          className="form-control"
                                                                          value={length}
                                                                          name="length"
                                                                          min="0"
                                                                          placeholder="Length"
                                                                          
                                                                          onChange={(e) => this.handleVarientInput(e, i)}/>
                                                                  </div> <div className="formgroup" style={{marginTop:"10px",width: "120px", float: "left"}}>
                                                                      <label>Breadth (In CM)</label><br></br>
                                                                      <input type="number"
                                                                          className="form-control"
                                                                          value={width}
                                                                          name="width"
                                                                          min="0"
                                                                          placeholder="Width"
                                                                          
                                                                          onChange={(e) => this.handleVarientInput(e, i)}/>
                                                                  </div> <div className="formgroup" style={{marginTop:"10px",width: "120px", float: "left"}}>
                                                                  <label>Height (In CM)</label><br></br>
                                                                  <input type="number"
                                                                      className="form-control"
                                                                      value={height}
                                                                      name="height"
                                                                      min="0"
                                                                      placeholder="Height"
                                                                      
                                                                      onChange={(e) => this.handleVarientInput(e, i)}/>
                                                                  </div> <div className="formgroup" style={{width: "100px",marginTop:"10px", float: "left"}}>
                                                                      <label>Weight</label><br></br>
                                                                      <input type="number"
                                                                          className="form-control"
                                                                          value={weight}
                                                                          name="weight"
                                                                          min="0"
                                                                          placeholder="Weight"
                                                                          
                                                                          onChange={(e) => this.handleVarientInput(e, i)}/>
                                                                  </div>
                                                                  <div className="formgroup" style={{width: "100px",marginTop:"10px", float: "left"}}>
                                                    <label>Measure</label>
                                                    <select className="form-control" id="weightLabel" name="weightLabel" value={weightLabel} onChange={(e) => this.handleVarientInput(e, i)}>
                                                        <option value="g">g</option>
                                                        <option value="lb">lb</option>
                                                        <option value="oz">oz</option>
                                                        <option value="kg">kg</option>
                                                    </select>
                                                </div>
                                                                  <br></br>
                                                                  <label className="btn btn_new" style={{marginTop:"9px"}}>
                                                                      Choose Images
                                                                      <input type="file"
                                                                          className="hidden"
                                                                          multiple
                                                                          accept="image/*"
                                                                          onChange={(e) => this.handleVarientMultipleImages(e, i)}/>
                                                                  </label>
                                                                  <div className="">
                                                                      <div className="row">
                                                                      {!_.isEmpty(singleVarient.imagesArray) && singleVarient.imagesArray.map((image, imageId) => {
                                                                          return (
                                                                              <div className='col-sm-3 col-md-3 col-lg-3 show-image1' key={imageId}>
                                                                                  <span className="closeButton1" onClick={() => this.handleVarientImageDelete(i, imageId)}>
                                                                                      <i className="fa fa-times" aria-hidden="true"></i>
                                                                                  </span>
                                                                                  <div className="img-overlay1"></div>
                                                                                  <img
                                                                                      src={image}
                                                                                      alt='uploadImage'
                                                                                      className='images1'
                                                                                  />
                                                                              </div>
                                                                          ) 
                                                                      })}
                                                                      </div>
                                                                  </div>
                                                                  <br></br><br></br> 
                                                              </div>
                                                          )
                                                      })
                                                  }
                                              </div>
                                          </div>
                                      </div>
                              }
                          </div>


                          {
                              !this.state.isVarien
                              &&
                                  <div>
                                      <div className="card" style={{padding: "0 2em", marginBottom:"0"}}>
                                          <div className="">
                                              <div className="row">
                                                  <div className="col-md-6">
                                                      <div className='form-group'>
                                                          <label htmlFor='idOrSku' style={{fontSize: 15 }}>ID/SKU&nbsp;
                                                              <span className="custom-tooltip" data-tooltip="Please put the Initial of your brand name in front of the numbers .EG-JVS11111111">
                                                                  <i className="fa fa-question-circle" aria-hidden="true"></i>
                                                              </span>
                                                          </label>
                                                          <input
                                                              type='text'
                                                              value={this.state.idOrSku}
                                                              onChange={this.handleIdOrSku.bind(this)}
                                                              className='form-control'
                                                              id='idOrSku'
                                                              placeholder='Enter Unique Id of Product'
                                                          />
                                                      </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                      <div className='form-group'>
                                                          <label style={{fontSize: 15 }}><span className="text-danger">*</span> Ex Factory Price&nbsp;
                                                              <span className="custom-tooltip" data-tooltip="The price that you want to sell the product on . All our further calculation will happen on this price">
                                                                  <i className="fa fa-question-circle" aria-hidden="true"></i>
                                                              </span>
                                                          </label>
                                                          <div
                                                              className='form-control '
                                                              style={{
                                                                  border: '1px solid #ddd',
                                                                  display: 'flex',
                                                                  flexDirection: 'row',
                                                              }}
                                                              >
                                                              <span className='icon-wrapp'><i className='input-icon fa fa-inr'></i></span>
                                                              <input
                                                                  className='input-with-icon'
                                                                  id='factoryPrice'
                                                                  type='number'
                                                                  min='0'
                                                                  step=".01"
                                                                
                                                                  value={this.state.factoryPrice}
                                                                  onChange={this.handleFactoryPrice.bind(this)}
                                                                  style={{ border: 'none', width: '48vw' }}
                                                                  placeholder='Enter Price'
                                                              />
                                                          </div>
                                                          {
                                                              this.state.errors.factoryPrice
                                                              &&
                                                                  <span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.factoryPrice}</span>
                                                          }
                                                      </div>
                                                  </div>
                                              </div>
                                              <br></br>
                                              <div className="row">
                                                  {/* <div className="col-md-6">
                                                      <div className='form-group'>
                                                          <label style={{fontSize: 15 }}>MRP&nbsp;
                                                              <span className="custom-tooltip" data-tooltip="Manufacturer calculated Price of the Product">
                                                                  <i className="fa fa-question-circle" aria-hidden="true"></i>
                                                              </span>
                                                          </label>
                                                          <div
                                                              className='form-control '
                                                              style={{
                                                                  border: '1px solid #ddd',
                                                                  display: 'flex',
                                                                  flexDirection: 'row',
                                                              }}
                                                              >
                                                              <span className='icon-wrapp'><i className='input-icon fa fa-inr'></i></span>
                                                              <input
                                                                  className='input-with-icon'
                                                                  id='mrp'
                                                                  type='number'
                                                                  min='0'
                                                                  step=".01"
                                                                  maxLength="10"
                                                                  value={this.state.mrp}
                                                                  onChange={this.handleMrp.bind(this)}
                                                                  style={{ border: 'none', width: '48vw' }}
                                                                  placeholder='Enter MRP'
                                                              />
                                                          </div>
                                                          {
                                                              this.state.errors.mrp
                                                              &&
                                                                  <span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.mrp}</span>
                                                          }
                                                      </div>
                                                  </div> */}
                                                  <div className="col-md-6">
                                                      <div className='form-group'>
                                                          <label htmlFor='quantity'><span className="text-danger">*</span> Quantity&nbsp;
                                                              <span className="custom-tooltip" data-tooltip="The number of products that you can supply at one given time">
                                                                  <i className="fa fa-question-circle" aria-hidden="true"></i>
                                                              </span>
                                                          </label>
                                                          <input
                                                              type='number'
                                                              min='0'
                                                              step="any"
                                                             
                                                              value={this.state.quantity}
                                                              onChange={this.handleQuantity.bind(this)}
                                                              className='form-control'
                                                              id='quantity'
                                                              placeholder='Enter Available Quanity of Product'
                                                          />
                                                          {
                                                              this.state.errors.quantity
                                                              &&
                                                                  <span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.quantity}</span>
                                                          }
                                                      </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                      <div className='form-group'>
                                                          <label htmlFor='cbm'><span className="text-danger">*</span>CBM&nbsp;
                                                              <span className="custom-tooltip" data-tooltip="cbm">
                                                                  <i className="fa fa-question-circle" aria-hidden="true"></i>
                                                              </span>
                                                          </label>
                                                          <input
                                                              type='number'
                                                              min='0'
                                                              step="any"
                                                            
                                                              value={this.state.cbm}
                                                              onChange={this.handleCbm.bind(this)}
                                                              className='form-control'
                                                              id='cbm'
                                                              placeholder='CBM'
                                                          />
                                                          {
                                                              this.state.errors.cbm
                                                              &&
                                                                  <span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.cbm}</span>
                                                          }
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                          }

                          <div className="card" style={{padding: "2em", marginBottom:"0"}}>
                            
                            <div className="">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor='selectCategory'><span className="text-danger">*</span> Select Category&nbsp;
                                                <span className="custom-tooltip" data-tooltip="Items are assigned based on similarity or defined criteria">
                                                    <i className="fa fa-question-circle" aria-hidden="true"></i>
                                                </span>
                                            </label>
                                            <select className="form-control" id="selectCategory" onChange={this.handleSelectedCategory.bind(this)}>
                                                <option value="Select Category">Select Category</option>
                                                {
                                                    this.state.categoryList.map((cat , i) => {
                                                        const { category,_id } = cat
                                                        return( <option value={category} date-_id={_id} key={i}>{category}</option> )
                                                    })
                                                }
                                            </select>
                                            {
                                                this.state.errors.category
                                                &&
                                                    <span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.category}</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br></br>
                            <div className="">
                                <div className="row">
                                    {/* <div className="col-md-6">
                                        <div className='form-group'>
                                            <label htmlFor='hsn'><span className="text-danger">*</span> HSN&nbsp;
                                                <span className="custom-tooltip" data-tooltip="standardized numerical method of classifying traded product">
                                                    <i className="fa fa-question-circle" aria-hidden="true"></i>
                                                </span>
                                            </label>
                                            <input
                                                type='text'
                                                value={this.state.hsn}
                                                onChange={this.handleHsn.bind(this)}
                                                className='form-control'
                                                id='hsn'
                                                placeholder='Enter HSN of Product'
                                                autoComplete="off"
                                            />
                                            {
                                                this.state.errors.hsn
                                                &&
                                                    <span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.hsn}</span>
                                            }
                                        </div>
                                    </div> */}
                                    
                                    <div className="col-md-6">
                                        <div className='form-group'>
                                            <label htmlFor='warranty'>Warranty&nbsp;
                                                <span className="custom-tooltip" data-tooltip="Written statement, which promises to repair or replace if product does not work">
                                                    <i className="fa fa-question-circle" aria-hidden="true"></i>
                                                </span>
                                            </label>
                                            <input
                                                type='text'
                                                value={this.state.warranty}
                                                onChange={this.handleWarrenty.bind(this)}
                                                className='form-control'
                                                id='warranty'
                                                placeholder='Enter Available warranty of Product'
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br></br>
                            {
                              !this.state.isVarien
                              &&
                            <div className="">
                                <div className="row">
                                <div className="col-md-6">
                                        <div className="">
                                            <div className="row">
                                                <div className="col-md-9 col-sm-6">
                                                    <div className='form-group'>
                                                        <label htmlFor='weight'><span className="text-danger">*</span> Weight&nbsp;
                                                            <span className="custom-tooltip" data-tooltip="The amount or quantity of heaviness">
                                                                <i className="fa fa-question-circle" aria-hidden="true"></i>
                                                            </span>
                                                        </label>
                                                        <input
                                                            type='text'
                                                            value={this.state.weight}
                                                            onChange={this.handleWeight.bind(this)}
                                                            className='form-control'
                                                            id='weight'
                                                            placeholder='Enter Weight of Product'
                                                            autoComplete="off"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <label>Measure</label>
                                                    <select className="form-control" id="weightLabel" onChange={this.handleWeightMeasure.bind(this)}>
                                                        <option value="g">g</option>
                                                        <option value="lb">lb</option>
                                                        <option value="oz">oz</option>
                                                        <option value="kg">kg</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {
                                                this.state.errors.weight
                                                &&
                                                    <span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.weight}</span>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className='form-group'>
                                                        <label htmlFor='height'>Height (In CM)&nbsp;
                                                            <span className="custom-tooltip" data-tooltip="Height of the product in centimeters">
                                                                <i className="fa fa-question-circle" aria-hidden="true"></i>
                                                            </span>
                                                        </label>
                                                        <input
                                                            type='number'
                                                            value={this.state.height}
                                                            onChange={this.handleHeight.bind(this)}
                                                            className='form-control'
                                                            id='height'
                                                            min="0"
                                                            placeholder='Height'
                                                            autoComplete="off"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className='form-group'>
                                                        <label htmlFor='width'>Width (In CM)&nbsp;
                                                            <span className="custom-tooltip" data-tooltip="Width of the product  in centimeters">
                                                                <i className="fa fa-question-circle" aria-hidden="true"></i>
                                                            </span>
                                                        </label>
                                                        <input
                                                            type='number'
                                                            value={this.state.width}
                                                            onChange={this.handleWidth.bind(this)}
                                                            className='form-control'
                                                            id='width'
                                                            min="0"
                                                            placeholder='Width'
                                                            autoComplete="off"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className='form-group'>
                                                        <label htmlFor='length'>Length (In CM)&nbsp;
                                                            <span className="custom-tooltip" data-tooltip="Length of the product  in centimeters">
                                                                <i className="fa fa-question-circle" aria-hidden="true"></i>
                                                            </span>
                                                        </label>
                                                        <input
                                                            type='number'
                                                            value={this.state.length}
                                                            onChange={this.handleLength.bind(this)}
                                                            className='form-control'
                                                            id='length'
                                                            min="0"
                                                            placeholder='Length'
                                                            autoComplete="off"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                this.state.errors.hwlError
                                                &&
                                                    <span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.hwlError}</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            <br></br>
                            {/* <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                        <div className='form-group'>
                                            <label htmlFor='bcd'>BCD&nbsp;
                                                <span className="custom-tooltip" data-tooltip="BCD">
                                                    <i className="fa fa-question-circle" aria-hidden="true"></i>
                                                </span>
                                            </label>
                                            <input
                                                type='number'
                                                value={this.state.bcd}
                                                onChange={this.handleBcd.bind(this)}
                                                className='form-control'
                                                id='bcd'
                                                min="0"
                                                placeholder='BCD'
                                                autoComplete="off"
                                            />
                                        </div>
                                        {
                                            this.state.errors.bcd
                                            &&
                                                <span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.bcd}</span>
                                        }
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                        <div className='form-group'>
                                            <label htmlFor='igst'>IGST&nbsp;
                                                <span className="custom-tooltip" data-tooltip="IGST">
                                                    <i className="fa fa-question-circle" aria-hidden="true"></i>
                                                </span>
                                            </label>
                                            <input
                                                type='number'
                                                value={this.state.igst}
                                                onChange={this.handleIgst.bind(this)}
                                                className='form-control'
                                                id='igst'
                                                min="0"
                                                placeholder='IGST'
                                                autoComplete="off"
                                            />
                                        </div>
                                        {
                                            this.state.errors.igst
                                            &&
                                                <span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.igst}</span>
                                        }
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                        <div className='form-group'>
                                            <label htmlFor='swc'>SWC&nbsp;
                                                <span className="custom-tooltip" data-tooltip="SWC">
                                                    <i className="fa fa-question-circle" aria-hidden="true"></i>
                                                </span>
                                            </label>
                                            <input
                                                type='number'
                                                value={this.state.swc}
                                                onChange={this.handleSwc.bind(this)}
                                                className='form-control'
                                                id='swc'
                                                min="0"
                                                placeholder='SWC'
                                                autoComplete="off"
                                            />
                                        </div>
                                        {
                                            this.state.errors.swc
                                            &&
                                                <span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.swc}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                            <br></br> */}
                            <div className="">
                                <h4 style={{marginTop:"0"}}>Shipping Details&nbsp;
                                    <span className="custom-tooltip" data-tooltip="Select shipping detail">
                                        <i className="fa fa-question-circle" aria-hidden="true"></i>
                                    </span>
                                </h4>
                                {
                                    this.state.errors.shippingMode
                                    &&
                                        <span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.shippingMode}</span>
                                }
                                <div className="form-group">
                                    <div className="form-check">
                                        <input className="form-check-input" 
                                                type="radio" 
                                                name="flexRadioDefault" 
                                                id="freeShipping" 
                                                value="freeShipping"
                                                onChange={this.handleShipping.bind(this)} />
                                        <label className="form-check-label" htmlFor="freeShipping">
                                            &nbsp;&nbsp;Free ePacket Shipping
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" 
                                                type="radio" 
                                                name="flexRadioDefault" 
                                                id="standardShipping" 
                                                value="standardShipping"
                                                onChange={this.handleShipping.bind(this)} />
                                        <label className="form-check-label" htmlFor="standardShipping">
                                            &nbsp;&nbsp;Standard ePacket Shipping
                                        </label>
                                    </div>
                                </div>

                                <div id="showOnStandardShipping">
                                    <div className="">
                                        <h5>Shipping Price</h5>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className='form-group'>
                                                    <label className='col-form-label'><span className="text-danger">*</span> USA</label>
                                                    <div
                                                        className='form-control'
                                                        style={{
                                                            border: '1px solid #ddd',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                        }}
                                                    >
                                                        <span className='icon-wrapp'>
                                                            <i className='input-icon fa fa-inr'></i>
                                                        </span>
                                                        <input
                                                            className='input-with-icon'
                                                            id='usaShippingPrice'
                                                            type='number'
                                                            min='0'
                                                            step=".01"
                                                         
                                                            value={this.state.usaShippingPrice}
                                                            onChange={this.handleUsaShippingPrice.bind(this)}
                                                            style={{ border: 'none', width: "100%" }}
                                                            placeholder='Enter Shipping Charges'
                                                        />
                                                    </div>
                                                    {
                                                        this.state.errors.usaShippingPrice
                                                        &&
                                                            <span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.usaShippingPrice}</span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className='form-group'>
                                                    <label className='col-form-label'><span className="text-danger">*</span> Canada</label>
                                                    <div
                                                        className='form-control'
                                                        style={{
                                                            border: '1px solid #ddd',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                        }}
                                                    >
                                                        <span className='icon-wrapp'>
                                                            <i className='input-icon fa fa-inr'></i>
                                                        </span>
                                                        <input
                                                            className='input-with-icon'
                                                            id='canadaShippingPrice'
                                                            type='number'
                                                            min='0'
                                                            step=".01"
                                                           
                                                            value={this.state.canadaShippingPrice}
                                                            onChange={this.handleCanadaShippingPrice.bind(this)}
                                                            style={{ border: 'none', width: "100%" }}
                                                            placeholder='Enter Shipping Charges'
                                                        />
                                                    </div>
                                                    {
                                                        this.state.errors.canadaShippingPrice
                                                        &&
                                                            <span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.canadaShippingPrice}</span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className='form-group'>
                                                    <label className='col-form-label'><span className="text-danger">*</span> United Kingdom</label>
                                                    <div
                                                        className='form-control'
                                                        style={{
                                                            border: '1px solid #ddd',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                        }}
                                                    >
                                                        <span className='icon-wrapp'>
                                                            <i className='input-icon fa fa-inr'></i>
                                                        </span>
                                                        <input
                                                            className='input-with-icon'
                                                            id='ukShippingPrice'
                                                            type='number'
                                                            min='0'
                                                            step=".01"
                                                          
                                                            value={this.state.ukShippingPrice}
                                                            onChange={this.handleUkShippingPrice.bind(this)}
                                                            style={{ border: 'none', width: "100%" }}
                                                            placeholder='Enter Shipping Charges'
                                                        />
                                                    </div>
                                                    {
                                                        this.state.errors.ukShippingPrice
                                                        &&
                                                            <span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.ukShippingPrice}</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className='form-group'>
                                                    <label className='col-form-label'><span className="text-danger">*</span> Australia</label>
                                                    <div
                                                        className='form-control'
                                                        style={{
                                                            border: '1px solid #ddd',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                        }}
                                                    >
                                                        <span className='icon-wrapp'>
                                                            <i className='input-icon fa fa-inr'></i>
                                                        </span>
                                                        <input
                                                            className='input-with-icon'
                                                            id='ausShippingPrice'
                                                            type='number'
                                                            min='0'
                                                            step=".01"
                                                          
                                                            value={this.state.ausShippingPrice}
                                                            onChange={this.handleAusShippingPrice.bind(this)}
                                                            style={{ border: 'none', width: "100%" }}
                                                            placeholder='Enter Shipping Charges'
                                                        />
                                                    </div>
                                                    {
                                                        this.state.errors.ausShippingPrice
                                                        &&
                                                            <span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.ausShippingPrice}</span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className='form-group'>
                                                    <label className='col-form-label'><span className="text-danger">*</span> International</label>
                                                    <div
                                                        className='form-control'
                                                        style={{
                                                            border: '1px solid #ddd',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                        }}
                                                    >
                                                        <span className='icon-wrapp'>
                                                            <i className='input-icon fa fa-inr'></i>
                                                        </span>
                                                        <input
                                                            className='input-with-icon'
                                                            id='interShippingPrice'
                                                            type='number'
                                                            min='0'
                                                            step=".01"
                                                           
                                                            value={this.state.interShippingPrice}
                                                            onChange={this.handleInterShippingPrice.bind(this)}
                                                            style={{ border: 'none', width: "100%" }}
                                                            placeholder='Enter Shipping Charges'
                                                        />
                                                    </div>
                                                    {
                                                        this.state.errors.interShippingPrice
                                                        &&
                                                            <span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.interShippingPrice}</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                
                                {/* <h4>Select Status&nbsp;
                                    <span className="custom-tooltip" data-tooltip="Select product status">
                                        <i className="fa fa-question-circle" aria-hidden="true"></i>
                                    </span>
                                </h4>
                                {
                                    this.state.errors.productStatus
                                    &&
                                        <span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.productStatus}</span>
                                }
                                <div className="form-group">
                                    <label>Product Status</label>
                                    <div className="form-check form-check-inline">
                                        &emsp;&emsp;<input className="form-check-input" type="radio" name="pdStatus" id="pdStatus" value="active" onChange={this.handlePdStatus.bind(this)}/>
                                        <label className="form-check-label" htmlFor="pdStatus">&nbsp;&nbsp;Active</label>
                                        &emsp;&emsp;<input className="form-check-input" type="radio" name="pdStatus" id="pdStatus1" value="inActive" onChange={this.handlePdStatus.bind(this)}/>
                                        <label className="form-check-label" htmlFor="pdStatus1">&nbsp;&nbsp;In Active</label>
                                    </div>

                                    <label>Display Status</label>
                                    <div className="form-check form-check-inline">
                                        &emsp;&emsp;<input className="form-check-input" type="radio" name="displayStatus" id="isDisplay" value="active" onChange={this.handleDisplayStatus.bind(this)}/>
                                        <label className="form-check-label" htmlFor="isDisplay">&nbsp;&nbsp;Active</label>
                                        &emsp;&emsp;<input className="form-check-input" type="radio" name="displayStatus" id="isDisplay1" value="inActive" onChange={this.handleDisplayStatus.bind(this)}/>
                                        <label className="form-check-label" htmlFor="isDisplay1">&nbsp;&nbsp;In Active</label>
                                    </div>
                                </div> */}
                            </div>
                            <br></br>
                            {/* <div className="continer-fluid">
                                <h4>Add Sub Supplier&nbsp;
                                    <span className="custom-tooltip" data-tooltip="Add sub supplier to the product">
                                        <i className="fa fa-question-circle" aria-hidden="true"></i>
                                    </span>
                                </h4>
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                        <select id="subSupplier" className="form-control" onChange={this.handleOnchangeSubSupplier.bind(this)}>
                                            <option value="Select Supplier">Select Supplier</option>
                                            {
                                                this.state.supplierList.map(supplier => {
                                                    const  { supplier_id, _id } = supplier
                                                    return(
                                                        <option value={_id} selected={this.state.selectedSupplier === supplier_id && true}>{supplier_id}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-2 col-xl-2">
                                        <input type="number" 
                                                placeholder="Enter priority"
                                                min="0"
                                                max="100"
                                                className="form-control"
                                                value={this.state.priority}
                                                onChange={(e) => this.setState({priority: e.target.value, errors: {}})}></input>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-2 col-xl-2">
                                        <button className="btn btn-outline-primary" onClick={this.handleSubSupplier}>{this.state.editMode ? "Update" : "Add"}</button>
                                    </div>
                                    <br></br><br></br>
                                    {
                                        this.state.errors.subSupplierError
                                        &&
                                        <span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.subSupplierError}</span>
                                    }
                                </div>
                                <br></br>
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                        {
                                            !_.isEmpty(this.state.subSupplierList)
                                            &&
                                                <table className="table table-hover">
                                                    <thead>
                                                        <th>Supplier Name</th>
                                                        <th>Priority</th>
                                                        <th>Action</th>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.subSupplierList.map((supplier, index) => {
                                                                const { priority, sid , supplierName } = supplier
                                                                return(
                                                                    <tr>
                                                                        <td>{supplierName}</td>
                                                                        <td>{priority}</td>
                                                                        <td colSpan="2">
                                                                            <span onClick={() => this.handleSubSupplierEdit(sid , index)}><i className="fa fa-pencil" aria-hidden="true"></i></span>
                                                                            &emsp;
                                                                            <span onClick={() => this.handleSubSupplierDelete(sid)}><i className="fa fa-trash" aria-hidden="true"></i></span>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                        }
                                    </div>
                                </div>
                            </div>
                            <br></br> */}
                            {
                            
                                <div>
                                    <div className="form-group">
                                        <label>Is Battery</label>
                                        <div className="form-check form-check-inline">
                                            &emsp;&emsp;<input className="form-check-input" 
                                                            type="radio" 
                                                            name="isBattery" 
                                                            id="isBattery" 
                                                            value="1" 
                                                            checked={this.state.isBattery === "1" && true}
                                                            onChange={this.handleIsBattery.bind(this)}/>
                                            <label className="form-check-label" htmlFor="isBattery">&nbsp;&nbsp;Yes</label>
                                            &emsp;&emsp;<input className="form-check-input" 
                                                                type="radio" 
                                                                name="isBattery" 
                                                                id="isBattery1" 
                                                                value="0" 
                                                                checked={this.state.isBattery === "0" && true}
                                                                onChange={this.handleIsBattery.bind(this)}/>
                                            <label className="form-check-label" htmlFor="isBattery1">&nbsp;&nbsp;No</label>
                                        </div>

                                        <label>Liquid</label>
                                        <div className="form-check form-check-inline">
                                            &emsp;&emsp;<input className="form-check-input" 
                                                            type="radio" 
                                                            name="displayStatus" 
                                                            id="isDisplay" 
                                                            value="1" 
                                                            checked={this.state.isLiquid === "1" && true}
                                                            onChange={this.handleIsLiquid.bind(this)}/>
                                            <label className="form-check-label" htmlFor="isDisplay">&nbsp;&nbsp;Yes</label>
                                            &emsp;&emsp;<input className="form-check-input" 
                                                            type="radio" 
                                                            name="displayStatus" 
                                                            id="isDisplay1" 
                                                            value="0" 
                                                            checked={this.state.isLiquid === "0" && true}
                                                            onChange={this.handleIsLiquid.bind(this)}/>
                                            <label className="form-check-label" htmlFor="isDisplay1">&nbsp;&nbsp;No</label>
                                        </div>
                                    </div>
                                    <br></br>
                                </div>
                            }

                            
                            {
                                <div>
                                    <div className="card">
                                        <div className="">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className='form-group'>
                                                        <label htmlFor='numberOfItems' style={{fontSize: 15 }}>Number of items&nbsp;
                                                            <span className="custom-tooltip" data-tooltip="Number(numeric) how may items are in a pack">
                                                                <i className="fa fa-question-circle" aria-hidden="true"></i>
                                                            </span>
                                                        </label>
                                                        <input
                                                            type='text'
                                                            value={this.state.numberOfItems}
                                                            onChange={this.handleNumberOfItems.bind(this)}
                                                            className='form-control'
                                                            id='numberOfItems'
                                                            placeholder='Number of itmes'
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className='form-group'>
                                                        <label style={{fontSize: 15 }}>Package Includes&nbsp;
                                                            <span className="custom-tooltip" data-tooltip="A group of objects packed in a box. ( description)">
                                                                <i className="fa fa-question-circle" aria-hidden="true"></i>
                                                            </span>
                                                        </label>
                                                        <div
                                                            className='form-control '
                                                            style={{
                                                                border: '1px solid #ddd',
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                            }}
                                                            >
                                                            <input
                                                                className='input-with-icon'
                                                                id='packageIncludes'
                                                                type='text'
                                                                min='0'
                                                                step=".01"
                                                              
                                                                value={this.state.packageIncludes}
                                                                onChange={this.handlePackageIncludes.bind(this)}
                                                                style={{ border: 'none', width: '48vw' }}
                                                                placeholder='Package Includes'
                                                            />
                                                        </div>
                                                        {
                                                            this.state.errors.packageIncludes
                                                            &&
                                                                <span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.packageIncludes}</span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                        {
                            <div>
                                <div className="card">
                                    <div className="">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className='form-group'>
                                                    <label htmlFor='yearOfManufacturer' style={{fontSize: 15 }}>Year of Manufacturer&nbsp;
                                                        <span className="custom-tooltip" data-tooltip="Year of Manufacturer">
                                                            <i className="fa fa-question-circle" aria-hidden="true"></i>
                                                        </span>
                                                    </label>
                                                    <input
                                                        type='text'
                                                        value={this.state.yearOfManufacturer}
                                                        onChange={this.handleYearOfManufacturer.bind(this)}
                                                        className='form-control'
                                                        id='yearOfManufacturer'
                                                        placeholder='Year of Manufacturer'
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className='form-group'>
                                                    <label style={{fontSize: 15 }}>Expiry &nbsp;
                                                        <span className="custom-tooltip" data-tooltip="Product Expiry">
                                                            <i className="fa fa-question-circle" aria-hidden="true"></i>
                                                        </span>
                                                    </label>
                                                    <div
                                                        className='form-control '
                                                        style={{
                                                            border: '1px solid #ddd',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                        }}
                                                        >
                                                        <input
                                                            className='input-with-icon'
                                                            id='productExpiry'
                                                            type='text'
                                                            min='0'
                                                            step=".01"
                                                           
                                                            value={this.state.productExpiry}
                                                            onChange={this.handleProductExpiry.bind(this)}
                                                            style={{ border: 'none', width: '48vw' }}
                                                            placeholder='Product Expiry'
                                                        />
                                                    </div>
                                                    {
                                                        this.state.errors.productExpiry
                                                        &&
                                                            <span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.productExpiry}</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        </div>
                        </div>
                        </div>
                        {/* basic form ends */}

                        {/* amazon template form starts */}
                        <div role="tabpanel" className="tab-pane fade" id="amazonForm">
                            <div id="amazonTemplateForm" style={{display:"none"}}>
                          <div className="card" style={{padding: "3em", marginBottom:"0"}}>

                              <div className="form-group">
                                  <label style={{fontSize: 15 }}>ASIN &nbsp;
                                      <span className="custom-tooltip" data-tooltip="hosts products of individual sellers on the same domain">
                                          <i className="fa fa-question-circle" aria-hidden="true"></i>
                                      </span>
                                  </label>
                                  <br></br>
                                  <input type="text"
                                          placeholder="ASIN"
                                          className="form-control"
                                          value={this.state.marketPlaceUrl}
                                          onChange={this.handleMarketPlaceUrl.bind(this)} />
                                  {
                                      this.state.errors.marketPlaceUrl
                                      &&
                                      <span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.marketPlaceUrl}</span>
                                  }
                              </div>
                              <br></br>

                              <div className="container-fluid bullet-container">
                                {[...Array(5).keys()].map((el,i)=>{ 
                                  return (<div className="row" key={i}>
                                    <div className="col-md-8" style={{padding:"inherit"}}>
                                      <div className="form-group">
                                        <label style={{fontSize: 15 }}>Bullet Points. &nbsp;
                                            <span className="custom-tooltip" data-tooltip="Bullet Points">
                                                <i className="fa fa-question-circle" aria-hidden="true"></i>
                                            </span>
                                        </label>
                                        <br></br>
                                        <input type="text" placeholder="Bullet Points" name="bulletpoints[]" className="form-control bullet-points-input"/>
                                      </div>
                                    </div>

                                    <div className="col-md-4" style={{marginTop:"25px"}}>
                                      { el == 0 ? 
                                        <div className="form-group button">
                                          <button className="form-control add-new-bullet">Add New Bullet</button>
                                        </div> : ""
                                      }
                                    </div>
                                  </div>)
                                })
                                }
                              </div>

                              <div className="container-fluid">
                                  <div className="row">
                                      <div className="col-md-12" style={{padding:"inherit"}}>
                                          <div className="form-group">
                                              <label style={{fontSize: 15 }}>Select Category. &nbsp;
                                                  <span className="custom-tooltip" data-tooltip="Select Category">
                                                      <i className="fa fa-question-circle" aria-hidden="true"></i>
                                                  </span>
                                              </label>
                                              <br></br>
                                              {this.state.browseNode ? <div className="col-sm-3 col-md-3 col-lg-3" style={{marginBottom:"10px"}}>Browse Nodes - <label data-index="0">{this.state.browseNode}</label></div> : ""}
                                              <Autocomplete
                                                id="browse-nodes"
                                                open={this.state.open}
                                                onOpen={() => {
                                                  this.setState({
                                                    open: true
                                                  });
                                                }}
                                                onInput={this.changeCatData.bind(this)}
                                                onClose={() => {
                                                  this.setState({
                                                    open: false
                                                  });
                                                }}
                                                getOptionSelected={(option, value) => option.path === value.path}
                                                onChange={ this.onBrowseNodeSelect.bind(this) }
                                                getOptionLabel={(option) => option.path}
                                                options={this.state.options}
                                                loading={this.state.loading}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    label=""
                                                    variant="outlined"
                                                    InputProps={{
                                                      ...params.InputProps,
                                                      endAdornment: (
                                                        <React.Fragment>
                                                          {this.state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                          {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                      ),
                                                    }}
                                                  />
                                                )}
                                              />
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <br></br>
                              <div className="custom-field-container">
                               <div className="row">
                                  <div className="col-md-9">
                                    <div className="form-group">
                                      <label style={{fontSize: 15 }} className="custom-field">Custom Field &nbsp;
                                          <span className="custom-tooltip" data-tooltip="Custom Field">
                                              <i className="fa fa-question-circle" aria-hidden="true"></i>
                                          </span>
                                      </label>
                                      <br></br>
                                      <div className="row">
                                        <div className="col-md-6">
                                          <input type="text" placeholder="Field Name" name="customfieldkeys[]" className="form-control custom-field-keys-input"/>
                                        </div>
                                        <div className="col-md-6">
                                          <input type="text" placeholder="Field Value" name="customfieldvalues[]" className="form-control custom-field-values-input"/>
                                        </div>
                                      </div>
                                      
                                    </div>
                                  </div>

                                  <div className="col-md-3" style={{marginTop:"25px"}}>
                                      <div className="form-group button">
                                        <button className="form-control add-new-field">Add More Fields</button>
                                      </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                        </div>
                        {/* amazon template form ends */}
                        </div>
                    </div>
                </div>
                <div className={this.state.loading ? "parentDisable" : ""}>
                    <div className="mt-3" style={{marginTop:"25px", marginBottom:"25px"}}>
                        {/* <div className="card" style={{"paddingTop": "20px","paddingBottom":"20px"}}>   
                          <div className="container-fluid">
                              <div className="row">
                                  <div className="col-md-3">
                                    <CustomButton fill type='button' id="basicFormButton" onClick={this.changeForm}>
                                        Basic Form
                                    </CustomButton>
                                  </div>
                                  <div className="col-md-3">
                                    <CustomButton fill type='button' id="amazonTemplateFormButton" onClick={this.changeForm}>
                                        Amazon Template Form
                                    </CustomButton>
                                  </div>
                              </div>
                          </div>
                        </div> */}

                        

                        
                        <div className="container-fluid">
                            <div className='pull-left'>
                                <CustomButton fill type='submit' id="addProductBtn" onClick={this.addProduct}>
                                    Add Product
                                </CustomButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}