import React, { useState, useMemo } from "react";
import axios from "axios";
import "../../assets/css/supplier.css";
import { NotificationManager } from "react-notifications";
import Select from "react-select";
import countryList from "react-select-country-list";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
function Supplier() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState("");
  // const [typeCheck, setTypeCheck] = useState(true);
  const [type, setType] = useState("text");
  const [origin, setOrigin] = useState("");
  const [priceAlgo, setPriceAlgo] = useState("");
  const [errors, setErrors] = useState({});

  const updateName = (e) => {
    setName(e.target.value);
  };

  const updateEmail = (e) => {
    setEmail(e.target.value);
  };

  const updatePwd = (e) => {
    setPassword(e.target.value);
  };

  // const handleClick = () => {
  //     setTypeCheck(!typeCheck);
  //     if (typeCheck == true) {
  //         setType('text');
  //     } else {
  //         setType('password');
  //     }
  // };

  const options = useMemo(() => countryList().getData(), []);

  const validate = () => {
    let errors = {};
    let isValid = true;
    if (!name) {
      isValid = false;
      errors["fullName"] = "Please enter your name.";
    }
    if (!origin) {
      isValid = false;
      errors["origin"] = "Please select origin.";
    }
    if (!priceAlgo) {
      isValid = false;
      errors["pricealgo"] = "Please select price algo.";
    }

    if (!email) {
      isValid = false;
      errors["email"] = "Please enter your email Address.";
    }

    if (typeof email !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        isValid = false;
        errors["email"] = "Please enter valid email address.";
      }
    }
    if (!password) {
      isValid = false;
      errors["password"] = "Please enter your password.";
    } else if (typeof password !== "undefined") {
      if (password.length < 8) {
        isValid = false;
        errors["password"] = "Password must be greater than eight.";
      }
    }
    setErrors(errors);
    return isValid;
  };

  const addSuplier = (e) => {
    e.preventDefault();
    if (validate()) {
      const obj = {
        supplier_id: name,
        email: email,
        password: password,
        origin: origin,
        price_algo: priceAlgo,
      };
      axios
        .post("/api/signUp", obj)
        .then((data) => {
          if (data) {
            NotificationManager.success("New Supplier Created Successfully");
            setName("");
            setEmail("");
            setPassword("");
            setOrigin("");
            setPriceAlgo("");
          }
        })
        .catch((err) => {
          NotificationManager.error("Id not Created" + err.message);
        });
    }
  };
  return (
    <div className="container-fluid">
      {/* <div
      style={{ padding: "1em", paddingBottom: "0px", marginTop: "15px" }}
      >

      <div className="form-group col-sm-12 col-md-12 col-lg-12">
        <form onSubmit={addSuplier}>
          <div className="form-group col-sm-3 col-md-3 col-lg-3"></div>
         
          <div
            className="form-group col-sm-6 col-md-6 col-lg-6"
            style={{ paddingLeft: "8px" }}
          >
            <label htmlFor="fullName" style={{ color: "black", fontWeight: "500px"}}>Username</label>
            <input
              type="text"
              className="form-control"
              name="fullName"
              value={name}
              onChange={updateName}
              placeholder="Please Enter Username"
              style={{border:"1px solid #1D8BF7"}}
            />
            <div className="text-danger">{errors.fullName}</div>
          </div>
          <div className="form-group col-sm-3 col-md-3 col-lg-3"></div>
          <div className="form-group col-sm-3 col-md-3 col-lg-3"></div>
          <div
            className="form-group col-sm-6 col-md-6 col-lg-6"
            style={{ paddingLeft: "8px" }}
          >
            <label htmlFor="email" style={{ color: "black", fontWeight: "500px"}}>Email</label>
            <input
            style={{border:"1px solid #1D8BF7"}}
              type="email"
              className="form-control"
              name="email"
              value={email}
              onChange={updateEmail}
              placeholder="Please Enter Email"
            />
            <div className="text-danger">{errors.email}</div>
          </div>
          <div className="form-group col-sm-3 col-md-3 col-lg-3"></div>

          <div className="form-group col-sm-3 col-md-3 col-lg-3"></div>
          <div
            className="form-group col-sm-6 col-md-6 col-lg-6"
            style={{ paddingLeft: "8px" }}
          >
            <label htmlFor="email" style={{ color: "black", fontWeight: "500px"}}>Origin</label>
            <Select
                options={options}
                value={origin}
                onChange={(e) => {
                  setOrigin(e);
                }}
              />
              <div className="text-danger">{errors.origin}</div>
          </div>
          <div className="form-group col-sm-3 col-md-3 col-lg-3"></div>

          <div className="form-group col-sm-3 col-md-3 col-lg-3"></div>
          <div
            className="form-group col-sm-6 col-md-6 col-lg-6"
            style={{ paddingLeft: "8px" }}
          >
            <label htmlFor="email" style={{ color: "black", fontWeight: "500px"}}>Price Algo Type</label>
              <select
              style={{border:"1px solid #1D8BF7"}}
                className="form-control"
                name="pricealgo"
                id="price_algo_type"
                onChange={(e) => {
                  setPriceAlgo(e.target.value);
                }}
                placeholder="Enter type"
                required
              >
                <option>Select Type</option>
                <option value="1">Qnu</option>
                <option value="2">Jv</option>
                <option value="3">Lyla/loomtree</option>
                <option value="4">Sfc</option>
                <option value="5">Cld</option>
              </select>
              <div className="text-danger">{errors.pricealgo}</div>
          </div>
          <div className="form-group col-sm-3 col-md-3 col-lg-3"></div>

          <div className="form-group col-sm-3 col-md-3 col-lg-3"></div>
          <div
            className="form-group col-sm-6 col-md-6 col-lg-6"
            style={{ paddingLeft: "8px" }}
          >
             <label htmlFor="password" style={{ color: "black", fontWeight: "500px"}}>Password</label>
              <input
              
                type={type}
                className="form-control"
                name="password"
                value={password}
                onChange={updatePwd}
                style={{ height: 40,border:"1px solid #1D8BF7" }}
                placeholder="Please Enter Password"
              />
              <small>Password must be eight characters in length.</small>
              <div className="text-danger">{errors.password}</div>
          </div>
          <div className="form-group col-sm-3 col-md-3 col-lg-3"></div>

          <div className="form-group col-sm-3 col-md-3 col-lg-3"></div>
          <div
            className="form-group col-sm-6 col-md-6 col-lg-6"
            style={{ paddingLeft: "8px" }}
          >
            <button
                    className="btn btn-fill"
                    style={{
                      backgroundColor: "#1D8BF7",
                      color: "rgb(255, 255, 255)",
                      fontWeight: "500",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 4,
                      border: "0px",
                      fontSize: "15px",
                    }}
                  >
                    Create
                  </button>
          </div>
         
          <div className="form-group col-sm-3 col-md-3 col-lg-3"></div>


        </form>
        </div>
      </div> */}

      <div className="form-group col-sm-3 col-md-3 col-lg-3"></div>
      <div
        className="form-group col-sm-6 col-md-6 col-lg-6"
        style={{ padding: "1em", paddingBottom: "0px", marginTop: "10px" }}
      >
        <Card sx={{ maxWidth: 500 }}>
          <CardActionArea id="cardContentClass">
            {/* <CardMedia
          component="img"
          height="140"
          image="https://i.ibb.co/q1ZcWFy/luke-chesser-p-Jad-Qetz-Tk-I-unsplash.jpg"
          alt="green iguana"
        /> */}
            <CardContent >
              <form onSubmit={addSuplier}>
                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                  <label
                    htmlFor="fullName"
                    style={{ color: "black", fontWeight: "500px" }}
                  >
                    Username
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="fullName"
                    value={name}
                    onChange={updateName}
                    placeholder="Please Enter Username"
                    style={{ border: "1px solid #1D8BF7" }}
                  />
                  <div className="text-danger">{errors.fullName}</div>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                  <label
                    htmlFor="email"
                    style={{ color: "black", fontWeight: "500px" }}
                  >
                    Email
                  </label>
                  <input
                    style={{ border: "1px solid #1D8BF7" }}
                    type="email"
                    className="form-control"
                    name="email"
                    value={email}
                    onChange={updateEmail}
                    placeholder="Please Enter Email"
                  />
                  <div className="text-danger">{errors.email}</div>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                  <label
                    htmlFor="email"
                    style={{ color: "black", fontWeight: "500px" }}
                  >
                    Origin
                  </label>
                  <Select
                    options={options}
                    value={origin}
                    onChange={(e) => {
                      setOrigin(e);
                    }}
                    style={{ color: "black" }}
                  />
                  <div className="text-danger">{errors.origin}</div>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                  <label
                    htmlFor="email"
                    style={{ color: "black", fontWeight: "500px" }}
                  >
                    Price Algo Type
                  </label>
                  <select
                    style={{ border: "1px solid #1D8BF7" }}
                    className="form-control"
                    name="pricealgo"
                    id="price_algo_type"
                    onChange={(e) => {
                      setPriceAlgo(e.target.value);
                    }}
                    placeholder="Enter type"
                    required
                  >
                    <option>Select Type</option>
                    <option value="1">Qnu</option>
                    <option value="2">Jv</option>
                    <option value="3">Lyla/loomtree</option>
                    <option value="4">Sfc</option>
                    <option value="5">Cld</option>
                  </select>
                  <div className="text-danger">{errors.pricealgo}</div>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                  <label
                    htmlFor="password"
                    style={{ color: "black", fontWeight: "500px" }}
                  >
                    Password
                  </label>
                  <input
                    type={type}
                    className="form-control"
                    name="password"
                    value={password}
                    onChange={updatePwd}
                    style={{ height: 40, border: "1px solid #1D8BF7" }}
                    placeholder="Please Enter Password"
                  />
                  <small>Password must be eight characters in length.</small>
                  <div className="text-danger">{errors.password}</div>
                </div>
                <button
                  className="btn btn-fill"
                  style={{
                    backgroundColor: "#1D8BF7",
                    color: "rgb(255, 255, 255)",
                    fontWeight: "500",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 4,
                    border: "0px",
                    fontSize: "15px",
                  }}
                >
                  Create
                </button>
              </form>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
      <div className="form-group col-sm-3 col-md-3 col-lg-3"></div>
    </div>
  );
}

export default Supplier;
