import React, { useState, useEffect } from "react";
import axios from "axios";
import "./SingleProduct.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const SingleProduct = ({ match }) => {
  const [singleProductData, setSingleProductData] = useState({
    imgUrl: [],
    tags: [],
    vo: [],
    vd: [],
  });
  const [quantitySelected, setQuantitySelected] = useState(1);
  const requestedProductId = match.params.id;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [variantOptions, setVariantOptions] = useState([]);

  const arrangeSetDefaultVariantSelected = (variant) => {
    if (variant.length > 0) {
      setVariantOptions(variant);
    } else {
      setVariantOptions([]);
    }
  };

  const handleQuantityAddByOne = () => {
    setQuantitySelected(quantitySelected + 1);
  };

  const handleQuantitySubtractByOne = () => {
    setQuantitySelected(quantitySelected - 1);
  };
  const handleVariantChange = (event) => {
    const changeVariantObject = variantOptions.map((object) => {
      if (object.name === event.target.name) {
        return {
          ...object,
          defaultSelected: event.target.value,
        };
      } else return object;
    });
    setVariantOptions(changeVariantObject);
  };

  const [varientOptions, setVarientOptions] = useState([]);
  const [mrpToShow, setMrpToShow] = useState(0);
  const [spToShow, setSpToShow] = useState(0);

  const setDynamicPrice = () => {
    const selectedValues = varientOptions.map(
      (vo, index) => vo.defaultSelected
    );
    const getMrp = singleProductData.vd.filter(
      (v) =>
        v.varientCombination.length === selectedValues.length &&
        v.varientCombination.every((val) => selectedValues.includes(val))
    );
    if (getMrp.length > 0) {
      setMrpToShow(getMrp[0].mrp);
      setSpToShow(getMrp[0].sp);
    }
  };

  const loadSingleProduct = async (productHandle) => {
    const productParam = {
      handle: productHandle,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const { data } = await axios.post(
        `/sscapi/mobile/getproduct`,
        productParam,
        config
      );
      return data;
    } catch (err) {
      return {
        data: [],
        status: 404,
      };
    }
  };

  useEffect(() => {
    async function callApi() {
      setLoading(true);
      const { data = [], status } = await loadSingleProduct(requestedProductId);

      setLoading(false);
      if (status === 200) {
        arrangeSetDefaultVariantSelected(data.product.vo);
        setMrpToShow(data.product.defaultMrp);
        setSpToShow(data.product.defaultSp);
        setSingleProductData(data.product);
        setError(status);
      } else setError(status);
    }
    callApi();
  }, [requestedProductId]);

  return (
    <div
      className="container single_product_cont"
      style={{ font: "14px/1.5 Helvetica !important" }}
    >
      {loading ? (
        <h3>Loading...</h3>
      ) : error !== 200 ? (
        <h3>Did not found any product</h3>
      ) : (
        <>
          {" "}
          <OwlCarousel
            className="owl-theme single_product_carousel"
            items={1}
            loop
            margin={10}
            nav
          >
            <div className="items">
              <h4>1</h4>
            </div>
            <div className="items">
              <h4>2</h4>
            </div>
            <div className="items">
              <h4>3</h4>
            </div>
            <div className="items">
              <h4>4</h4>
            </div>
            <div className="items">
              <h4>5</h4>
            </div>
          </OwlCarousel>
          <div className="productPanel">
            <div className="panelBody">
              <div style={{ display: "flex" }}>
                <div style={{ width: "50%" }}></div>
                <div style={{ width: "50%" }}>
                  <h3>{singleProductData.name}</h3>
                  <div className="productPriceList clearfix">
                    <ul>
                      <li>
                        <span>Shopperr Price</span>
                        <em>{singleProductData.sp}</em>
                      </li>
                      <li>
                        <span>Recommended Retail Price</span>
                        <em>{singleProductData.mrp}</em>
                      </li>
                    </ul>
                  </div>
                  <div className="productSpec clearfix">
                    <ul>
                      <li>
                        Our Wholesale Price includes Shipping &amp; Handling
                        Charges and GST{" "}
                      </li>
                      <li>
                        <span>Processing time</span>
                        <em>7 ~ 15 days</em>
                      </li>
                      <li>
                        <span>Country Of Origin</span>
                        <em>China</em>
                      </li>
                      {variantOptions.map((m, index) => (
                        <li key={m.name}>
                          <span>{m.name}</span>
                          <select
                            name={m.name}
                            value={m.defaultSelected}
                            onChange={handleVariantChange}
                          >
                            {m.values.map((o, i) => (
                              <option key={o} value={o}>
                                {o}
                              </option>
                            ))}
                          </select>
                        </li>
                      ))}

                      <li className="quantity">
                        <span>Quantity</span>
                        <em
                          className="minus"
                          onClick={handleQuantitySubtractByOne}
                        />
                        <input
                          className="num"
                          type="text"
                          id="buyQuantity"
                          name="buyQuantity"
                          value={quantitySelected}
                          onChange={() => {}}

                          // onPaste="return false"
                        />
                        <em className="add" onClick={handleQuantityAddByOne} />
                      </li>
                      <li>
                        <span>Available</span>
                        <em>{singleProductData.quantity}</em>
                      </li>
                      <li>
                        <span>SKU</span>
                        {singleProductData.code}
                      </li>
                    </ul>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "row",
                    }}
                  >
                    <button
                      className="productBtn lightBlue"
                      style={{ minWidth: "30%" }}
                    >
                      <svg className="subsribe-svg">
                        <use
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          xlinkHref="#subsribe-svg"
                        />
                      </svg>
                      <em>Subsribe Now</em>
                    </button>
                    <button
                      className="productBtn darkBlue"
                      style={{ minWidth: "30%" }}
                    >
                      <svg className="addcart-svg">
                        <use
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          xlinkHref="#addcart-svg"
                        />
                      </svg>
                      <em>Add to Cart</em>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="productPanel productDesc">
            <div className="panelHeader">
              <h3>Product Description</h3>
            </div>
            <div className="panelBody">
              <p></p>
              <p>
                <b>Description:</b>
              </p>
              <p>
                3 Layers disposable dustproof waterproof mask mat cushion, you
                can just replace this mat but not mask, economical and
                practical.
                <br />
                Breathable and skin-friendly, you can use several at once to
                meet your requirement.
                <br />
                Material: Non-Woven Cloth
                <br />
                Shape: Round/Rectangle, flexible and proper size to fit in your
                mask
                <br />
                Quantity: 50 Pieces
              </p>
              <p>
                <b>Package Includes:</b>
              </p>
              <p>
                50 Pieces Disposable Mask Replacement Mat
                <br />
              </p>
              <p>
                <b>Note:</b>
              </p>
              <p>
                1. Please allow a slight color difference between the real item
                and the pictures due to the difference between monitors.
                <br />
                2. Please allow a slight measurement error.
                <br />
                Hope for your kind understanding and have a nice shopping day!
              </p>
              <p />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SingleProduct;
