	import React, { Component } from 'react';
import { Grid, Row, Col, Table , Button, Modal} from 'react-bootstrap';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import CustomButton from '../components/CustomButton/CustomButton.jsx';
import Card from '../components/Card/Card.jsx';
import SyncLoader from "react-spinners/SyncLoader"
import { Link } from "react-router-dom"
import _ from 'lodash'  
export default class walletSummary extends Component{ 
	constructor(){
		super()
		this.state = {  
		}
		// const search = this.props.location.search;
		// const searchObj = new URLSearchParams(search)
		// console.log(searchObj)
	}  

	render(){
		return(
			<div style={{ padding : '10px' }}> 
				 gshgshag
			</div>
		)
	}
} 