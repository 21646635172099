import React, { Component } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import "../../assets/css/addProductCatalog.css";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import "font-awesome/css/font-awesome.min.css";
import _ from "lodash";
import CustomButton from "../../components/CustomButton/CustomButton";
import jwt_decode from "jwt-decode";
import { NotificationManager } from "react-notifications";
import SyncLoader from "react-spinners/SyncLoader";
import validator from "validator";
import ReactPaginate from "react-paginate";
import { Modal, Button } from "react-bootstrap";
import "../../assets/css/productList.css";
import { Link } from "react-router-dom";

const override = `
    position: fixed;
    display: block; 
    top: 45%;
    left: 50%;
    transform: translateX(-50%);
    transform: translate(-50%, -50%);
    z-index: 9999;
`;
export default class AddOnProductCatalog extends Component {
  constructor() {
    super();
    this.state = {
      csvData: "",
      loading: false,
      currentPage: 1,
      showPerPage: 50,
      pageCount: 0,
      csvTotalCount: 0,
      csvResponse: [],
    };
  }

  componentDidMount() {
    this.getcsvData(this.state.currentPage, this.state.showPerPage);
  }

  getcsvData(page, perPage) {
    this.setState({ loading: true });
    axios
      .get(
        `/add/getUploadProductCsv?page=${page}&limit=${perPage}&id=${
          jwt_decode(localStorage.getItem("token")).id
        }`
      )
      .then((result) => {
        // console.log(result); return false;
        this.setState({
          csvResponse: result.data.items,
          csvTotalCount: result.data.totalCount,
          pageCount: Math.ceil(result.data.totalCount / this.state.showPerPage),
          currentPage: page,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  }

  handlePageClick = (e) => {
    this.getcsvData(e.selected + 1, this.state.showPerPage);
  };

  handleAllInputs = (e) => {
    e.persist();
    this.setState({ [e.target.name]: e.target.value });
  };

  uploadProductCsv = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("file", this.state.csvData[0]);
    data.append("supplier_id", jwt_decode(localStorage.getItem("token")).id);
    console.log(this.state.csvData);
    if (this.state.csvData) {
      this.setState({ loading: true });
      axios
        .post("/add/uploadProductCsv", data)
        .then((result) => {
          if (result.data.error === 0) {
            NotificationManager.success(result.data.Response);
            this.setState({ loading: false });
            window.location.reload(false);
          } else {
            NotificationManager.success(result.data.Response);
            this.setState({ loading: false });
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
          document.getElementById("uploadProductCsv").disabled = false;
          NotificationManager.error(
            "Something went wrong, please try again later."
          );
        });
    } else {
      NotificationManager.success("Select File.");
    }
  };

  render() {
    const Editor = {
      modules: {
        toolbar: [
          [{ header: [1, 2, false] }],
          ["bold"],
          ["italic"],
          ["underline"],
          ["strike"],
          ["blockquote"],
          [{ list: "ordered" }],
          [{ list: "bullet" }],
          [{ indent: "+1" }],
          [{ indent: "-1" }],
          ["link"],
          ["video"],
          ["image"],
        ],
      },
      formats: [
        "header",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video",
      ],
    };
    return (
      <div className="mainBox">
        <SyncLoader
          css={override}
          size={15}
          margin={6}
          color={"#3E3E3E"}
          loading={this.state.loading}
        />
        <br></br>
        <div className={this.state.loading ? "parentDisable" : ""}>
          <div className="container-fluid">
            <div className="card" style={{ padding: "1em" }}>
              <label htmlFor="productImage" style={{ fontSize: 15 }}>
                Product Csv upload &nbsp;
                <span
                  className="custom-tooltip"
                  data-tooltip="Clear and informative csv, which allow clear recognition of the product"
                >
                  <i className="fa fa-question-circle" aria-hidden="true"></i>
                </span>
              </label>
              <br></br>
              <form
                onSubmit={this.uploadProductCsv}
                style={{
                  justifyContent: "flex-start",
                  alignItems: "center",
                  margin: "0",
                }}
              >
                <div className="form-group" style={{ margin: "0" }}>
                  <input
                    type="file"
                    id="productCsv"
                    style={{ height: "40px" }}
                    onChange={(e) => {
                      this.setState({ csvData: e.target.files });
                    }}
                    encType="multipart/form-data"
                    accept=".csv"
                  />
                </div>
                <div className="" style={{ margin: "0 20px" }}>
                  <CustomButton fill type="submit" id="uploadProductCsv">
                    Upload Products
                  </CustomButton>
                </div>
                <a
                  href="https://static-sellercentral.shopperr.in/product/60deae77da7e45105c54529e2021/7/fBKRUV_60deae77da7e45105c54529e.csv"
                  target="_blank"
                >
                  Sample CSV
                </a>
              </form>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="card" style={{ padding: "1em" }}>
            <div className="table-responsive">
              <table className="table table-hover ProductListTable">
                <thead>
                  <tr className="d-flex">
                    <th className="text-center" scope="col">
                      S.NO.
                    </th>
                    <th className="text-center" scope="col">
                      Status
                    </th>
                    <th className="text-center" scope="col">
                      Action
                    </th>
                    <th className="text-center" scope="col">
                      Logs
                    </th>
                    <th className="text-center" scope="col">
                      Created Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!_.isEmpty(this.state.csvResponse) ? (
                    this.state.csvResponse.map((item, index) => {
                      const { _id, status, action, resUrl, date } = item;
                      return (
                        <tr key={index} className="d-flex">
                          <td className="text-center">
                            {(this.state.currentPage - 1) *
                              this.state.showPerPage +
                              (index + 1)}
                          </td>
                          <td
                            className="text-center"
                            style={{
                              color:
                                item.status === "Success" ? "Green" : "orange",
                            }}
                          >
                            {" "}
                            {status || "NA"}
                          </td>
                          <td className="text-center">
                            <a href={action}>Import</a>
                          </td>
                          <td className="text-center">
                            <a href={resUrl}>Download</a>
                          </td>
                          <td className="text-center">{date}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan="5"
                        className="text-center"
                        style={{ fontSize: "20px" }}
                      >
                        No Data Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={this.state.pageCount}
              marginPagesDisplayed={3}
              pageRangeDisplayed={10}
              onPageChange={this.handlePageClick.bind(this)}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
    );
  }
}
