import React, { useState, useEffect } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import axios from "axios";
import jwt_decode from "jwt-decode";
import "../../assets/css/settings.css";
import { NotificationManager } from "react-notifications";
import Card from "../../components/Card/Card.jsx";
import CustomButton from "../../components/CustomButton/CustomButton";
import { Redirect } from "react-router-dom"
import SyncLoader from "react-spinners/SyncLoader"

const override = `
	position: fixed;
	display: block;
	top: 45%;
	left: 50%;
	transform: translateX(-50%);
	transform: translate(-50%, -50%);
	z-index: 9999;
`
 
const SupplierOrders = () => { 
    const [id, setId] = useState("");
    const [mongoId, setMongoId] = useState("");
    const [email, setEmail] = useState(""); 
    const [password, setPassword] = useState("");
    const [priceAlgo, setPriceAlgo] = useState("");
    const [redirectTo, setRedirectTo] = useState(false)
    const [loading, setLoading] = useState(true)
    const [origin, setOrigin] = useState(''); 
    const token = localStorage.getItem("token")
    const decode = jwt_decode(token);

    useEffect(() => {
        if(localStorage.getItem("editSupplierFrom") === "admin")
            getSupplierProfile(localStorage.getItem("code"))
        else
            getSupplierProfile(decode.id)
    }, []);

    const getSupplierProfile = (searchId) => {
        axios.get("/supplierProfile" + searchId).then((res) => { 
            setEmail(res.data.email);
            setId(res.data.supplier_id); 
            setMongoId(res.data._id); 
            setPriceAlgo(res.data.price_algo)
            setOrigin(res.data.origin)
            setLoading(false)
        });
    };

    const updateSettings = async (e) => {
        e.preventDefault(); 
        try {
            setLoading(true)
            var obj = {};
            if(password == ''){
                obj = { 
                    origin      : origin,
                    priceAlgo   : priceAlgo,
                    supplier_id : mongoId
                }
            }else{
                obj = { 
                    origin      : origin,
                    priceAlgo   : priceAlgo,
                    password    : password,
                    supplier_id : mongoId
                }
            } 
            // console.log(obj) ; return false; 
            await axios.post("/settingsUpdate", obj).then(result => {
                setLoading(false)
                if(result.data.status != 1){
                     NotificationManager.success('Something Went Wrong!');
                }else{ 
                    setPassword('')
                    NotificationManager.success(result.data.response);
                    if(localStorage.getItem("editSupplierFrom") === "admin"){
                        setRedirectTo(true)
                    }  
                }
            }) 
        }catch(error){ 
            NotificationManager.error("something unusual happened");
        }
    };

    if(redirectTo){
        return( 
            localStorage.getItem('editSupplierFrom') === 'admin' && <Redirect to="/admin/supplier" />
        )
    }else{
        return (
            <div className="mainBox">
                <br />
                <SyncLoader css={override} size={15} margin={6} color={"#3E3E3E"} loading={loading} />
                <div className={loading ? "parentDisable" : ""}>
                        <Grid fluid>
                            <Row>
                                <Col md={12}>
                                    <Card title="Edit Supplier" ctTableFullWidth ctTableResponsive
                                        content={
                                            <form onSubmit={updateSettings} style={{ marginBottom:0 }}>
                                                <div className="card card-input" style={{ width : '100%', marginTop: 0 , marginBottom:0}}>
                                                    <div className='col-md-6' style={{ paddingLeft: '8px' }}>
                                                        <div className="form-group">
                                                        <label htmlFor="product_quantity">Supplier ID</label>
                                                        <input type="text" value={id} onChange={(e) => setId(e.target.value)}
                                                        min="0" className="form-control" id="product_id" placeholder="@id" disabled="disabled"
                                                            /> 
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className="form-group">
                                                            <label htmlFor="product_email">Email</label>
                                                            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)}  className="form-control" id="product_email" placeholder="example@any.com" disabled="disabled"
                                                            /> 
                                                        </div>
                                                    </div>  
                                                    <div className='col-md-6'>
                                                         <div className='form-group'>
                                                            <label htmlFor='Origin'>Origin</label>
                                                            <select value={origin} className='form-control' id='product_type' onChange={(e) => { setOrigin(e.target.value);  }} placeholder='Enter type' required >
                                                                <option>Select Origin</option>
                                                                <option value="china">China</option>
                                                                <option value="india">India</option>
                                                            </select>
                                                        </div>
                                                    </div> 
                                                    <div className='col-md-6'>
                                                        <div className='form-group'>
                                                            <label htmlFor='priceAlgoType'>Price Algo Type</label>
                                                            <select value={priceAlgo} className='form-control' id='price_algo_type' onChange={(e) => { setPriceAlgo(e.target.value);  }} placeholder='Enter type'  required >
                                                                <option>Select Type</option>
                                                                <option value="1">Qnu</option>
                                                                <option value="2">Jv</option>
                                                                <option value="3">Lyla/loomtree</option>
                                                                <option value="4">Sfc</option>
                                                                <option value="5">Cld</option> 
                                                            </select>
                                                        </div>
                                                    </div> 
                                                    <div className='col-md-12'>
                                                        <div className="form-group">
                                                            <label htmlFor="product_quantity">Password</label>
                                                            <input  type="text" value={password} onChange={(e) => setPassword(e.target.value)} min="0" className="form-control" id="supplierPassword" placeholder="Enter New Password"   />
                                                        </div>
                                                    </div> 
                                                    <div className="" style={{ paddingLeft: '7px' }}>
                                                        <div className='pull-left'>
                                                            <CustomButton fill type="submit" name="button"> Update Profile </CustomButton>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        }
                                    />
                                </Col>
                            </Row>
                        </Grid> 
                </div>
            </div>
        )
    }
};

export default SupplierOrders;
