import React,{useState, useEffect} from 'react'
import axios from 'axios'
import jwt_decode from 'jwt-decode';
import Swal from 'sweetalert2'

function EditPo(props) {

    const statusArray = [
        {
            "id": 1,
            "display": "Pending",
            "value": "Pending"
        }, 
        {
            "id": 3,
            "display": "Processed",
            "value": "Processed"
        }, 
        {
            "id": 14,
            "display": "Shipped",
            "value": "Shipped"
        } 
    ]
    const [poInfo, setPoInfo] = useState({})
    const [customer, setCustomer] = useState({})
    const [qty, setQty] = useState(0)
    const [fulfillmentStatus, setFulfillmentStatus] = useState(0)
    const [fulfillment, setFulfilment] = useState(0)
    const [comments, setComments] = useState("")
    const [history, setHistory] = useState([])
    const [netAmt, setNetAmt] = useState(0)

    useEffect(() => {
        axios.get(`/sscapi/purchase-order/${props.match.params.id}`)
            .then(res => {
                const poData = res.data.poData
                if (poData) {
                    // console.log("response - ", poData)
                    setPoInfo(poData)
                    setQty(poData.orderAmt.quantity)
                    console.log(statusArray.find(data => data.id == poData.fulfillmentStatus).display,' !!!!!! ')
                    setFulfillmentStatus(statusArray.find(data => data.id == poData.fulfillmentStatus).display)
                    setFulfilment(poData.fulfillmentStatus)
                    setHistory(poData.history)
                    // poData.igst ? setIgst(poData.igst) : setIgst(18)
                    const orderValue = poData.productInfo.price
                    const igst =  poData.igst ? poData.igst : 18
                    const value = (18/100) + 1
                    const netAmount = orderValue / value
                    const gst = orderValue - netAmount
                    setNetAmt(netAmount)
                    console.log("Net amt - GST - ", netAmount, gst)
                }
            }) 
            .catch(err => {
                console.log("Error - ",err)
            })
    },[])

    const handleEdit = () => {
        console.log("Clicked!!")
        const formData = {
            fulfillmentStatus: fulfillment,
            quantity: qty,
            comments: comments,
            user: jwt_decode(localStorage.getItem('token')).email
        }
        axios.put(`/sscapi/purchase-order-edit/${props.match.params.id}`,formData)
            .then(res => {
                if (!res.data.error) {
                    console.log("Res ", res.data.poData)
                    const poData = res.data.poData
                    setQty(poData.qty)
                    setFulfillmentStatus(poData.fulfillmentStatus)
                    setHistory(poData.history)
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Your data has been edited',
                        timer: 1500
                    })
                }
                
            })
            .catch(err => {

            })
    }

    return(
        <div className="container-fluid">
            <h4>Edit PO</h4>
            <div className="row">
                <div className="col-md-6">
                    <h5>Order Info</h5>
                    <div className="card" style={{paddingTop:"10px", paddingBottom:"10px"}}>
                    <div className="container-fluid">
                    {
                        Object.keys(poInfo).length && (
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">Order Number - { poInfo.orderNumber }</li>
                            <li className="list-group-item">Product Title - { poInfo.productTitle }</li>
                            {/* <li className="list-group-item">Item Price - { poInfo.orderAmt.unitPrice }</li> */}
                            <li className="list-group-item">SKU - { poInfo.sku }</li>
                            {/* <li className="list-group-item">IGST - { poInfo.igst }</li> */}
                            {/* <li className="list-group-item">Net Amount - { netAmt }</li> */}
                            <li className="list-group-item">PO creation Date - { poInfo.createdAt.substring(0, 10) }</li>
                            {/* <li className="list-group-item">Order Amount - { poInfo.orderAmt.totalAmount }</li> */}
                            {/* <li className="list-group-item">Shipping Amount - { poInfo.orderAmt.totalShipping }</li> */}
                            <li className="list-group-item">Fulfillment Status 
                                <select className="form-control" value={fulfillmentStatus} onChange={(e)=> setFulfillmentStatus(e.target.value)}>
                                    {
                                        statusArray.map((status, i) => {
                                            const { id, display } = status
                                            return(
                                                <option key={id} value={id}>{display}</option>
                                            )
                                        })
                                    }
								</select>
                            </li>
                            <li className="list-group-item">Quantity - <input type="number" value={ qty } onChange={(e) => setQty(e.target.value)} min="0" /></li>
                            <li style={{marginTop:"20px"}}>Comment - 
                                <textarea className="form-control" onChange={(e)=>setComments(e.target.value)}></textarea>
                            </li>
                        </ul>
                        )
                    }
                    <button className="btn btn_new btn-primary" onClick={handleEdit}>Edit</button>
                    </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <h5>Historyyy</h5>
                    <div className="card" style={{ margin: '4px, 4px',padding: '4px',textAlign: 'justify'} }>
                    {
                        history.length && (
                            <div style={{overflow: 'scroll', height: "550px"}}>
                                {
                                    history.reverse().map(data=> {
                                        return (
                                            <div key={Math.random()}>
                                                <p><b>user - </b><span>{data.userName}</span></p>
                                                <p><b>update at - </b><span>{data.updatedOn}</span></p>
                                                <p><b>Earlier Fulfillment Status - </b><span>{data.earlierStatus}</span></p>
                                                <p><b>Earlier Quantity - </b><span>{data.earlierQty}</span></p>
                                                <p><b>Note - </b><span>{data.comments}</span></p>
                                                <hr/>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    }
                    </div>
                </div>
            </div>
           
        </div>
    )
    
}
export default EditPo