import CopyDashboard from './views/AdminScreenRoutes/CopyDashboard.jsx'
import SupplierList from './views/AdminScreenRoutes/SupplierList.jsx'
import UnicommercePicklist from './views/AdminScreenRoutes/UnicommercePicklist.jsx'
import Supplier from './views/AdminScreenRoutes/Supplier.jsx'
import AdminProduct from './views/AdminScreenRoutes/AdminProduct.jsx'
import AddCategory from './views/AdminScreenRoutes/Category.jsx'
import AddOrderProcess from './views/AdminScreenRoutes/addOrderProcess.jsx'
import CategoryList from './views/AdminScreenRoutes/categoryList.jsx'
import UpdateCategory from './views/updateCategory'
import Margin from './views/Margin.jsx'
import AutoMargin from './views/AdminScreenRoutes/AutoMargin.jsx'
import MerchantDetail from './views/AdminScreenRoutes/MerchantDetail.jsx'
import MerchantAccountDetail from './views/AdminScreenRoutes/MerchantAccountDetail.jsx'
import SingleProductDetail from './views/AdminScreenRoutes/SingleProductDetail.jsx'
import AdminProfile from './views/AdminScreenRoutes/AdminProfile.jsx'
import AdminOrderDetails from './views/AdminScreenRoutes/AdminOrderDetails.jsx'
import AdminPayment from './views/AdminScreenRoutes/AdminPayment.jsx'
import TransactionHistory from './views/AdminScreenRoutes/TransactionHistory.jsx'
import RequestProductList from './views/RequestProductList'
import EditProduct from './views/AdminScreenRoutes/EditProduct.jsx'
import EditSupplier from './views/AdminScreenRoutes/Settings.jsx'
import addLogisticUser from './views/AdminScreenRoutes/addLogisticUser.jsx'
import LogisticUserList from './views/AdminScreenRoutes/logisticUserList.jsx'
import EditLogisticUser from './views/AdminScreenRoutes/editLogisticUser.jsx'
import PurchaseOrders from './views/AdminScreenRoutes/adminPurchaseOrdersList.jsx'
import OrderProcess from './views/AdminScreenRoutes/adminOrderProcess.jsx'
import AddWarehouse from './views/AdminScreenRoutes/AdminAddWarehouses.jsx'
import ViewWarehouses from './views/AdminScreenRoutes/ViewWarehouses.jsx'
import addSubAdminUser from './views/AdminScreenRoutes/addSubAdminUser.jsx'
import addSubAdmin from './views/AdminScreenRoutes/addSubAdmin.jsx'
import editSubAdmin from './views/AdminScreenRoutes/editSubAdmin.jsx'
import jwt_decode from 'jwt-decode';
import axios from 'axios'; 
import ShowOrder from './views/AdminScreenRoutes/MerchantOrderView.jsx'
import Leads from './views/Leads.js'
import BulkProduct from "./views/AdminScreenRoutes/BulkProduct.jsx";


class adminRoutes{
    dashboardRoutes = [];
    allDashboardRoutes = [
        {
            path: '/dashboard',
            name: 'Dashboard',
            icon: 'pe-7s-graph2',
            component: CopyDashboard,
            layout: '/admin',
        },
        {
            path: '/supplier',
            name: 'Suppliers',
            icon: 'pe-7s-users',
            component: SupplierList,
            layout: '/admin',
        },
        // {
        //     path: '/unicommercepicklist',
        //     name: 'Unicommerce Picklist',
        //     icon: 'pe-7s-menu',
        //     component: UnicommercePicklist,
        //     layout: '/admin',
        // },
        {
            path: '/logisticUsersList',
            name: 'Logistic Users',
            icon: 'pe-7s-users',
            component: LogisticUserList,
            layout: '/admin',
        },
        {
            path: '/addSupplier',
            name: 'Add Supplier',
            icon: 'pe-7s-add-user',
            component: Supplier,
            layout: '/admin',
        },
        {
            path: '/addLogisticUser',
            name: 'Add Logistic User',
            icon: 'pe-7s-add-user',
            component: addLogisticUser,
            layout: '/admin',
        }, 
        {
            path: '/sub-admin',
            name: 'Add Sub Admin',
            icon: 'pe-7s-add-user',
            component: addSubAdminUser,
            layout: '/admin',
        },
        {
            path: '/product',
            name: 'Products',
            icon: 'pe-7s-shopbag',
            component: AdminProduct,
            layout: '/admin',
        },
		{
			path: "/BulkProduct",
			name: "Bulk Product",
			icon: "pe-7s-ticket",
			component: BulkProduct,
			layout: "/admin",
		},
        {
            path: '/categoryList',
            name: 'Product Category',
            icon: 'pe-7s-menu',
            component: CategoryList,
            layout: '/admin',
        },
        {
            path: '/AutoMargin',
            name: 'Auto Margin',
            icon: 'pe-7s-edit',
            component: AutoMargin,
            layout: '/admin',
        },
        {
            path: '/merchant-detail',
            name: 'Resellers',
            icon: 'pe-7s-cart',
            component: MerchantDetail,
            layout: '/admin',
        },
        {
            path: '/profile',
            name: 'Settings',
            icon: 'pe-7s-config',
            component: AdminProfile,
            layout: '/admin',
        },
        {
            path: '/orders',
            name: 'Orders',
            icon: 'pe-7s-display1',
            component: AdminOrderDetails,
            layout: '/admin',
        },
        {
            path: '/purchaseOrders',
            name: 'Purchase Orders',
            icon: 'pe-7s-display1',
            component: PurchaseOrders,
            layout: '/admin',
        },
        {
            path: '/orderProcess',
            name: 'Order Process',
            icon: 'pe-7s-display1',
            component: OrderProcess,
            layout: '/admin',
        },
        {
            path: '/payments',
            name: 'Payments',
            icon: 'pe-7s-wallet',
            component: AdminPayment,
            layout: '/admin',
        },
        {
            path: '/transaction',
            name: 'Transaction History',
            icon: 'pe-7s-cash',
            component: TransactionHistory,
            layout: '/admin',
        }, 
        // {
        //     path: '/requested-product',
        //     name: 'Requested Product',
        //     icon: 'pe-7s-cash',
        //     component: RequestProductList,
        //     layout: '/admin',
        // },
        {
            path: '/addwarehouse',
            name: 'Add Warehouse',
            icon: 'pe-7s-graph',
            component: AddWarehouse,
            layout: '/admin',
        },
        {
            path: '/viewWarehouses',
            name: 'View Warehouses',
            icon: 'pe-7s-graph',
            component: ViewWarehouses,
            layout: '/admin',
        },
        {
            path: '/leads',
            name: 'Leads',
            icon: 'fa fa-bullhorn',
            component: Leads,
            layout: '/admin',
        },
        {
            path: '/merchant/:id',
            component: MerchantAccountDetail,
            layout: '/admin',
        },
        {
            path: '/single-product/:id',
            component: SingleProductDetail,
            layout: '/admin',
        },
        {
            path: '/editProduct',
            component: EditProduct,
            layout: '/admin',
        },
        {
            path: '/editSupplier',
            component: EditSupplier,
            layout: '/admin',
        },
        {
            path: '/editLogisticUser',
            component: EditLogisticUser,
            layout: '/admin',
        },
        {
            path: '/AddCategory',
            component: AddCategory,
            layout: '/admin',
        },
        {
            path: '/add-subAdmin',
            component: addSubAdmin,
            layout: '/admin',
        },
        {
            path: '/editSubAdmin',
            component: editSubAdmin,
            layout: '/admin',
        },
        {
            path: "/order/:id",
            component: ShowOrder,
            layout: "/admin"
        },
        {
            path: '/AddOrderProcess',
            component: AddOrderProcess,
            layout: '/admin',
        },
    ]
    constructor(id){
      this._id = id;
    }
    adminRoute() {
        return axios.get("/adminApi/adminProfile" + this._id).then((res) => {
            if(res.data.error == 0){
                let menus = res.data.items.menu;
                if(res.data.items.menu && menus.length){ 
                    menus = menus.map((item)=>'/'+item.Url) 
                    this.dashboardRoutes = this.allDashboardRoutes.filter((item)=>{ 
                        return menus.includes(item.path);
                    }); 
                    return this.dashboardRoutes; 
                } 
            }
            return this.allDashboardRoutes;
        }); 
    }
}

export default adminRoutes;

