import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Redirect,
  Link,
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/bootstrap/dist/js/bootstrap.js";
import * as Icon from "react-bootstrap-icons";
import "../assets/css/new_template.css";
import "../assets/css/Landing.css";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Table,
} from "react-bootstrap";
import Header from "./Header.jsx";
import Footer from "./Footer.jsx";
import IMG from "../assets/Img/shopperr_white_logo.png";

const Landing = () => {
  const [basic, setBasic] = useState("19.99 / month");
  const [pro, setPro] = useState("69.99 / month");
  const [plus, setPlus] = useState("199.99 / month");
  const [plan, setPlan] = useState("Monthly");
  const [checkBox, setCheckBox] = useState(true);

  useEffect(() => {
    getchPage();
  }, []);

  const getchPage = () => {};

  const handleClick = () => {
    // setCheckBox(!checkBox);
    // console.log(checkBox);
  };

  return (
    <>
      <div className="mainContainer">
        <div className="container">
          {/* <!--header starts here--> */}
          <header>
            <div className="brand">
              <img width="150px" height="40px" src={IMG} />
            </div>
            <div className="dropdown">
              <a
                className="dropdown-toggle"
                href="#"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Log In
              </a>

              <div
                className="dropdown-menu dropdown-menu-right"
                aria-labelledby="dropdownMenuLink"
              >
                <a className="dropdown-item" href="/login-admin">
                  <Icon.GearFill className="ml-4" />
                  Admin
                </a>
                <a className="dropdown-item" href="/login-supplier">
                  <Icon.People className="ml-4" /> Supplier
                </a>
                <a className="dropdown-item" href="/login-merchant">
                  <Icon.Shop className="ml-4" /> Reseller
                </a>
                <a className="dropdown-item" href="/login-logisticUser">
                  <Icon.Shop className="ml-4" /> Logistic User
                </a>
              </div>
            </div>
          </header>
          {/* <!--header ends here--> */}

          {/* <!--main banner starts--> */}
          <section className="mainBanner">
            <div className="mainBanner__content">
              <h1>
                You sell.
                <br />
                We take care of the rest!
              </h1>
              <p>
                Shopperr is a dropshipping platform offering 100,000+ products
                for you to resell. You can easily sync the selected products to
                your store. Shopperr will ship them directly to your customers.
              </p>
              <div style={{ width: "200px", marginTop: "20px" }}>
                <button
                  type="button"
                  style={{ lineHeight: "13px" }}
                  className="btn btn-lg"
                >
                  Shop Now
                </button>
              </div>
            </div>
            <div className="mainBanner__img">
              <img
                src={
                  process.env.REACT_APP_S3_URL.trim() +
                  "/img/online-shopping.jpg"
                }
              />
            </div>
          </section>
          {/* <!--main banner starts--> */}
        </div>

        <svg
          alt=""
          className="wave-flip"
          height="278px"
          preserveAspectRatio="none"
          role="presentation"
          version="1.1"
          viewBox="0 0 1440 278"
          width="1440px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            id="Sandbox"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <path
              d="M0,260.670469 C268,291.980818 533.333333,258.847538 796,161.270627 C1058.66667,63.6937169 1273.33333,9.93684108 1440,0 L1440,278 L0,278 L0,260.670469 Z"
              id="Path-8"
              fill="#fff"
              transform="translate(720.000000, 139.000000) scale(-1, 1) translate(-720.000000, -139.000000) "
            ></path>
          </g>
        </svg>
      </div>

      <section id="seller-moreInfo">
        <div className="container">
          <h3>Need more information about selling on Shopperr?</h3>
          <p>Learn more by clicking on any of the links below</p>
          <div className="row mt-5">
            <div className="col-md-3">
              <h4>Sell on Shopperr</h4>
              <ul>
                <li>
                  <a href="#">Become an Shopperr Seller</a>
                </li>
                <li>
                  <a href="#">Sell products online</a>
                </li>
                <li>
                  <a href="#">Guide to Sell on Shopperr</a>
                </li>
                <li>
                  <a href="#">Fees & Pricing</a>
                </li>
                <li>
                  <a href="https://shopperr.zendesk.com/hc/en-us">
                    Help & Support
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <h4>Grow your business</h4>
              <ul>
                <li>
                  <a href="#">Become a Prime Seller</a>
                </li>
                <li>
                  <a href="#">Shopperr FBA</a>
                </li>
                <li>
                  <a href="#">Tools to help you grow</a>
                </li>
                <li>
                  <a href="#">Selling Programs</a>
                </li>
                <li>
                  <a href="#">Shopperr Global Selling</a>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <h4>Seller Resources</h4>
              <ul>
                <li>
                  <a href="#">Start Selling Guide (PDF)</a>
                </li>
                <li>
                  <a href="#">Seller Events</a>
                </li>
                <li>
                  <a href="#">Seller University</a>
                </li>
                <li>
                  <a href="#">All About Selling (Blog)</a>
                </li>
                <li>
                  <a href="#">A to Z GST Guide</a>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <h4>Social Media</h4>
              <ul>
                <li>
                  <a href="#">Facebook</a>
                </li>
                <li>
                  <a href="#">Youtube</a>
                </li>
                <li>
                  <a href="#">Instagram</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <footer className="footerSpace">
        <div className="container">
          <div className="row" style={{ fontSize: "12px" }}>
            Copyright &copy; 2021 Shopperr com All right reserved
          </div>
        </div>
      </footer>
    </>
  );
};
export default Landing;
