
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import isEmpty from 'is-empty'
import ReactPaginate from 'react-paginate'
import SyncLoader from "react-spinners/SyncLoader"
import { Link } from 'react-router-dom'
import jwt_decode from 'jwt-decode';
import {getPriceFormatter} from '../../helpers/index.js';
const override = `
    position: fixed;
    display: block;
    top: 45%;
    left: 50%;
    transform: translateX(-50%);
    transform: translate(-50%, -50%);
    z-index: 1;
`

function OrderList() {
    const [orders, setOrders] = useState([])
    const [pageData, setPageData] = useState([])
    const [errormessage, setErrorMessage] = useState('')
    const [pageCount, setPageCount] = useState(0)
    const [showPerPage, setShowPerPage] = useState(50) 
    const [search, setSearch] = useState("")
    const [tempCustomerData, setTempCustomerData] = useState([])
    const [loading, setLoading] = useState(true)
    const [fromDate, setFromDate] = useState("")
    const [toDate, setTodate] = useState("")
    const [error, setErrors] = useState({})
    const [storeDomain, setStoreDomain] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [source, setOrderSource] = useState("")
    const [currentPage, setCurrentPage] = useState(1); 
    const [lastSearchParams,setLastSearchParams] = useState({});
    const [totalOrders, setTotalOrders] = useState(0); 
    
    useEffect(() => {  
        setLoading(false) 
        getOrders(currentPage,showPerPage,{refresh:1}) 
    },[]); 
    const getOrders = (currentPage,showPerPage,searchparams={}) => {
        setLoading(true);
        if(!searchparams.refresh){
            searchparams = {...searchparams,storeDomain,phoneNumber,source};
        } else {
            delete searchparams.refresh;
        }
        
        setLastSearchParams(searchparams);
        const qs = Object.keys(searchparams).map(key => `${key}=${searchparams[key]}`).join('&'); 
        // console.log("qs",qs);
        axios.get(`/sscapi/adminOrders?page=${currentPage}&limit=${showPerPage}&${qs}`, {
            headers: {
                'auth-token': localStorage.getItem('token')
            }
        }).then(res => {
                setLoading(false)
                if (!res.data.hasOwnProperty('message')) { 
                    setPageCount(Math.ceil(res.data.count / showPerPage)) 
                    setOrders({ data:{currentPage:res.data}});
                    setPageData(res.data.results);
                    setTotalOrders(res.data.count); 
                }else {
                    setErrorMessage(res.data.message)
                }
            })
            .catch(err => {
                setLoading(false)
                console.log("Error",err)
            })
    }

    const handlePageClick = (e) => {
        setLoading(true)
        setCurrentPage(e.selected+1);  
        getOrders(e.selected+1,showPerPage,lastSearchParams);
    } 

    const handleChange = (e) => { 
        document.getElementById("searchOrder").value = ""
        setFromDate("")
        setTodate("") 
        getOrders(currentPage,showPerPage,{})
    }

    const handleSearchOrder = e => { 
        setSearch(e.target.value) 
    }

    const handleSearchPhoneNumber = e => { 
        e.target.value = e.target.value.replace(/[^\d]/g,'');
        setPhoneNumber(e.target.value) 
    }
    const handleSearchStoreDomain = e => { 
        setStoreDomain(e.target.value) 
    }
    const handleSearchBySource = e => {
        setOrderSource(e.target.value); 
    }
    const handleFromdate = fromDate => { 
        setFromDate(fromDate)
        setErrors({})
    }

    const handleToDate = toDate => { 
        setTodate(toDate)
        setErrors({})
    }

    function showDateRangeData(){
        let errors = {}
        if(!isEmpty(fromDate) && !isEmpty(toDate)){ 
            let d = new Date(fromDate)
            if(d.getFullYear() > 9999){
                errors.fromDate = "From Date must be less than 9999"
            }else if(d.getFullYear() < 1950){
                errors.fromDate = "From Date must be greater than 1950"
            }
            let d1 = new Date(toDate)
            if(d1.getFullYear() > 9999){
                errors.toDate = "To Date must be less than 9999"
            }else if(d1.getFullYear() < 1950){
                errors.toDate = "To Date must be greater than 1950"
            }
            if(isEmpty(errors)){
                const date1 = new Date(fromDate);
                const date2 = new Date(toDate);
                const diffTime = date2 - date1
                const diffDays = (diffTime / (1000 * 60 * 60 * 24))
                if(diffDays < 0){
                    errors.toDate = "End date needs to be after From date";
                }else {
                    getOrders(1,showPerPage,{fromDate,toDate,text:search})
                }
            }
            setErrors(errors)
        } else{
            getOrders(1,showPerPage,{text:search})
        }
    }

    function downloadOrder(){
        let errors = {} 
        if(!isEmpty(fromDate) && !isEmpty(toDate)){ 
            let d = new Date(fromDate)
            if(d.getFullYear() > 9999){
                errors.fromDate = "From Date must be less than 9999"
            }else if(d.getFullYear() < 1950){
                errors.fromDate = "From Date must be greater than 1950"
            }
            let d1 = new Date(toDate)
            if(d1.getFullYear() > 9999){
                errors.toDate = "To Date must be less than 9999"
            }else if(d1.getFullYear() < 1950){
                errors.toDate = "To Date must be greater than 1950"
            }
            if(isEmpty(errors)){
                const date1 = new Date(fromDate);
                const date2 = new Date(toDate);
                const diffTime = date2 - date1
                const diffDays = (diffTime / (1000 * 60 * 60 * 24))
                if(diffDays < 0){
                    errors.toDate = "End date needs to be after From date";
                }else {  
                    getDownloadOrders(1,showPerPage,{fromDate,toDate,text:search})
                }
            }
            setErrors(errors)
        } else if(search){
            getDownloadOrders(1,showPerPage,{text:search})
        }else{
            getDownloadOrders(currentPage,showPerPage,{})
        }
    } 

    const getDownloadOrders = (currentPage,showPerPage,searchparams={}) => {
        setLoading(true)
        searchparams = {...searchparams,storeDomain,phoneNumber,source};
        const qs = Object.keys(searchparams)
        .map(key => `${key}=${searchparams[key]}`)
        .join('&');  
        axios.get(`/adminApi/downloadAllOrderCsv?page=${currentPage}&limit=${showPerPage}&${qs}`).then(data => {
            let result = data.data;
            setLoading(false)
            if(result.error == 0){
                window.open(result.response)
            }else if(result.error == 1){
                alert(result.response);
            } 
        })
    } 

    return (
        <div className="content bdycontent">
            <SyncLoader css={override} size={15} margin={6} color={"#3E3E3E"} loading={loading} /> 
            <div className="container-fluid"> 
                <div className="card" style={{padding: "1em"}}>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3 ">
                            <div className="form-group"> 
                               <label >Search</label>
                                <input type="text" className="form-control " placeholder="Search..." onChange={(e)=>setSearch(e.target.value)} id="searchOrder" autoComplete="off" />
                            </div>
                        </div>

                      

                       

                      
                        <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                            <div className="form-group">
                                <label>From Date &nbsp;<span onClick={() => document.getElementById("fromDate").value = ""}   style={{cursor: "pointer" , color : "#42d0ed"}}>(Clear)</span></label>
                                <input type="date"  className="form-control"  id="fromDate" max="9999-12-01"  min="1950-12-31" placeholder="From Date" value={fromDate} onChange={ (e) => handleFromdate(e.target.value) } />
                                {
                                    !isEmpty(error.fromDate)
                                    &&
                                        <span className="text-danger">{error.fromDate}</span>
                                }
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                            <div className="form-group">
                                <label>To Date &nbsp;<span onClick={() => document.getElementById("toDate").value = ""}   style={{cursor: "pointer" , color : "#42d0ed"}}>(Clear)</span></label>
                                <input
                                    type="date"
                                    className="form-control"
                                    id="toDate"
                                    max="9999-12-01"
                                    min="1950-12-31"
                                    placeholder="To Date"
                                    value={toDate}
                                    onChange={ (e) => handleToDate(e.target.value) }
                                />
                                {
                                    !isEmpty(error.toDate)
                                    &&
                                        <span className="text-danger">{error.toDate}</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                            <div className="form-group"> 
                                <label>Order Source</label>
                                <select className="form-control" onChange={ handleSearchBySource }> 
                                    <option value="">Select</option>
                                    <option value="1">DS Shopify</option>
                                    <option value="2">DS Amazon</option>
                                    <option value="3">Droppshipping SFC</option>
                                    <option value="4">Social commerce app</option>
                                    <option value="5">Shopperr Global(B2C)</option>  
                                </select>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
                            <label>&nbsp;</label>
                            <button style={{ background: "rgb(255, 160, 0)"  ,  borderColor: "rgb(255, 160, 0)" , fontSize: "15px" , fontWeight: "500"}} className="btn btn-primary btn-fill" onClick={() => showDateRangeData()}>Get</button>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
                            <label>&nbsp;</label>
                            <button className="btn btn-primary btn-fill" style={{ background: "rgb(255, 160, 0)"  ,  borderColor: "rgb(255, 160, 0)" , fontSize: "15px" , fontWeight: "500"}} onClick={() => downloadOrder()}>Download</button>
                        </div>
                    </div>
                </div>
            </div>

                <div className="container-fluid">
                    <div className="card" style={{padding: "1em"}}>
                        <div style={{cursor: "pointer"}} onClick={() => getOrders(currentPage,showPerPage,{refresh:1})}>
                            <div className="form-group">
                                <h5 className="text-bold">Total Orders: <span className="text-muted">{totalOrders}</span>
                                    &nbsp;<i className="fa fa-refresh" aria-hidden="true"></i>
                                </h5>
                            </div>
                        </div>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">Order Number.</th>
                                    <th scope="col">Customer Name</th>
                                    <th scope="col">Customer Phone</th>
                                    <th scope="col">Total Amount</th>
                                    <th scope="col">Total Items</th>
                                    <th scope="col">Store Name</th>
                                    <th scope="col">Order Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !isEmpty(pageData)
                                    ?
                                        pageData
                                        .filter((val) => {
                                            if (search === "") {
                                              return val;
                                            } else if (
                                              val.customer.first_name?.toLowerCase().includes(search?.toLowerCase()) ||
                                              val.customer?.phone?.toString().includes(search) ||
                                              val.customer?.price?.toString().includes(search) ||
                                              val.customer?.orderNumber?.toString().includes(search) ||
                                              val.last_name?.toLowerCase().includes(search?.toLowerCase()) ||
                                           
                                            
                                              val.storeDomain?.toLowerCase().includes(search?.toLowerCase()) 
                                            ) {
                                              return val;
                                            }
                                          })
                                        .map(order => {
                                            let d = new Date(order.created_on)
                                            return (
                                            <tr key={order._id}>
                                                <td scope="row">{ order.orderNumber }</td>
                                                <td>{ order.customer && order.customer.first_name } { order.customer && order.customer.last_name }</td>
                                                <td>{ order.customer && order.customer.phone}</td>
                                                <td>{getPriceFormatter(order.price) }</td>
                                                <td>{ order.products.length }</td>
                                                <td>{ order.storeDomain }</td>
                                                <td>{ `${d.getDate()}-${(d.getMonth() + 1)}-${d.getFullYear()}` }</td>
                                                <td><Link to={`/admin/order/${order._id}`}><i className="fa fa-eye" aria-hidden="true"></i></Link></td>
                                            </tr>
                                            )
                                        })
                                    :
                                        <tr>
                                            <td colSpan="8" className="text-center"><h4>No Data Found</h4></td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={ pageCount }
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={10}
                            onPageChange={(e)=> handlePageClick(e)}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                        />
                </div>
                </div>
        </div>
    )
         
}
export default OrderList
