import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Redirect} from 'react-router-dom';
import '../assets/css/Login.css'
import { NotificationManager } from "react-notifications";

const SupplierLogin = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState("");
  const [isLoggedIn, setisLoggedIn] = useState(false);


   useEffect(() => {
     const token = localStorage.getItem("token")

    if (token) {
    setisLoggedIn(true)
    }
},[])

  const userLogin = (e) =>{
    e.preventDefault();
    if (!(email && password)) {
      return NotificationManager.error("Please Fill All the Fields");
    }
    if (email) {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        return NotificationManager.error("Please enter valid email address.");
      }
    }
    const obj = {
      email: email,
      password: password
    }
    axios
    .post('api/login', obj)
    .then(data=>{
      const token =  localStorage.setItem("token", data.data.token);
      localStorage.setItem("loggedInUser", data.data.user);
        console.log(data.data)
        NotificationManager.success("Login Success")
        setisLoggedIn(true)

    })
    .catch(err=>{
      return NotificationManager.error("User Id Password Not Matched with Supplier Account")
    })
  }


  return(
    <div className="wrapper" id="wrapper-login" >
    {isLoggedIn===true?(<Redirect to = "/auth"/>):(

    <div className='form-wrapper-login' id="form-wrapper-login">
    <h2>Supplier Login</h2>

      <form onSubmit={userLogin}>
        <div className="email">
        <label htmlFor="email" style={{textAlign:'left',fontSize:'16px'}}>Email</label>
          <input value={email}
                  onChange={(e)=>setEmail(e.target.value)}
                  type="email"
                  placeholder="name@example.com"/>
        </div>

        <div className="password">
        <label htmlFor="password" style={{textAlign:'left',fontSize:'16px'}}>Password</label>
          <input value={password}
                  onChange={(e)=>setPassword(e.target.value)}
                  type="password"
                  placeholder="Enter Password" />
        </div>
        <br/>
        <div className="submit-login">
          <button className="btn btn-fill" style={{ backgroundColor: 'rgb(255, 160, 0)', color: 'rgb(255, 255, 255)', fontWeight: '500', height: "40px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 4, border: '0px', 'fontSize': '15px' }} type="submit">Login</button>
        </div>
        <div className="submit-login">
        <p className="text-center" style={{ marginTop: "5px" }}>
          Create New Account?{" "}
          <a href="supplier-form" style={{ color: "#ffa000" }}>
            Sign Up
          </a>
        </p>
        <p><a href='/supplier/forgot-password' style={{ color: '#ffa000' , textAlign : 'center'}}>Forgot Password?</a></p>
      </div>
       
            
       </form>
    </div>
    )}
    </div>
  )
}

export default SupplierLogin;
