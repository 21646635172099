import React, { useState, useEffect } from 'react'
import SyncLoader from 'react-spinners/SyncLoader'
import { Route, Switch, Redirect, useHistory } from 'react-router-dom'
import _ from 'lodash'
import '../assets/css/warehouse.css'
import ReactPaginate from 'react-paginate'
import axios from 'axios'
import { NotificationManager } from 'react-notifications'
import { Grid, Row, Col, Table, Button, Modal } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import SelectCountries from '../assets/SelectCountries.js'
const override = `
	position: fixed;
	display: block;
	top: 45%;
	left: 50%;
	transform: translateX(-50%);
	transform: translate(-50%, -50%);
	z-index: 9999;
`
const EditWareHouse = (props) => {
    const { setEditToggle, editToggle } = props
    const [wareName, setWarename] = useState(editToggle.data?.wn)
    const [wareAddress, setWareAddress] = useState(editToggle.data?.wa)
    const [wareCity, setWareCity] = useState(editToggle.data?.wcty)
    const [wareState, setwareState] = useState(editToggle.data?.ws)
    const [wareCountry, setWareCountry] = useState(editToggle.data?.wct)
    const [wareStatus, setWareStatus] = useState(editToggle.data?.wst)
    const [warePriority, setWarePriority] = useState(editToggle.data?.wpr)
    const [wareMobile, setWareMobile] = useState('' + editToggle.data?.wm)
    const [warePhone, setWarePhone] = useState(editToggle.data?.wph.split('').splice(5, 7).join(''))
    const [areaCode, setAreaCode] = useState(editToggle.data?.wph.split('').slice(0, 4).join(''))
    const [wareCode, setWareCode] = useState(editToggle.data?.wc)
    const [warePincode, setWarePincode] = useState('' + editToggle.data?.wp)
    const [errors, setErrors] = useState({})
    const [pincodeFlag, setPincodeFlag] = useState(editToggle.data?.wpf)
    const [pinUpdateFlag, setPinUpdateFlag] = useState(false) 
    const addWare = (e) => {
        e.preventDefault()
        let error = {} 
        let codeExp = /((^[0-9]+[a-z]+)|(^[a-z]+[0-9]+))+[0-9a-z]+$/i
        let phoneExp = /^\d{3}([ -]\d\d|\d[ -]\d|\d\d[ -])\d{6}$/
        let completePhone = areaCode + '-' + warePhone

        if (!wareCode.match(codeExp)) {
            error.code = 'Warehouse code must contain 2 numbers and 2 alphabets.'
        }
        if (!completePhone.match(phoneExp)) { 
            error.phone = 'Not a valid landline number'
        }
        if (!isPossiblePhoneNumber(wareMobile)) {
            error.mobile = 'Mobile number is incorrect'
        }
        setErrors(error)
        if (Object.keys(error).length === 0) { 
            const scvdata = new FormData()
            scvdata.append('name', wareName)
            scvdata.append('address', wareAddress)
            scvdata.append('city', wareCity)
            scvdata.append('state', wareState)
            scvdata.append('country', wareCountry)
            scvdata.append('status', wareStatus)
            scvdata.append('priority', warePriority)
            scvdata.append('mobile', wareMobile)
            scvdata.append('phone', completePhone)
            scvdata.append('code', wareCode)
            scvdata.append('pincodeflag', pincodeFlag)
            scvdata.append('pinupdateflag', pinUpdateFlag)
            if (pinUpdateFlag) {
                scvdata.append('pincode', warePincode[0])
            } else {
                scvdata.append('pincode', warePincode)
            } 
            axios
                .put('/api/updatewarehouse', scvdata)
                .then((data) => {
                    if (data.data.errors) { 
                        NotificationManager.error('Warehouse code already present.', 'Code Exists!');

                    } else { 
                        setErrors({})
                        NotificationManager.success('Warehouse updates.', 'Succesfully Updated');
                        setEditToggle({ toggle: false, data: {} })
                    } 
                })
                .catch((err) => { 
                    NotificationManager.error(err.message)
                })
        } else {
            NotificationManager.error('something went wrong', 'sorry!'); 
        }
    }
 

    return (
        <div className='addware-wrapper' style={{ margin: '20px', background: 'white', padding: '5px 25px' }}>
            <div className=' '>
                <h3 style={{ paddingLeft: '15px' }}>Update Warehouse </h3>
                <form onSubmit={addWare}> 
                    <div id='nameandaddress'>
                        <div className='fullName form-group'>
                            <label>Warehouse Name *</label>
                            <input
                                placeholder='Enter warehouse name'
                                required
                                type='text'
                                name='Name'
                                value={wareName}
                                onChange={(e) => setWarename(e.target.value)}
                            />
                        </div>


                        <div className='fullName form-group'>
                            <label>Warehouse Address</label>
                            <input
                                // required
                                placeholder='Enter warehouse address'
                                type='text'
                                name='address'
                                value={wareAddress}
                                onChange={(e) => setWareAddress(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='fullName form-group'>
                        <SelectCountries countrySetter={setWareCountry} stateSetter={setwareState} citySetter={setWareCity}
                            cplaceholder={wareCountry} cityplaceholder={wareCity} splaceholder={wareState} />
                    </div>


                    <div id='nameandaddress'>
                        <div className='fullName form-group'>
                            <label>Priority - {warePriority}%</label>
                            <input
                                type='range'
                                min='0'
                                max='99'
                                value={warePriority}
                                onChange={(e) => {
                                    setWarePriority(e.target.value)
                                }}
                            />
                        </div>
                        <div className='fullName form-group'>

                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <td>
                                                <label>Status : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                            </td>
                                            <td>
                                                <div>
                                                    <input
                                                        type='radio'
                                                        value='1'
                                                        name='status'
                                                        // checked={wareStatus === 1 && true}
                                                        onChange={(e) => setWareStatus(e.target.value)}
                                                    />
						                            &nbsp;&nbsp;
						                            <label>Active</label>  &nbsp;&nbsp;
						                            <input
                                                        type='radio'
                                                        value='0'
                                                        name='status'
                                                        // checked={wareStatus === 0 && true}
                                                        onChange={(e) => setWareStatus(e.target.value)}
                                                    />
						                            &nbsp;&nbsp;
						                            <label>Inactive</label>
                                                </div>
                                            </td>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div id='nameandaddress'>
                        <div className='fullName form-group'>
                            <label>Privious Mobile * : {editToggle.data?.mobile}</label>
                            <label>[Note: Please choose country code again to avoid error.]</label>
                            <PhoneInput
                                country="IN"
                                placeholder='Enter mobile number'
                                // className='input-box'
                                value={wareMobile}
                                onChange={setWareMobile}
                                required
                            />
                            <label>
                                <p style={{ color: 'red' }}>{errors.mobile ? errors.mobile : ''}</p>
                            </label>
                        </div>


                        <div className='fullName form-group'>
                            <label>Warehouse Phone [Privious Phone : {editToggle.data?.phone}]</label>
                            <div id='nameandaddress'>
                                <div>
                                    <input
                                        type='number'
                                        name='areacode'
                                        placeholder='Enter area code'
                                        maxLength='8'
                                        value={areaCode}
                                        onChange={(e) => setAreaCode(e.target.value)}
                                    />
                                </div>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <div>
                                    <input
                                        type='number'
                                        name='phone'
                                        placeholder='Enter landline number'
                                        maxLength='4'
                                        value={warePhone}
                                        onChange={(e) => setWarePhone(e.target.value)}
                                    />
                                </div>
                            </div>
                            <label><p style={{ color: 'red' }}>{errors.phone ? errors.phone : ''}</p></label>
                        </div>
                    </div>

                    <div id='nameandaddress'>
                        <div className='fullName form-group'>
                            <label>Warehouse Code** [Note : Warehouse code is uneditable]</label>
                            <br /> 
                            <input
                                disabled
                                placeholder='warehouse code Ex:jk13'
                                type='text'
                                name='code'
                                required
                                value={wareCode}
                                onChange={(e) => setWareCode(e.target.value)}
                            />
                            <label><p style={{ color: 'red' }}>{errors.code ? errors.code : ''}</p></label>
                        </div>
                        <div className='fullName form-group'>
                            <label>Upload Pincode File </label>
                            <label>[If you donnot re-upload edited Pincode file previous file will be saved]</label>
                            <br />
                            <input
                                type='file'
                                className='form-control text-center'
                                name='avatar'
                                onChange={(e) => {
                                    setWarePincode(e.target.files)
                                    setPincodeFlag(1)
                                    setPinUpdateFlag(true)
                                }}
                                encType='multipart/form-data'
                                accept='.csv'
                            />
                        </div>
                    </div>
                    <br /> 
                    <div id='button'>
                        <div id='nameandaddress'>
                            <div style={{ padding: '15px 15px 15px 15px' }}>
                                <div className='submit'>
                                    <button className='btn btn-fill' style={{ background: "rgb(255, 160, 0)"  ,  borderColor: "rgb(255, 160, 0)" , fontSize: "15px" , fontWeight: "500"}}>Update</button>
                                </div>
                            </div>
                            <div style={{ padding: '15px 15px 15px 15px' }}>
                                <div className='submit'>
                                    <button onClick={() => setEditToggle({ toggle: false, data: {} })} className='btn btn-fill' style={{ background: "rgb(255, 160, 0)"  ,  borderColor: "rgb(255, 160, 0)" , fontSize: "15px" , fontWeight: "500"}}>Cencel Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default EditWareHouse