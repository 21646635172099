import React, { Component } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import _ from "lodash";
import ReactPaginate from "react-paginate";
import SyncLoader from "react-spinners/SyncLoader";
import { Modal, Button } from "react-bootstrap";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";


export default class MerchantCustomerList extends Component {
  constructor() {
    super();
    this.state = {
      productTotalCount: 0,
    };
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <div className="container-fluid"></div>

        <div className="container-fluid">
          <div className="card" style={{ padding: "1em" }}>
          <ProgressBar
        percent={75}
        filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
      >
        <Step transition="scale">
          {({ accomplished }) => (
            <img
              style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
              width="30"
              src="https://vignette.wikia.nocookie.net/pkmnshuffle/images/9/9d/Pichu.png/revision/latest?cb=20170407222851"
            />
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <img
              style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
              width="30"
              src="https://vignette.wikia.nocookie.net/pkmnshuffle/images/9/97/Pikachu_%28Smiling%29.png/revision/latest?cb=20170410234508"
            />
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <img
              style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
              width="30"
              src="https://orig00.deviantart.net/493a/f/2017/095/5/4/raichu_icon_by_pokemonshuffle_icons-db4ryym.png"
            />
          )}
        </Step>
      </ProgressBar>
          </div>
        </div>
      </div>
    );
  }
}
