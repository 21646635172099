import React, {useState,useEffect} from 'react'
import axios from 'axios'
import { jsPDF } from "jspdf"
import logo from '../shopperr_logo.png'

function CreateInvoice(props) {
    const [PO, setPO] = useState({})
    const [method, setMethod] = useState("")

    useEffect(() => {
        axios.get(`/sscapi/getPoData?id=${props.match.params.id}&method=${props.match.params.method}`)
        .then(res=> {
            console.log(res.data)
            if(res.data){
                setPO(res.data)
                setMethod(props.match.params.method)
            }
        })
    }, [])

    const printMe = (e) => {
        // window.print()
        e.preventDefault()
        const doc = new jsPDF("p" , "px" , "a4")
        drawLines(doc)
        putData(doc, PO, method)
        doc.output('dataurlnewwindow')
    }

    const drawLines = (doc) => {
        doc.setDrawColor("#000")
        doc.setLineWidth(0.50)
        // Border Lines
        doc.line(20 , 20 , 20 , 610) // Left Line
        doc.line(20 , 20 , 425 , 20) // Top Line
        doc.line(425 , 610 , 425, 20) //Right Line
        doc.line(20 , 610 , 425, 610) //Bottom Line

        //head
        doc.line(20 , 100 , 425, 100)
        doc.line(20 , 115 , 425, 115)
        doc.line(20 , 130 , 425, 130)
        doc.line(20 , 145 , 425, 145)
        doc.line(150 , 100 , 150, 130)
        doc.line(280 , 100 , 280, 145)
        doc.line(200 , 130 , 200, 145)
        doc.line(240 , 130 , 240, 145)

        doc.line(20 , 165 , 425, 165)

        //bill to party and ship to party
        doc.line(20 , 180 , 425, 180)
        doc.line(20 , 195 , 425, 195)
        doc.line(20 , 245 , 425, 245) // below address line
        doc.line(20 , 260 , 425, 260)// below phno line
        doc.line(20 , 275 , 425, 275)// below gstin line
        doc.line(20 , 290 , 425, 290)// below state,code,country line
        doc.line(215 , 165 , 215, 290)//vertical line of bill party and ship party
        doc.line(120 , 275 , 120, 290)//code line
        doc.line(150 , 275 , 150, 290)//country line
        doc.line(320 , 275 , 320, 290)//code line
        doc.line(350 , 275 , 350, 290)//country line

        doc.line(20 , 305 , 425, 305)

        //item and order amount data
        doc.line(20 , 330 , 425, 330)//below headings line(description,hsn,qty,total)
        doc.line(150 , 305 , 150, 403)//between description and qty
        doc.line(175 , 305 , 175, 403)//between qty and hsn
        doc.line(220 , 305 , 220, 403)//between hsn and rate/unit
        doc.line(267 , 305 , 267, 403)//between rate/unit and discount
        doc.line(312 , 305 , 312, 403)//between discount and price / unit
        doc.line(358 , 305 , 358, 403)//between price / unit and total
        doc.line(20, 403, 425, 403)//below description line

        // doc.line(20 , 435 , 425, 435)//below description line

        //footer
        doc.line(215 , 403 , 215, 578)//vertical line of footer first one
        doc.line(358 , 403 , 358, 578)//vertical line of footer second one
        doc.line(20 , 423 , 425, 423)//below paymentmode line
        doc.line(20 , 463 , 425, 463)//below amount in words
        doc.line(215 , 443 , 425, 443)//below total amount before tax line
        doc.line(20, 578, 425, 578)//above blank space line(last line)
    }

    const putData = (doc, data, method) => {
        doc.setFontSize(18)
        doc.setTextColor('#000')
        doc.text("Tax Invoice", 25, 35)
        doc.setFontSize(11)
        doc.text(`${method.toUpperCase()}`, 355, 35)
        doc.text(`GSTIN: 06AAHCC8685P1ZR`, 25, 50)
        doc.addImage(logo, 'png', 170 , 45 , 87, 12 , undefined, 'FAST')
        doc.text("5th & 6th Floor, Plot No 436 Phase 4, Gurgaon - 122001, Delhi, India", 95, 72)
        doc.text("www.shopperr.in", 182, 84)
        doc.setFontSize(10)
        doc.text(`Invoice No: ${data.invoiceNo}`, 25, 110)
        doc.text(`Order No: ${data.orderNumber}`, 155, 110)
        doc.text(`Transport Mode: ${data.transportMode}`, 285, 110)
        doc.text(`Invoice Date: ${data.invoiceDate}`, 25, 125)
        doc.text(`Order Date: ${data.orderDate}`, 155, 125)
        doc.text(`Date of Supply: ${data.dateOfSupply}`, 285, 125)
        doc.text(`State: ${data.state}`, 25, 140)
        doc.text(`Code`, 209 ,140)
        doc.text(`${data.code}`, 250 ,140)
        doc.text(`Place of Supply: ${data.placeOfSupply}`, 285, 140)
        doc.text("BILL TO PARTY", 90 , 176)
        doc.text("SHIP TO PARTY / DELIVERY ADDRESS", 250 , 176)
        doc.text(`${data.billingAddress.name}`, 25, 191)
        doc.text(`${data.shippingAddress.name}`, 220, 191)
        let splittedText = doc.splitTextToSize(`${data.billingAddress.address}`, 185)
        doc.text(25, 206, splittedText)
        splittedText = doc.splitTextToSize(`${data.shippingAddress.address}`, 185)
        doc.text(220, 206, splittedText)
        doc.text(`Phone No: ${data.billingAddress.phone}`, 25, 255)
        doc.text(`Phone No: ${data.shippingAddress.phone}`, 220, 255)
        doc.text(`GSTIN: ${data.billingAddress.gstin}`, 25, 270)
        doc.text(`GSTIN: ${data.shippingAddress.gstin}`, 220, 270)
        doc.setFontSize(8)
        doc.text(`State: ${data.billingAddress.state}`, 25, 285)
        doc.text(`State: ${data.shippingAddress.state}`, 220, 285)
        doc.text(`Code: ${data.billingAddress.code}`, 122, 285)
        doc.text(`Code: ${data.shippingAddress.code}`, 323, 285)
        doc.text(`Country: ${data.billingAddress.country}`, 152, 285)
        doc.text(`Country: ${data.shippingAddress.country}`, 353, 285)
        doc.setFontSize(11)
        doc.text("Description", 70, 320)
        doc.text("QTY", 154 , 320)
        doc.text("HSN", 190, 320)
        splittedText = doc.splitTextToSize("Rate / Unit", 37)
        doc.text(233, 316, splittedText)
        doc.text("Discount", 275, 320)
        splittedText = doc.splitTextToSize("Price / Unit", 40)
        doc.text(322, 316, splittedText)
        doc.text("Total", 376, 320)
        doc.setFontSize(10)
        splittedText = doc.splitTextToSize(`${PO.title}`, 125)
        doc.text(25, 339, splittedText)
        doc.text(`${PO.qty}`, 156, 345)
        splittedText = doc.splitTextToSize(`${PO.rateUnit}`, 40)
        doc.text(223, 345, splittedText)
        splittedText = doc.splitTextToSize(`${PO.discount}`, 40)
        doc.text(270, 345, splittedText)
        splittedText = doc.splitTextToSize(`${PO.priceUnit}`, 40)
        doc.text(315, 345, splittedText)
        splittedText = doc.splitTextToSize(`${PO.total}`, 40)
        doc.text(365, 345, splittedText)
        doc.text(`Payment Mode: ${PO.paymentMode}`, 25, 416)
        doc.text("Total Amount before Tax", 220, 416)
        alignRight(doc, PO.AmountBeforeTax, 416)
	
		splittedText = doc.splitTextToSize(`Total Invoice Amount in words: ${PO.amountInWords}`, 185)
        doc.text(25, 436, splittedText)
        doc.text("Shipping Charges ", 220, 436)
        alignRight(doc, PO.shippingCharges, 436)

        doc.text("Total Taxable Amount", 220, 456)
        alignRight(doc, PO.totalTaxableAmount, 456)

        doc.text("This is computer generated invoice and does not require a signature", 194, 620)

        if(PO.invoiceGstType !== 1){
            doc.line(215 , 480 , 425, 480)//below gst rate
            doc.line(215 , 499 , 425, 499)//below gst amount
            doc.line(215 , 519 , 425, 519)//below round off
            doc.line(215 , 559 , 425, 559)//above total
            doc.text("GST Rate %", 220, 474)
            doc.text("GST Amount", 220, 492)
            doc.text("Rounding Off (-)", 220, 510)
            doc.text("TOTAL",220, 571)
            alignRight(doc, PO.gstRate, 474)
            alignRight(doc, PO.gstAmount, 492)
            alignRight(doc, PO.roundOff, 510)
            alignRight(doc, PO.totalAmountForGst, 571)
        }else{
            doc.line(215 , 480 , 425, 480)
            doc.line(215 , 499 , 425, 499)
            doc.line(215 , 519 , 425, 519)
            doc.line(215 , 539 , 425, 539)
            doc.line(215 , 559 , 425, 559)
            doc.text("CGST Rate %", 220, 474)
            doc.text("CGST Amount", 220, 492)
            doc.text("SGST Rate %", 220, 510)
            doc.text("SGST Amount", 220, 531)
            doc.text("Rounding Off (-)", 220, 552)
            doc.text("TOTAL",220, 571)
            alignRight(doc, PO.cgstRate, 474)
            alignRight(doc, PO.cgstAmount, 492)
            alignRight(doc, PO.sgstRate, 510)
            alignRight(doc, PO.sgstAmount, 531)
            alignRight(doc, PO.roundOff, 552)
            alignRight(doc, PO.totalAmountForGst, 571)
        }

        if(method == "performa"){
            doc.text("Bank Account Details", 25, 478)
            splittedText = doc.splitTextToSize(`Account Holder: CREATIONIST TECHNOVATIONS PRIVATE LIMITED`, 175)
            doc.text(25, 492, splittedText)
            doc.text("Bank: ICICI Bank", 25, 512)
            doc.text("Account Number: 114505500187", 25, 522)
            doc.text("IFSC Code: ICIC0001145", 25, 532)
        }else if(method == "original" || method == "duplicate"){
            doc.text("Terms & Conditions", 25, 478)
            splittedText = doc.splitTextToSize(`Goods one sold will not be taken back or exchanged.`, 175)
            doc.text(25, 492, splittedText)
            splittedText = doc.splitTextToSize(`Bills not paid due date will attract 24% interest.`, 175)
            doc.text(25, 502, splittedText)
            splittedText = doc.splitTextToSize(`All disputes subject to Haryana Jurisdiction only.`, 175)
            doc.text(25, 512, splittedText)
        }
    }

    function alignRight(doc, data, height){
        if(data.toString().length <= 3)
            return doc.text(`${data}`, 405, height)
        else if(data.toString().length <= 4)
            return doc.text(`${data}`, 401, height)
        else if(data.toString().length <= 5)
            return doc.text(`${data}`, 397, height)
        else if(data.toString().length <= 6)
            return doc.text(`${data}`, 393, height)
        else if(data.toString().length <= 7)
            return doc.text(`${data}`, 389, height)
        else if(data.toString().length <= 8)
            return doc.text(`${data}`, 385, height)
        else if(data.toString().length <= 9)
            return doc.text(`${data}`, 381, height)
        else return doc.text(`${data}`, 377, height)
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                    <button className="btn btn-primary hidden-print" onClick={printMe}><span className="glyphicon glyphicon-print" aria-hidden="true"> Print </span></button>
                </div>
            </div>

            {/* <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <table cellpadding="0" cellspacing="0" width="100%" border="0" style={{border: "1px solid #444"}}>
                        <tr>
                            <td >
                                <table cellpadding="0" cellspacing="10" width="100%" border="0">
                                    <tr>
                                        <td align="left" style={{fontSize: "20px", fontWeight: "bold"}}>Tax Invoice</td>
                                        <td align="right" style={{fontSize: "14px", fontWeight: "bold", textTransform: "uppercase"}}>{method}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" style={{fontSize: "14px", fontWeight: "bold", textTransform: "uppercase"}}>GSTIN :</td>
                                    </tr>
                                    <tr>
                                        <td align="center" colspan="2" style={{fontSize: "14px"}}><b>shopperr2</b><br />5th & 6th Floor, Plot No 436 Phase 4, Gurgaon - 122001, Delhi, India</td>
                                    </tr>
                                    <tr>
                                        <td align="center" colspan="2" style={{fontSize: "14px", fontWeight: "bold"}}>shopperr2.myshopify.com</td>
                                    </tr>
                                    <tr>
                                        <td align="left">account@shopper.in</td>
                                        <td align="right">9311494803</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{fontSize: "12px"}}>
                                <table cellpadding="0" cellspacing="0" width="100%" border="0">
                                    <tr>
                                        <td width="25%" style={{borderLeft: "inherit"}}>Invoice No: <b>{PO.invoiceNo}</b></td>
                                        <td width="25%">Order No: <b>{PO.orderNumber}</b></td>
                                        <td width="50%" style={{borderRight: "inherit"}}>Transport Mode: -</td>
                                    </tr>
                                    <tr>
                                        <td width="25%" style={{borderLeft: "inherit"}}>Invoice Date: <b>{PO.invoiceDate}</b></td>
                                        <td width="25%">Order Date: <b>{PO.orderDate}</b></td>
                                        <td width="50%">Date of Supply: <b>{PO.dateOfSupply}</b></td>
                                    </tr>
                                    <tr>
                                        <td width="50%" colspan="2" style={{padding: "inherit", borderLeft: "inherit"}}>
                                            <table cellpadding="0" cellspacing="0" width="100%" border="0">
                                                <tr>
                                                    <td style={{borderTop: "inherit", borderLeft: "inherit", borderBottom: "inherit"}} width="70%">State: <b>{PO.state}</b></td>
                                                    <td style={{borderTop: "inherit", textAlign: "center", borderBottom: "inherit"}} width="15%">Code</td>
                                                    <td style={{borderTop: "inherit", borderRight: "inherit", borderBottom: "inherit", textAlign: "center"}} width="15%"><b>{PO.code}</b></td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td width="50%">Place of Supply: <b>{PO.placeOfSupply}</b></td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{fontSize: "12px"}}>
                                <table cellpadding="0" cellspacing="0" width="100%" border="0" id="table-border">
                                    <tr>
                                        <th width="50%" style={{textTransform: "uppercase", borderLeft: "inherit"}}>BILL TO PARTY</th>
                                        <th width="50%" style={{textTransform: "uppercase", borderRight: "inherit"}}>SHIP TO PARTY / DELIVERY ADDRESS</th>
                                    </tr>
                                    <tr>
                                        <td style={{fontWeight: "bold", borderLeft: "inherit"}}>{PO.billingAddress ? PO.billingAddress.name : ""}</td>
                                        <td style={{fontWeight: "bold", borderRight: "inherit"}}>{PO.shippingAddress ? PO.shippingAddress.name : ""}</td>
                                    </tr>
                                    <tr>
                                        <td style={{borderLeft: "inherit"}}>{PO.billingAddress ? PO.billingAddress.address : ""}</td>
                                        <td>{PO.shippingAddress ? PO.shippingAddress.address : ""}</td>
                                    </tr>
                                    <tr>
                                        <td style={{borderLeft: "inherit"}}><b>Phone No: - {PO.billingAddress ? PO.billingAddress.phone : ""}</b></td>
                                        <td><b>Phone No: - {PO.shippingAddress ? PO.shippingAddress.phone : ""}</b></td>
                                    </tr>
                                    <tr>
                                        <td style={{borderLeft: "inherit"}}><b>GSTIN: - </b></td>
                                        <td><b>GSTIN: - </b></td>
                                    </tr>
                                    <tr>
                                        <td style={{borderLeft: "inherit", padding: "0"}}>
                                            <table cellpadding="0" cellspacing="0" width="100%" border="0" id="table-border">
                                                <tr>
                                                    <td style={{borderTop: "inherit", borderLeft: "inherit", borderBottom: "inherit"}}><b>State:</b> {PO.billingAddress ? PO.billingAddress.state : ""}</td>
                                                    <td style={{borderTop: "inherit", borderBottom: "inherit", fontWeight: "bold"}}>Code</td>
                                                    <td style={{borderBottom: "inherit", borderTop: "inherit"}}>{PO.billingAddress ? PO.billingAddress.code : ""}</td>
                                                    <td style={{borderTop: "inherit", borderBottom: "inherit", borderRight: "inherit"}}><b>Country:</b> {PO.billingAddress ? PO.billingAddress.country : ""}</td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td style={{padding: "0"}}>
                                            <table cellpadding="0" cellspacing="0" width="100%" border="0" id="table-border">
                                                <tr>
                                                <td style={{borderTop: "inherit", borderLeft: "inherit", borderBottom: "inherit"}}><b>State:</b> {PO.shippingAddress ? PO.shippingAddress.state : ""}</td>
                                                    <td style={{borderTop: "inherit", borderBottom: "inherit", fontWeight: "bold"}}>Code</td>
                                                    <td style={{borderBottom: "inherit", borderTop: "inherit"}}>{PO.shippingAddress ? PO.shippingAddress.code : ""}</td>
                                                    <td style={{borderTop: "inherit", borderBottom: "inherit", borderRight: "inherit"}}><b>Country:</b> {PO.shippingAddress ? PO.shippingAddress.country : ""}</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{fontSize: "12px"}}>
                                <table cellpadding="0" cellspacing="0" width="100%" border="0" id="table-border">
                                    <tr>
                                        <th width="20%" style={{textTransform: "uppercase", borderLeft: "inherit"}}>Description</th>
                                        <th sstyle={{textTransform: "uppercase"}}>QTY</th>
                                        <th style={{textTransform: "uppercase"}}>HSN</th>
                                        <th style={{textTransform: "uppercase"}}>Rate / Unit</th>
                                        <th style={{textTransform: "uppercase"}}>Discount</th>
                                        <th style={{textTransform: "uppercase"}}>Price / Unit</th>
                                        <th style={{textTransform: "uppercase"}}>Total</th>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: "center" , borderLeft: "inherit"}}>{PO.title}</td>
                                        <td style={{textAlign: "center"}}>{PO.qty}</td>
                                        <td style={{textAlign: "center"}}>{PO.hsn}</td>
                                        <td style={{textAlign: "center"}}>{PO.rateUnit}</td>
                                        <td style={{textAlign: "center"}}>{PO.discount}</td>
                                        <td style={{textAlign: "center"}}>{PO.priceUnit}</td>
                                        <td style={{textAlign: "center"}}>{PO.total}</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{fontSize: "12px"}}>
                                <table cellpadding="0" cellspacing="0" width="100%" border="0">
                                    <tr>
                                        <td width="50%">Payment Mode: {PO.paymentMode}</td>
                                        <td width="40%">Amount Before Tax </td>
                                        <td width="10%">{PO.AmountBeforeTax}</td>
                                    </tr>
                                    <tr>
                                        <td width="50%" rowSpan="2">Total Invoice Amount in words: {PO.amountInWords}</td>
                                        <td width="40%" >Shipping Charges </td>
                                        <td width="10%">{PO.shippingCharges}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%">Total Taxable Amount</td>
                                        <td width="10%">{PO.totalTaxableAmount}</td>
                                    </tr>
                                    <tr>
                                        <td width="50%" rowSpan="7">
                                            {
                                                method === "performa"
                                                &&
                                                    <div className="text-center">
                                                        <span>Bank Account Details:Account Holder : CREATIONIST TECHNOVATIONS PRIVATE LIMITED</span><br></br>
                                                        <span>Bank : ICICI Bank</span><br></br>
                                                        <span>Account Number : 114505500187</span><br></br>
                                                        <span>IFSC Code : ICIC0001145</span>
                                                    </div>
                                            }
                                        </td>
                                        {
                                            PO.invoiceGstType === 1
                                            ?
                                                <>
                                                    <td width="40%">GST Rate %</td>
                                                    <td width="10%">{PO.gstRate}</td>
                                                </>
                                            :
                                                <>
                                                    <td width="40%">CGST Rate %</td>
                                                    <td width="10%">{PO.cgstRate}</td>
                                                </>
                                        }
                                    </tr>
                                    <tr>
                                        {
                                            PO.invoiceGstType === 1
                                            ?
                                                <>
                                                    <td width="40%">GST Amount</td>
                                                    <td width="10%">{PO.gstAmount}</td>
                                                </>
                                            :
                                                <>
                                                    <td width="40%">CGST Amount </td>
                                                    <td width="10%">{PO.cgstAmount}</td>
                                                </>
                                        }
                                    </tr>
                                    <tr>
                                        {
                                            PO.invoiceGstType !== 1
                                            &&
                                                <>
                                                    <td width="40%">SGST Rate</td>
                                                    <td width="10%">{PO.sgstRate}</td>
                                                </>
                                        }
                                    </tr>
                                    <tr>
                                        {
                                            PO.invoiceGstType !== 1
                                            &&
                                                <>
                                                    <td width="40%">SGST Amount</td>
                                                    <td width="10%">{PO.sgstAmount}</td>
                                                </>
                                        }
                                    </tr>
                                    <tr>
                                        <td width="40%">Rounding Off</td>
                                        <td width="10%">{PO.roundOff}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%">Total Amount</td>
                                        {
                                            PO.invoiceGstType === 1
                                            ?
                                                <>
                                                    <td width="10%">{PO.totalAmountForGst}</td>
                                                </>
                                            :
                                                <>
                                                    <td width="10%">{PO.totalAmountForCstAndIgst}</td>
                                                </>
                                        }
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </div>
            </div> */}
        </div>
    )
}
export default CreateInvoice