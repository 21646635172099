import React, { useState, useEffect } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { NotificationManager } from "react-notifications";
import Card from "../../components/Card/Card.jsx";
import CustomButton from "../../components/CustomButton/CustomButton";
import "../../assets/css/supplierOrders.css";
// import MerchantAccountDetail from ".././MerchantAccountDetail.jsx";
import moment from "moment";
import _ from "lodash";
import ReactPaginate from "react-paginate";
import SyncLoader from "react-spinners/SyncLoader";
import { getPriceFormatter } from "../../helpers/index.js";
const override = `
    position: fixed;
    display: block;
    top: 45%;
    left: 50%;
    transform: translateX(-50%);
    transform: translate(-50%, -50%);
    z-index: 1;
`;
const MerchantDetail = () => {
  const [expand, setExpand] = useState("");
  const [merchantList, setMerchantList] = useState([]);
  const [withoutSortmerchantLists, setwithoutSortmerchantList] = useState([]);
  const [fulfill, setFulfill] = useState("");
  const [orderDetail, setOrderDetail] = useState("");
  const [found, setFound] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [showPerPage, setshowPerPage] = useState(50);
  const [pageCount, setpageCount] = useState(0);
  const [merchnatTotalCount, setmerchnatTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    setLoading(true);
    getMerchant(currentPage, showPerPage);
  }, []);

  const getMerchant = async (page, perPage) => {
    const res = await axios.get(
      `/api/customMerchantDetail?page=${page}&limit=${perPage}`
    );
    var resdata = res.data.data.sort(
      (a, b) => new Date(b.joiningDate) - new Date(a.joiningDate)
    );
    if (res.data.length === 0) {
      setFound("No Data Found");
    } else {
      setMerchantList(resdata);
      setwithoutSortmerchantList(resdata);
      setmerchnatTotalCount(res.data.totalCount);
      setpageCount(Math.ceil(res.data.totalCount / showPerPage));
      setcurrentPage(page);
      setLoading(false);
    }
  };

  const handlePageClick = (e) => {
    setLoading(true);
    getMerchant(e.selected + 1, showPerPage);
  };

  const handleSort = (e) => {
    e.preventDefault();

    if (e.target.value === "balance") {
      const data = [...withoutSortmerchantLists];
      data.sort((a, b) => parseFloat(b.balance) - parseFloat(a.balance));

      setMerchantList(data);
    } else if (e.target.value === "price") {
      const data = [...withoutSortmerchantLists];
      data.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
      setMerchantList(data);
    } else if (e.target.value === "revenue") {
      const data = [...withoutSortmerchantLists];
      setMerchantList(
        data.sort((a, b) => parseFloat(b.revenue) - parseFloat(a.revenue))
      );
    } else if (e.target.value === "nothing") {
      setMerchantList(withoutSortmerchantLists);
    }
  };

  const handleSearchSKU = () => {
    if (_.isEmpty(search)) {
      NotificationManager.error("Enter Sku");
    } else {
      axios.post(`/api/resellerByNam=${search}`).then((res) => {
        // setSuppliers(res.data.data);
        // setwithoutSortsuppliers(res.data.data);
        // setproductTotalCount(res.data.totalCount);
        // setpageCount(Math.ceil(res.data.totalCount / showPerPage));
        // setcurrentPage(currentPage);
      });
    }
  };

  const triggerHanleSearch = (e) => {
    e.persist();
    if (e.key === "Enter") {
      handleSearchSKU();
    }
  };
  return (
    <div>
      <SyncLoader
        css={override}
        size={15}
        margin={6}
        color={"#3E3E3E"}
        loading={loading}
      />
      <div className="content">
        <div
          className="row"
          style={{ paddingLeft: "15px", marginBottom: "15px" }}
        >
          <div className="col-md-5">
            <input
              type="search"
              onChange={(e) => setSearch(e.target.value)}
              onKeyPress={triggerHanleSearch}
              className="primary"
              placeholder="Search..."
              style={{ height: "40px", width: "100%" }}
            />
          </div>
          <div className="col-md-4">
            <select className="form-control" onChange={(e) => handleSort(e)}>
              <option value="nothing">Sort By</option>
              <option value="balance"> Balance</option>
              <option value="price">Revenue</option>
            </select>
          </div>
        </div>
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Reseller List"
                category={"Total Reseller :" + merchnatTotalCount}
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table hover size="sm">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Date of Joining</th>
                        <th>Current Balance</th>
                        <th>Total No. of Orders</th>
                        <th>Total Revenue</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* <tr>{found}</tr> */}
                      { merchantList.map((item, key) => {
                        return (
                          <>
                            <tr
                              key={key}
                              onClick={() => {
                                if (expand === item.id) {
                                  setExpand(null);
                                } else {
                                  setExpand(item.id);
                                }
                              }}
                            >
                              <td>
                                {showPerPage * (currentPage - 1) + (key + 1)}
                              </td>
                              <td>
                                {item.firstName || "NA"} {item.lastName || ""}
                              </td>
                              <td>{item.email || "NA"}</td>
                              <td>
                                {moment(item.joiningDate).format(
                                  "DD-MM-YYYY"
                                ) || "NA"}
                              </td>
                              <td>
                                {getPriceFormatter(item.balance).slice(1) ||
                                  "NA"}
                              </td>
                              <td>{item.count || "NA"}</td>
                              <td>{getPriceFormatter(item.price) || "NA"}</td>
                              <td>
                                <a href={"/admin/merchant/" + item.id}>
                                  View More
                                </a>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </Table>
                }
              />
              {!_.isEmpty(merchantList) && (
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  marginPagesDisplayed={3}
                  pageRangeDisplayed={10}
                  onPageChange={(e) => handlePageClick(e)}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              )}
            </Col>
          </Row>
        </Grid>
      </div>
    </div>
  );
};

export default MerchantDetail;
