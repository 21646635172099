import React, { useState, useEffect } from 'react'
import SyncLoader from 'react-spinners/SyncLoader'
import { Route, Switch, Redirect, useHistory } from 'react-router-dom'
import _ from 'lodash'
import ReactPaginate from 'react-paginate'
import axios from 'axios'
import { NotificationManager } from 'react-notifications'
import { Grid, Row, Col, Table, Button, Modal } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import EditWareHouse from '.././WarehouseEdit'
const override = `
	position: fixed;
	display: block;
	top: 45%;
	left: 50%;
	transform: translateX(-50%);
	transform: translate(-50%, -50%);
	z-index: 9999;
`


const DisplayWarehouses = (props) => {
    const { setEditToggle } = props
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(20)
    const [pageCount, setPageCount] = useState(0)
    const [query, setQuery] = useState({})
    const [loading, setLoading] = useState(true)
    const [total, setTotal] = useState()
    const [modalShow, setModalShow] = useState(false)
    const [deleteid, setDeleteId] = useState('')
    const [rdata, setRedirect] = useState({ r: false, data: '' })

    // useEffect(() => {
    //     axios.get('/api/viewwarehouse').then((response) => {
    //         console.log(response.data)
    //         // setData(response.data)
    //     }).catch((err) => {
    //         alert(err.message)
    //     }, [])
    // }, [])
    useEffect(() => { getwarehouse(page, perPage, query) }, [])
    const getwarehouse = (page, perPage, query) => {
        axios.get(`/api/viewwarehouses?page=${page}&limit=${perPage}&query=${!_.isEmpty(query) ? query : "{}"}`)
            .then(result => {
                // console.log('result : ', result)
                setData(result.data.items)
                setLoading(false)
                setTotal(result.data.totalCount)
                setPageCount(Math.ceil(result.data.totalCount / perPage))
                setPage(1)
            })
    }
    const handlePageClick = e => {
        getwarehouse((e.selected + 1), perPage, query)
    }

    const deleteProduct = (e) => {
        // console.log('delete id :  ', deleteid)
        axios.delete(`/api/deleteWare/${deleteid}`).then((response) => {
            // console.log(response.data)
            NotificationManager.success('Warehouse Deleted.', 'Succesfully Deleted');
            setModalShow(false)
        }).catch((err) => {
            NotificationManager.success('Warehouse not Deleted.', 'Something went wrong!');
            console.log(err.message)
        }, [])
    }
  

    return <>
        <div className='container-fluid'>
            {(rdata.r) ? (<Redirect
                to={{
                    pathname: "/admin/warehousedetails",
                    state: rdata.data
                }}
            />) : ('')}
            <div className='card' style={{ padding: '1em' }}>
                <SyncLoader css={override} size={15} margin={6} color={"#3E3E3E"} loading={loading} />
                <div style={{ cursor: "pointer" }}>
                    <div className="form-group">
                        <h5 className="text-bold">Total WareHouses: <span className="text-muted">{total}</span>
                            {/* &nbsp;<i class="fa fa-refresh" aria-hidden="true"></i> */}
                        </h5>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table table-hover">
                        <thead>
                            <tr className="d-flex">
                                <th scope="col" >Name</th>
                                <th scope="col" >Code</th>
                                <th scope="col" >Priority</th>
                                <th scope="col" >Status</th>
                                <th scope="col" >Mobile</th>
                                <th scope="col" >Phone</th>
                                {/* <th scope="col" >Pincode</th> */}
                                {/* <th scope="col" >View</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {!_.isEmpty(data) ? data.map((ele, i) => {
                                return (<tr key={i} className="d-flex">
                                    <td>{ele.wn}</td>
                                    <td>{ele.wc}</td>
                                    <td>{ele.wpr}</td>
                                    <td>{ele.wst}</td>
                                    <td>{ele.wm}</td>
                                    <td>{ele.wph}</td>
                                    {/* <td>{ele.pincode}</td> */}
                                    <td>
                                        {/* <button className='btn btn-primary'
                                        onClick={() => setRedirect({ r: true, data: ele })}>View</button> */}
                                        <i className="fa fa-eye" style={{ cursor: "poiner",   color: 'rgb(66, 208, 237)' }}
                                            aria-hidden="true" onClick={() => setRedirect({ r: true, data: ele })}></i>
                                    </td>
                                    <td>
                                        <span >
                                            <i className="fa fa-pencil" style={{ cursor: "poiner",   color: 'rgb(66, 208, 237)' }}
                                                aria-hidden="true" onClick={() => { setEditToggle({ toggle: true, data: ele }) }}></i>
                                        </span>
                                    </td>
                                    {/* <td>
                                        <span className="text-danger" style={{ cursor: "poiner" }}>
                                            <i className="fa fa-trash" style={{ fontSize: "22px" }} aria-hidden="true"
                                                onClick={() => {
                                                    setModalShow(true)
                                                    setDeleteId(ele._id)
                                                }}></i>
                                        </span>
                                    </td> */}
                                </tr>)
                            }) : null}

                        </tbody>
                    </table>
                </div>

                <Modal show={modalShow} onHide={() => setModalShow({ modalShow: false })} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Product</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>Are You sure you want to delete this entry?</h5>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setModalShow(false)}>
                            Cancel
						</Button>
                        <Button variant="success" onClick={() => deleteProduct()}>
                            Delete
						</Button>
                    </Modal.Footer>
                </Modal>



                <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pageCount}
                    marginPagesDisplayed={3}
                    pageRangeDisplayed={10}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                />
            </div>
        </div>
    </>
}




const ViewWarehouses = (props) => {
    const [editToggle, setEditToggle] = useState({ toggle: false, data: {} })
    return (
        <>
            {(editToggle.toggle) ? (<EditWareHouse setEditToggle={setEditToggle} editToggle={editToggle} />) : (<DisplayWarehouses setEditToggle={setEditToggle} />)}
        </>
    )
}
export default ViewWarehouses
