import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";

export class StatsCard extends Component {
  render() {
    return (
      <div  className="card card-stats">

        <div className={
          (this.props.statsText==='Total Products') ? "content green":(this.props.statsText==='Orders Fulfill Left') ? "content green":(this.props.statsText==='Merchants') ? "content green":(this.props.statsText==='Total Revenue') ? "content yellow":(this.props.statsText==='Total Orders') ? "content lightyellow":(this.props.statsText==='Total Customers') ? "content lightblue":"content"
        } 
        style={{borderRadius:"10px"}}
       >
          <Row>
            <Col xs={3}>
              <div className="icon-big icon-warning">
                {this.props.bigIcon}
              </div>
            </Col>
            <Col xs={9}>
              <div className="numbers">
                <p style={{color:"#fff"}}>{this.props.statsText}</p>
               <span style={{color:"#fff" ,fontSize:"20px"}}>{this.props.statsValue}</span> 
              </div>
            </Col>
          </Row>
          {/* <div className="footer">
            
            <div className="stats" style={{ cursor: "pointer" }} onClick={(e) => this.props.func()}>
              {this.props.statsIcon} <span style={{color:"#fff"}}>{this.props.statsIconText}</span>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

export default StatsCard;
