import React, { Component } from "react";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import SyncLoader from "react-spinners/SyncLoader"
import _ from "lodash";
import { Redirect } from 'react-router-dom'

const imageExtensions = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/bmp']

const override = `
	position: fixed;
	display: block;
	top: 45%;
	left: 50%;
	transform: translateX(-50%);
	transform: translate(-50%, -50%);
	z-index: 9999;
`

export default class AddOrderProcess extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            poId: "",
            supplierId: "",
            productId: "",
            amount: "",
            sku: "",
            handoverAddress:"",
            handoverName:"",
            boxId:"",
            orderNumber:"",
            errors: {},
            redirect: false
        }
    }

    handlePoId = e => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                poId: ""
            },
            poId: e.target.value
        }))
    }

    handleSupplierId = e => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                supplierId: ""
            },
            supplierId: e.target.value
        }))
    }

    handleSku = e => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                sku: ""
            },
            sku: e.target.value
        }))
    }

    handleProductId = e => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                productId: ""
            },
            productId: e.target.value
        }))
    }

    handleAmount = e => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                amount: ""
            },
            amount: e.target.value
        }))
    }
    handleShipmentId = e => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                boxId: ""
            },
            boxId: e.target.value
        }))
    }
    handleHandoverName = e => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                handoverName: ""
            },
            handoverName: e.target.value
        }))
    }
    handleHandoverAddress = e => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                handoverAddress: ""
            },
            handoverAddress: e.target.value
        }))
    }
    handleOrderNumber = e => {
        e.persist()
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                orderNumber: ""
            },
            orderNumber: e.target.value
        }))
    }
    orderNumber
    handleAddOrderProcess = e => {
        e.preventDefault()
        console.log('Calling heree');
        let obj = {
            OrderProcessData: {
                
                // poId: this.state.poId,
                // supplierId: this.state.supplierId,
                sku: this.state.sku,
                // productId: this.state.productId,
                amount: this.state.amount,
                handoverName: this.state.handoverName,
                handoverAddress: this.state.handoverAddress,
                boxId: this.state.boxId,
                orderNumber: this.state.orderNumber
            }
        }
        console.log('OrderProcessData: ', obj);
        let validate = this.handleValidation(obj.OrderProcessData)
        console.log('validate: ', validate);
        if (validate.isValid) {
            this.setState({ loading: true })
            axios.post("orderProcess/createOrderProcess", obj)
                .then(result => {
                    console.log('result: ', result);
                    if (result.data.data === 1) {
                        NotificationManager.success(result.data.message)
                        this.setState({ loading: false, redirect: true })
                    } else if (result.data.data === 2) {
                        NotificationManager.error(result.data.message)
                        this.setState({ loading: false })
                    }
                })
                .catch(err => {
                    console.log('err: ', err);
                    this.setState({ loading: false })
                    console.log(err)
                    NotificationManager.error("Failed to add Order Process")
                })
        } else {
            this.setState({ errors: validate.errors })
        }
    }

    handleValidation(data) {
        let errors = {}

        if (_.isEmpty(data.sku))
            errors.sku = "sku is required"

        return { errors, isValid: _.isEmpty(errors) }
    }

    render() {
        if (this.state.redirect) {
            return (
                <Redirect to="/admin/orderProcess" />
            )
        } else {
            return (
                <div>
                    <SyncLoader css={override} size={15} margin={6} color={"#3E3E3E"} loading={this.state.loading} />
                    <br></br>
                    <div className={this.state.loading ? "parentDisable" : ""}>
                        <div className="container">
                            <div className="card" style={{ padding: "1em" }}>
                                <div className="row">
                                    {/* <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                        <div className='form-group'>
                                            <label style={{ fontSize: 15 }} htmlFor='poId'>
                                                <span className="text-danger">* </span> po Id &nbsp;
                                                <span className="custom-tooltip" data-tooltip="PO Id">
                                                    <i class="fa fa-question-circle" aria-hidden="true"></i>
                                                </span>
                                            </label>
                                            <input
                                                type='text'
                                                value={this.state.poId}
                                                onChange={this.handlePoId.bind(this)}
                                                className='form-control'
                                                placeholder='poId'
                                                id="poId"
                                                autoComplete="off"
                                            />
                                            {
                                                this.state.errors.poId
                                                &&
                                                <span className="text-danger"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {this.state.errors.poId}</span>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                        <div className='form-group'>
                                            <label style={{ fontSize: 15 }} htmlFor='supplierId'>
                                                supplier Id &nbsp;
                                                <span className="custom-tooltip" data-tooltip="Supplier Id">
                                                    <i class="fa fa-question-circle" aria-hidden="true"></i>
                                                </span>
                                            </label>
                                            <input
                                                type='text'
                                                value={this.state.supplierId}
                                                onChange={this.handleSupplierId.bind(this)}
                                                className='form-control'
                                                placeholder='supplier Id'
                                                id="supplierId"
                                                autoComplete="off"
                                            />
                                        </div>
                                        <br></br>
                                    </div> */}
                                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                        <div className='form-group'>
                                            <label style={{ fontSize: 15 }} htmlFor='sku'>
                                                SKU &nbsp;
                                                <span className="custom-tooltip" data-tooltip="sku">
                                                    <i class="fa fa-question-circle" aria-hidden="true"></i>
                                                </span>
                                            </label>
                                            <input
                                                type='text'
                                                value={this.state.sku}
                                                onChange={this.handleSku.bind(this)}
                                                className='form-control'
                                                placeholder='sku'
                                                id="sku"
                                                autoComplete="off"
                                            />
                                        </div>
                                        <br></br>
                                    </div>
                                    {/* <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                        <div className='form-group'>
                                            <label style={{ fontSize: 15 }} htmlFor='productId'>
                                                productId &nbsp;
                                                <span className="custom-tooltip" data-tooltip="productId">
                                                    <i class="fa fa-question-circle" aria-hidden="true"></i>
                                                </span>
                                            </label>
                                            <input
                                                type='text'
                                                value={this.state.productId}
                                                onChange={this.handleProductId.bind(this)}
                                                className='form-control'
                                                placeholder='productId'
                                                id="productId"
                                                autoComplete="off"
                                            />
                                        </div>
                                        <br></br>
                                    </div> */}
                                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                        <div className='form-group'>
                                            <label style={{ fontSize: 15 }} htmlFor='amount'>
                                                total amount &nbsp;
                                                <span className="custom-tooltip" data-tooltip="amount">
                                                    <i class="fa fa-question-circle" aria-hidden="true"></i>
                                                </span>
                                            </label>
                                            <input
                                                type='text'
                                                value={this.state.amount}
                                                onChange={this.handleAmount.bind(this)}
                                                className='form-control'
                                                placeholder='amount'
                                                id="amount"
                                                autoComplete="off"
                                            />
                                        </div>
                                        <br></br>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                        <div className='form-group'>
                                            <label style={{ fontSize: 15 }} htmlFor='handoverAddress'>
                                            handoverAddress &nbsp;
                                                <span className="custom-tooltip" data-tooltip="handoverAddress">
                                                    <i class="fa fa-question-circle" aria-hidden="true"></i>
                                                </span>
                                            </label>
                                            <input
                                                type='text'
                                                value={this.state.handoverAddress}
                                                onChange={this.handleHandoverAddress.bind(this)}
                                                className='form-control'
                                                placeholder='handoverAddress'
                                                id="handoverAddress"
                                                autoComplete="off"
                                            />
                                        </div>
                                        <br></br>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                        <div className='form-group'>
                                            <label style={{ fontSize: 15 }} htmlFor='handoverName'>
                                            handoverName &nbsp;
                                                <span className="custom-tooltip" data-tooltip="handoverName">
                                                    <i class="fa fa-question-circle" aria-hidden="true"></i>
                                                </span>
                                            </label>
                                            <input
                                                type='text'
                                                value={this.state.handoverName}
                                                onChange={this.handleHandoverName.bind(this)}
                                                className='form-control'
                                                placeholder='handoverName'
                                                id="handoverName"
                                                autoComplete="off"
                                            />
                                        </div>
                                        <br></br>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                        <div className='form-group'>
                                            <label style={{ fontSize: 15 }} htmlFor='boxId'>
                                            boxId &nbsp;
                                                <span className="custom-tooltip" data-tooltip="boxId">
                                                    <i class="fa fa-question-circle" aria-hidden="true"></i>
                                                </span>
                                            </label>
                                            <input
                                                type='text'
                                                value={this.state.boxId}
                                                onChange={this.handleShipmentId.bind(this)}
                                                className='form-control'
                                                placeholder='boxId'
                                                id="boxId"
                                                autoComplete="off"
                                            />
                                        </div>
                                        <br></br>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                        <div className='form-group'>
                                            <label style={{ fontSize: 15 }} htmlFor='orderNumber'>
                                            orderNumber &nbsp;
                                                <span className="custom-tooltip" data-tooltip="orderNumber">
                                                    <i class="fa fa-question-circle" aria-hidden="true"></i>
                                                </span>
                                            </label>
                                            <input
                                                type='text'
                                                value={this.state.orderNumber}
                                                onChange={this.handleOrderNumber.bind(this)}
                                                className='form-control'
                                                placeholder='orderNumber'
                                                id="orderNumber"
                                                autoComplete="off"
                                            />
                                        </div>
                                        <br></br>
                                    </div>
                                </div>
                                
                                <div className="row">
                                    <div className="col-sm-12 col-md-2 col-lg-2 col-xl-2">
                                        <button className="btn btn-outline-primary" style={{
                                            backgroundColor: 'rgb(255, 160, 0)',
                                            color: 'rgb(255, 255, 255)',
                                            fontWeight: '500',
                                            height: "40px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            borderRadius: 4,
                                            border: '0px',
                                            'fontSize': '15px'
                                        }} onClick={this.handleAddOrderProcess}>Add Order Process
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}