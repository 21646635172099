import React, { Component } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import "../../assets/css/addProductCatalog.css";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import "font-awesome/css/font-awesome.min.css";
import _ from "lodash";
import CustomButton from "../../components/CustomButton/CustomButton";
import jwt_decode from "jwt-decode";
import { NotificationManager } from "react-notifications";
import { Redirect } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
import validator from "validator";
import $ from "jquery";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

const override = `
	position: fixed;
	display: block;
	top: 45%;
	left: 50%;
	transform: translateX(-50%);
	transform: translate(-50%, -50%);
	z-index: 9999;
`;
const imageExtensions = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/webp",
  "image/bmp",
];

export default class EditProduct extends Component {
  constructor() {
    super();
    this.state = {
      loaded: false,
      productTitleCharLength: 0,
      productTitleCharMaxLength: 160,
      productTitle: "",
      productSubTitleCharLength: 0,
      productSubTitleCharMaxLength: 160,
      productSubTitle: "",
      invoiceDescriptionCharLength: 0,
      invoiceDescriptionCharMaxLength: 100,
      invoiceDescription: "",
      detailDescription: "",
      marketPlaceUrl: "",
      marketPlacePrice: "",
      marketPlaceMrp: "",
      tags: [],
      imageArray: [],
      isVarien: false,
      varientArray: [],
      varientArrayCombo: [],
      idOrSku: "",
      factoryPrice: "",
      mrp: "",
      quantity: "",
      cbm: "",
      categoryList: [],
      category: "Select Category",
      margin: 0,
      hsn: "",
      weight: 0.0,
      weightLabel: "g",
      warranty: 0,
      height: 0.0,
      width: 0.0,
      length: 0.0,
      bcd: "",
      igst: "",
      swc: "",
      shippingMode: "",
      usaShippingPrice: 0,
      canadaShippingPrice: 0,
      ausShippingPrice: 0,
      ukShippingPrice: 0,
      interShippingPrice: 0,
      pdStatus: "0",
      spStatus: 0,
      isDisplay: "0",
      pid: "",
      redirectTo: false,
      errors: {},
      loading: true,
      supplierList: [],
      selectedSupplier: "Select Supplier",
      priority: "",
      subSupplierList: [],
      editMode: false,
      selectedSupplierId: "",
      selectedSupplierIndex: "",
      isSubscribed: 0,
      su_status: 0,
      slabOne: 0,
      slabTwo: 0,
      slabThree: 0,
      slabFour: 0,
      loggedInUser: "",
      deliveryDaysTo: 0,
      deliveryDaysFrom: 0,
      isBattery: "0",
      isLiquid: "0",
      numberOfItems: "",
      packageIncludes: "",
      yearOfManufacturer: "",
      productExpiry: "",
      browseNode: "",
      bulletpoints: [],
      categoryId: "",
      customfields: [],
      channel: [],
      imgUrlValue:""
    };
    this.state.open = false;
    this.state.options = [];
    this.state.loading = this.state.open && this.state.options.length === 0;
    this.uploadMultipleImages = this.uploadMultipleImages.bind(this);
    this.nodesData = {};
  }

  changeCatData(e) {
    let text = e.target.value;
    if (this.nodesData && this.nodesData[text]) {
      this.setState({ options: this.nodesData[text] });
      return true;
    }
    axios.get("/api/getBrowseNode?text=" + text).then((result) => {
      this.nodesData[text] = result.data.Response.nodes;
      this.setState({ options: this.nodesData[text] });
    });
  }

  onBrowseNodeSelect(e, option) {
    this.setState({ browseNode: option.node });
  }
  componentDidUpdate() {
    this.addBulletField();
    this.addCustomFields();
  }
  componentDidMount() {
    this.setState({ loggedInUser: localStorage.getItem("loggedInUser") });
    axios
      .get(`/add/getItem/${localStorage.getItem("code")}`)
      .then(async (result) => {
        if (result.data === 1) {
          this.setState({ loading: false });
          NotificationManager.error("Product not found");
        } else {
          let productData = result.data.product[0];

          this.isSubcribedProductByShopperr(productData._id);

          axios.get("/add/totalCategory").then((data) => {
            this.setState({ categoryList: data.data }, () => {
              this.state.categoryList.map((cat) => {
                if (cat.category === productData.category) {
                  this.setState({
                    category: cat.category,
                    margin: cat.margin ? cat.margin : "0",
                  });
                }
              });
            });
          });

          axios
            .get("/api/totalSupplier")
            .then((data) => {
              this.setState({ supplierList: data.data });
            })
            .catch((err) => {
              console.log(err);
              NotificationManager.error("Failed to get supplier list");
            });

          if (!_.isEmpty(productData.tags)) {
            axios.post(`/add/getTags`, productData.tags).then((tagsResult) => {
              this.setState({ tags: tagsResult.data.tagArray });
            });
          }

         
            let imgArr = [];
            for (let i = 0; i < productData.imgUrl.length; i++) {
              if (_.isEmpty(productData.imgUrl[i].varient_id)) {
                let obj = {
                  image: `${result.data.url}/${productData.imgUrl[i].src}`,
                  status: "old",
                  src: productData.imgUrl[i].src,
                };
                imgArr.push(obj);
              }
            }
            
            for (let i = 0; i < productData.imgFromUrl.length; i++) {
              if (_.isEmpty(productData.imgFromUrl[i].varient_id)) {
                let obj = {
                  image: productData.imgFromUrl[i].src,
                  status: "new_url",
                  src: productData.imgFromUrl[i].src,
                };
                imgArr.push(obj);
              }
            }
            console.log("imageArr",imgArr)
            this.setState({ imageArray: imgArr });
          

          if (_.isEmpty(productData.varientArray)) {
            this.setState({
              isVarien: false,
              idOrSku: productData.code,
              factoryPrice: productData?.price?.toString() || 0,
              mrp: productData?.mrp?.toString() || 0,
              quantity: productData?.quantity?.toString() || 0,
            });
          } else {
            let temp = [];
            if (!_.isEmpty(productData.varientArray)) {
              for (let i = 0; i < productData.varientArray.length; i++) {
                let imgArr = [];
                for (let j = 0; j < productData.imgUrl.length; j++) {
                  if (
                    productData.imgUrl[j].varient_id ===
                    productData.varientArray[i].varient_id
                  ) {
                    imgArr.push({
                      image: `${result.data.url}/${productData.imgUrl[j].src}`,
                      status: "old",
                      src: productData.imgUrl[j].src,
                    });
                  }
                }
               
                let obj = {
                  id: productData.varientArray[i].varient_id,
                  varient: productData.varientArray[i].varient,
                  price: _.isEmpty(
                    productData.varientArray[i]?.price?.toString()
                  )
                    ? "0"
                    : productData.varientArray[i]?.price?.toString(),
                  mrp: _.isEmpty(productData.varientArray[i]?.mrp?.toString())
                    ? "0"
                    : productData.varientArray[i]?.mrp?.toString(),
                  quantity: _.isEmpty(
                    productData.varientArray[i]?.quantity?.toString()
                  )
                    ? "0"
                    : productData.varientArray[i]?.quantity?.toString(),
                  sku: productData.varientArray[i].sku,
                  selliingPrice: 0,
                  quantity: _.isEmpty(
                    productData.varientArray[i]?.quantity?.toString()
                  )
                    ? "0"
                    : productData.varientArray[i]?.quantity?.toString(),
                  height: _.isEmpty(
                    productData.varientArray[i]?.height?.toString()
                  )
                    ? "0"
                    : productData.varientArray[i]?.height?.toString(),
                  width: _.isEmpty(
                    productData.varientArray[i]?.width?.toString()
                  )
                    ? "0"
                    : productData.varientArray[i]?.width?.toString(),
                  length: _.isEmpty(
                    productData.varientArray[i]?.length?.toString()
                  )
                    ? "0"
                    : productData.varientArray[i]?.length?.toString(),
                  weight: _.isEmpty(
                    productData.varientArray[i]?.weight?.toString()
                  )
                    ? "0"
                    : this.convertVarientToWeightMeasurementReverse(
                        productData.varientArray[i]?.weight?.toString(),
                        productData.varientArray[i]?.weightLabel
                      ),
                  weightLabel: _.isEmpty(
                    productData.varientArray[i]?.weightLabel
                  )
                    ? "g"
                    : productData.varientArray[i]?.weightLabel,
                  cbm: _.isEmpty(productData.varientArray[i]?.cbm?.toString())
                    ? "0"
                    : productData.varientArray[i]?.cbm?.toString(),
                  imagesArray: imgArr,
                };
                temp.push(obj);
              }
            }
            this.setState({
              isVarien: true,
              varientArray: productData.options,
              varientArrayCombo: temp,
            });
          }

          if (productData.mu === "g")
            this.setState({
              weight: productData?.weight?.toString(),
              weightLabel: productData.mu,
            });
          else if (productData.mu === "lb")
            this.convertToWeightMeasurementReverse(
              productData?.weight?.toString(),
              productData.mu
            );
          else if (productData.mu === "oz")
            this.convertToWeightMeasurementReverse(
              productData?.weight?.toString(),
              productData.mu
            );
          else if (productData.mu === "kg")
            this.convertToWeightMeasurementReverse(
              productData?.weight?.toString(),
              productData.mu
            );
          else
            this.setState({
              weight: productData?.weight?.toString(),
              weightLabel: this.state.weightLabel,
            });

          if (productData.shippingCharge.method === "freeShipping") {
            this.setState({ shippingMode: productData.shippingCharge.method });
          } else if (productData.shippingCharge.method === "standardShipping") {
            this.setState({
              shippingMode: productData.shippingCharge.method,
              ausShippingPrice: productData.shippingCharge.australia,
              canadaShippingPrice: productData.shippingCharge.canada,
              ukShippingPrice: productData.shippingCharge.unitedKingdom,
              usaShippingPrice: productData.shippingCharge.usa,
              interShippingPrice: productData.shippingCharge.international,
            });
          }

          this.setState({
            loaded: true,
            productTitle: _.has(productData, "name") ? productData.name : "",
            productSubTitle: _.has(productData, "subtitle")
              ? productData.subtitle
              : "",
            invoiceDescription: _.has(productData, "invoicedesc")
              ? productData.invoicedesc
              : "",
            detailDescription: _.has(productData, "description")
              ? productData.description
              : "",
            productTitleCharLength: _.has(productData, "name")
              ? productData.name.length
              : 0,
            productSubTitleCharLength: _.has(productData, "subtitle")
              ? productData.subtitle.length
              : 0,
            invoiceDescriptionCharLength: _.has(productData, "invoicedesc")
              ? productData.invoicedesc.length
              : 0,
            hsn: productData.hsn,
            warranty: productData.warranty,
            height: productData.height,
            width: productData.width,
            length: productData.length,
            pdStatus: productData.pd_status,
            isDisplay: productData.is_display,
            pid: productData._id,
            marketPlaceUrl: _.has(productData, "marketPlaceUrl")
              ? productData.marketPlaceUrl
              : "",
            bcd: _.has(productData, "bcd") ? productData.bcd : "",
            igst: _.has(productData, "igst") ? productData.igst : "",
            cbm: _.has(productData, "cbm") ? productData.cbm : "",
            swc: _.has(productData, "swcOnBcd") ? productData.swcOnBcd : "",
            loading: false,
            marketPlaceMrp: _.has(productData, "marketPlaceMrp")
              ? productData.marketPlaceMrp
              : "",
            marketPlacePrice: _.has(productData, "marketPlacePrice")
              ? productData.marketPlacePrice
              : "",
            subSupplierList: _.has(productData, "taggedSuppliers")
              ? productData.taggedSuppliers
              : [],
            slabOne: _.has(productData, "so") ? productData.so : 0,
            slabTwo: _.has(productData, "st") ? productData.st : 0,
            slabThree: _.has(productData, "stt") ? productData.stt : 0,
            slabFour: _.has(productData, "sf") ? productData.sf : 0,
            deliveryDaysFrom: _.has(productData, "deliveryDaysFrom")
              ? productData.deliveryDaysFrom
              : 0,
            deliveryDaysTo: _.has(productData, "deliveryDaysTo")
              ? productData.deliveryDaysTo
              : 0,
            numberOfItems: _.has(productData, "nfi") ? productData.nfi : 0,
            packageIncludes: _.has(productData, "pincl")
              ? productData.pincl
              : 0,
            yearOfManufacturer: _.has(productData, "yfmf")
              ? productData.yfmf
              : 0,
            productExpiry: _.has(productData, "pex") ? productData.pex : 0,
            isLiquid: _.has(productData, "iliu") ? productData.iliu : 0,
            isBattary: _.has(productData, "ibat") ? productData.ibat : 0,
            bulletpoints: _.has(productData, "bpt") ? productData.bpt : 0,
            browseNode: _.has(productData, "bno") ? productData.bno : 0,
            categoryId: _.has(productData, "catId") ? productData.catId : "",
            channel: _.has(productData, "channel") ? productData.channel : [],
            customfields: _.has(productData, "customfields")
              ? productData.customfields
              : "",
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        this.setState({ loading: false });
        NotificationManager.error("Something went wrong, Please try again");
      });
  }

  isSubcribedProductByShopperr(productId) {
    axios
      .get(`/web/subscribed-by-shopperr`, {
        params: { productId },
      })
      .then((result) => {
        // console.log(result.data.Response, "result.data.Response");
        if (result.data.Response.status == 200) {
          if (result.data.Response.data.su_status != 0)
            this.setState({ su_status: 1 });
          this.setState({ isSubscribed: 1 });
        }
      })
      .catch((err) => {
        // console.log(err, "asdf ");
      });
  }

  handleProductTitleInput = (e) => {
    e.persist();
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        productTitle: "",
      },
      productTitle: e.target.value,
      productTitleCharLength: e.target.value.length,
    }));
  };

  handleProductSubTitle = (e) => {
    e.persist();
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        productSubTitle: "",
      },
      productSubTitle: e.target.value,
      productSubTitleCharLength: e.target.value.length,
    }));
  };

  handleInvoiceDescription = (e) => {
    e.persist();
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        invoiceDescription: "",
      },
      invoiceDescription: e.target.value,
      invoiceDescriptionCharLength: e.target.value.length,
    }));
  };

  handleReactQuillInput = (e) => {
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        detailDescription: "",
      },
      detailDescription: e,
    }));
  };

  handleMarketPlaceUrl = (e) => {
    e.persist();
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        marketPlaceUrl: "",
      },
      marketPlaceUrl: e.target.value,
    }));
  };

  handleMarketPlacePrice = (e) => {
    e.persist();
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        marketPlacePrice: "",
      },
      marketPlacePrice: e.target.value,
    }));
  };

  handleMarketPlaceMrp = (e) => {
    e.persist();
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        marketPlaceMrp: "",
      },
      marketPlaceMrp: e.target.value,
    }));
  };

  uploadMultipleImages = (e) => {
    if(this.state.imgUrlValue !="")
    {
    this.setState({
      imageArray: this.state.imageArray.concat({
        image: this.state.imgUrlValue,
        status: "new_url",
        src: "",
      }),
    });
  }  
    this.setState({
      imgUrlValue: "",
    })
    const readImage = (file) => {
      if (imageExtensions.includes(file.type)) {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          this.setState({
            imageArray: this.state.imageArray.concat({
              image: reader.result,
              status: "new",
              src: "",
            }),
          });
        });
        reader.readAsDataURL(file);
      } else {
        NotificationManager.error(
          `File not supported, Supported files are jpeg, png, webp, bmp, gif.`
        );
      }
    };

    const files = e.target.files;
    if (!files || !files[0]) return 
    [...files].forEach(readImage);
  };

  handleDeleteImage = (index) => {
    this.setState({
      imageArray: this.state.imageArray.filter((img, i) => {
        return i !== index;
      }),
    });
  };

  handleIsVarien = (e) => {
    e.persist();
    if (e.target.checked)
      this.setState({
        isVarien: true,
        varientArray: [],
        varientArrayCombo: [],
        idOrSku: "",
        factoryPrice: "",
        mrp: "",
        quantity: "",
        errors: {},
      });
    else
      this.setState({
        isVarien: false,
        varientArray: [],
        varientArrayCombo: [],
        idOrSku: "",
        factoryPrice: "",
        mrp: "",
        quantity: "",
        errors: {},
      });
  };

  handleAddVarient(e) {
    let obj = {
      name: "",
      values: [],
    };

    this.setState(
      { varientArray: [...this.state.varientArray.concat(obj)], errors: {} },
      () => {
        let onlyVarientsArray = this.state.varientArray.map((varient) => {
          return varient.values;
        });
        !_.isEmpty(onlyVarientsArray) &&
          this.makeArraysCombo(onlyVarientsArray);
      }
    );
  }

  handleVarientOption(e, index) {
    this.setState({
      varientArray: [
        ...this.state.varientArray.slice(0, index),
        Object.assign({}, this.state.varientArray[index], {
          name: e.target.value,
        }),
        ...this.state.varientArray.slice(index + 1),
      ],
      errors: {},
    });
  }

  handleRemoveVarient(e, index) {
    e.preventDefault();
    this.setState(
      {
        varientArray: this.state.varientArray.filter((varient, i) => {
          return i !== index;
        }),
      },
      () => {
        let onlyVarientsArray = this.state.varientArray.map((varient) => {
          return varient.values;
        });
        !_.isEmpty(onlyVarientsArray)
          ? this.makeArraysCombo(onlyVarientsArray)
          : this.setState({ varientArrayCombo: [] });
      }
    );
  }

  handleVarientTag(tag, index) {
    this.setState(
      {
        varientArray: [
          ...this.state.varientArray.slice(0, index),
          Object.assign({}, this.state.varientArray[index], { values: tag }),
          ...this.state.varientArray.slice(index + 1),
        ],
      },
      () => {
        let onlyVarientsArray = this.state.varientArray.map((varient) => {
          return varient.values;
        });
        !_.isEmpty(onlyVarientsArray) &&
          this.makeArraysCombo(onlyVarientsArray);
      }
    );
  }

  makeArraysCombo = (args) => {
    args = args.filter((item) => {
      return !item || (item && item.length);
    });
    if (!args || (args && !args.length)) {
      return false;
    }
    var r = [],
      max = args.length - 1;
    function helper(arr, i) {
      for (var j = 0, l = args[i].length; j < l; j++) {
        var a = arr.slice(0); // clone arr
        a.push(args[i][j]);
        if (i === max) r.push(a);
        else helper(a, i + 1);
      }
    }
    helper([], 0);
    this.concatWithSlash(r);
  };

  concatWithSlash = (arrCombo) => {
    let tempArr = [];
    arrCombo.map((singleArray) => {
      let varient = singleArray.map((e) => e).join("/");
      let obj = {
        varient: varient.toString(),
        price: "0",
        mrp: "0",
        quantity: "0",
        sku: "",
        selliingPrice: 0,
        height: "0",
        width: "0",
        length: "0",
        weight: "0",
        weightLabel: "g",
        cbm: "0",
        imagesArray: [],
      };
      tempArr.push(obj);
    });
    this.setState({ varientArrayCombo: tempArr });
  };

  handleVarientMultipleImages(e, index) {
    let temp1 = [];
    const images = e.target.files;
    const pushImage = (file) => {
      if (imageExtensions.includes(file.type)) {
        const reader = new FileReader();
        reader.addEventListener("load", (e1) => {
          temp1.push({
            image: reader.result,
            status: "new",
            src: "",
          });
        });
        reader.readAsDataURL(file);
      } else {
        NotificationManager.error(
          `File not supported, Supported files are jpeg,png,webp,bmp,gif.`
        );
      }
    };
    [...images].forEach(pushImage);

    setTimeout(() => {
      let selectedImageArrayObj = this.state.varientArrayCombo.filter(
        (obj, i) => {
          return i === index;
        }
      );
      let previousImages = selectedImageArrayObj[0].imagesArray;

      this.setState({
        varientArrayCombo: [
          ...this.state.varientArrayCombo.slice(0, index),
          Object.assign({}, this.state.varientArrayCombo[index], {
            imagesArray: [...previousImages.concat(temp1)],
          }),
          ...this.state.varientArrayCombo.slice(index + 1),
        ],
      });
    }, 1500);
  }

  handleVarientImageDelete(objId, imageId) {
    let obj = this.state.varientArrayCombo.filter((obj, i) => {
      return i === objId;
    });
    let tempArr1 = obj[0].imagesArray.filter((img, i) => {
      return i !== imageId;
    });

    this.setState({
      varientArrayCombo: [
        ...this.state.varientArrayCombo.slice(0, objId),
        Object.assign({}, this.state.varientArrayCombo[objId], {
          imagesArray: tempArr1,
        }),
        ...this.state.varientArrayCombo.slice(objId + 1),
      ],
    });
  }

  handleVarientInput(e, index) {
    this.setState({
      varientArrayCombo: [
        ...this.state.varientArrayCombo.slice(0, index),
        Object.assign({}, this.state.varientArrayCombo[index], {
          [e.target.name]: e.target.value,
        }),
        ...this.state.varientArrayCombo.slice(index + 1),
      ],
      errors: {},
    });
  }

  handleIdOrSku = (e) => {
    e.persist();
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        idOrSku: "",
      },
      idOrSku: e.target.value,
    }));
  };

  handleFactoryPrice = (e) => {
    e.persist();
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        factoryPrice: "",
      },
      factoryPrice: e.target.value,
    }));
  };

  handleMrp = (e) => {
    e.persist();
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        mrp: "",
      },
      mrp: e.target.value,
    }));
  };

  handleQuantity = (e) => {
    e.persist();
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        quantity: "",
      },
      quantity: e.target.value,
    }));
  };

  handleCbm = (e) => {
    e.persist();
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        cbm: "",
      },
      cbm: e.target.value,
    }));
  };

  handleSelectedCategory = (e) => {
    e.persist();
    let margin = 0;
    let categoryId = "";
    this.state.categoryList.forEach((item) => {
      if (item.category === e.target.value) {
        categoryId = item._id;
        if (item.margin !== undefined || null) margin = item.margin;
      }
    });
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        category: "",
      },
      category: e.target.value,
      margin,
    }));
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
      },
      categoryId: categoryId,
    }));
  };

  handleHsn = (e) => {
    e.persist();
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        hsn: "",
      },
      hsn: e.target.value,
    }));
  };

  handleWeight = (e) => {
    e.persist();
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        weight: "",
      },
      weight: e.target.value,
    }));
  };

  handleWeightMeasure = (e) => {
    e.persist();
    this.setState({ weightLabel: e.target.value });
  };

  handleWarrenty = (e) => {
    e.persist();
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        warranty: "",
      },
      warranty: e.target.value,
    }));
  };

  handleHeight = (e) => {
    e.persist();
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        hwlError: "",
      },
      height: e.target.value,
    }));
  };

  handleWidth = (e) => {
    e.persist();
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        hwlError: "",
      },
      width: e.target.value,
    }));
  };

  handleLength = (e) => {
    e.persist();
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        hwlError: "",
      },
      length: e.target.value,
    }));
  };

  handleBcd = (e) => {
    e.persist();
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        bcd: "",
      },
      bcd: e.target.value,
    }));
  };

  handleIgst = (e) => {
    e.persist();
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        igst: "",
      },
      igst: e.target.value,
    }));
  };

  handleNumberOfItems = (e) => {
    e.persist();
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        numberOfItems: "",
      },
      numberOfItems: e.target.value,
    }));
  };

  handlePackageIncludes = (e) => {
    e.persist();
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        packageIncludes: "",
      },
      packageIncludes: e.target.value,
    }));
  };

  handleYearOfManufacturer = (e) => {
    e.persist();
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        yearOfManufacturer: "",
      },
      yearOfManufacturer: e.target.value,
    }));
  };

  handleProductExpiry = (e) => {
    e.persist();
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        productExpiry: "",
      },
      productExpiry: e.target.value,
    }));
  };

  handleIsLiquid = (e) => {
    e.persist();
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        isLiquid: "",
      },
      isLiquid: e.target.value,
    }));
  };

  handleIsBattery = (e) => {
    e.persist();
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        isBattery: "",
      },
      isBattery: e.target.value,
    }));
  };
  handlechannel = (e) => {
    let arr = this.state.channel;
    if (e.target.checked) {
      if (e.target.value === "b2c") {
        document.getElementById("dropshipper").checked = false;
        document.getElementById("dropshipper").disabled = true;
        document.getElementById("b2b").checked = false;
        document.getElementById("b2b").disabled = true;
        document.getElementById("distributor").checked = false;
        document.getElementById("distributor").disabled = true;
        this.setState({ channel: ["b2c"] });
      } else {
        arr.push(e.target.value);
        this.setState({ channel: arr });
      }
    } else {
      if (e.target.value == "b2c") {
        document.getElementById("dropshipper").disabled = false;
        document.getElementById("distributor").disabled = false;
        document.getElementById("b2b").disabled = false;
      }
      const index = arr.indexOf(e.target.value);
      if (index > -1) {
        arr.splice(index, 1);
        this.setState({ channel: arr });
      }
    }
    // console.log(e.target.value, "arr", arr, this.state.channel);
  };
  handleSwc = (e) => {
    e.persist();
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        swc: "",
      },
      swc: e.target.value,
    }));
  };

  handleShipping = (e) => {
    e.persist();
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        shippingMode: "",
      },
      shippingMode: e.target.value,
    }));
    if (e.target.value === "freeShipping") {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          usaShippingPrice: "",
          ukShippingPrice: "",
          ausShippingPrice: "",
          canadaShippingPrice: "",
          interShippingPrice: "",
        },
        usaShippingPrice: 0,
        ukShippingPrice: 0,
        ausShippingPrice: 0,
        canadaShippingPrice: 0,
        interShippingPrice: 0,
      }));
    }
  };

  handleUsaShippingPrice = (e) => {
    e.persist();
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        usaShippingPrice: "",
      },
      usaShippingPrice: e.target.value,
    }));
  };

  handleCanadaShippingPrice = (e) => {
    e.persist();
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        canadaShippingPrice: "",
      },
      canadaShippingPrice: e.target.value,
    }));
  };

  handleUkShippingPrice = (e) => {
    e.persist();
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        ukShippingPrice: "",
      },
      ukShippingPrice: e.target.value,
    }));
  };

  handleAusShippingPrice = (e) => {
    e.persist();
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        ausShippingPrice: "",
      },
      ausShippingPrice: e.target.value,
    }));
  };

  handleInterShippingPrice = (e) => {
    e.persist();
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        interShippingPrice: "",
      },
      interShippingPrice: e.target.value,
    }));
  };

  handlePdStatus = (e) => {
    e.persist();
    if (e.target.value === "active") {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          productStatus: "",
        },
        pdStatus: "1",
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          productStatus: "",
        },
        pdStatus: "0",
      }));
    }
  };

  handleShopifyStatus = (e) => {
    e.persist();
    if (e.target.value === "active") {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          productStatus: "",
        },
        spStatus: "1",
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          productStatus: "",
        },
        spStatus: "0",
      }));
    }
  };

  handleDisplayStatus = (e) => {
    e.persist();
    if (e.target.value === "active") {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          productStatus: "",
        },
        isDisplay: "1",
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          productStatus: "",
        },
        isDisplay: "0",
      }));
    }
  };

  handleAllInputs = (e) => {
    e.persist();
    this.setState({ [e.target.name]: e.target.value });
  };

  updateProduct = (e) => {
    e.preventDefault();
    let data = {
      productTitle: this.state.productTitle,
      productSubTitle: this.state.productSubTitle,
      invoiceDescription: this.state.invoiceDescription,
      detailDescription: this.state.detailDescription,
      marketPlaceUrl: this.state.marketPlaceUrl,
      marketPlacePrice: this.state.marketPlacePrice,
      marketPlaceMrp: this.state.marketPlaceMrp,
      isVarien: this.state.isVarien,
      idOrSku: this.state.idOrSku,
      factoryPrice: this.state.factoryPrice,
      mrp: this.state.mrp,
      quantity: this.state.quantity,
      cbm: this.state.cbm,
      category: this.state.category,
      margin: this.state.margin,
      hsn: this.state.hsn,
      weight: this.state.weight,
      weightLabel: this.state.weightLabel,
      warranty: this.state.warranty,
      height: this.state.height,
      width: this.state.width,
      length: this.state.length,
      bcd: this.state.bcd,
      igst: this.state.igst,
      swc: this.state.swc,
      shippingMode: this.state.shippingMode,
      usaShippingPrice: this.state.usaShippingPrice,
      canadaShippingPrice: this.state.canadaShippingPrice,
      ausShippingPrice: this.state.ausShippingPrice,
      ukShippingPrice: this.state.ukShippingPrice,
      interShippingPrice: this.state.interShippingPrice,
      tags: this.state.tags,
      imageArray: this.state.imageArray,
      varientArray: this.state.varientArray,
      varientArrayCombo: this.state.varientArrayCombo,
      pdStatus: this.state.pdStatus,
      spStatus: this.state.spStatus,
      isDisplay: this.state.isDisplay,
      _id: this.state.pid,
      taggedSuppliers: this.state.subSupplierList,
      slabOne: this.state.slabOne,
      slabTwo: this.state.slabTwo,
      slabThree: this.state.slabThree,
      slabFour: this.state.slabFour,
      deliveryDaysFrom: this.state.deliveryDaysFrom,
      deliveryDaysTo: this.state.deliveryDaysTo,
      yearOfManufacturer: this.state.yearOfManufacturer,
      productExpiry: this.state.productExpiry,
      numberOfItems: this.state.numberOfItems,
      packageIncludes: this.state.packageIncludes,
      isLiquid: this.state.isLiquid,
      isBattery: this.state.isBattery,
      categoryId: this.state.categoryId,
      browseNode: this.state.browseNode,
      channel: this.state.channel,
    };

    data.bulletpoints = $(".bullet-points-input")
      .map((index, el) => {
        return $(el).val();
      })
      .toArray();

    let customfieldsvalues = $(".custom-field-values-input")
      .map((index, el) => {
        return $(el).val();
      })
      .toArray();

    data.customfields = $(".custom-field-keys-input")
      .map((index, el) => {
        return { [$(el).val()]: customfieldsvalues[index] };
      })
      .toArray();

    let validation = this.validateAllInput(data);
    if (validation.isValid) {
      document.getElementById("updateProductBtn").disabled = true;
      this.setState({ loading: true });
      this.convertToWeightMeasurement(data, (data1) => {
        axios
          .post("/add/updateProduct", data1)
          .then((result) => {
            if (result.data === 1) {
              NotificationManager.success("Product Updated Successfully");
              this.setState({ redirectTo: true, loading: false });
            } else {
              NotificationManager.error(
                "Failed to Update product, Please check your entries and try again"
              );
              this.setState({ loading: false });
              document.getElementById("updateProductBtn").disabled = false;
            }
          })
          .catch((err) => {
            // console.log(err);
            this.setState({ loading: false });
            NotificationManager.error(
              "Something went wrong, please try again later."
            );
            document.getElementById("updateProductBtn").disabled = false;
          });
      });
    } else {
      // console.log(validation.errors);
      this.setState({ errors: validation.errors });
    }
  };

  updateOnShopifyBtn = (e) => {
    e.preventDefault();
    e.target.disabled = true;
    axios
      .post("/web/updateVariantOnShopify", { productId: this.state.pid })
      .then((result) => {
        if (!result.data.Response.error) {
          NotificationManager.success(result.data.Response.msg);
        } else {
          NotificationManager.error(result.data.Response.msg);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  updateOnShopperrBtn = (e) => {
    e.preventDefault();
    e.target.disabled = true;
    axios
      .post("/web/updateVariantOnShopify", {
        productId: this.state.pid,
        onShopper: 1,
      })
      .then((result) => {
        if (!result.data.Response.error) {
          NotificationManager.success(result.data.Response.msg);
        } else {
          NotificationManager.error(result.data.Response.msg);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  uploadOnShopperrBtn = (e) => {
    e.preventDefault();
    e.target.disabled = true;
    axios
      .post("/web/upload-on-shopperr", { productId: this.state.pid })
      .then((result) => {
        if (!result.data.Response.error) {
          NotificationManager.success(result.data.Response.msg);
        } else {
          NotificationManager.error(result.data.Response.msg);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  convertToWeightMeasurement(data, callback) {
    if (data.weightLabel === "kg") {
      data.weight = parseFloat(data.weight) * 1000;
    } else if (data.weightLabel === "lb") {
      data.weight = parseFloat(data.weight) * 453.59237;
    } else if (data.weightLabel === "oz") {
      data.weight = parseFloat(data.weight) / 0.035274;
    }
    if (data.varientArrayCombo > 0) {
      data.varientArrayCombo.forEach((element) => {
        if (element.weightLabel === "g") {
          element.weight = parseFloat(element.weight);
        } else if (element.weightLabel === "kg") {
          element.weight = parseFloat(element.weight) * 1000;
        } else if (element.weightLabel === "lb") {
          element.weight = parseFloat(element.weight) * 453.59237;
        } else if (element.weightLabel === "oz") {
          element.weight = parseFloat(element.weight) / 0.035274;
        }
      });
    }
    callback(data);
  }
  convertVarientToWeightMeasurementReverse(weightInGrams, label) {
    // console.log("weightInGrams, label", weightInGrams, label);
    if (label === "g") return parseFloat(weightInGrams).toFixed(2).toString();
    if (label === "lb") {
      return (parseFloat(weightInGrams) / 453.59237).toFixed(2).toString();
    } else if (label === "oz") {
      return (parseFloat(weightInGrams) * 0.035274).toFixed(2).toString();
    } else if (label === "kg") {
      return (parseFloat(weightInGrams) / 1000).toFixed(2).toString();
    }
  }
  convertToWeightMeasurementReverse(weightInGrams, label) {
    if (label === "lb") {
      this.setState({
        weight: (parseFloat(weightInGrams) / 453.59237).toFixed(2).toString(),
        weightLabel: label,
      });
    } else if (label === "oz") {
      this.setState({
        weight: (parseFloat(weightInGrams) * 0.035274).toFixed(2).toString(),
        weightLabel: label,
      });
    } else if (label === "kg") {
      this.setState({
        weight: (parseFloat(weightInGrams) / 1000).toFixed(2).toString(),
        weightLabel: label,
      });
    }
  }

  groupBy = (array, key) => {
    // Return the end result
    return array.reduce((result, currentValue) => {
      // If an array already present for key, push it to the array. Else create an array and push the object
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
      return result;
    }, {}); // empty object is the initial value for result object
  };

  validateAllInput = (data) => {
    let errors = {};

    if (_.isEmpty(data.productTitle)) {
      errors.productTitle = "Product Title should not be empty";
    }

    if (this.state.loggedInUser == "admin") {
      if (_.isEmpty(data.invoiceDescription)) {
        errors.invoiceDescription = "Invoice Description should not be empty";
      }

      if (!_.isEmpty(data.marketPlaceUrl)) {
        if (!validator.isURL(data.marketPlaceUrl))
          errors.marketPlaceUrl = "Invalid Market Place URL";
      }

      if (!_.isEmpty(data.marketPlaceMrp)) {
        if (parseFloat(data.marketPlaceMrp) <= 0) {
          errors.marketPlaceMrp = "Invalid Market Place Mrp";
        }
      }

      if (!_.isEmpty(data.marketPlacePrice)) {
        if (parseFloat(data.marketPlacePrice) <= 0) {
          errors.marketPlacePrice = "Invalid Market Place Price";
        }
      }
    }

    let variants = this.groupBy(data.varientArray, "name");

    if (Math.max(...Object.values(variants).map((item) => item.length)) > 1) {
      errors.varientArray = "Name of Option is not unique";
    }

    if (!_.isEmpty(data.slabOne) && data.slabOne < 0) {
      errors.slabError = "Invalid Slab Price";
    } else if (!_.isEmpty(data.slabTwo) && data.slabTwo < 0)
      errors.slabError = "Invalid Slab Price";
    else if (!_.isEmpty(data.slabThree) && data.slabThree < 0)
      errors.slabError = "Invalid Slab Price";
    else if (!_.isEmpty(data.slabFour) && data.slabFour < 0)
      errors.slabError = "Invalid Slab Price";

    if (!_.isEmpty(data.deliveryDaysFrom) && data.deliveryDaysFrom < 0)
      errors.deliveryTimeError = "Invalid Number";
    else if (!_.isEmpty(data.deliveryDaysTo) && data.deliveryDaysTo < 0)
      errors.deliveryTimeError = "Invalid Number";
    else if (data.deliveryDaysFrom > data.deliveryDaysTo)
      errors.deliveryTimeError = "From days should be less than To days";

    if (!data.isVarien) {
      if (_.isEmpty(data.factoryPrice)) {
        errors.factoryPrice = "Factory price should not be empty";
      } else if (parseFloat(data.factoryPrice) <= 0) {
        errors.factoryPrice = "Factory price should be greater than 0";
      } else if (isNaN(data.factoryPrice)) {
        errors.factoryPrice = "Factory price should be a number";
      }
    }

    if (this.state.loggedInUser == "admin") {
      if (!_.isEmpty(data.mrp) && !data.isVarien) {
        if (parseFloat(data.mrp) < 0) {
          errors.mrp = "MRP should be greater than 0";
        } else if (isNaN(data.mrp)) {
          errors.mrp = "MRP should be a number";
        }
      }
    }

    if (!data.isVarien) {
      if (_.isEmpty(data.quantity))
        errors.quantity = "Quantity should not be empty";
      else if (data.quantity % 1 !== 0)
        errors.quantity = "Quantity should be an integer";
      else if (data.quantity < 0)
        errors.quantity = "Quantity should be greater than 0";

      if (_.isEmpty(data.weight)) errors.weight = "Weight should not be empty";
      else if (data.weight < 0) errors.weight = "Invalid weight";
      else if (isNaN(data.weight)) errors.weight = "weight should be a number";

      if (!_.isEmpty(data.height) && data.height < 0)
        errors.hwlError = "Height value should be greater than 0";
      else if (!_.isEmpty(data.width) && data.width < 0)
        errors.hwlError = "Width value should be greater than 0";
      else if (!_.isEmpty(data.length) && data.length < 0)
        errors.hwlError = "Length value should be greater than 0";
    }

    if (data.isVarien && _.isEmpty(data.varientArray)) {
      errors.emptyVarient = "Variant Product should not be empty";
    }

    if (data.isVarien) {
      data.varientArrayCombo.map((varientObj) => {
        if (_.isEmpty(varientObj.price))
          errors.emptyVarient = "Price should not be empty";
        else if (varientObj.price < 0)
          errors.emptyVarient = "Price should be greater than 0";

        if (_.isEmpty(varientObj.quantity))
          errors.emptyVarient = "Quantity should not be empty";
        else if (varientObj.quantity < 0)
          errors.emptyVarient = "Quantity should be greater than 0";
        else if (varientObj.quantity % 1 !== 0)
          errors.emptyVarient = "Quantity should be an integer";

        if (_.isEmpty(varientObj.weight)) {
          errors.emptyVarient = "Weight should not be empty";
        } else if (varientObj.weight < 0) {
          errors.emptyVarient = "Invalid weight";
        } else if (isNaN(varientObj.weight)) {
          errors.emptyVarient = "weight should be a number";
        }

        if (!_.isEmpty(varientObj.height) && varientObj.height < 0) {
          errors.emptyVarient = "Height value should be greater than 0";
        } else if (!_.isEmpty(varientObj.width) && varientObj.width < 0) {
          errors.emptyVarient = "Width value should be greater than 0";
        } else if (!_.isEmpty(varientObj.length) && varientObj.length < 0) {
          errors.emptyVarient = "Length value should be greater than 0";
        }

        if (
          !_.isEmpty(varientObj.mrp) &&
          varientObj.mrp < 0 &&
          this.state.loggedInUser == "admin"
        )
          errors.emptyVarient = "Mrp should be greater than 0";
      });

      data.varientArray.map((varientObj) => {
        if (varientObj.name === "" || _.isEmpty(varientObj.values))
          errors.emptyVarient = "Please fill all the variant records";
      });
    }

    if (data.category === "Select Category")
      errors.category = "Please select any one category";

    if (this.state.loggedInUser == "admin") {
      if (_.isEmpty(data.hsn)) errors.hsn = "HSN should not be empty";
    }

    if (this.state.loggedInUser == "admin") {
      if (!_.isEmpty(data.bcd) && parseInt(data.bcd) <= 0)
        errors.bcd = "BCD value should be greater than 0";

      if (!_.isEmpty(data.igst) && parseInt(data.igst) <= 0)
        errors.igst = "IGST value should be greater than 0";

      if (!_.isEmpty(data.swc) && parseInt(data.swc) <= 0)
        errors.swc = "SWC value should be greater than 0";
    }

    if (_.isEmpty(data.shippingMode)) {
      errors.shippingMode = "Please choose any one option";
    } else {
      if (data.shippingMode === "standardShipping") {
        if (_.isEmpty(data.usaShippingPrice)) {
          errors.usaShippingPrice = "should not be empty";
        } else if (parseInt(data.usaShippingPrice) < 0) {
          errors.usaShippingPrice = "should be greater than 0";
        }

        if (_.isEmpty(data.ukShippingPrice)) {
          errors.ukShippingPrice = "should not be empty";
        } else if (parseInt(data.ukShippingPrice) < 0) {
          errors.ukShippingPrice = "should be greater than 0";
        }

        if (_.isEmpty(data.canadaShippingPrice)) {
          errors.canadaShippingPrice = "should not be empty";
        } else if (parseInt(data.canadaShippingPrice) < 0) {
          errors.canadaShippingPrice = "should be greater than 0";
        }

        if (_.isEmpty(data.ausShippingPrice)) {
          errors.ausShippingPrice = "should not be empty";
        } else if (parseInt(data.ausShippingPrice) < 0) {
          errors.ausShippingPrice = "should be greater than 0";
        }

        if (_.isEmpty(data.interShippingPrice)) {
          errors.interShippingPrice = "should not be empty";
        } else if (parseInt(data.interShippingPrice) < 0) {
          errors.interShippingPrice = "should be greater than 0";
        }
      }
    }

    if (this.state.loggedInUser == "admin") {
      if (_.isEmpty(data.pdStatus) || _.isEmpty(data.isDisplay))
        errors.productStatus = "Please choose any one option";
    }

    return { errors, isValid: _.isEmpty(errors) };
  };

  handleSubSupplier = (e) => {
    e.preventDefault();
    this.validateSubSupplierObjectAndAdd();
  };

  validateSubSupplierObjectAndAdd() {
    let errors = {};
    if (
      _.isEmpty(this.state.selectedSupplier) ||
      this.state.selectedSupplier === "Select Supplier"
    ) {
      errors.subSupplierError = "Please select any one supplier";
      this.setState({ errors });
    } else if (_.isEmpty(this.state.priority)) {
      errors.subSupplierError = "Please provide priority";
      this.setState({ errors });
    } else if (
      parseInt(this.state.priority) <= 0 ||
      parseInt(this.state.priority) > 100
    ) {
      errors.subSupplierError =
        "Priority should be greater than 0 and less than 100";
      this.setState({ errors });
    } else {
      this.setState({ errors: {} });
      this.findRedundentId((redundentId) => {
        if (_.isEmpty(redundentId)) {
          this.checkPriorityExists((priorityResponse) => {
            if (_.isEmpty(priorityResponse)) {
              !this.state.editMode
                ? this.getSupplierName((supplierNameResponse) => {
                    this.setState({
                      subSupplierList: this.state.subSupplierList.concat({
                        priority: this.state.priority,
                        sid: this.state.selectedSupplierId,
                        supplierName: supplierNameResponse,
                      }),
                      selectedSupplier: "Select Supplier",
                      priority: "",
                      editMode: false,
                    });
                  })
                : this.getSupplierName((supplierNameResponse) => {
                    this.setState({
                      subSupplierList: [
                        ...this.state.subSupplierList.slice(
                          0,
                          parseInt(this.state.selectedSupplierIndex)
                        ),
                        Object.assign(
                          {},
                          this.state.subSupplierList[
                            parseInt(this.state.selectedSupplierIndex)
                          ],
                          {
                            sid: this.state.selectedSupplierId,
                            supplierName: supplierNameResponse,
                            priority: this.state.priority,
                          }
                        ),
                        ...this.state.subSupplierList.slice(
                          parseInt(this.state.selectedSupplierIndex) + 1
                        ),
                      ],
                      selectedSupplier: "Select Supplier",
                      priority: "",
                      editMode: false,
                    });
                  });

              document.getElementById("subSupplier").selectedIndex = 0;
            } else {
              errors.subSupplierError = "Priority has already been assigned";
              this.setState({ errors });
            }
          });
        } else {
          errors.subSupplierError =
            "Supplier has already been added to the list";
          this.setState({ errors });
        }
      });
    }
  }

  getSupplierName(callback) {
    if (this.state.selectedSupplierId === "Select Supplier") callback("");
    else {
      const supplierName = this.state.supplierList.filter((supplier) => {
        return this.state.selectedSupplierId === supplier._id;
      });
      callback(supplierName[0].supplier_id);
    }
  }

  findRedundentId(callback) {
    const redundentId = this.state.subSupplierList.filter((supplier) => {
      return this.state.selectedSupplierId === supplier.sid;
    });
    if (!this.state.editMode || redundentId.length > 1) callback(redundentId);
    else callback([]);
  }

  checkPriorityExists(callback) {
    const priority = this.state.subSupplierList.filter((supplier) => {
      return this.state.priority === supplier.priority;
    });
    if (!this.state.editMode || priority.length > 1) callback(priority);
    else callback([]);
  }

  handleSubSupplierDelete(id) {
    this.setState({
      subSupplierList: this.state.subSupplierList.filter((supplier) => {
        return supplier.sid !== id;
      }),
    });
  }

  handleSubSupplierEdit(id, index) {
    this.getSelectedSupplierData(id, (response) => {
      this.setState({
        editMode: true,
        selectedSupplier: response[0].supplierName,
        priority: response[0].priority,
        selectedSupplierId: id,
        selectedSupplierIndex: index,
      });
    });
  }

  getSelectedSupplierData(id, callback) {
    const selectedSupplier = this.state.subSupplierList.filter((supplier) => {
      return supplier.sid === id;
    });
    callback(selectedSupplier);
  }

  handleOnchangeSubSupplier = (e) => {
    e.preventDefault();
    this.setState({ selectedSupplierId: e.target.value }, () => {
      this.getSupplierName((response) => {
        this.setState({ selectedSupplier: response, errors: {} });
      });
    });
  };

  changeForm = (e) => {
    e.preventDefault();
    let id = e.target.getAttribute("id");
    if (id == "basicFormButton") {
      document.getElementById("basicForm").style.display = "block";
      document.getElementById("amazonTemplateForm").style.display = "none";
    } else {
      document.getElementById("basicForm").style.display = "none";
      document.getElementById("amazonTemplateForm").style.display = "block";
    }
  };

  addBulletField = (ele) => {
    $(".add-new-bullet")
      .off()
      .on("click", function () {
        let html = $(".bullet-container .row:first").clone();
        html
          .find(".button")
          .html(
            '<a href="javascript:;" class="remove-bullet"><i class="fa fa-close" style="font-size:36px"></i></a>'
          );
        $(".bullet-container").append(html);
        $(document)
          .off()
          .on("click", ".remove-bullet", function () {
            $(this).closest(".row").remove();
          });
      });
  };

  addCustomFields = (ele) => {
    $(".add-new-field")
      .off()
      .on("click", function () {
        let html = $(".custom-field-container .row:first").clone();
        html.find(".custom-field").remove();
        html
          .find(".button")
          .html(
            '<a href="javascript:;" class="remove-custom-field"><i class="fa fa-close" style="font-size:36px"></i></a>'
          );
        $(".custom-field-container").append(html);
      });
    $(document)
      .off()
      .on("click", ".remove-custom-field", function () {
        $(this).closest(".row").remove();
      });
  };
  render() {
    const Editor = {
      modules: {
        toolbar: [
          [{ header: [1, 2, false] }],
          ["bold"],
          ["italic"],
          ["underline"],
          ["strike"],
          ["blockquote"],
          [{ list: "ordered" }],
          [{ list: "bullet" }],
          [{ indent: "+1" }],
          [{ indent: "-1" }],
          ["link"],
          ["video"],
          ["image"],
        ],
      },
      formats: [
        "header",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video",
      ],
    };
    if (this.state.redirectTo) {
      return localStorage.getItem("from") === "admin" ? (
        <Redirect to="/admin/product" />
      ) : (
        <Redirect to="/supplier/products" />
      );
    } else {
      return (
        <div>
          <SyncLoader
            css={override}
            size={15}
            margin={6}
            color={"#3E3E3E"}
            loading={this.state.loading}
          />
          <br></br>
          <div className={this.state.loading ? "parentDisable" : ""}>
            {this.state.loaded ? (
              <div>
                {localStorage.getItem("from") === "admin" ? (
                  <div
                    className="card-button"
                    style={{
                      width: "20%",
                      marginLeft: "50%",
                      marginBottom: "1%",
                      display: "inline-flex",
                    }}
                  >
                    {this.state.isSubscribed ? (
                      this.state.su_status == 0 ? (
                        <CustomButton
                          round
                          fill
                          type="button"
                          id="uploadOnShopperrBtn"
                          onClick={this.uploadOnShopperrBtn}
                        >
                          Upload on Shopperr.in
                        </CustomButton>
                      ) : (
                        <CustomButton
                          round
                          fill
                          type="button"
                          id="updateOnShopperrBtn"
                          onClick={this.updateOnShopperrBtn}
                        >
                          Update on Shopperr.in
                        </CustomButton>
                      )
                    ) : (
                      ""
                    )}
                    <CustomButton
                      round
                      fill
                      type="button"
                      id="updateOnShopifyBtn"
                      onClick={this.updateOnShopifyBtn}
                    >
                      Update on all Store
                    </CustomButton>
                  </div>
                ) : (
                  ""
                )}

                <div className="container-fluid">
                  <ul className="nav nav-tabs" role="tablist">
                    <li role="presentation" className="active">
                      <a
                        href="#basicForm"
                        aria-controls="home"
                        role="tab"
                        data-toggle="tab"
                        style={{ marginLeft: "20px" }}
                        id="basicFormButton"
                        onClick={this.changeForm}
                      >
                        BASIC FORM
                      </a>
                    </li>
                    <li role="presentation">
                      <a
                        href="#amazonForm"
                        aria-controls="profile"
                        role="tab"
                        data-toggle="tab"
                        id="amazonTemplateFormButton"
                        onClick={this.changeForm}
                      >
                        AMAZON TEMPLATE FORM
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="container-fluid">
                  <div id="basicForm">
                    <div
                      className="card"
                      style={{ padding: "3em 3em 0", marginBottom: "0" }}
                    >
                      <div className="form-group">
                        <label style={{ fontSize: 15 }} htmlFor="productTitle">
                          <span className="text-danger">* </span> Title&nbsp;
                          <span
                            className="custom-tooltip"
                            data-tooltip="Convey what we are selling "
                          >
                            <i
                              className="fa fa-question-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </label>
                        <small
                          style={{ float: "right" }}
                        >{`${this.state.productTitleCharLength} / ${this.state.productTitleCharMaxLength}`}</small>
                        <input
                          type="text"
                          value={this.state.productTitle}
                          onChange={this.handleProductTitleInput.bind(this)}
                          className="form-control"
                          placeholder="Enter Title of Product"
                          id="productTitle"
                          maxLength={this.state.productTitleCharMaxLength}
                          autoComplete="off"
                        />
                        {this.state.errors.productTitle && (
                          <span className="text-danger">
                            <i
                              className="fa fa-exclamation-circle"
                              aria-hidden="true"
                            ></i>{" "}
                            {this.state.errors.productTitle}
                          </span>
                        )}
                      </div>
                      <br></br>
                      <div className="form-group">
                        <label
                          style={{ fontSize: 15 }}
                          htmlFor="productSubTitle"
                        >
                          Sub Title{" "}
                          <span className="text-muted"> (Optional)</span>&nbsp;
                          <span
                            className="custom-tooltip"
                            data-tooltip="Short text displayed on the product list"
                          >
                            <i
                              className="fa fa-question-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </label>
                        <small
                          style={{ float: "right" }}
                        >{`${this.state.productSubTitleCharLength} / ${this.state.productSubTitleCharMaxLength}`}</small>
                        <input
                          type="text"
                          value={this.state.productSubTitle}
                          onChange={this.handleProductSubTitle.bind(this)}
                          className="form-control"
                          placeholder="Enter Sub Title of Product"
                          id="productSubTitle"
                          maxLength={this.state.productSubTitleCharMaxLength}
                          autoComplete="off"
                        />
                      </div>
                      <br></br>
                      {this.state.loggedInUser == "admin" && (
                        <div>
                          <div className="form-group">
                            <label
                              style={{ fontSize: 15 }}
                              htmlFor="invoiceDescription"
                            >
                              <span className="text-danger">* </span> Invoice
                              Description&nbsp;
                              <span
                                className="custom-tooltip"
                                data-tooltip="Keep track of quantity and payment of the product"
                              >
                                <i
                                  className="fa fa-question-circle"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </label>
                            <small
                              style={{ float: "right" }}
                            >{`${this.state.invoiceDescriptionCharLength} / ${this.state.invoiceDescriptionCharMaxLength}`}</small>
                            <input
                              type="text"
                              value={this.state.invoiceDescription}
                              onChange={this.handleInvoiceDescription.bind(
                                this
                              )}
                              className="form-control"
                              placeholder="Enter Invoice Description"
                              id="invoiceDescription"
                              maxLength={
                                this.state.invoiceDescriptionCharMaxLength
                              }
                              autoComplete="off"
                            />
                            {this.state.errors.invoiceDescription && (
                              <span className="text-danger">
                                <i
                                  className="fa fa-exclamation-circle"
                                  aria-hidden="true"
                                ></i>{" "}
                                {this.state.errors.invoiceDescription}
                              </span>
                            )}
                          </div>
                          <br></br>
                        </div>
                      )}
                      <div className="form-group">
                        <label
                          style={{ fontSize: 15 }}
                          htmlFor="detailDescription"
                        >
                          Detail Description&nbsp;
                          <span
                            className="custom-tooltip"
                            data-tooltip="Detailed description of the product "
                          >
                            <i
                              className="fa fa-question-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </label>
                        <ReactQuill
                          theme={"snow"}
                          onChange={this.handleReactQuillInput.bind(this)}
                          value={this.state.detailDescription}
                          modules={Editor.modules}
                          formats={Editor.formats}
                          placeholder={"Write something..."}
                        />
                        <br />
                      </div>
                    </div>

                    <div
                      className="card"
                      style={{ padding: "3em 3em 0", marginBottom: "0" }}
                    >
                      {this.state.loggedInUser == "admin" && (
                        <div>
                          <div className="container-fluid">
                            <div className="row">
                              <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                <div className="form-group">
                                  <label style={{ fontSize: 15 }}>
                                    Market Place URL &nbsp;
                                    <span
                                      className="custom-tooltip"
                                      data-tooltip="hosts products of individual sellers on the same domain"
                                    >
                                      <i
                                        className="fa fa-question-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </label>
                                  <br></br>
                                  <input
                                    type="text"
                                    placeholder="Market Place URL"
                                    className="form-control"
                                    value={this.state.marketPlaceUrl}
                                    onChange={this.handleMarketPlaceUrl.bind(
                                      this
                                    )}
                                  />
                                  {this.state.errors.marketPlaceUrl && (
                                    <span className="text-danger">
                                      <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {this.state.errors.marketPlaceUrl}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                <div className="form-group">
                                  <label style={{ fontSize: 15 }}>
                                    Market Place Price &nbsp;
                                    <span
                                      className="custom-tooltip"
                                      data-tooltip="Market Place Price"
                                    >
                                      <i
                                        className="fa fa-question-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </label>
                                  <br></br>
                                  <input
                                    type="number"
                                    placeholder="Market Place Price"
                                    className="form-control"
                                    value={this.state.marketPlacePrice}
                                    onChange={this.handleMarketPlacePrice.bind(
                                      this
                                    )}
                                  />
                                  {this.state.errors.marketPlacePrice && (
                                    <span className="text-danger">
                                      <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {this.state.errors.marketPlacePrice}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                <div className="form-group">
                                  <label style={{ fontSize: 15 }}>
                                    Market Place MRP &nbsp;
                                    <span
                                      className="custom-tooltip"
                                      data-tooltip="Market Place MRP"
                                    >
                                      <i
                                        className="fa fa-question-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </label>
                                  <br></br>
                                  <input
                                    type="number"
                                    placeholder="Market Place Mrp"
                                    className="form-control"
                                    value={this.state.marketPlaceMrp}
                                    onChange={this.handleMarketPlaceMrp.bind(
                                      this
                                    )}
                                  />
                                  {this.state.errors.marketPlaceMrp && (
                                    <span className="text-danger">
                                      <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {this.state.errors.marketPlaceMrp}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <br></br>
                          <div className="form-group">
                            <label style={{ fontSize: 15 }}>
                              Tags&nbsp;
                              <span
                                className="custom-tooltip"
                                data-tooltip="Descriptors assigned to specific products to organize and track their progress"
                              >
                                <i
                                  className="fa fa-question-circle"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </label>
                            <TagsInput
                              value={this.state.tags}
                              onChange={(tags) => this.setState({ tags })}
                              onlyUnique
                              addKeys={[9, 13, 32, 188]}
                            />
                          </div>
                        </div>
                      )}
                      <div className="container-fluid">
                        <label htmlFor="productImage" style={{ fontSize: 15 }}>
                          Slabs &nbsp;
                          <span
                            className="custom-tooltip"
                            data-tooltip="Price Range between slabs"
                          >
                            <i
                              className="fa fa-question-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </label>
                        <br></br>
                        {this.state.errors.slabError && (
                          <span className="text-danger">
                            <i
                              className="fa fa-exclamation-circle"
                              aria-hidden="true"
                            ></i>{" "}
                            {this.state.errors.slabError}
                          </span>
                        )}
                        <div className="row">
                          <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <div className="form-group">
                              <label>1 - 5 pieces</label>
                              <input
                                type="number"
                                value={this.state.slabOne}
                                placeholder="Slab One Price"
                                className="form-control"
                                min="0"
                                onChange={(e) =>
                                  this.setState({ slabOne: e.target.value })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <div className="form-group">
                              <label>6 - 20 pieces</label>
                              <input
                                type="number"
                                value={this.state.slabTwo}
                                placeholder="Slab One Price"
                                className="form-control"
                                min="0"
                                onChange={(e) =>
                                  this.setState({ slabTwo: e.target.value })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <div className="form-group">
                              <label>21 - 100 pieces</label>
                              <input
                                type="number"
                                value={this.state.slabThree}
                                placeholder="Slab One Price"
                                className="form-control"
                                min="0"
                                onChange={(e) =>
                                  this.setState({ slabThree: e.target.value })
                                }
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <div className="form-group">
                              <label>100 and Above</label>
                              <input
                                type="number"
                                value={this.state.slabFour}
                                placeholder="Slab One Price"
                                className="form-control"
                                min="0"
                                onChange={(e) =>
                                  this.setState({ slabFour: e.target.value })
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="container-fluid">
                        {this.state.errors.deliveryTimeError && (
                          <span className="text-danger">
                            <i
                              className="fa fa-exclamation-circle"
                              aria-hidden="true"
                            ></i>{" "}
                            {this.state.errors.deliveryTimeError}
                          </span>
                        )}
                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <label
                              htmlFor="productImage"
                              style={{ fontSize: 15 }}
                            >
                              Delivery Time From (In Days) &nbsp;
                              <span
                                className="custom-tooltip"
                                data-tooltip="Delivery Time From "
                              >
                                <i
                                  className="fa fa-question-circle"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </label>
                            <br></br>
                            <input
                              type="number"
                              placeholder="Delivery Time From"
                              value={this.state.deliveryDaysFrom}
                              onChange={(e) =>
                                this.setState({
                                  deliveryDaysFrom: e.target.value,
                                })
                              }
                              className="form-control"
                              min="0"
                            />
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <label
                              htmlFor="productImage"
                              style={{ fontSize: 15 }}
                            >
                              Delivery Time To (In Days) &nbsp;
                              <span
                                className="custom-tooltip"
                                data-tooltip="Delivery Time To"
                              >
                                <i
                                  className="fa fa-question-circle"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </label>
                            <br></br>
                            <input
                              type="number"
                              placeholder="Delivery Time To"
                              value={this.state.deliveryDaysTo}
                              onChange={(e) =>
                                this.setState({
                                  deliveryDaysTo: e.target.value,
                                })
                              }
                              className="form-control"
                              min="0"
                            />
                          </div>
                        </div>
                      </div>
                      <br></br>
                      <div className="form-group">
                        <label htmlFor="productImage" style={{ fontSize: 15 }}>
                          Image upload&nbsp;
                          <span
                            className="custom-tooltip"
                            data-tooltip="Clear and informative images, which allow clear recognition of the product"
                          >
                            <i
                              className="fa fa-question-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </label>
                        <br></br>
                        <label className="btn btn_new">
                          Choose Images
                          <input
                            type="file"
                            id="productImage"
                            className="hidden"
                            onChange={this.uploadMultipleImages}
                            multiple
                            accept="image/*"
                          />
                        </label>
                        <label className="">
                        Image Url
                        <input
                          type="text"
                          // id="productImage"
                          onKeyPress={this.uploadMultipleImages}
                         style={{color:"black"}}
                          onChange={(e) =>
                            this.setState({
                              imgUrlValue: e.target.value,
                            })
                          }
                          value={this.state.imgUrlValue}
                        />
                      </label>
                      </div>
                      <br></br>
                      <div className="container-fluid">
                        <div className="row">
                          <div className="form-group multi-preview">
                            {this.state.imageArray.map((obj, i) => {
                              const { image } = obj;
                              return (
                                <div className="col-md-3 show-image1" key={i}>
                                  <span
                                    className="closeButton1"
                                    onClick={() => this.handleDeleteImage(i)}
                                  >
                                    <i
                                      className="fa fa-times"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <div className="img-overlay1"></div>
                                  <img
                                    src={image}
                                    alt="uploadImage"
                                    className="images"
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="card"
                      style={{ padding: "3em 3em 0", marginBottom: "0" }}
                    >
                      {this.state.errors.varientArray && (
                        <span className="text-danger">
                          <i
                            className="fa fa-exclamation-circle"
                            aria-hidden="true"
                          ></i>{" "}
                          {this.state.errors.varientArray}
                        </span>
                      )}
                      <div className="form-group">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={this.state.isVarien}
                            checked={this.state.isVarien}
                            onChange={this.handleIsVarien.bind(this)}
                            id="defaultCheck1"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="defaultCheck1"
                          >
                            &nbsp;&nbsp;This product has multiple options, like
                            different sizes or colors
                          </label>
                        </div>
                      </div>

                      {this.state.errors.emptyVarient && (
                        <span className="text-danger">
                          <i
                            className="fa fa-exclamation-circle"
                            aria-hidden="true"
                          ></i>{" "}
                          {this.state.errors.emptyVarient}
                        </span>
                      )}

                      {this.state.isVarien && (
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-md-4">
                              {this.state.varientArray.map((varient, i) => {
                                const { name, values } = varient;
                                return (
                                  <div key={i} className="form-group">
                                    <input
                                      value={name}
                                      type="text"
                                      list="variantOptions"
                                      onChange={(e) =>
                                        this.handleVarientOption(e, i)
                                      }
                                      placeholder="Select an option"
                                      className="mb-3"
                                    />
                                    <h5
                                      onClick={(e) =>
                                        this.handleRemoveVarient(e, i)
                                      }
                                      style={{
                                        float: "right",
                                        cursor: "pointer",
                                        fontWeight: "bold",
                                      }}
                                      className="text-danger"
                                    >
                                      X
                                    </h5>
                                    <datalist id="variantOptions">
                                      <option value="color">Color</option>
                                      <option value="size">Size</option>
                                      <option value="style">Style</option>
                                      <option value="material">Material</option>
                                      <option value="title">Title</option>
                                    </datalist>
                                    <TagsInput
                                      value={values}
                                      onChange={(tags) =>
                                        this.handleVarientTag(tags, i)
                                      }
                                      onlyUnique
                                      addKeys={[9, 13, 32, 188]}
                                    />
                                    <hr></hr>
                                  </div>
                                );
                              })}
                              <div className="text-center">
                                <button
                                  className="btn btn_new btn-primary-outline"
                                  onClick={this.handleAddVarient.bind(this)}
                                >
                                  Add Variant
                                </button>
                              </div>
                            </div>
                            <div className="col-md-8">
                              {this.state.varientArrayCombo.map(
                                (singleVarient, i) => {
                                  const {
                                    varient,
                                    price,
                                    mrp,
                                    height,
                                    width,
                                    length,
                                    cbm,
                                    weight,
                                    quantity,
                                    weightLabel,
                                    sku,
                                  } = singleVarient;

                                  return (
                                    <div key={i} className="form-group">
                                      <div
                                        className="formgroup"
                                        style={{
                                          width: "180px",
                                          float: "left",
                                        }}
                                      >
                                        <label>Varient</label>
                                        <br></br>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={varient}
                                          disabled
                                        />
                                      </div>
                                      <div
                                        className="formgroup"
                                        style={{
                                          width: "100px",
                                          float: "left",
                                        }}
                                      >
                                        <label>Price</label>
                                        <br></br>
                                        <input
                                          type="number"
                                          className="form-control"
                                          value={price}
                                          name="price"
                                          min="0"
                                          placeholder="Price"
                                          onChange={(e) =>
                                            this.handleVarientInput(e, i)
                                          }
                                        />
                                      </div>
                                      {this.state.loggedInUser == "admin" && (
                                        <div
                                          className="formgroup"
                                          style={{
                                            width: "100px",
                                            float: "left",
                                          }}
                                        >
                                          <label>MRP</label>
                                          <br></br>
                                          <input
                                            type="number"
                                            className="form-control"
                                            value={mrp}
                                            name="mrp"
                                            min="0"
                                            placeholder="Mrp"
                                            onChange={(e) =>
                                              this.handleVarientInput(e, i)
                                            }
                                          />
                                        </div>
                                      )}
                                      <div
                                        className="formgroup"
                                        style={{
                                          width: "100px",
                                          float: "left",
                                        }}
                                      >
                                        <label>Quantity</label>
                                        <br></br>
                                        <input
                                          type="number"
                                          className="form-control"
                                          value={quantity}
                                          name="quantity"
                                          min="0"
                                          placeholder="Quantity"
                                          onChange={(e) =>
                                            this.handleVarientInput(e, i)
                                          }
                                        />
                                      </div>
                                      <div
                                        className="formgroup"
                                        style={{ width: "90px", float: "left" }}
                                      >
                                        <label>SKU</label>
                                        <br></br>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={sku}
                                          name="sku"
                                          placeholder="SKU"
                                          onChange={(e) =>
                                            this.handleVarientInput(e, i)
                                          }
                                          autoComplete="off"
                                        />
                                      </div>
                                      <div
                                        className="formgroup"
                                        style={{ width: "90px", float: "left" }}
                                      >
                                        <label>CBM</label>
                                        <br></br>
                                        <input
                                          type="number"
                                          className="form-control"
                                          value={cbm}
                                          name="cbm"
                                          min="0"
                                          placeholder="CBM"
                                          onChange={(e) =>
                                            this.handleVarientInput(e, i)
                                          }
                                        />
                                      </div>
                                      <div
                                        className="formgroup"
                                        style={{
                                          width: "120px",
                                          float: "left",
                                        }}
                                      >
                                        <label>Length (In CM)</label>
                                        <br></br>
                                        <input
                                          type="number"
                                          className="form-control"
                                          value={length}
                                          name="length"
                                          min="0" /*  */
                                          placeholder="Length"
                                          onChange={(e) =>
                                            this.handleVarientInput(e, i)
                                          }
                                        />
                                      </div>{" "}
                                      <div
                                        className="formgroup"
                                        style={{
                                          width: "120px",
                                          float: "left",
                                        }}
                                      >
                                        <label>Breadth (In CM)</label>
                                        <br></br>
                                        <input
                                          type="number"
                                          className="form-control"
                                          value={width}
                                          name="width"
                                          min="0"
                                          placeholder="Width"
                                          onChange={(e) =>
                                            this.handleVarientInput(e, i)
                                          }
                                        />
                                      </div>{" "}
                                      <div
                                        className="formgroup"
                                        style={{
                                          width: "120px",
                                          float: "left",
                                        }}
                                      >
                                        {" "}
                                        <label>Height (In CM)</label>
                                        <br></br>
                                        <input
                                          type="number"
                                          className="form-control"
                                          value={height}
                                          name="height"
                                          min="0"
                                          placeholder="Height"
                                          onChange={(e) =>
                                            this.handleVarientInput(e, i)
                                          }
                                        />
                                      </div>{" "}
                                      <div
                                        className="formgroup"
                                        style={{
                                          width: "100px",
                                          float: "left",
                                        }}
                                      >
                                        <label>Weight</label>
                                        <br></br>
                                        <input
                                          type="number"
                                          className="form-control"
                                          value={weight}
                                          name="weight"
                                          min="0"
                                          placeholder="Weight"
                                          onChange={(e) =>
                                            this.handleVarientInput(e, i)
                                          }
                                        />
                                      </div>
                                      <div
                                        className="formgroup"
                                        style={{
                                          width: "100px",
                                          float: "left",
                                        }}
                                      >
                                        <label>Measure</label>
                                        <select
                                          className="form-control"
                                          id="weightLabel"
                                          name="weightLabel"
                                          value={weightLabel}
                                          onChange={(e) =>
                                            this.handleVarientInput(e, i)
                                          }
                                        >
                                          <option value="g">g</option>
                                          <option value="lb">lb</option>
                                          <option value="oz">oz</option>
                                          <option value="kg">kg</option>
                                        </select>
                                      </div>
                                      <br></br>
                                      <label
                                        className="btn btn_new"
                                        style={{
                                          marginLeft: "10px",
                                          marginTop: "30px",
                                        }}
                                      >
                                        Choose Images
                                        <input
                                          type="file"
                                          className="hidden"
                                          multiple
                                          accept="image/*"
                                          onChange={(e) =>
                                            this.handleVarientMultipleImages(
                                              e,
                                              i
                                            )
                                          }
                                        />
                                      </label>
                                      <br></br>
                                      <br></br>
                                      <div className="container-fluid">
                                        <div className="row">
                                          {!_.isEmpty(
                                            singleVarient.imagesArray
                                          ) &&
                                            singleVarient.imagesArray.map(
                                              (imageData, imageId) => {
                                                const { image } = imageData;
                                                return (
                                                  <div
                                                    className="col-sm-3 show-image1"
                                                    key={imageId}
                                                  >
                                                    <span
                                                      className="closeButton1"
                                                      onClick={() =>
                                                        this.handleVarientImageDelete(
                                                          i,
                                                          imageId
                                                        )
                                                      }
                                                    >
                                                      <i
                                                        className="fa fa-times"
                                                        aria-hidden="true"
                                                      ></i>
                                                    </span>
                                                    <div className="img-overlay1"></div>
                                                    <img
                                                      src={image}
                                                      alt="uploadImage"
                                                      className="images1"
                                                    />
                                                  </div>
                                                );
                                              }
                                            )}
                                        </div>
                                      </div>
                                      <br></br>
                                      <br></br>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {!this.state.isVarien && (
                      <div>
                        <div
                          className="card"
                          style={{ padding: "2em 2em 0", marginBottom: "0" }}
                        >
                          <div className="container-fluid">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label
                                    htmlFor="idOrSku"
                                    style={{ fontSize: 15 }}
                                  >
                                    ID/SKU&nbsp;
                                    <span
                                      className="custom-tooltip"
                                      data-tooltip="Unique id of the product"
                                    >
                                      <i
                                        className="fa fa-question-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </label>
                                  <input
                                    type="text"
                                    value={this.state.idOrSku}
                                    onChange={this.handleIdOrSku.bind(this)}
                                    className="form-control"
                                    id="idOrSku"
                                    placeholder="Enter Unique Id of Product"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label style={{ fontSize: 15 }}>
                                    <span className="text-danger">*</span> Ex
                                    Factory Price&nbsp;
                                    <span
                                      className="custom-tooltip"
                                      data-tooltip="Selling Price of the Product"
                                    >
                                      <i
                                        className="fa fa-question-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </label>
                                  <div
                                    className="form-control "
                                    style={{
                                      border: "1px solid #ddd",
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <span className="icon-wrapp">
                                      <i className="input-icon fa fa-inr"></i>
                                    </span>
                                    <input
                                      className="input-with-icon"
                                      id="factoryPrice"
                                      type="number"
                                      min="0"
                                      step=".01"
                                      maxLength="10"
                                      value={this.state.factoryPrice}
                                      onChange={this.handleFactoryPrice.bind(
                                        this
                                      )}
                                      style={{ border: "none", width: "48vw" }}
                                      placeholder="Enter Price"
                                    />
                                  </div>
                                  {this.state.errors.factoryPrice && (
                                    <span className="text-danger">
                                      <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {this.state.errors.factoryPrice}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            <br></br>
                            <div className="row">
                              {this.state.loggedInUser == "admin" && (
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label style={{ fontSize: 15 }}>
                                      MRP&nbsp;
                                      <span
                                        className="custom-tooltip"
                                        data-tooltip="Manufacturer calculated Price of the Product"
                                      >
                                        <i
                                          className="fa fa-question-circle"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </label>
                                    <div
                                      className="form-control "
                                      style={{
                                        border: "1px solid #ddd",
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <span className="icon-wrapp">
                                        <i className="input-icon fa fa-inr"></i>
                                      </span>
                                      <input
                                        className="input-with-icon"
                                        id="mrp"
                                        type="number"
                                        min="0"
                                        step=".01"
                                        maxLength="10"
                                        value={this.state.mrp}
                                        onChange={this.handleMrp.bind(this)}
                                        style={{
                                          border: "none",
                                          width: "48vw",
                                        }}
                                        placeholder="Enter MRP"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label htmlFor="quantity">
                                    <span className="text-danger">*</span>{" "}
                                    Quantity&nbsp;
                                    <span
                                      className="custom-tooltip"
                                      data-tooltip="Amount of the Product"
                                    >
                                      <i
                                        className="fa fa-question-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </label>
                                  <input
                                    type="number"
                                    min="0"
                                    step="any"
                                    maxLength="10"
                                    value={this.state.quantity}
                                    onChange={this.handleQuantity.bind(this)}
                                    className="form-control"
                                    id="quantity"
                                    placeholder="Enter Available Quanity of Product"
                                  />
                                  {this.state.errors.quantity && (
                                    <span className="text-danger">
                                      <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {this.state.errors.quantity}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label htmlFor="cbm">
                                    <span className="text-danger">*</span>
                                    CBM&nbsp;
                                    <span
                                      className="custom-tooltip"
                                      data-tooltip="cbm"
                                    >
                                      <i
                                        className="fa fa-question-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </label>
                                  <input
                                    type="number"
                                    min="0"
                                    step="any"
                                    maxLength="10"
                                    value={this.state.cbm}
                                    onChange={this.handleCbm.bind(this)}
                                    className="form-control"
                                    id="cbm"
                                    placeholder="CBM"
                                  />
                                  {this.state.errors.cbm && (
                                    <span className="text-danger">
                                      <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {this.state.errors.cbm}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div
                      className="card"
                      style={{ padding: "0 2em 0", marginBottom: "0" }}
                    >
                      <br></br>
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="selectCategory">
                                <span className="text-danger">*</span> Select
                                Category&nbsp;
                                <span
                                  className="custom-tooltip"
                                  data-tooltip="Items are assigned based on similarity or defined criteria"
                                >
                                  <i
                                    className="fa fa-question-circle"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </label>
                              <select
                                className="form-control"
                                id="selectCategory"
                                onChange={this.handleSelectedCategory.bind(
                                  this
                                )}
                              >
                                <option value="Select Category">
                                  Select Category
                                </option>
                                {this.state.categoryList.map((cat, i) => {
                                  const { category } = cat;
                                  return (
                                    <option
                                      value={category}
                                      key={i}
                                      selected={
                                        this.state.category === category && true
                                      }
                                    >
                                      {category}
                                    </option>
                                  );
                                })}
                              </select>
                              {this.state.errors.category && (
                                <span className="text-danger">
                                  <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {this.state.errors.category}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="container-fluid">
                        <div className="row">
                          {this.state.loggedInUser == "admin" && (
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="hsn">
                                  <span className="text-danger">*</span>{" "}
                                  HSN&nbsp;
                                  <span
                                    className="custom-tooltip"
                                    data-tooltip="standardized numerical method of classifying traded product"
                                  >
                                    <i
                                      className="fa fa-question-circle"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  value={this.state.hsn}
                                  onChange={this.handleHsn.bind(this)}
                                  className="form-control"
                                  id="hsn"
                                  placeholder="Enter HSN of Product"
                                  autoComplete="off"
                                />
                                {this.state.errors.hsn && (
                                  <span className="text-danger">
                                    <i
                                      className="fa fa-exclamation-circle"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    {this.state.errors.hsn}
                                  </span>
                                )}
                              </div>
                            </div>
                          )}

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="warranty">
                                Warranty&nbsp;
                                <span
                                  className="custom-tooltip"
                                  data-tooltip="Written statement, which promises to repair or replace if product does not work"
                                >
                                  <i
                                    className="fa fa-question-circle"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </label>
                              <input
                                type="text"
                                value={this.state.warranty}
                                onChange={this.handleWarrenty.bind(this)}
                                className="form-control"
                                id="warranty"
                                placeholder="Enter Available warranty of Product"
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <br></br>
                      {!this.state.isVarien && (
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="">
                                <div className="row">
                                  <div className="col-md-9 col-sm-6">
                                    <div className="form-group">
                                      <label htmlFor="weight">
                                        <span className="text-danger">*</span>{" "}
                                        Weight&nbsp;
                                        <span
                                          className="custom-tooltip"
                                          data-tooltip="The amount or quantity of heaviness"
                                        >
                                          <i
                                            className="fa fa-question-circle"
                                            aria-hidden="true"
                                          ></i>
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        value={this.state.weight}
                                        onChange={this.handleWeight.bind(this)}
                                        className="form-control"
                                        id="weight"
                                        placeholder="Enter Weight of Product in Grams"
                                        autoComplete="off"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3 col-sm-6">
                                    <label htmlFor="measure">Measure</label>
                                    <select
                                      className="form-control"
                                      id="measure"
                                      onChange={this.handleWeightMeasure.bind(
                                        this
                                      )}
                                    >
                                      <option
                                        value="g"
                                        selected={
                                          this.state.weightLabel === "g" && true
                                        }
                                      >
                                        g
                                      </option>
                                      <option
                                        value="lb"
                                        selected={
                                          this.state.weightLabel === "lb" &&
                                          true
                                        }
                                      >
                                        lb
                                      </option>
                                      <option
                                        value="oz"
                                        selected={
                                          this.state.weightLabel === "oz" &&
                                          true
                                        }
                                      >
                                        oz
                                      </option>
                                      <option
                                        value="kg"
                                        selected={
                                          this.state.weightLabel === "kg" &&
                                          true
                                        }
                                      >
                                        kg
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                {this.state.errors.weight && (
                                  <span className="text-danger">
                                    <i
                                      className="fa fa-exclamation-circle"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    {this.state.errors.weight}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="container-fluid">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="form-group">
                                    <label htmlFor="length">
                                    Length (In CM)&nbsp;
                                    <span
                                      className="custom-tooltip"
                                      data-tooltip="Length of the product"
                                    >
                                      <i
                                        className="fa fa-question-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </label>
                                  <input
                                    type="number"
                                    value={this.state.length}
                                    onChange={this.handleLength.bind(this)}
                                    className="form-control"
                                    id="length"
                                    min="0"
                                    placeholder="Length"
                                    autoComplete="off"
                                  />
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label htmlFor="width">
                                        Breadth (In CM)&nbsp;
                                        <span
                                          className="custom-tooltip"
                                          data-tooltip="Width of the product"
                                        >
                                          <i
                                            className="fa fa-question-circle"
                                            aria-hidden="true"
                                          ></i>
                                        </span>
                                      </label>
                                      <input
                                        type="number"
                                        value={this.state.width}
                                        onChange={this.handleWidth.bind(this)}
                                        className="form-control"
                                        id="width"
                                        min="0"
                                        placeholder="Width"
                                        autoComplete="off"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form-group">
                                    <label htmlFor="height">
                                    Height (In CM)&nbsp;
                                    <span
                                      className="custom-tooltip"
                                      data-tooltip="Height of the product"
                                    >
                                      <i
                                        className="fa fa-question-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </label>
                                  <input
                                    type="number"
                                    value={this.state.height}
                                    onChange={this.handleHeight.bind(this)}
                                    className="form-control"
                                    id="height"
                                    min="0"
                                    placeholder="Height"
                                    autoComplete="off"
                                  />
                                     
                                    </div>
                                  </div>
                                </div>
                                {this.state.errors.hwlError && (
                                  <span className="text-danger">
                                    <i
                                      className="fa fa-exclamation-circle"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    {this.state.errors.hwlError}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.loggedInUser == "admin" && (
                        <div>
                          <br></br>
                          <div className="container-fluid">
                            <div className="row">
                              <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                <div className="form-group">
                                  <label htmlFor="bcd">
                                    BCD&nbsp;
                                    <span
                                      className="custom-tooltip"
                                      data-tooltip="BCD"
                                    >
                                      <i
                                        className="fa fa-question-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </label>
                                  <input
                                    type="number"
                                    value={this.state.bcd}
                                    onChange={this.handleBcd.bind(this)}
                                    className="form-control"
                                    id="bcd"
                                    min="0"
                                    placeholder="BCD"
                                    autoComplete="off"
                                  />
                                </div>
                                {this.state.errors.bcd && (
                                  <span className="text-danger">
                                    <i
                                      className="fa fa-exclamation-circle"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    {this.state.errors.bcd}
                                  </span>
                                )}
                              </div>
                              <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                <div className="form-group">
                                  <label htmlFor="igst">
                                    IGST&nbsp;
                                    <span
                                      className="custom-tooltip"
                                      data-tooltip="IGST"
                                    >
                                      <i
                                        className="fa fa-question-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </label>
                                  <input
                                    type="number"
                                    value={this.state.igst}
                                    onChange={this.handleIgst.bind(this)}
                                    className="form-control"
                                    id="igst"
                                    min="0"
                                    placeholder="IGST"
                                    autoComplete="off"
                                  />
                                </div>
                                {this.state.errors.igst && (
                                  <span className="text-danger">
                                    <i
                                      className="fa fa-exclamation-circle"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    {this.state.errors.igst}
                                  </span>
                                )}
                              </div>
                              <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                <div className="form-group">
                                  <label htmlFor="swc">
                                    SWC&nbsp;
                                    <span
                                      className="custom-tooltip"
                                      data-tooltip="SWC"
                                    >
                                      <i
                                        className="fa fa-question-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </label>
                                  <input
                                    type="number"
                                    value={this.state.swc}
                                    onChange={this.handleSwc.bind(this)}
                                    className="form-control"
                                    id="swc"
                                    min="0"
                                    placeholder="SWC"
                                    autoComplete="off"
                                  />
                                </div>
                                {this.state.errors.swc && (
                                  <span className="text-danger">
                                    <i
                                      className="fa fa-exclamation-circle"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    {this.state.errors.swc}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="container-fluid">
                        <h4>
                          Shipping Details&nbsp;
                          <span
                            className="custom-tooltip"
                            data-tooltip="Select shipping detail"
                          >
                            <i
                              className="fa fa-question-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </h4>
                        {this.state.errors.shippingMode && (
                          <span className="text-danger">
                            <i
                              className="fa fa-exclamation-circle"
                              aria-hidden="true"
                            ></i>{" "}
                            {this.state.errors.shippingMode}
                          </span>
                        )}
                        <div className="form-group">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault1"
                              value="freeShipping"
                              checked={
                                this.state.shippingMode === "freeShipping" &&
                                true
                              }
                              onChange={this.handleShipping.bind(this)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexRadioDefault1"
                            >
                              &nbsp;&nbsp;Free ePacket Shipping
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault2"
                              value="standardShipping"
                              checked={
                                this.state.shippingMode ===
                                  "standardShipping" && true
                              }
                              onChange={this.handleShipping.bind(this)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexRadioDefault2"
                            >
                              &nbsp;&nbsp;Standard ePacket Shipping
                            </label>
                          </div>
                        </div>

                        {this.state.shippingMode === "standardShipping" && (
                          <div id="showOnStandardShipping">
                            <div className="">
                              <h5>Shipping Price</h5>
                              <div className="row">
                                <div className="col-sm-4">
                                  <div className="form-group">
                                    <label className="col-form-label">
                                      <span className="text-danger">*</span> USA
                                    </label>
                                    <div
                                      className="form-control"
                                      style={{
                                        border: "1px solid #ddd",
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <span className="icon-wrapp">
                                        <i className="input-icon fa fa-inr"></i>
                                      </span>
                                      <input
                                        className="input-with-icon"
                                        id="usaShippingPrice"
                                        type="number"
                                        min="0"
                                        step=".01"
                                        maxLength="10"
                                        value={this.state.usaShippingPrice}
                                        onChange={this.handleUsaShippingPrice.bind(
                                          this
                                        )}
                                        style={{
                                          border: "none",
                                          width: "100%",
                                        }}
                                        placeholder="Enter Shipping Charges"
                                      />
                                    </div>
                                    {this.state.errors.usaShippingPrice && (
                                      <span className="text-danger">
                                        <i
                                          className="fa fa-exclamation-circle"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        {this.state.errors.usaShippingPrice}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="col-sm-4">
                                  <div className="form-group">
                                    <label className="col-form-label">
                                      <span className="text-danger">*</span>{" "}
                                      Canada
                                    </label>
                                    <div
                                      className="form-control"
                                      style={{
                                        border: "1px solid #ddd",
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <span className="icon-wrapp">
                                        <i className="input-icon fa fa-inr"></i>
                                      </span>
                                      <input
                                        className="input-with-icon"
                                        id="canadaShippingPrice"
                                        type="number"
                                        min="0"
                                        step=".01"
                                        maxLength="10"
                                        value={this.state.canadaShippingPrice}
                                        onChange={this.handleCanadaShippingPrice.bind(
                                          this
                                        )}
                                        style={{
                                          border: "none",
                                          width: "100%",
                                        }}
                                        placeholder="Enter Shipping Charges"
                                      />
                                    </div>
                                    {this.state.errors.canadaShippingPrice && (
                                      <span className="text-danger">
                                        <i
                                          className="fa fa-exclamation-circle"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        {this.state.errors.canadaShippingPrice}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="col-sm-4">
                                  <div className="form-group">
                                    <label className="col-form-label">
                                      <span className="text-danger">*</span>{" "}
                                      United Kingdom
                                    </label>
                                    <div
                                      className="form-control"
                                      style={{
                                        border: "1px solid #ddd",
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <span className="icon-wrapp">
                                        <i className="input-icon fa fa-inr"></i>
                                      </span>
                                      <input
                                        className="input-with-icon"
                                        id="ukShippingPrice"
                                        type="number"
                                        min="0"
                                        step=".01"
                                        maxLength="10"
                                        value={this.state.ukShippingPrice}
                                        onChange={this.handleUkShippingPrice.bind(
                                          this
                                        )}
                                        style={{
                                          border: "none",
                                          width: "100%",
                                        }}
                                        placeholder="Enter Shipping Charges"
                                      />
                                    </div>
                                    {this.state.errors.ukShippingPrice && (
                                      <span className="text-danger">
                                        <i
                                          className="fa fa-exclamation-circle"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        {this.state.errors.ukShippingPrice}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <br></br>
                              <div className="row">
                                <div className="col-sm-4">
                                  <div className="form-group">
                                    <label className="col-form-label">
                                      <span className="text-danger">*</span>{" "}
                                      Australia
                                    </label>
                                    <div
                                      className="form-control"
                                      style={{
                                        border: "1px solid #ddd",
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <span className="icon-wrapp">
                                        <i className="input-icon fa fa-inr"></i>
                                      </span>
                                      <input
                                        className="input-with-icon"
                                        id="ausShippingPrice"
                                        type="number"
                                        min="0"
                                        step=".01"
                                        maxLength="10"
                                        value={this.state.ausShippingPrice}
                                        onChange={this.handleAusShippingPrice.bind(
                                          this
                                        )}
                                        style={{
                                          border: "none",
                                          width: "100%",
                                        }}
                                        placeholder="Enter Shipping Charges"
                                      />
                                    </div>
                                    {this.state.errors.ausShippingPrice && (
                                      <span className="text-danger">
                                        <i
                                          className="fa fa-exclamation-circle"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        {this.state.errors.ausShippingPrice}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="col-sm-4">
                                  <div className="form-group">
                                    <label className="col-form-label">
                                      <span className="text-danger">*</span>{" "}
                                      International
                                    </label>
                                    <div
                                      className="form-control"
                                      style={{
                                        border: "1px solid #ddd",
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <span className="icon-wrapp">
                                        <i className="input-icon fa fa-inr"></i>
                                      </span>
                                      <input
                                        className="input-with-icon"
                                        id="interShippingPrice"
                                        type="number"
                                        min="0"
                                        step=".01"
                                        maxLength="10"
                                        value={this.state.interShippingPrice}
                                        onChange={this.handleInterShippingPrice.bind(
                                          this
                                        )}
                                        style={{
                                          border: "none",
                                          width: "100%",
                                        }}
                                        placeholder="Enter Shipping Charges"
                                      />
                                    </div>
                                    {this.state.errors.interShippingPrice && (
                                      <span className="text-danger">
                                        <i
                                          className="fa fa-exclamation-circle"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        {this.state.errors.interShippingPrice}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        <br></br>
                        {this.state.loggedInUser == "admin" && (
                          <div>
                            <h4>
                              Select Status&nbsp;
                              <span
                                className="custom-tooltip"
                                data-tooltip="Select product status"
                              >
                                <i
                                  className="fa fa-question-circle"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </h4>
                            {this.state.errors.productStatus && (
                              <span className="text-danger">
                                <i
                                  className="fa fa-exclamation-circle"
                                  aria-hidden="true"
                                ></i>{" "}
                                {this.state.errors.productStatus}
                              </span>
                            )}
                            <div className="form-group">
                              <label>Product Status</label>
                              <div className="form-check form-check-inline">
                                &emsp;&emsp;
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="pdStatus"
                                  id="pdStatus"
                                  value="active"
                                  checked={this.state.pdStatus === "1" && true}
                                  onChange={this.handlePdStatus.bind(this)}
                                />
                                <label
                                  className="form-check-label"
                                  for="pdStatus"
                                >
                                  &nbsp;&nbsp;Active
                                </label>
                                &emsp;&emsp;
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="pdStatus"
                                  id="pdStatus1"
                                  value="inActive"
                                  checked={this.state.pdStatus === "0" && true}
                                  onChange={this.handlePdStatus.bind(this)}
                                />
                                <label
                                  className="form-check-label"
                                  for="pdStatus1"
                                >
                                  &nbsp;&nbsp;In Active
                                </label>
                              </div>

                              <label>Display Status</label>
                              <div className="form-check form-check-inline">
                                &emsp;&emsp;
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="displayStatus"
                                  id="isDisplay"
                                  value="active"
                                  checked={this.state.isDisplay === "1" && true}
                                  onChange={this.handleDisplayStatus.bind(this)}
                                />
                                <label
                                  className="form-check-label"
                                  for="isDisplay"
                                >
                                  &nbsp;&nbsp;Active
                                </label>
                                &emsp;&emsp;
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="displayStatus"
                                  id="isDisplay1"
                                  value="inActive"
                                  checked={this.state.isDisplay === "0" && true}
                                  onChange={this.handleDisplayStatus.bind(this)}
                                />
                                <label
                                  className="form-check-label"
                                  for="isDisplay1"
                                >
                                  &nbsp;&nbsp;In Active
                                </label>
                              </div>
                            </div>

                            <br></br>
                            <div className="continer-fluid">
                              <h4>
                                Add Sub Supplier&nbsp;
                                <span
                                  className="custom-tooltip"
                                  data-tooltip="Add sub supplier to the product"
                                >
                                  <i
                                    className="fa fa-question-circle"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </h4>
                              <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                  <select
                                    id="subSupplier"
                                    className="form-control"
                                    onChange={this.handleOnchangeSubSupplier.bind(
                                      this
                                    )}
                                  >
                                    <option value="Select Supplier">
                                      Select Supplier
                                    </option>
                                    {this.state.supplierList.map((supplier) => {
                                      const { supplier_id, _id } = supplier;
                                      return (
                                        <option
                                          value={_id}
                                          selected={
                                            this.state.selectedSupplier ===
                                              supplier_id && true
                                          }
                                        >
                                          {supplier_id}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-2 col-xl-2">
                                  <input
                                    type="number"
                                    placeholder="Enter priority"
                                    min="0"
                                    max="100"
                                    className="form-control"
                                    value={this.state.priority}
                                    onChange={(e) =>
                                      this.setState({
                                        priority: e.target.value,
                                        errors: {},
                                      })
                                    }
                                  ></input>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-2 col-xl-2">
                                  <button
                                    className="btn btn_new btn-outline-primary"
                                    onClick={this.handleSubSupplier}
                                  >
                                    {this.state.editMode ? "Update" : "Add"}
                                  </button>
                                </div>
                                <br></br>
                                <br></br>
                                {this.state.errors.subSupplierError && (
                                  <span className="text-danger">
                                    <i
                                      className="fa fa-exclamation-circle"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    {this.state.errors.subSupplierError}
                                  </span>
                                )}
                              </div>
                              <br></br>
                              <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                  {!_.isEmpty(this.state.subSupplierList) && (
                                    <table className="table table-hover">
                                      <thead>
                                        <th>Supplier Name</th>
                                        <th>Priority</th>
                                        <th>Action</th>
                                      </thead>
                                      <tbody>
                                        {this.state.subSupplierList.map(
                                          (supplier, index) => {
                                            const {
                                              priority,
                                              sid,
                                              supplierName,
                                            } = supplier;
                                            return (
                                              <tr>
                                                <td>{supplierName}</td>
                                                <td>{priority}</td>
                                                <td colSpan="2">
                                                  <span
                                                    onClick={() =>
                                                      this.handleSubSupplierEdit(
                                                        sid,
                                                        index
                                                      )
                                                    }
                                                  >
                                                    <i
                                                      className="fa fa-pencil"
                                                      aria-hidden="true"
                                                    ></i>
                                                  </span>
                                                  &emsp;
                                                  <span
                                                    onClick={() =>
                                                      this.handleSubSupplierDelete(
                                                        sid
                                                      )
                                                    }
                                                  >
                                                    <i
                                                      className="fa fa-trash"
                                                      aria-hidden="true"
                                                    ></i>
                                                  </span>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                  )}
                                </div>
                              </div>
                            </div>
                            <br></br>
                          </div>
                        )}
                      </div>
                      {
                        <div>
                          <div
                            className="form-group"
                            style={{ paddingLeft: "15px" }}
                          >
                            <label>Is Battery</label>
                            <div className="form-check form-check-inline">
                              &emsp;&emsp;
                              <input
                                className="form-check-input"
                                type="radio"
                                name="isBattery"
                                id="isBattery"
                                value="1"
                                checked={this.state.isBattery === "1" && true}
                                onChange={this.handleIsBattery.bind(this)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="isBattery"
                              >
                                &nbsp;&nbsp;Yes
                              </label>
                              &emsp;&emsp;
                              <input
                                className="form-check-input"
                                type="radio"
                                name="isBattery"
                                id="isBattery1"
                                value="0"
                                checked={this.state.isBattery === "0" && true}
                                onChange={this.handleIsBattery.bind(this)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="isBattery1"
                              >
                                &nbsp;&nbsp;No
                              </label>
                            </div>

                            <label>Liquied</label>
                            <div className="form-check form-check-inline">
                              &emsp;&emsp;
                              <input
                                className="form-check-input"
                                type="radio"
                                name="liquidStatus"
                                id="isLiquid"
                                value="1"
                                checked={this.state.isLiquid === "1" && true}
                                onChange={this.handleIsLiquid.bind(this)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="isLiquid"
                              >
                                &nbsp;&nbsp;Yes
                              </label>
                              &emsp;&emsp;
                              <input
                                className="form-check-input"
                                type="radio"
                                name="liquidStatus"
                                id="isLiquid1"
                                value="0"
                                checked={this.state.isLiquid === "0" && true}
                                onChange={this.handleIsLiquid.bind(this)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="isLiquid1"
                              >
                                &nbsp;&nbsp;No
                              </label>
                            </div>

                            <label>Channel</label>
                            <div className="form-check form-check-inline">
                              &emsp;&emsp;
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="dropshipper"
                                id="dropshipper"
                                value="ds"
                                disabled={
                                  this.state.channel.includes("b2c")
                                    ? true
                                    : false
                                }
                                defaultChecked={
                                  this.state.channel.includes("ds")
                                    ? true
                                    : false
                                }
                                onChange={this.handlechannel.bind(this)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="channel"
                              >
                                &nbsp;&nbsp;Dropshipper
                              </label>
                              &emsp;&emsp;
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="b2c"
                                id="b2c"
                                value="b2c"
                                defaultChecked={
                                  this.state.channel.includes("b2c")
                                    ? true
                                    : false
                                }
                                onChange={this.handlechannel.bind(this)}
                              />
                              <label className="form-check-label" htmlFor="b2c">
                                &nbsp;&nbsp;B2C
                              </label>
                              &emsp;&emsp;
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="b2b"
                                id="b2b"
                                value="b2b"
                                disabled={
                                  this.state.channel.includes("b2c")
                                    ? true
                                    : false
                                }
                                defaultChecked={
                                  this.state.channel.includes("b2b")
                                    ? true
                                    : false
                                }
                                onChange={this.handlechannel.bind(this)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="global"
                              >
                                &nbsp;&nbsp;B2B
                              </label>
                              &emsp;&emsp;
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="distributor"
                                id="distributor"
                                disabled={
                                  this.state.channel.includes("b2c")
                                    ? true
                                    : false
                                }
                                defaultChecked={
                                  this.state.channel.includes("distributor")
                                    ? true
                                    : false
                                }
                                value="distributor"
                                onChange={this.handlechannel.bind(this)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="distributor"
                              >
                                &nbsp;&nbsp;Distributor
                              </label>
                            </div>
                          </div>
                          <br></br>
                        </div>
                      }
                      {
                        <div>
                          <div className="card">
                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label
                                      htmlFor="numberOfItems"
                                      style={{ fontSize: 15 }}
                                    >
                                      Number of items&nbsp;
                                      <span
                                        className="custom-tooltip"
                                        data-tooltip="Number of itmes"
                                      >
                                        <i
                                          className="fa fa-question-circle"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      value={this.state.numberOfItems}
                                      onChange={this.handleNumberOfItems.bind(
                                        this
                                      )}
                                      className="form-control"
                                      id="numberOfItems"
                                      placeholder="Number of itmes"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label style={{ fontSize: 15 }}>
                                      Package Includes&nbsp;
                                      <span
                                        className="custom-tooltip"
                                        data-tooltip="Package Includes"
                                      >
                                        <i
                                          className="fa fa-question-circle"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </label>
                                    <div
                                      className="form-control "
                                      style={{
                                        border: "1px solid #ddd",
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <input
                                        className="input-with-icon"
                                        id="packageIncludes"
                                        type="text"
                                        min="0"
                                        step=".01"
                                        value={this.state.packageIncludes}
                                        onChange={this.handlePackageIncludes.bind(
                                          this
                                        )}
                                        style={{
                                          border: "none",
                                          width: "48vw",
                                        }}
                                        placeholder="Package Includes"
                                      />
                                    </div>
                                    {this.state.errors.packageIncludes && (
                                      <span className="text-danger">
                                        <i
                                          className="fa fa-exclamation-circle"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        {this.state.errors.packageIncludes}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      }

                      {
                        <div>
                          <div className="card">
                            <div className="container-fluid">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label
                                      htmlFor="yearOfManufacturer"
                                      style={{ fontSize: 15 }}
                                    >
                                      Year of Manufacturer&nbsp;
                                      <span
                                        className="custom-tooltip"
                                        data-tooltip="Year of Manufacturer"
                                      >
                                        <i
                                          className="fa fa-question-circle"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      value={this.state.yearOfManufacturer}
                                      onChange={this.handleYearOfManufacturer.bind(
                                        this
                                      )}
                                      className="form-control"
                                      id="yearOfManufacturer"
                                      placeholder="Year of Manufacturer"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label style={{ fontSize: 15 }}>
                                      Expiry &nbsp;
                                      <span
                                        className="custom-tooltip"
                                        data-tooltip="Product Expiry"
                                      >
                                        <i
                                          className="fa fa-question-circle"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </label>
                                    <div
                                      className="form-control "
                                      style={{
                                        border: "1px solid #ddd",
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <input
                                        className="input-with-icon"
                                        id="productExpiry"
                                        type="text"
                                        min="0"
                                        step=".01"
                                        maxLength="10"
                                        value={this.state.productExpiry}
                                        onChange={this.handleProductExpiry.bind(
                                          this
                                        )}
                                        style={{
                                          border: "none",
                                          width: "48vw",
                                        }}
                                        placeholder="Product Expiry"
                                      />
                                    </div>
                                    {this.state.errors.productExpiry && (
                                      <span className="text-danger">
                                        <i
                                          className="fa fa-exclamation-circle"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        {this.state.errors.productExpiry}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>

                  <div id="amazonTemplateForm" style={{ display: "none" }}>
                    <div className="card" style={{ padding: "3em" }}>
                      <div className="form-group">
                        <label style={{ fontSize: 15 }}>
                          ASIN &nbsp;
                          <span
                            className="custom-tooltip"
                            data-tooltip="hosts products of individual sellers on the same domain"
                          >
                            <i
                              className="fa fa-question-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </label>
                        <br></br>
                        <input
                          type="text"
                          placeholder="ASIN"
                          className="form-control"
                          value={this.state.marketPlaceUrl}
                          onChange={this.handleMarketPlaceUrl.bind(this)}
                        />
                        {this.state.errors.marketPlaceUrl && (
                          <span className="text-danger">
                            <i
                              className="fa fa-exclamation-circle"
                              aria-hidden="true"
                            ></i>{" "}
                            {this.state.errors.marketPlaceUrl}
                          </span>
                        )}
                      </div>
                      <br></br>

                      <div className="container-fluid bullet-container">
                        {this.state.bulletpoints &&
                        this.state.bulletpoints.length
                          ? this.state.bulletpoints.map((field, index) => {
                              return (
                                <div className="row" key={index.toString()}>
                                  <div
                                    className="col-md-8"
                                    style={{ padding: "inherit" }}
                                  >
                                    <div className="form-group">
                                      <label style={{ fontSize: 15 }}>
                                        Bullet Points. &nbsp;
                                        <span
                                          className="custom-tooltip"
                                          data-tooltip="Bullet Points"
                                        >
                                          <i
                                            className="fa fa-question-circle"
                                            aria-hidden="true"
                                          ></i>
                                        </span>
                                      </label>
                                      <br></br>
                                      <input
                                        type="text"
                                        placeholder="Bullet Points"
                                        name="bulletpoints[]"
                                        defaultValue={field}
                                        className="form-control bullet-points-input"
                                      />
                                    </div>
                                  </div>

                                  <div
                                    className="col-md-4"
                                    style={{ marginTop: "25px" }}
                                  >
                                    {index == 0 ? (
                                      <div className="form-group button">
                                        <button className="form-control add-new-bullet">
                                          Add New Bullet
                                        </button>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              );
                            })
                          : [...Array(5).keys()].map((el, index) => {
                              return (
                                <div className="row" key={index.toString()}>
                                  <div
                                    className="col-md-8"
                                    style={{ padding: "inherit" }}
                                  >
                                    <div className="form-group">
                                      <label style={{ fontSize: 15 }}>
                                        Bullet Points. &nbsp;
                                        <span
                                          className="custom-tooltip"
                                          data-tooltip="Bullet Points"
                                        >
                                          <i
                                            className="fa fa-question-circle"
                                            aria-hidden="true"
                                          ></i>
                                        </span>
                                      </label>
                                      <br></br>
                                      <input
                                        type="text"
                                        placeholder="Bullet Points"
                                        name="bulletpoints[]"
                                        className="form-control bullet-points-input"
                                      />
                                    </div>
                                  </div>

                                  <div
                                    className="col-md-4"
                                    style={{ marginTop: "25px" }}
                                  >
                                    {el == 0 ? (
                                      <div className="form-group button">
                                        <button className="form-control add-new-bullet">
                                          Add New Bullet
                                        </button>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                      </div>

                      <br></br>
                      <div className="container-fluid">
                        <div className="row">
                          <div
                            className="col-md-12"
                            style={{ padding: "inherit" }}
                          >
                            <div className="form-group">
                              <label style={{ fontSize: 15 }}>
                                Select Category. &nbsp;
                                <span
                                  className="custom-tooltip"
                                  data-tooltip="Select Category"
                                >
                                  <i
                                    className="fa fa-question-circle"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </label>
                              <br></br>
                              {this.state.browseNode ? (
                                <div
                                  className="col-sm-3 col-md-3 col-lg-3"
                                  style={{ marginBottom: "10px" }}
                                >
                                  Browse Nodes -{" "}
                                  <label data-index="0">
                                    {this.state.browseNode}
                                  </label>
                                </div>
                              ) : (
                                ""
                              )}

                              <Autocomplete
                                id="browse-nodes"
                                open={this.state.open}
                                onOpen={() => {
                                  this.setState({
                                    open: true,
                                  });
                                }}
                                onInput={this.changeCatData.bind(this)}
                                onClose={() => {
                                  this.setState({
                                    open: false,
                                  });
                                }}
                                getOptionSelected={(option, value) =>
                                  option.path === value.path
                                }
                                onChange={this.onBrowseNodeSelect.bind(this)}
                                getOptionLabel={(option) => option.path}
                                options={this.state.options}
                                loading={this.state.loading}
                                value={this.state.browseNode}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label=""
                                    variant="outlined"
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <React.Fragment>
                                          {this.state.loading ? (
                                            <CircularProgress
                                              color="inherit"
                                              size={20}
                                            />
                                          ) : null}
                                          {params.InputProps.endAdornment}
                                        </React.Fragment>
                                      ),
                                    }}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <br></br>
                      <div className="container-fluid custom-field-container">
                        {this.state.customfields &&
                        this.state.customfields.length ? (
                          this.state.customfields.map((field, index) => {
                            return (
                              <div className="row">
                                <div
                                  className="col-md-9"
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <div className="form-group">
                                    {index === 0 ? (
                                      <label
                                        style={{ fontSize: 15 }}
                                        className="custom-field"
                                      >
                                        Custom Field &nbsp;
                                        <span
                                          className="custom-tooltip"
                                          data-tooltip="Custom Field"
                                        >
                                          <i
                                            className="fa fa-question-circle"
                                            aria-hidden="true"
                                          ></i>
                                        </span>
                                      </label>
                                    ) : (
                                      ""
                                    )}
                                    <br></br>
                                    <div className="row">
                                      <div className="col-md-6">
                                        <input
                                          type="text"
                                          placeholder="Field Name"
                                          name="customfieldkeys[]"
                                          value={Object.keys(field)[0]}
                                          className="form-control custom-field-keys-input"
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <input
                                          type="text"
                                          placeholder="Field Value"
                                          name="customfieldvalues[]"
                                          value={Object.values(field)[0]}
                                          className="form-control custom-field-values-input"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {index === 0 ? (
                                  <div
                                    className="col-md-3"
                                    style={{ marginTop: "25px" }}
                                  >
                                    <div className="form-group button">
                                      <button className="form-control add-new-field">
                                        Add More Fields
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            );
                          })
                        ) : (
                          <div className="row">
                            <div
                              className="col-md-9"
                              style={{ paddingLeft: "0px" }}
                            >
                              <div className="form-group">
                                <label
                                  style={{ fontSize: 15 }}
                                  className="custom-field"
                                >
                                  Custom Field &nbsp;
                                  <span
                                    className="custom-tooltip"
                                    data-tooltip="Custom Field"
                                  >
                                    <i
                                      className="fa fa-question-circle"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </label>
                                <br></br>
                                <div className="row">
                                  <div className="col-md-6">
                                    <input
                                      type="text"
                                      placeholder="Field Name"
                                      name="customfieldkeys[]"
                                      className="form-control custom-field-keys-input"
                                    />
                                  </div>
                                  <div className="col-md-6">
                                    <input
                                      type="text"
                                      placeholder="Field Value"
                                      name="customfieldvalues[]"
                                      className="form-control custom-field-values-input"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className="col-md-3"
                              style={{ marginTop: "25px" }}
                            >
                              <div className="form-group button">
                                <button className="form-control add-new-field">
                                  Add More Fields
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div style={{ marginBottom: "30px" }}>
                    <div>
                      <CustomButton
                        fill
                        type="submit"
                        id="updateProductBtn"
                        className="btn_new"
                        onClick={this.updateProduct}
                      >
                        Update Product
                      </CustomButton>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="container mt-3 my-5">
                <h4 className="text-center mt-5">Loading....</h4>
              </div>
            )}
          </div>
        </div>
      );
    }
  }
}
